/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvoiceItem,
    InvoiceItemFromJSON,
    InvoiceItemToJSON,
    InvoiceItemRequest,
    InvoiceItemRequestFromJSON,
    InvoiceItemRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsGetRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDeleteRequest {
    sessionId: string;
    invoiceId: string;
    invoiceItemId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGetRequest {
    sessionId: string;
    invoiceId: string;
    invoiceItemId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsPutRequest {
    sessionId: string;
    invoiceId: string;
    invoiceItemRequest?: InvoiceItemRequest;
}

/**
 * 
 */
export class InvoiceItemsApi extends runtime.BaseAPI {

    /**
     * Gets a list of invoiceItems for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsGetRequest): Promise<runtime.ApiResponse<Array<InvoiceItem>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/items`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceItemFromJSON));
    }

    /**
     * Gets a list of invoiceItems for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsGetRequest): Promise<Array<InvoiceItem>> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdItemsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an invoiceItem
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDelete.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDelete.');
        }

        if (requestParameters.invoiceItemId === null || requestParameters.invoiceItemId === undefined) {
            throw new runtime.RequiredError('invoiceItemId','Required parameter requestParameters.invoiceItemId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/items/{invoiceItemId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoiceItemId"}}`, encodeURIComponent(String(requestParameters.invoiceItemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an invoiceItem
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDelete(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDeleteRaw(requestParameters);
    }

    /**
     * Gets an invoiceItem by session id, invoice id and invoiceItem id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGetRequest): Promise<runtime.ApiResponse<InvoiceItem>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGet.');
        }

        if (requestParameters.invoiceItemId === null || requestParameters.invoiceItemId === undefined) {
            throw new runtime.RequiredError('invoiceItemId','Required parameter requestParameters.invoiceItemId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/items/{invoiceItemId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoiceItemId"}}`, encodeURIComponent(String(requestParameters.invoiceItemId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceItemFromJSON(jsonValue));
    }

    /**
     * Gets an invoiceItem by session id, invoice id and invoiceItem id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGetRequest): Promise<InvoiceItem> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts or updates a invoiceItem
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsPutRequest): Promise<runtime.ApiResponse<InvoiceItem>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsPut.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdItemsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/items`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceItemRequestToJSON(requestParameters.invoiceItemRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceItemFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a invoiceItem
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdItemsPut(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsPutRequest): Promise<InvoiceItem> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdItemsPutRaw(requestParameters);
        return await response.value();
    }

}
