import {
  ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePostRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdDeleteRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdExtrasPutRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDeleteRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPutRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDeleteRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsPutRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDeleteRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPutRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDeleteRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPutRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDeleteRequest,
  ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPutRequest,
  ClientCacheSessionsSessionIdInvoicesPutRequest,
  ClientCacheSignaturesImagesPutRequest,
  Invoice,
  InvoiceExtra,
  InvoiceExtraRequest,
  InvoiceExtrasApi,
  InvoiceIdentification,
  InvoiceIdentificationsApi,
  InvoiceItem,
  InvoiceItemsApi,
  InvoicePayment,
  InvoicePaymentsApi,
  InvoicesApi,
  InvoiceSignature,
  InvoiceSignaturesApi,
  InvoiceTax,
  InvoiceTaxesApi,
  InvoiceTaxRequest,
  Session,
  SignatureImage,
  SignatureImageRequest,
  SignatureImagesApi,
} from '@emporos/api-enterprise/src/gen-session';
import {Modal, NotificationAlert, usePrevious} from '@emporos/components';
import {User} from 'oidc-client';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useAlertState} from './AlertStateProvider';
import {useAuthentication} from './AuthenticationProvider';
import {useNetworkAvailable} from './NetworkAvailableProvider';
import {useTransactionsState} from './TransactionsStateProvider';
import {useTransactionsConfig} from './TransactionsConfigProvider';
import {OfflineInvoice, OfflineSession, OfflineSynced} from '../api/common';
import useOpenApiSession from '../api/useOpenApiSession';

export declare type SessionStatus = 'success' | 'unsynced' | 'error';

interface Context {
  syncing: boolean;
  syncSession: () => void;
  sessionStatus: SessionStatus;
}

export const SyncSessionContext = createContext<Context>({
  syncing: false,
  syncSession: async () => console.log(),
  sessionStatus: 'success',
});

export const useSyncSession = (): Context => useContext(SyncSessionContext);

export function SyncSessionProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const online = useNetworkAvailable();
  const {session, setSession} = useTransactionsState();
  const {notification} = useAlertState();

  const {user} = useAuthentication();
  const {run: putSignatureImage} = useOpenApiSession(
    SignatureImagesApi,
    'clientCacheSignaturesImagesPut',
  );
  const {run: putInvoice} = useOpenApiSession(
    InvoicesApi,
    'clientCacheSessionsSessionIdInvoicesPut',
  );
  const {run: postInvoice} = useOpenApiSession(
    InvoicesApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePost',
  );
  const {run: putInvoiceItem} = useOpenApiSession(
    InvoiceItemsApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdItemsPut',
  );
  const {run: putInvoiceIdentification} = useOpenApiSession(
    InvoiceIdentificationsApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPut',
  );
  const {run: putInvoicePayment} = useOpenApiSession(
    InvoicePaymentsApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPut',
  );
  const {run: putInvoiceTax} = useOpenApiSession(
    InvoiceTaxesApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPut',
  );
  const {run: putInvoiceSignature} = useOpenApiSession(
    InvoiceSignaturesApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPut',
  );
  const {run: putInvoiceExtra} = useOpenApiSession(
    InvoiceExtrasApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdExtrasPut',
  );

  const {run: deleteInvoice} = useOpenApiSession(
    InvoicesApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdDelete',
  );
  const {run: deleteInvoiceItem} = useOpenApiSession(
    InvoiceItemsApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDelete',
  );
  const {run: deleteInvoicePayment} = useOpenApiSession(
    InvoicePaymentsApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDelete',
  );
  const {run: deleteInvoiceTax} = useOpenApiSession(
    InvoiceTaxesApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDelete',
  );
  const {run: deleteInvoiceIdentification} = useOpenApiSession(
    InvoiceIdentificationsApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDelete',
  );
  const {run: deleteInvoiceSignature} = useOpenApiSession(
    InvoiceSignaturesApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDelete',
  );

  return (
    <SyncSession
      online={online}
      session={session}
      setSession={setSession}
      user={user}
      notification={notification}
      putInvoice={putInvoice}
      postInvoice={postInvoice}
      putInvoiceItem={putInvoiceItem}
      putInvoiceIdentification={putInvoiceIdentification}
      putInvoicePayment={putInvoicePayment}
      putInvoiceTax={putInvoiceTax}
      putSignatureImage={putSignatureImage}
      putInvoiceSignature={putInvoiceSignature}
      putInvoiceExtra={putInvoiceExtra}
      deleteInvoice={deleteInvoice}
      deleteInvoiceItem={deleteInvoiceItem}
      deleteInvoicePayment={deleteInvoicePayment}
      deleteInvoiceIdentification={deleteInvoiceIdentification}
      deleteInvoiceTax={deleteInvoiceTax}
      deleteInvoiceSignature={deleteInvoiceSignature}
    >
      {children}
    </SyncSession>
  );
}

interface SyncSessionProps {
  children: JSX.Element;
  online: boolean;
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  user: User | null;
  notification: (alert: Omit<NotificationAlert, 'id'>) => void;
  putInvoice: (
    request: ClientCacheSessionsSessionIdInvoicesPutRequest,
  ) => Promise<Invoice>;
  postInvoice: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePostRequest,
  ) => Promise<Invoice>;
  putInvoiceItem: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsPutRequest,
  ) => Promise<InvoiceItem>;
  putInvoiceIdentification: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPutRequest,
  ) => Promise<InvoiceIdentification>;
  putInvoicePayment: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPutRequest,
  ) => Promise<InvoicePayment>;
  putInvoiceTax: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPutRequest,
  ) => Promise<InvoiceTax>;
  putSignatureImage: (
    request: ClientCacheSignaturesImagesPutRequest,
  ) => Promise<SignatureImage>;
  putInvoiceSignature: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPutRequest,
  ) => Promise<InvoiceSignature>;
  putInvoiceExtra: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdExtrasPutRequest,
  ) => Promise<InvoiceExtra>;
  deleteInvoice: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdDeleteRequest,
  ) => Promise<void>;
  deleteInvoiceItem: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdItemsInvoiceItemIdDeleteRequest,
  ) => Promise<void>;
  deleteInvoicePayment: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDeleteRequest,
  ) => Promise<void>;
  deleteInvoiceIdentification: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDeleteRequest,
  ) => Promise<void>;
  deleteInvoiceTax: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDeleteRequest,
  ) => Promise<void>;
  deleteInvoiceSignature: (
    request: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDeleteRequest,
  ) => Promise<void>;
}

export function SyncSession({
  children,
  online,
  session,
  setSession,
  user,
  notification,
  putInvoice,
  postInvoice,
  putInvoiceItem,
  putInvoiceIdentification,
  putInvoicePayment,
  putInvoiceTax,
  putSignatureImage,
  putInvoiceSignature,
  putInvoiceExtra,
  deleteInvoice,
  deleteInvoiceItem,
  deleteInvoicePayment,
  deleteInvoiceIdentification,
  deleteInvoiceTax,
  deleteInvoiceSignature,
}: SyncSessionProps): JSX.Element {
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>('success');
  const {sessionId, invoices} = session;
  const [showModal, setShowModal] = useState(false);
  const [throwNotification, setThrowNotification] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const {loadUserSession} = useTransactionsConfig();

  const responseUpdates: {
    [invoiceItemId: string]: (
      | Partial<Session>
      | Partial<Invoice>
      | Partial<InvoiceItem>
      | Partial<InvoiceIdentification>
      | Partial<SignatureImage>
      | Partial<InvoicePayment>
      | Partial<InvoiceSignature>
      | Partial<InvoiceExtra>
      | Partial<InvoiceTax>
    ) &
      OfflineSynced;
  } = {};

  const syncEntity = async <
    T,
    PutParams,
    PutResult extends
      | Session
      | Invoice
      | InvoiceItem
      | InvoicePayment
      | InvoiceSignature
      | InvoiceExtra
      | InvoiceIdentification
      | InvoiceTax
      | SignatureImage,
    DeleteParams
  >(
    invoice: Invoice | null,
    entity: T,
    getId: (entity: T) => string,
    runPut: (params: PutParams) => Promise<PutResult>,
    getPutParams: (
      session: Session,
      invoice: Invoice | null,
      entity: T,
    ) => PutParams,
    runDelete?: (params: DeleteParams) => Promise<void>,
    getDeleteParams?: (
      sessionId: string,
      invoiceId: string,
      entityId: string,
    ) => DeleteParams,
  ) => {
    const id = getId(entity);
    if (
      !(entity as OfflineSynced).isSynced ||
      (entity as OfflineSynced).isErrorSynced
    ) {
      if (online) {
        const success = {isSynced: true, isErrorSynced: false};
        const failure = {isSynced: false, isErrorSynced: true};

        if (
          runDelete &&
          getDeleteParams &&
          (entity as OfflineSynced).isDeleted
        ) {
          try {
            await runDelete(
              getDeleteParams(session.sessionId, invoice?.invoiceId || '', id),
            );
            return (responseUpdates[id] = success);
          } catch (e) {
            return (responseUpdates[id] = failure);
          }
        }
        try {
          const result = await runPut(getPutParams(session, invoice, entity));
          responseUpdates[id] = {
            ...result,
            ...success,
          };
        } catch (e) {
          responseUpdates[id] = failure;
        }
      } else {
        responseUpdates[id] = {isSynced: false};
      }
    }
  };

  const syncSession = async () => {
    setSyncing(true);

    await syncInvoices();

    if (Object.keys(responseUpdates).length) {
      setSession(prevSession => ({
        ...prevSession,
        triggerSync: false,
        ...(responseUpdates[prevSession.sessionId] as Partial<Session>),
        invoices: prevSession.invoices.map(invoice => {
          const {
            signatureImage,
            identification,
            items,
            payments,
            signatures,
            taxes,
            extras,
          } = invoice as OfflineInvoice;
          return {
            ...invoice,
            ...responseUpdates[invoice.invoiceId],
            ...(identification && {
              identification: {
                ...invoice.identification,
                ...responseUpdates[identification.invoiceIdentificationId],
              },
            }),
            ...(signatureImage && {
              signatureImage: {
                ...(invoice as OfflineInvoice).signatureImage,
                ...responseUpdates[signatureImage.signatureImageId],
              },
            }),
            items: items.map(item => ({
              ...item,
              ...responseUpdates[item.invoiceItemId],
            })),
            taxes: taxes.map(tax => ({
              ...tax,
              ...responseUpdates[tax.invoiceTaxId],
            })),
            signatures: signatures.map(signature => ({
              ...signature,
              ...responseUpdates[signature.invoiceSignatureId],
            })),
            extras: extras.map(extra => ({
              ...extra,
              ...responseUpdates[extra.rowId],
            })),
            payments: payments.map(payment => ({
              ...payment,
              ...responseUpdates[payment.invoicePaymentId],
            })),
          } as OfflineInvoice;
        }),
      }));
    }
    setSyncing(false);
  };

  const syncInvoices = async () => {
    return Promise.all(
      invoices.map(async invoice => {
        const p1 = syncInvoice(invoice);
        const p2 = syncSignatureImage(invoice);
        await Promise.all([p1, p2]);

        if ((invoice as OfflineSynced).isDeleted) {
          return;
        }
        const p3 = syncInvoiceItems(invoice);
        const p4 = syncInvoiceIdentification(invoice);
        const p5 = syncInvoiceExtras(invoice);
        const p6 = syncInvoicePayments(invoice);
        const p7 = syncInvoiceTaxes(invoice);
        const p8 = syncInvoiceSignatures(invoice);
        return Promise.all([p3, p4, p5, p6, p7, p8]);
      }),
    );
  };

  const syncInvoice = async (invoice: Invoice) => {
    const {invoiceId, status} = invoice;
    if (!online || status === 2) {
      return;
    }

    if (
      (invoice as OfflineInvoice).isCompleted &&
      isRecursivelySynced(invoice)
    ) {
      return postInvoice({sessionId, invoiceId}).then(
        response =>
          (responseUpdates[invoiceId] = {
            ...response,
          }),
      );
    }
    return syncEntity(
      invoice,
      invoice,
      invoice => invoice.invoiceId,
      putInvoice,
      (session, invoice, entity) => ({sessionId, invoiceRequest: entity}),
      deleteInvoice,
      (sessionId, invoiceId) => ({sessionId, invoiceId}),
    );
  };

  const syncInvoiceItems = async (invoice: Invoice) =>
    Promise.all(
      invoice.items.map(async invoiceItem =>
        syncEntity(
          invoice,
          invoiceItem,
          invoiceItem => invoiceItem.invoiceItemId,
          putInvoiceItem,
          (session, invoice, entity) => ({
            sessionId,
            invoiceId: invoice?.invoiceId || '',
            invoiceItemRequest: entity,
          }),
          deleteInvoiceItem,
          (sessionId, invoiceId, invoiceItemId) => ({
            sessionId,
            invoiceId,
            invoiceItemId,
          }),
        ),
      ),
    );

  const syncInvoiceIdentification = async (invoice: Invoice) => {
    if (!invoice.identification) {
      return;
    }
    return syncEntity(
      invoice,
      invoice.identification,
      identification => identification.invoiceIdentificationId,
      putInvoiceIdentification,
      (session, invoice, entity) => ({
        sessionId,
        invoiceId: invoice?.invoiceId || '',
        invoiceIdentificationRequest: entity,
      }),
      deleteInvoiceIdentification,
      (sessionId, invoiceId, invoiceIdentificationId) => ({
        sessionId,
        invoiceId,
        invoiceIdentificationId,
      }),
    );
  };

  const syncInvoiceExtras = async (invoice: Invoice) =>
    Promise.all(
      invoice.extras.map(invoiceExtra =>
        syncEntity(
          invoice,
          invoiceExtra,
          invoiceExtra => invoiceExtra.rowId,
          putInvoiceExtra,
          (session, invoice, entity) => ({
            sessionId,
            invoiceId: invoice?.invoiceId || '',
            invoiceExtraRequest: mapInvoiceExtraRequest(entity),
          }),
        ),
      ),
    );

  const syncInvoicePayments = async (invoice: Invoice) =>
    Promise.all(
      invoice.payments.map(async invoicePayment =>
        syncEntity(
          invoice,
          invoicePayment,
          invoicePayment => invoicePayment.invoicePaymentId,
          putInvoicePayment,
          (session, invoice, entity) => ({
            sessionId,
            invoiceId: invoice?.invoiceId || '',
            invoicePaymentRequest: entity,
          }),
          deleteInvoicePayment,
          (sessionId, invoiceId, invoicePaymentId) => ({
            sessionId,
            invoiceId,
            invoicePaymentId,
          }),
        ),
      ),
    );

  const syncInvoiceTaxes = async (invoice: Invoice) =>
    Promise.all(
      invoice.taxes.map(async invoiceTax =>
        syncEntity(
          invoice,
          invoiceTax,
          invoiceTax => invoiceTax.invoiceTaxId,
          putInvoiceTax,
          (session, invoice, entity) => ({
            sessionId,
            invoiceId: invoice?.invoiceId || '',
            invoiceTaxRequest: mapInvoiceTaxRequest(entity),
          }),
          deleteInvoiceTax,
          (sessionId, invoiceId, invoiceTaxId) => ({
            sessionId,
            invoiceId,
            invoiceTaxId,
          }),
        ),
      ),
    );

  const syncInvoiceSignatures = async (invoice: Invoice) => {
    return Promise.all(
      invoice.signatures.map(invoiceSignature =>
        syncEntity(
          invoice,
          invoiceSignature,
          invoiceSignature => invoiceSignature.invoiceSignatureId,
          putInvoiceSignature,
          (session, invoice, entity) => ({
            sessionId,
            invoiceId: invoice?.invoiceId || '',
            invoiceSignatureRequest: entity,
          }),
          deleteInvoiceSignature,
          (sessionId, invoiceId, invoiceSignatureId) => ({
            sessionId,
            invoiceId,
            invoiceSignatureId,
          }),
        ),
      ),
    );
  };

  const syncSignatureImage = async (invoice: Invoice) => {
    const {signatureImage, isDeleted} = invoice as OfflineInvoice &
      OfflineSynced;
    if (isDeleted || !signatureImage) {
      return;
    }
    return syncEntity(
      invoice,
      signatureImage,
      signatureImage => signatureImage.signatureImageId,
      putSignatureImage,
      (session, invoice, entity) => ({
        sessionId,
        invoiceId: invoice?.invoiceId || '',
        signatureImageRequest: (entity as unknown) as SignatureImageRequest,
      }),
    );
  };

  const {changed: onlineChanged, value: previouslyOnline} = usePrevious(online);

  useEffect(() => {
    if (
      onlineChanged &&
      // Note – we do an explicit false check here since usePrevious().value
      // returns `undefined` on the first update, which is falsy.
      previouslyOnline === false
    ) {
      (session as OfflineSession).triggerSync = true;
    }
  }, [onlineChanged, previouslyOnline]);

  useEffect(() => {
    if (user && (session as OfflineSession).triggerSync) {
      syncSession().then(() => console.log('Session synced'));
    }
  }, [user, online, session]);

  useMemo(() => {
    setSessionStatus(getSessionStatus(session));
  }, [session]);

  useEffect(() => {
    if (throwNotification) {
      setThrowNotification(false);
      if (getSessionStatus(session) !== 'success') {
        notification({
          title: 'Syncing Failed',
          description:
            'Something happened while syncing your session. Please try syncing again.',
          type: 'warning',
          icon: 'Warning',
        });
      }
    }
  }, [throwNotification]);

  return (
    <>
      <SyncSessionContext.Provider
        value={{
          syncing,
          syncSession: () => setShowModal(true),
          sessionStatus,
        }}
      >
        {children}
      </SyncSessionContext.Provider>
      <Modal
        visible={showModal && sessionStatus === 'success'}
        icon="Upload"
        color="success"
        title="Session Fully Synced"
        subtitle="Your session is synced successfully. All data will be accessible across devices."
        buttonText="Retry Sync"
        onCancel={() => setShowModal(false)}
        onContinue={() => {
          setShowModal(false);
          return loadUserSession();
        }}
      />
      <Modal
        visible={showModal && ['unsynced', 'error'].includes(sessionStatus)}
        icon="Pending"
        color="warning"
        title="Session Not Fully Synced"
        subtitle="Your session is not fully synced yet. Some data will not be accessible across devices."
        buttonText="Retry Sync"
        onCancel={() => setShowModal(false)}
        onContinue={() => {
          setShowModal(false);
          return loadUserSession();
        }}
      />
    </>
  );
}

export const getSessionStatus = (session: Session): SessionStatus =>
  session.invoices.every(invoice => getInvoiceStatus(invoice) === 'success')
    ? 'success'
    : (session as OfflineSynced).isErrorSynced ||
      session.invoices.some(hasSyncError)
    ? 'error'
    : 'unsynced';

export const getInvoiceStatus = (invoice: Invoice): SessionStatus =>
  ((invoice as OfflineInvoice).isCompleted && invoice.status === 2) ||
  (!(invoice as OfflineInvoice).isCompleted && isRecursivelySynced(invoice))
    ? 'success'
    : hasSyncError(invoice)
    ? 'error'
    : 'unsynced';

export const isRecursivelySynced = (invoice: Invoice): boolean | undefined =>
  ((invoice as OfflineSynced).isDeleted &&
    (invoice as OfflineSynced).isSynced) ||
  ((invoice as OfflineSynced).isSynced &&
    invoice.items.every(item => (item as OfflineSynced).isSynced) &&
    invoice.payments.every(payment => (payment as OfflineSynced).isSynced) &&
    invoice.extras.every(extra => (extra as OfflineSynced).isSynced) &&
    invoice.taxes.every(tax => (tax as OfflineSynced).isSynced) &&
    invoice.signatures.every(
      signature => (signature as OfflineSynced).isSynced,
    ) &&
    (invoice.identification
      ? (invoice.identification as OfflineSynced).isSynced
      : true) &&
    ((invoice as OfflineInvoice).signatureImage
      ? ((invoice as OfflineInvoice).signatureImage as OfflineSynced).isSynced
      : true));

const hasSyncError = (invoice: Invoice): boolean | undefined =>
  ((invoice as OfflineSynced).isDeleted &&
    (invoice as OfflineSynced).isErrorSynced) ||
  (invoice as OfflineSynced).isErrorSynced ||
  invoice.items.some(item => (item as OfflineSynced).isErrorSynced) ||
  invoice.payments.some(payment => (payment as OfflineSynced).isErrorSynced) ||
  invoice.extras.some(extra => (extra as OfflineSynced).isErrorSynced) ||
  invoice.taxes.some(tax => (tax as OfflineSynced).isErrorSynced) ||
  invoice.signatures.some(
    signature => (signature as OfflineSynced).isErrorSynced,
  ) ||
  (invoice.identification
    ? (invoice.identification as OfflineSynced).isErrorSynced
    : false) ||
  ((invoice as OfflineInvoice).signatureImage
    ? ((invoice as OfflineInvoice).signatureImage as OfflineSynced)
        .isErrorSynced
    : false);

export const markSynced = (object: unknown): boolean =>
  typeof object === 'object' && object !== null
    ? Reflect.set(object as OfflineSynced, 'isSynced', true)
    : false;

export const markRecursivelySynced = (invoice: Invoice): void =>
  [
    invoice,
    ...invoice.items,
    ...invoice.payments,
    ...invoice.extras,
    ...invoice.taxes,
    ...invoice.signatures,
    invoice.identification,
    (invoice as OfflineInvoice).signatureImage,
  ].forEach(markSynced);

export const mapInvoiceExtraRequest = (
  invoiceExtra: InvoiceExtra,
): InvoiceExtraRequest => ({
  ...invoiceExtra,
  dataKey: invoiceExtra.dataKey || '',
  dataValue: invoiceExtra.dataValue || '',
});

export const mapInvoiceTaxRequest = (
  invoiceTax: InvoiceTax,
): InvoiceTaxRequest => ({
  ...invoiceTax,
  transactionID: invoiceTax.transactionID || 0,
  taxAmount: invoiceTax.taxAmount || 0,
  taxRate: invoiceTax.taxRate || 0,
  taxableSubTotal: invoiceTax.taxableSubTotal || 0,
});
