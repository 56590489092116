/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentVoidRequest
 */
export interface PaymentVoidRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentVoidRequest
     */
    siteId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidRequest
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidRequest
     */
    transactionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidRequest
     */
    terminalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidRequest
     */
    userId: string;
}

export function PaymentVoidRequestFromJSON(json: any): PaymentVoidRequest {
    return PaymentVoidRequestFromJSONTyped(json, false);
}

export function PaymentVoidRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentVoidRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteId': json['siteId'],
        'referenceNumber': json['referenceNumber'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'terminalId': !exists(json, 'terminalId') ? undefined : json['terminalId'],
        'userId': json['userId'],
    };
}

export function PaymentVoidRequestToJSON(value?: PaymentVoidRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteId': value.siteId,
        'referenceNumber': value.referenceNumber,
        'transactionId': value.transactionId,
        'terminalId': value.terminalId,
        'userId': value.userId,
    };
}


