import {RouteComponentProps} from '@reach/router';
import {memo, PropsWithChildren} from 'react';
import {CustomerSearchProvider} from '../../../../../contexts/CustomerSearchProvider';
import CustomerPaymentIntegration from './CustomerPaymentIntegration';

function CustomerPayment(
  props: PropsWithChildren<RouteComponentProps>,
): JSX.Element {
  return (
    <CustomerSearchProvider>
      <CustomerPaymentIntegration {...props} />
    </CustomerSearchProvider>
  );
}

export default memo(CustomerPayment);
