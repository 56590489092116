import React, {memo, ReactNode} from 'react';
import {animated, useTransition} from 'react-spring';
import styled, {DefaultTheme} from 'styled-components';
import {Gutter, Icons, IndicatorCircle, Row} from './';
import Text, {Variant} from './Text';

type Type = 'success' | 'warning' | 'error' | 'general';
interface AlertProps {
  variant?: Type;
  text: ReactNode;
  icon: keyof typeof Icons;
  animated?: boolean;
}

const mapColor = (theme: DefaultTheme, variant?: Type, dark?: boolean) => {
  switch (variant) {
    case 'success':
      return dark ? `${theme.colors.success}` : `${theme.colors.success_20}`;
    case 'warning':
      return dark ? `${theme.colors.warning}` : `${theme.colors.warning_20}`;
    case 'error':
      return dark ? `${theme.colors.error}` : `${theme.colors.error_20}`;
    case 'general':
    default:
      return dark ? `${theme.colors.blue}` : `${theme.colors.blue_20}`;
  }
};

const indicatorCircle = (
  icon: keyof typeof Icons,
  variant?: string,
  animated?: boolean,
) => {
  switch (variant) {
    case 'success':
      return (
        <IndicatorCircle
          icon={icon}
          variant="success"
          loading={animated}
          size="small"
        />
      );
    case 'warning':
      return (
        <IndicatorCircle
          icon={icon}
          variant="warning"
          loading={animated}
          size="small"
        />
      );
    case 'error':
      return (
        <IndicatorCircle
          icon={icon}
          variant="error"
          loading={animated}
          size="small"
        />
      );
    case 'general':
    default:
      return (
        <IndicatorCircle
          icon={icon}
          variant="primary"
          loading={animated}
          size="small"
        />
      );
  }
};

const StyledAlertBadge = styled.div<{variant?: Type}>`
  background: ${({theme, variant}) => `linear-gradient(${mapColor(
    theme,
    variant,
  )}, ${mapColor(theme, variant)}),
  linear-gradient(${theme.colors.white}, ${theme.colors.white})`};
  border: 2px solid ${({theme, variant}) => mapColor(theme, variant, true)};
  border-radius: 40px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  width: auto;
  height: 50px;
`;

const AnimatedAlertBadge = animated(StyledAlertBadge);

export const AlertBadge = memo(
  ({
    animated = false,
    icon,
    text,
    variant,
    ...props
  }: AlertProps & React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const transition = useTransition(text, {
      from: {opacity: 0},
      enter: [{opacity: 1}],
      leave: [{opacity: 0}],
    });
    return transition((style, item, _t, i) =>
      item ? (
        <AnimatedAlertBadge
          key={
            /* istanbul ignore next */
            typeof item === 'object' ? i : item.toString()
          }
          style={style}
          variant={variant}
          data-testid="alert-badge"
          {...props}
        >
          <Row align="center" gutter={Gutter.S} noWrap>
            {indicatorCircle(icon, variant, animated)}
            <Text variant={Variant.Main}>{item}</Text>
          </Row>
        </AnimatedAlertBadge>
      ) : /* istanbul ignore next */
      null,
    );
  },
);
