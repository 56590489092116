/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentInitiateResponse
 */
export interface PaymentInitiateResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentInitiateResponse
     */
    transportKey: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInitiateResponse
     */
    validationKey: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentInitiateResponse
     */
    messages: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PaymentInitiateResponse
     */
    rawResponse: string;
}

export function PaymentInitiateResponseFromJSON(json: any): PaymentInitiateResponse {
    return PaymentInitiateResponseFromJSONTyped(json, false);
}

export function PaymentInitiateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInitiateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transportKey': json['transportKey'],
        'validationKey': json['validationKey'],
        'messages': json['messages'],
        'rawResponse': json['rawResponse'],
    };
}

export function PaymentInitiateResponseToJSON(value?: PaymentInitiateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transportKey': value.transportKey,
        'validationKey': value.validationKey,
        'messages': value.messages,
        'rawResponse': value.rawResponse,
    };
}


