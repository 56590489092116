/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Peripheral,
    PeripheralFromJSON,
    PeripheralToJSON,
    PeripheralRequest,
    PeripheralRequestFromJSON,
    PeripheralRequestToJSON,
} from '../models';

export interface ClientPeripheralsGetRequest {
    siteId?: number;
    peripheralTypeId?: number;
    includeAssigned?: boolean;
    includeChildren?: boolean;
}

export interface ClientPeripheralsPeripheralIdGetRequest {
    peripheralId: number;
    includeChildren?: boolean;
}

export interface ClientPeripheralsPutRequest {
    peripheralRequest?: PeripheralRequest;
}

/**
 * 
 */
export class PeripheralsApi extends runtime.BaseAPI {

    /**
     * Gets a list of peripherals
     */
    async clientPeripheralsGetRaw(requestParameters: ClientPeripheralsGetRequest): Promise<runtime.ApiResponse<Array<Peripheral>>> {
        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.peripheralTypeId !== undefined) {
            queryParameters['peripheralTypeId'] = requestParameters.peripheralTypeId;
        }

        if (requestParameters.includeAssigned !== undefined) {
            queryParameters['includeAssigned'] = requestParameters.includeAssigned;
        }

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/peripherals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeripheralFromJSON));
    }

    /**
     * Gets a list of peripherals
     */
    async clientPeripheralsGet(requestParameters: ClientPeripheralsGetRequest): Promise<Array<Peripheral>> {
        const response = await this.clientPeripheralsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets a peripheral by id
     */
    async clientPeripheralsPeripheralIdGetRaw(requestParameters: ClientPeripheralsPeripheralIdGetRequest): Promise<runtime.ApiResponse<Peripheral>> {
        if (requestParameters.peripheralId === null || requestParameters.peripheralId === undefined) {
            throw new runtime.RequiredError('peripheralId','Required parameter requestParameters.peripheralId was null or undefined when calling clientPeripheralsPeripheralIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/peripherals/{peripheralId}`.replace(`{${"peripheralId"}}`, encodeURIComponent(String(requestParameters.peripheralId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeripheralFromJSON(jsonValue));
    }

    /**
     * Gets a peripheral by id
     */
    async clientPeripheralsPeripheralIdGet(requestParameters: ClientPeripheralsPeripheralIdGetRequest): Promise<Peripheral> {
        const response = await this.clientPeripheralsPeripheralIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts or updates a peripheral
     */
    async clientPeripheralsPutRaw(requestParameters: ClientPeripheralsPutRequest): Promise<runtime.ApiResponse<Peripheral>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/peripherals`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PeripheralRequestToJSON(requestParameters.peripheralRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeripheralFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a peripheral
     */
    async clientPeripheralsPut(requestParameters: ClientPeripheralsPutRequest): Promise<Peripheral> {
        const response = await this.clientPeripheralsPutRaw(requestParameters);
        return await response.value();
    }

}
