/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentProcessorResponse
 */
export interface PaymentProcessorResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponse
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentProcessorResponse
     */
    amountApproved: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponse
     */
    paymentType: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponse
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponse
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponse
     */
    authorizationCode: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponse
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorResponse
     */
    rawResponse: string;
}

export function PaymentProcessorResponseFromJSON(json: any): PaymentProcessorResponse {
    return PaymentProcessorResponseFromJSONTyped(json, false);
}

export function PaymentProcessorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentProcessorResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'amountApproved': json['amountApproved'],
        'paymentType': json['paymentType'],
        'accountNumber': json['accountNumber'],
        'message': json['message'],
        'authorizationCode': json['authorizationCode'],
        'referenceNumber': json['referenceNumber'],
        'rawResponse': json['rawResponse'],
    };
}

export function PaymentProcessorResponseToJSON(value?: PaymentProcessorResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'amountApproved': value.amountApproved,
        'paymentType': value.paymentType,
        'accountNumber': value.accountNumber,
        'message': value.message,
        'authorizationCode': value.authorizationCode,
        'referenceNumber': value.referenceNumber,
        'rawResponse': value.rawResponse,
    };
}


