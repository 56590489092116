/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    BarcodeCollection,
    BarcodeCollectionFromJSON,
    BarcodeCollectionFromJSONTyped,
    BarcodeCollectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface BarcodeCollectionApiResponse
 */
export interface BarcodeCollectionApiResponse {
    /**
     * 
     * @type {string}
     * @memberof BarcodeCollectionApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof BarcodeCollectionApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {BarcodeCollection}
     * @memberof BarcodeCollectionApiResponse
     */
    data?: BarcodeCollection;
}

export function BarcodeCollectionApiResponseFromJSON(json: any): BarcodeCollectionApiResponse {
    return BarcodeCollectionApiResponseFromJSONTyped(json, false);
}

export function BarcodeCollectionApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BarcodeCollectionApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : BarcodeCollectionFromJSON(json['data']),
    };
}

export function BarcodeCollectionApiResponseToJSON(value?: BarcodeCollectionApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': BarcodeCollectionToJSON(value.data),
    };
}


