import {
  ButtonShape,
  Gutter,
  NumberInput,
  Row,
  ButtonSize as Size,
  Stack,
  toCurrency,
} from '@emporos/components';
import {memo} from 'react';
import styled from 'styled-components';

interface BillboardProps {
  value: number;
  onChange: (v: number) => void;
  totalDue: number;
  showNumberButtons?: boolean;
}

const AmountButton = styled(ButtonShape)`
  @media (max-width: 1034px) {
    height: 100px;
    width: 100px;
    font-size: 28px;
  }
`;

function BillBoard({
  value,
  onChange,
  totalDue,
  showNumberButtons,
}: BillboardProps): JSX.Element {
  const addBill = (bill: number) => {
    onChange(Number((bill + value).toFixed(2)));
  };

  return (
    <Stack gutter={Gutter.L} data-testid="BillBoard">
      <NumberInput
        value={'$' + toCurrency(value, '.')}
        onClear={() => onChange(0)}
        onChange={e => {
          onChange(Number(toCurrency(e.target.value)));
        }}
      />

      {showNumberButtons && (
        <>
          <Row gutter={Gutter.L} justify="space-between" noWrap>
            <AmountButton size={Size.Large} flex onClick={() => addBill(5)}>
              $5
            </AmountButton>
            <AmountButton size={Size.Large} flex onClick={() => addBill(10)}>
              $10
            </AmountButton>
            <AmountButton size={Size.Large} flex onClick={() => addBill(20)}>
              $20
            </AmountButton>
          </Row>
          <Row gutter={Gutter.L} justify="space-between" noWrap>
            <AmountButton size={Size.Large} flex onClick={() => addBill(50)}>
              $50
            </AmountButton>
            <AmountButton size={Size.Large} flex onClick={() => addBill(100)}>
              $100
            </AmountButton>
            <AmountButton
              size={Size.Large}
              flex
              onClick={() => onChange(Number(totalDue.toFixed(2)))}
            >
              Exact
            </AmountButton>
          </Row>
        </>
      )}
    </Stack>
  );
}
export default memo(BillBoard);
