/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvoiceTax,
    InvoiceTaxFromJSON,
    InvoiceTaxToJSON,
    InvoiceTaxRequest,
    InvoiceTaxRequestFromJSON,
    InvoiceTaxRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGetRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDeleteRequest {
    sessionId: string;
    invoiceId: string;
    invoiceTaxId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGetRequest {
    sessionId: string;
    invoiceId: string;
    invoiceTaxId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPutRequest {
    sessionId: string;
    invoiceId: string;
    invoiceTaxRequest?: InvoiceTaxRequest;
}

/**
 * 
 */
export class InvoiceTaxesApi extends runtime.BaseAPI {

    /**
     * Gets a list of invoiceTaxes for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGetRequest): Promise<runtime.ApiResponse<Array<InvoiceTax>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/taxes`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceTaxFromJSON));
    }

    /**
     * Gets a list of invoiceTaxes for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGetRequest): Promise<Array<InvoiceTax>> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an invoiceTax
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDelete.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDelete.');
        }

        if (requestParameters.invoiceTaxId === null || requestParameters.invoiceTaxId === undefined) {
            throw new runtime.RequiredError('invoiceTaxId','Required parameter requestParameters.invoiceTaxId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/taxes/{invoiceTaxId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoiceTaxId"}}`, encodeURIComponent(String(requestParameters.invoiceTaxId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an invoiceTax
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDelete(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdDeleteRaw(requestParameters);
    }

    /**
     * Gets an invoiceTax by session id , an Invoice id and invoiceTax id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGetRequest): Promise<runtime.ApiResponse<InvoiceTax>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGet.');
        }

        if (requestParameters.invoiceTaxId === null || requestParameters.invoiceTaxId === undefined) {
            throw new runtime.RequiredError('invoiceTaxId','Required parameter requestParameters.invoiceTaxId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/taxes/{invoiceTaxId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoiceTaxId"}}`, encodeURIComponent(String(requestParameters.invoiceTaxId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceTaxFromJSON(jsonValue));
    }

    /**
     * Gets an invoiceTax by session id , an Invoice id and invoiceTax id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGetRequest): Promise<InvoiceTax> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesInvoiceTaxIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts or updates a invoiceTax
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPutRequest): Promise<runtime.ApiResponse<InvoiceTax>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPut.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/taxes`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceTaxRequestToJSON(requestParameters.invoiceTaxRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceTaxFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a invoiceTax
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPut(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPutRequest): Promise<InvoiceTax> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdTaxesPutRaw(requestParameters);
        return await response.value();
    }

}
