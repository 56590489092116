import {Relationship, Setting} from '@emporos/api-enterprise/src/gen';
import {Invoice, InvoiceExtra} from '@emporos/api-enterprise/src/gen-session';
import {
  Button,
  ButtonShape,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  Row,
  SegmentSlider,
  Select,
  ButtonSize as Size,
  Stack,
} from '@emporos/components';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavigateFn} from '@reach/router';
import {memo, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {formatCustomerName} from '../../../../../utils/customer';
import {COMPLIANCE_DATA_KEY} from '../../../../../utils/mappers';
import mapIdCheckIndicators from '../id-check/IdCheckIndicators';
import mapComplianceIndicators, {
  COUNSELING_LINE1,
  HIPAA_LINE1,
} from './ComplianceIndicators';
import {
  ComplianceForm,
  COUNSEL_LATER,
  EMPTY_COMPLIANCE,
  NavigateTo,
  NO,
  NO_COUNSELING,
  NO_NUM,
  YES,
  YES_NUM,
} from './index';

const mapExtrasToCompliance = (extras: Array<InvoiceExtra>): ComplianceForm => {
  const compliance = extras.find(
    extra => extra.dataKey === COMPLIANCE_DATA_KEY,
  );
  return compliance
    ? JSON.parse(compliance.dataValue || '{}')
    : EMPTY_COMPLIANCE;
};

interface Props {
  invoice: Invoice;
  defaultAnimatePanel: boolean;
  settings: Setting[];
  relationships: Relationship[];
  navigate: NavigateFn;
  onBack(navigateTo: NavigateTo): void;
  onSubmit(form: ComplianceForm): void;
}

export function Compliance({
  invoice,
  defaultAnimatePanel,
  settings,
  relationships,
  navigate,
  onBack,
  onSubmit,
}: Props): JSX.Element {
  const [animatePanel, setAnimatePanel] = useState(defaultAnimatePanel);
  const {customer, extras} = invoice;
  const defaultValues = mapExtrasToCompliance(extras);

  const [hipaaActive, setHipaaActive] = useState(
    defaultValues.hipaa === YES ? YES_NUM : NO_NUM,
  );

  // TODO: i don’t understand the purpose of this logic
  useEffect(() => {
    if (!animatePanel) {
      setAnimatePanel(true);
    }
  }, []);

  const {
    showCounsel,
    showCounselRequired,
    showHipaa,
    showRelationship,
  } = mapComplianceIndicators(invoice, settings);

  if (showCounselRequired) {
    defaultValues.counseling = COUNSEL_LATER;
  }

  const schema = yup.object().shape({
    counseling: showCounsel
      ? yup.string().required()
      : yup.string().notRequired(),
    hipaa: showHipaa ? yup.string().required() : yup.string().notRequired(),
    relationship: showRelationship
      ? yup.string().required()
      : yup.string().notRequired(),
  });
  const {handleSubmit, control, formState, register} = useForm<ComplianceForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <>
      <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
        <Header title="Compliance">
          <ButtonShape
            type="button"
            size={Size.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={() => navigate('customer-info')}
          />
        </Header>

        <Stack gutter={Gutter.XXL} style={{flex: 1}}>
          {showCounsel && (
            <Row justify="space-between" align="center" noWrap>
              <Text variant={TV.Main}>
                {(
                  settings.find(s => s.name === COUNSELING_LINE1)?.value || ''
                ).replace('~True', '')}
              </Text>
              <Controller
                name="counseling"
                control={control}
                render={({onChange, value, name}) => (
                  <Select
                    name={name}
                    value={value}
                    onChangeValue={onChange}
                    label="Counseling"
                    options={[COUNSEL_LATER, NO_COUNSELING]}
                    optionsText={['Counsel Later', 'No Counseling']}
                    disabled={showCounselRequired}
                    style={{flex: '0 0 278px'}}
                  />
                )}
              />
            </Row>
          )}

          {showRelationship && customer && (
            <Row justify="space-between" align="center" noWrap>
              <Text variant={TV.Main}>
                What is your relationship to {formatCustomerName(customer)}?
              </Text>
              <Controller
                name="relationship"
                control={control}
                render={({onChange, value, name}) => (
                  <Select
                    name={name}
                    value={value}
                    onChangeValue={onChange}
                    label="Relationship"
                    options={relationships.map(({name}) => name)}
                    optionsText={relationships.map(({name}) => name)}
                    style={{flex: '0 0 278px'}}
                  />
                )}
              />
            </Row>
          )}

          {showHipaa && (
            <Row
              justify="space-between"
              align="center"
              gutter={Gutter.None}
              noWrap
            >
              <input
                type="hidden"
                name="hipaa"
                value={hipaaActive === YES_NUM ? YES : NO}
                ref={register()}
              />
              <Text variant={TV.Main}>
                {(
                  settings.find(s => s.name === HIPAA_LINE1)?.value || ''
                ).replace('~True', '')}
              </Text>
              <SegmentSlider
                items={['Yes', 'No']}
                active={hipaaActive}
                onSelectIndex={setHipaaActive}
              />
            </Row>
          )}
        </Stack>

        <FooterGroup>
          <Button
            type="button"
            variant={BV.Secondary}
            flex
            onClick={() => {
              const {
                isNplex,
                isControlledSubstance,
                isRequiredAge,
              } = mapIdCheckIndicators(invoice);
              const navigateTo =
                (isNplex && invoice.pseCheckResult !== 1) ||
                isControlledSubstance ||
                isRequiredAge
                  ? NavigateTo.IDCheck
                  : NavigateTo.Transactions;
              onBack(navigateTo);
              setAnimatePanel(false);
            }}
          >
            Back
          </Button>
          <Button flex type="submit" disabled={!formState.isValid}>
            Confirm
          </Button>
        </FooterGroup>
      </Stack>
    </>
  );
}

export default memo(Compliance);
