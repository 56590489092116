import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';

const Video = styled.video`
  width: 100%;
  object-fit: contain;
`;

const canPlayMov = document
  .createElement('video')
  .canPlayType('video/quicktime');

export function LogoHiloAnimated() {
  const mov = '/hilo-animation.mov';
  const webm = '/hilo-animation.webm';
  const ref = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.muted = true;
      ref.current.play();
    }
  }, []);
  return (
    <Video ref={ref}>
      {canPlayMov && <source src={mov} />}
      <source src={webm} />
    </Video>
  );
}
