import {
  Button,
  Gutter,
  IndicatorCircle,
  RemovePadding,
  Row,
  RowSidebar,
  Stack,
  Size,
  useMounted,
} from '@emporos/components';
import Text, {Variant} from '@emporos/components/src/Text';
import {RouteComponentProps, useLocation} from '@reach/router';
import assert from 'assert';
import {memo, useEffect, useMemo} from 'react';
import {animated, useTransition, UseTransitionProps} from 'react-spring';

import config from '../../config';
import {InvoiceLogTypes, useLog} from '../../contexts/LoggingProvider';
import {
  Invoice,
  useTransactionsState,
} from '../../contexts/TransactionsStateProvider';
import useSession from '../../hooks/useSession';
import {
  behindTabBarStackingContext,
  fullHeightStack,
  invoiceSynced,
} from '../../routes/Sidebar';
import {mapInvoice} from '../../utils/mappers';

function SalesSidebar({
  navigate,
  requestedUrl,
  onConfirmNavigation,
}: RouteComponentProps & {
  requestedUrl: string | null;
  onConfirmNavigation: (url?: string) => void;
}) {
  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<SalesSidebar /> must have a `navigate` prop.' + config.NodeEnv ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
  }

  const {pathname} = useLocation();
  const {logUserSelection} = useLog();
  const {
    session,
    currentInvoiceId,
    setCurrentInvoiceId,
  } = useTransactionsState();
  const {
    pendingInvoices: invoices,
    updateSession,
    canCreateInvoice,
  } = useSession();

  useEffect(() => {
    if (requestedUrl) {
      onConfirmNavigation();
    }
  }, [requestedUrl, onConfirmNavigation]);

  const onNew = () => {
    const invoice = mapInvoice(session.sessionId, session.siteId);
    updateSession(prevSession => ({
      invoices: [invoice, ...prevSession.invoices],
    }));
    setCurrentInvoiceId(invoice.invoiceId);
    logUserSelection(InvoiceLogTypes.NewInvoice);
    onConfirmNavigation('/sales/transactions');
  };

  const mounted = useMounted();
  const transition = useMemo<UseTransitionProps<Invoice>>(
    () =>
      mounted
        ? {
            keys: (invoice: Invoice) => invoice.invoiceId,
            from: {y: -68, opacity: 0},
            leave: (invoice, i) => ({
              y: i * 68 - 68,
              opacity: 0,
            }),
            enter: (invoice, i) => ({y: i * 68, opacity: 1}),
            update: (invoice, i) => ({y: i * 68}),
          }
        : {
            keys: (invoice: Invoice) => invoice.invoiceId,
            from: (invoice, i) => ({y: i * 68, opacity: 1}),
            leave: (invoice, i) => ({y: i * 68, opacity: 1}),
            enter: (invoice, i) => ({y: i * 68, opacity: 1}),
          },
    [mounted],
  );
  const invoiceRowTransitions = useTransition(
    invoices.concat([{invoiceId: 'spacer'} as Invoice]),
    transition,
  );

  return (
    <>
      {invoices.length > 0 ? (
        <Stack gutter={Gutter.XL} style={fullHeightStack}>
          <Row align="center" justify="space-between">
            <Text variant={Variant.T1}>Sales</Text>
            <Button
              size={Size.Small}
              icon="PlusCircle"
              onClick={onNew}
              disabled={!canCreateInvoice}
            >
              New
            </Button>
          </Row>

          <RemovePadding style={behindTabBarStackingContext}>
            {invoiceRowTransitions(({y, opacity}, iv, state, index) =>
              iv.invoiceId === 'spacer' ? (
                <animated.div
                  style={{
                    transform: y.to((y: number) => `translate3d(0,${y}px,0)`),
                    position: 'absolute',
                    height: '6em',
                    width: '100%',
                  }}
                />
              ) : (
                <animated.div
                  key={iv.invoiceId}
                  data-testid="transactionItem"
                  style={{
                    zIndex: invoices.length - index,
                    transform: y.to((y: number) => `translate3d(0,${y}px,0)`),
                    position: 'absolute',
                    width: '100%',
                    opacity,
                  }}
                >
                  <RowSidebar
                    title={
                      iv.customer
                        ? `${iv.customer.firstName} ${iv.customer.lastName}`
                        : 'General Sale'
                    }
                    // TODO: make this dynamic when we have different types of transactions
                    icon="Bed"
                    iconColor="indigo"
                    badgeText={iv.roomNumber || ''}
                    selected={
                      (iv.invoiceId === currentInvoiceId && !iv.items.length) ||
                      (pathname.indexOf('/transactions') >= 0 &&
                        iv.invoiceId === currentInvoiceId)
                    }
                    onClick={() => {
                      setCurrentInvoiceId(iv.invoiceId);
                      logUserSelection(InvoiceLogTypes.Selection, {
                        item: iv.invoiceId,
                      });
                      return navigate('/sales/transactions');
                    }}
                    synced={invoiceSynced(iv)}
                  />
                </animated.div>
              ),
            )}
          </RemovePadding>
        </Stack>
      ) : (
        <>
          <Row align="center" justify="space-between">
            <Text variant={Variant.T1}>Sales</Text>
            <Button size={Size.Small} icon="PlusCircle" onClick={onNew}>
              New
            </Button>
          </Row>
          <Stack
            align="center"
            gutter={Gutter.XL}
            data-testid="sales-sidebar-indicator"
          >
            <IndicatorCircle icon="CashRegister" variant="gray" size="large" />
            <Text variant={Variant.Main} align="center">
              Tap the new sale button
            </Text>
            <Text variant={Variant.Main} align="center" style={{marginTop: 4}}>
              above to get started
            </Text>
          </Stack>
          <div style={{height: 70}}>
            {/* TODO: just here to fix the layout */}
          </div>
        </>
      )}
    </>
  );
}

export default memo(SalesSidebar);
