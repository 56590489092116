import {
  BarcodeCollection,
  BarcodeComponent,
  ClientPseCheckPutRequest,
  PseCheckResponseApiResponse,
  Setting,
} from '@emporos/api-enterprise/src/gen';
import {InvoiceIdentification} from '@emporos/api-enterprise/src/gen-session';
import {
  CompleteBarcodeComponent,
  decodeID,
  IdBarcodeResult,
  ScanResult,
  Symbology,
} from '@emporos/barcodes';
import {
  Button,
  ButtonShape,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  IndicatorCircle,
  Modal,
  PendingChangesModal,
  Row,
  ScrollContainer,
  Select,
  ButtonSize as Size,
  SelectSize as SS,
  Stack,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import TextInput from '@emporos/components/src/TextInput';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavigateFn} from '@reach/router';
import assert from 'assert';
import {get} from 'lodash';
import moment from 'moment';
import {memo, useEffect, useState} from 'react';
import {Controller, SetValueConfig, useForm} from 'react-hook-form';
import styled from 'styled-components';
import {NotificationFn} from '../../../../../contexts/AlertStateProvider';
import {
  AlertLogTypes,
  ApiLogTypes,
  useLog,
  UserLogTypes,
} from '../../../../../contexts/LoggingProvider';
import {
  Invoice,
  InvoiceUpdates,
} from '../../../../../contexts/TransactionsStateProvider';
import {diffYears, isBeforeNow} from '../../../../../utils/dates';
import {
  COMPLIANCE_DATA_KEY,
  mapInvoiceIdentification,
} from '../../../../../utils/mappers';
import {EMPTY_COMPLIANCE} from '../compliance';
import mapComplianceIndicators from '../compliance/ComplianceIndicators';
import mapIdCheckIndicators from './IdCheckIndicators';
import mapSchema from './IdCheckValidation';
import {mapItemMilligrams} from './milligrams';
import {usStates} from './stateList';

const CheckBar = styled(Row)`
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.gray_100};
  border-radius: 8px;
  padding: 22px;
  margin: 36px 0;
`;

interface Err {
  status: number;
}

interface IdCheckForm {
  firstName: string;
  idNumber: string;
  middleName: string;
  lastName: string;
  address1: string;
  address2: string;
  state: string | null;
  city: string;
  zipCode: string;
  gender: string | null;
  dob: string;
  idExpirationDate: string;
}

const EMPTY_ID_CHECK_FORM: IdCheckForm = {
  firstName: '',
  idNumber: '',
  middleName: '',
  lastName: '',
  address1: '',
  address2: '',
  state: null,
  city: '',
  zipCode: '',
  gender: null,
  dob: '',
  idExpirationDate: '',
};

const mapIdentificationToIdCheckForm = (
  identification: InvoiceIdentification,
): IdCheckForm => ({
  firstName: identification.firstName || '',
  idNumber: identification.idNumber || '',
  middleName: identification.middleName || '',
  lastName: identification.lastName || '',
  address1: identification.address1 || '',
  address2: identification.address2 || '',
  state: identification.state || '',
  city: identification.city || '',
  zipCode: identification.zipCode || '',
  gender: identification.gender || '',
  dob: identification.dob
    ? moment.utc(identification.dob).format('MM/DD/YYYY')
    : '',
  idExpirationDate: identification.idExpirationDate
    ? moment.utc(identification.idExpirationDate).format('MM/DD/YYYY')
    : '',
});

const mapIdentificationFromIdCheckForm = (
  form: IdCheckForm,
): Partial<InvoiceIdentification> => ({
  firstName: form.firstName,
  idNumber: form.idNumber,
  middleName: form.middleName,
  lastName: form.lastName,
  address1: form.address1,
  address2: form.address2,
  state: form.state,
  city: form.city,
  zipCode: form.zipCode,
  gender: form.gender,
  dob: moment.utc(form.dob, 'MM/DD/YYYY', true).toDate(),
  idExpirationDate: moment
    .utc(form.idExpirationDate, 'MM/DD/YYYY', true)
    .toDate(),
});

const getDefaultForm = ({identification}: Invoice) =>
  identification
    ? mapIdentificationToIdCheckForm(identification)
    : EMPTY_ID_CHECK_FORM;

interface Props {
  onUpdateInvoice: (updates: InvoiceUpdates) => void;
  invoice: Invoice;
  barcodes: BarcodeCollection;
  settings: Setting[];
  navigate: NavigateFn;
  loadingPseCheck: boolean;
  putPseCheck: (
    params: ClientPseCheckPutRequest,
  ) => Promise<PseCheckResponseApiResponse>;
  siteID: string;
  stationID: string;
  userID: string;
  notification: NotificationFn;
  onBack(): void;
}

function findLicenseBarcodes(barcodes: BarcodeCollection) {
  return barcodes.barcodeComponents.filter(
    (value: BarcodeComponent) =>
      value.barcodeName === 'HoneywellXenon1900DriverLicense',
  );
}

function IdCheck({
  onUpdateInvoice,
  invoice,
  barcodes,
  settings,
  navigate,
  loadingPseCheck,
  putPseCheck,
  siteID,
  stationID,
  notification,
  onBack,
}: Props): JSX.Element {
  const {logAlert, logApi, logUserSelection} = useLog();
  const [showBackModal, setShowBackModal] = useState(false);
  const [showNplexDeniedModal, setShowNplexDeniedModal] = useState(false);

  const {invoiceId, identification} = invoice;

  const schema = mapSchema(invoice);
  const {handleSubmit, errors, watch, control, formState, setValue} = useForm<
    IdCheckForm
  >({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: getDefaultForm(invoice),
  });

  useEffect(() => {
    if (errors) {
      logUserSelection(UserLogTypes.UserError, {
        data: Object.getOwnPropertyNames(errors),
      });
    }
  }, [errors]);

  const requiredStar = (field: string) =>
    get(schema, `fields.${field}._exclusive.required`) ? '*' : '';

  const {
    isNplex,
    isControlledSubstance,
    isRequiredAge,
    age,
  } = mapIdCheckIndicators(invoice);
  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    invoice,
    settings,
  );

  function onScan(scanResult: ScanResult) {
    if (!barcodes) {
      return;
    }

    const barcodesComponents = findLicenseBarcodes(barcodes);
    logUserSelection(UserLogTypes.ScannedCustID);
    resetWithIdResult(
      decodeID(
        barcodesComponents as CompleteBarcodeComponent[],
        scanResult.raw,
      ),
    );
  }

  const isNplexValid = async (form: IdCheckForm): Promise<boolean> => {
    if ((isNplex && invoice.pseCheckResult === 1) || !isNplex) {
      return true;
    }
    assert(form.state, 'state not defined');
    logApi(ApiLogTypes.FetchNPLEX);
    try {
      const nplexResult = await putPseCheck({
        pseCheckRequest: {
          identification: {
            idNumber: form.idNumber,
            firstName: form.firstName,
            lastName: form.lastName,
            dob: moment(form.dob, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            address1: form.address1,
            city: form.city,
            state: form.state,
            zipCode: form.zipCode,
            idExpirationDate: moment(
              form.idExpirationDate,
              'MM/DD/YYYY',
            ).format('YYYY-MM-DD'),
            note: '',
            idTypeID: 1,
            idTypeCode: 'DL',
          },
          items: mapItemMilligrams(invoice),
        },
        siteId: +siteID,
        stationId: +stationID,
      });
      if (nplexResult.data?.result === 1) {
        return true;
      } else {
        setShowNplexDeniedModal(true);
        return false;
      }
    } catch (e) {
      if ((e as Err).status >= 300) {
        setShowNplexDeniedModal(true);
      } else {
        notification({
          title: 'Could Not Reach NPLEx',
          description:
            'NPLEx verification could not be reached. Please try again.',
          icon: 'Warning',
          type: 'warning',
        });
      }
      return false;
    }
  };

  const onSubmit = async (form: IdCheckForm) => {
    const _isNplexValid = await isNplexValid(form);
    if (!_isNplexValid) {
      return;
    }

    onUpdateInvoice({
      identification: {
        ...(identification
          ? identification
          : mapInvoiceIdentification(invoiceId)),
        ...mapIdentificationFromIdCheckForm(form),
      },
      pseCheckResult: _isNplexValid ? 1 : 0,
    });
    return navigate(
      showCounsel || showHipaa || showRelationship
        ? '../compliance'
        : '../customer-payment',
    );
  };

  function resetWithIdResult(idResult: IdBarcodeResult) {
    const toEmpty = (s?: string) => (s && s !== 'NULL' ? s : '');
    const config: SetValueConfig = {shouldDirty: true, shouldValidate: true};
    setValue('firstName', toEmpty(idResult.firstName), config);
    setValue('idNumber', toEmpty(idResult.idNumber), config);
    setValue('middleName', toEmpty(idResult.middleName), config);
    setValue('lastName', toEmpty(idResult.lastName), config);
    setValue('address1', toEmpty(idResult.address1), config);
    setValue('address2', toEmpty(idResult.address2), config);
    setValue('state', toEmpty(idResult.state), config);
    setValue('city', toEmpty(idResult.city), config);
    setValue('zipCode', toEmpty(idResult.zip).slice(0, 5), config);
    setValue('gender', toEmpty(idResult.gender), config);
    setValue('dob', toEmpty(idResult.dateOfBirth), config);
    setValue('idExpirationDate', toEmpty(idResult.expirationDate), config);
  }

  const canSubmit = identification || formState.isDirty;

  return (
    <>
      <Stack
        as="form"
        justify="stretch"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Header title="ID Verification">
          <ButtonShape
            type="button"
            size={Size.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={() => navigate('customer-info')}
          />
        </Header>

        <CheckBar data-testid="check-bar">
          {isNplex && invoice.pseCheckResult !== 1 && (
            <Row align="center" gutter={Gutter.XXL}>
              {(!formState.isSubmitted && identification) ||
              (formState.isSubmitted &&
                !errors.idNumber &&
                !errors.firstName &&
                !errors.lastName &&
                !errors.idExpirationDate &&
                !errors.address1 &&
                !errors.city &&
                !errors.state &&
                !errors.zipCode &&
                isBeforeNow(watch('dob'))) ? (
                <IndicatorCircle
                  icon="Checkmark"
                  variant="success"
                  data-testid="nplex-indicator"
                />
              ) : formState.isSubmitted ? (
                <IndicatorCircle
                  icon="X"
                  variant="error"
                  data-testid="nplex-indicator"
                />
              ) : (
                <IndicatorCircle
                  icon="Pending"
                  variant="gray"
                  data-testid="nplex-indicator"
                />
              )}
              <Text variant={TV.Main} style={{marginLeft: 12}}>
                NPLEx
              </Text>
            </Row>
          )}

          {isControlledSubstance && (
            <Row align="center" gutter={Gutter.XXL}>
              {(!formState.isSubmitted && identification) ||
              (formState.isSubmitted &&
                !errors.idExpirationDate &&
                !errors.idNumber &&
                !errors.zipCode &&
                isBeforeNow(watch('dob'))) ? (
                <IndicatorCircle
                  icon="Checkmark"
                  variant="success"
                  data-testid="controlled-substance-indicator"
                />
              ) : formState.isSubmitted ? (
                <IndicatorCircle
                  icon="X"
                  variant="error"
                  data-testid="controlled-substance-indicator"
                />
              ) : (
                <IndicatorCircle
                  icon="Pending"
                  variant="gray"
                  data-testid="controlled-substance-indicator"
                />
              )}
              <Text variant={TV.Main} style={{marginLeft: 12}}>
                Controlled Substance
              </Text>
            </Row>
          )}

          {isRequiredAge && (
            <Row align="center" gutter={Gutter.XXL}>
              {(!formState.isSubmitted && identification) ||
              (formState.isSubmitted &&
                age &&
                diffYears(watch('dob')) >= age) ? (
                <IndicatorCircle
                  icon="Checkmark"
                  variant="success"
                  data-testid="age-restricted-indicator"
                />
              ) : formState.isSubmitted ? (
                <IndicatorCircle
                  icon="X"
                  variant="error"
                  data-testid="age-restricted-indicator"
                />
              ) : (
                <IndicatorCircle
                  icon="Pending"
                  variant="gray"
                  data-testid="age-restricted-indicator"
                />
              )}
              <Text variant={TV.Main} style={{marginLeft: 12}}>
                Age Restricted
              </Text>
            </Row>
          )}
        </CheckBar>

        <ScrollContainer>
          <Row gutter={Gutter.XL} style={{marginTop: '10px'}}>
            <Stack gutter={Gutter.L} style={{flex: 1}}>
              <Controller
                as={
                  <TextInput
                    label={`ID Number ${requiredStar('idNumber')}`}
                    isError={!!errors.idNumber}
                    value={watch('idNumber') || ''}
                    inputMode="numeric"
                  />
                }
                name="idNumber"
                data-testid="idNumber"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`First Name ${requiredStar('firstName')}`}
                    isError={!!errors.firstName}
                    value={watch('firstName') || ''}
                  />
                }
                name="firstName"
                data-testid="firstName"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`Middle Name ${requiredStar('middleName')}`}
                    isError={!!errors.middleName}
                    value={watch('middleName') || ''}
                  />
                }
                name="middleName"
                data-testid="middleName"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`Last Name ${requiredStar('lastName')}`}
                    isError={!!errors.lastName}
                    value={watch('lastName') || ''}
                  />
                }
                name="lastName"
                data-testid="lastName"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`Date of Birth ${requiredStar('dob')}`}
                    isError={!!errors.dob}
                    value={watch('dob')}
                    mask="99/99/9999"
                    inputMode="numeric"
                  />
                }
                name="dob"
                data-testid="dob"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`Expiration Date ${requiredStar(
                      'idExpirationDate',
                    )}`}
                    isError={!!errors.idExpirationDate}
                    value={watch('idExpirationDate')}
                    mask="99/99/9999"
                    inputMode="numeric"
                  />
                }
                name="idExpirationDate"
                data-testid="idExpirationDate"
                control={control}
              />
            </Stack>
            <Stack gutter={Gutter.L} style={{flex: 1}}>
              <Controller
                as={
                  <TextInput
                    label={`Address 1 ${requiredStar('address1')}`}
                    isError={!!errors.address1}
                    value={watch('address1') || ''}
                  />
                }
                name="address1"
                data-testid="address1"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`Address 2 ${requiredStar('address2')}`}
                    isError={!!errors.address2}
                    value={watch('address2') || ''}
                  />
                }
                name="address2"
                data-testid="address2"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`City ${requiredStar('city')}`}
                    isError={!!errors.city}
                    value={watch('city') || ''}
                  />
                }
                name="city"
                data-testid="city"
                control={control}
              />
              <Controller
                render={({onChange, value, name}) => (
                  <Select
                    name={name}
                    selectSize={SS.Small}
                    label={`State ${requiredStar('state')}`}
                    options={usStates.map(({value}) => value)}
                    optionsText={usStates.map(({text}) => text)}
                    value={value}
                    onChangeValue={onChange}
                    isError={!!errors.state}
                  />
                )}
                name="state"
                data-testid="state"
                control={control}
              />
              <Controller
                as={
                  <TextInput
                    label={`Zip Code ${requiredStar('zipCode')}`}
                    isError={!!errors.zipCode}
                    value={watch('zipCode') || ''}
                    inputMode="numeric"
                  />
                }
                name="zipCode"
                data-testid="zipCode"
                control={control}
              />
              <Controller
                render={({onChange, value, name}) => (
                  <Select
                    name={name}
                    selectSize={SS.Small}
                    label={`Gender ${requiredStar('gender')}`}
                    options={['M', 'F', 'X']}
                    optionsText={['Male', 'Female', 'Other']}
                    value={value}
                    onChangeValue={onChange}
                    isError={!!errors.gender}
                  />
                )}
                name="gender"
                data-testid="gender"
                control={control}
              />
            </Stack>
          </Row>
        </ScrollContainer>
        <FooterGroup>
          <Button
            type="button"
            variant={
              identification || formState.isDirty ? BV.Danger : BV.Secondary
            }
            flex
            onClick={() => {
              if (identification || formState.isDirty) {
                setShowBackModal(true);
                logAlert(AlertLogTypes.PendingChanges);
              } else {
                onBack();
              }
            }}
          >
            Back
          </Button>
          <Button
            type="submit"
            flex
            disabled={!canSubmit}
            loading={loadingPseCheck}
          >
            Confirm
          </Button>
        </FooterGroup>
      </Stack>

      <PageBarcodeScanner
        onScan={onScan}
        enabledSymbologies={[Symbology.PDF417]}
      />

      <PendingChangesModal
        visible={showBackModal}
        onCancel={() => setShowBackModal(false)}
        onContinue={() => {
          onUpdateInvoice({
            ...(identification && {
              ...identification,
              isDeleted: true,
              isSynced: false,
            }),
            extras: invoice.extras.map(extra =>
              extra.dataKey === COMPLIANCE_DATA_KEY
                ? {
                    ...extra,
                    dataValue: JSON.stringify(EMPTY_COMPLIANCE),
                    isSynced: false,
                  }
                : extra,
            ),
          });

          setShowBackModal(false);
          onBack();
        }}
      />
      <Modal
        visible={showNplexDeniedModal}
        icon="IdCard"
        color="warning"
        title="NPLEx Verification Denied"
        subtitle="This customer was not approved to purchase PSE items. Please remove item(s) from the sale."
        buttonText="Okay"
        onContinue={() => navigate('/sales/transactions')}
      />
    </>
  );
}

export default memo(IdCheck);
