import {theme} from '@emporos/components';
import {createGlobalStyle} from 'styled-components';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

export default createGlobalStyle<{theme: typeof theme}>`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  body {
    background-color: ${({theme}) => {
      return theme.colors.gray_100;
    }};
    margin: 0;
    padding: 0;
  }

  html,
  body {
    overflow: hidden;
    position: relative;
  }
`;
