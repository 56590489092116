import {Navbar} from '@emporos/components-pos';
import assert from 'assert';
import {memo, useCallback} from 'react';
import {AuthClaim} from '../../../auth/const';
import {useAuthentication} from '../../../contexts/AuthenticationProvider';
import {useSyncSession} from '../../../contexts/SyncSessionProvider';
import {useTransactionsConfig} from '../../../contexts/TransactionsConfigProvider';
import CloseSession from './CloseSession';
import {RouteComponentProps} from '@reach/router';

function CloseSessionContainer(_: RouteComponentProps) {
  const {user, logout} = useAuthentication();
  const {sessionStatus, syncSession} = useSyncSession();
  const {setSessionClosed} = useTransactionsConfig();

  const onNewSession = useCallback(() => {
    setSessionClosed(false);
  }, []);

  assert(
    user !== null,
    'Internal Error: tried to render CloseSession without an authenticated user',
  );

  return (
    <>
      <Navbar
        username={user.profile[AuthClaim.Name]}
        onSessionStatus={syncSession}
        sessionStatus={sessionStatus}
        isLoadingData={false}
        onLogout={logout}
        hideSessionStatus={true}
      />
      <CloseSession onLogout={logout} onNewSession={onNewSession} />
    </>
  );
}

export default memo(CloseSessionContainer);
