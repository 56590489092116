import {AlertBannerStack, NotificationAlert} from '@emporos/components';
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import {v4 as uuid} from 'uuid';
import {AlertLogTypes, useLog} from './LoggingProvider';

export type NotificationFn = (alert: Omit<NotificationAlert, 'id'>) => void;

export type AlertStateContext = {
  notification: NotificationFn;
  reset: () => void;
};
export interface AlertStateProviderProps {
  notification: NotificationFn;
  reset: () => void;
}

export const alertStateContext = createContext<AlertStateContext>({
  notification: console.log,
  reset: console.log,
});

function AlertStateProvider({children}: {children: ReactNode}): ReactElement {
  const {logAlert} = useLog();
  const [alerts, setAlerts] = useState<Array<NotificationAlert>>([]);

  const notification = (alert: Omit<NotificationAlert, 'id'>) => {
    setAlerts(prevAlerts => {
      const id = uuid();
      setTimeout(() => removeAlert(id), 1000 * 30);
      return [...prevAlerts, {...alert, id}];
    });
    const statusType =
      alert.type === 'warning'
        ? 'warn'
        : alert.type === 'general' || alert.type === 'success'
        ? 'info'
        : alert.type;
    logAlert(AlertLogTypes.AlertTriggered, {...alert}, statusType);
  };

  const reset = () => setAlerts([]);

  const removeAlert = (_id: string) =>
    setAlerts(prevAlerts => prevAlerts.filter(({id}) => id !== _id));

  return (
    <>
      <AlertBannerStack alerts={alerts} onDismiss={removeAlert} />
      <alertStateContext.Provider
        value={{
          notification,
          reset,
        }}
      >
        {children}
      </alertStateContext.Provider>
    </>
  );
}
const useAlertState = (): AlertStateProviderProps =>
  useContext(alertStateContext);
export {AlertStateProvider, useAlertState};
