import {
  Gutter,
  IndicatorCircle,
  RemovePadding,
  Row,
  RowSidebar,
  Stack,
} from '@emporos/components';
import Text, {Variant} from '@emporos/components/src/Text';
import {RouteComponentProps} from '@reach/router';
import {memo, useEffect} from 'react';
import {InvoiceLogTypes, useLog} from '../../../contexts/LoggingProvider';
import {useTransactionsState} from '../../../contexts/TransactionsStateProvider';
import useSession from '../../../hooks/useSession';
import {
  behindTabBarStackingContext,
  fullHeightStack,
  invoiceSynced,
} from '../../../routes/Sidebar';

function CompletedSidebar({
  requestedUrl,
  onConfirmNavigation,
}: RouteComponentProps & {
  requestedUrl: string | null;
  onCancelNavigation(): void;
  onConfirmNavigation(): void;
}) {
  const {logUserSelection} = useLog();
  const {currentInvoiceId, setCurrentInvoiceId} = useTransactionsState();
  const {completedInvoices: invoices} = useSession();

  // autoconfirm navigation
  useEffect(() => {
    if (requestedUrl) {
      onConfirmNavigation();
    }
  }, [requestedUrl, onConfirmNavigation]);

  return invoices.length > 0 ? (
    <Stack gutter={Gutter.XL} style={fullHeightStack}>
      <Row align="center">
        <Text variant={Variant.T1}>Completed</Text>
      </Row>

      <RemovePadding style={behindTabBarStackingContext}>
        {invoices.map(iv => (
          <RowSidebar
            key={iv.invoiceId}
            title={
              iv.customer
                ? `${iv.customer.firstName} ${iv.customer.lastName}`
                : 'General Sale'
            }
            // TODO: make this dynamic when we have different types of transactions
            icon="Bed"
            iconColor="indigo"
            badgeText={iv.roomNumber || ''}
            selected={iv.invoiceId === currentInvoiceId}
            onClick={() => {
              setCurrentInvoiceId(iv.invoiceId);
              logUserSelection(InvoiceLogTypes.Selection, {
                item: iv.invoiceId,
              });
            }}
            synced={invoiceSynced(iv)}
          />
        ))}
        <div style={{height: 96}}>
          {/* TODO: just here to fix the layout */}
        </div>
      </RemovePadding>
    </Stack>
  ) : (
    <>
      <Row align="center">
        <Text variant={Variant.T1}>Completed</Text>
      </Row>
      <Stack align="center" gutter={Gutter.XL}>
        <IndicatorCircle icon="Checkmark" variant="gray" size="large" />
        <Text variant={Variant.Main} align="center">
          No completed transactions
        </Text>
      </Stack>
      <div style={{height: 70}}>{/* TODO: just here to fix the layout */}</div>
    </>
  );
}

export default memo(CompletedSidebar);
