import {
  BarcodeCollection,
  OtcItem,
  Setting,
} from '@emporos/api-enterprise/src/gen';
import {
  Invoice,
  InvoiceItem,
  InvoiceTax,
} from '@emporos/api-enterprise/src/gen-session';
import {ScanResult} from '@emporos/barcodes';
import {
  Button,
  ButtonShape,
  ButtonSquareText,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  IndicatorCircle,
  Modal,
  NumberPanel,
  PriceFooter,
  Row,
  RowItem,
  ScrollContainer,
  ButtonSize as Size,
  SmallSidebarWrapper,
  Stack,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import Text, {Variant as TV, Variant} from '@emporos/components/src/Text';
import {NavigateFn} from '@reach/router';
import {Dispatch, memo, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {OfflineSynced} from '../../../api/common';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../contexts/AnalyticsProvider';
import {InvoiceLogTypes, useLog} from '../../../contexts/LoggingProvider';
import {InvoiceUpdates} from '../../../contexts/TransactionsStateProvider';
import {
  MultiSelectMode,
  MultiSelectResult,
} from '../../../hooks/useMultiSelect';
import {TotalsResult} from '../../../hooks/useTotals';
import {formatCustomerName} from '../../../utils/customer';
import {
  updateDiscount,
  updatePrice,
  updateQuantity,
} from '../../../utils/invoiceItems';
import {invoiceItemIcons} from '../../../utils/rowIcons';
import {formatMedication} from '../../../utils/string';
import mapComplianceIndicators from './payments/compliance/ComplianceIndicators';
import mapIdCheckIndicators from './payments/id-check/IdCheckIndicators';

const RightSidebar = styled(SmallSidebarWrapper)`
  padding: 0 20px 20px;
  @media (max-width: 1034px) {
    padding: 0 12px 20px 12px;
  }
`;
const ActionButton = styled(ButtonSquareText)`
  margin-top: 20px;
  width: 96px;
  @media (max-width: 1077px) {
    width: 85px;
  }
  @media (max-width: 1047px) {
    width: 75px;
  }
`;

interface Props {
  invoice: Invoice;
  canDeleteInvoice: boolean;
  totals: TotalsResult;
  settings: Setting[];
  otcItems: OtcItem[];
  barcodes: BarcodeCollection;
  multiselect: MultiSelectResult<string>;
  navigate: NavigateFn;
  onRemoveInvoice: (invoice: Invoice) => void;
  onUpdateInvoice: (updates: InvoiceUpdates) => void;
  onUpdateInvoiceItem: (
    item: InvoiceItem,
    updates: Partial<InvoiceItem>,
  ) => void;
  setCurrentInvoiceId: Dispatch<SetStateAction<string>>;
  onScan: (scanResult: ScanResult) => void;
}

function Transaction({
  invoice,
  canDeleteInvoice,
  totals,
  settings,
  multiselect,
  navigate,
  onRemoveInvoice,
  onUpdateInvoice,
  onUpdateInvoiceItem,
  setCurrentInvoiceId,
  onScan,
}: Props): JSX.Element {
  const {track} = useAnalyticsProvider();
  const {logUserSelection} = useLog();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showChangePriceNumpad, setShowChangePriceNumpad] = useState(false);
  const [showDiscountNumpad, setShowDiscountNumpad] = useState(false);
  const [showQuantityNumpad, setShowQuantityNumpad] = useState(false);
  const [numpadNumber, setNumpadNumber] = useState('');

  const {isNplex, isControlledSubstance, isRequiredAge} = mapIdCheckIndicators(
    invoice,
  );
  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    invoice,
    settings,
  );

  const {invoiceId, items, customer, taxes} = invoice;

  const single = multiselect.single();
  const createClickHandlersForItem = (item: InvoiceItem) => {
    return {
      onClick: () => {
        if (showChangePriceNumpad || showDiscountNumpad || showQuantityNumpad) {
          return;
        }

        if (multiselect.isSelected(item.invoiceItemId)) {
          multiselect.deselect(item.invoiceItemId);
        } else if (multiselect.mode === MultiSelectMode.Multiple) {
          multiselect.selectMultiple(item.invoiceItemId);
        } else {
          multiselect.selectOne(item.invoiceItemId);
        }
      },
      onLongClick: () => {
        if (showChangePriceNumpad || showDiscountNumpad || showQuantityNumpad) {
          return;
        }

        if (multiselect.isSelected(item.invoiceItemId)) {
          multiselect.deselect(item.invoiceItemId);
        } else {
          multiselect.selectMultiple(item.invoiceItemId);
        }
      },
    };
  };

  return (
    <>
      <Stack gutter={Gutter.None} style={{height: '100%'}}>
        <Header
          title={customer ? formatCustomerName(customer) : 'General Sale'}
          badgeText={invoice.roomNumber || ''}
          data-testid="navbar-header"
        >
          <ButtonShape
            size={Size.Small}
            icon="User"
            onClick={() => navigate('customer')}
            data-testid="customer-info-btn"
          />
          <ButtonShape
            disabled={!canDeleteInvoice}
            size={Size.Small}
            icon="Trash"
            onClick={() => setDeleteModalOpen(true)}
            data-testid="delete"
          />
        </Header>

        <Row
          gutter={Gutter.XXL}
          style={{height: '100%', marginTop: 20, minHeight: 0}}
          noWrap
        >
          <Stack style={{flex: 1}}>
            {!items.length && (
              <Stack align="center" justify="center" style={{flex: 1}}>
                <IndicatorCircle
                  icon="PrescriptionBottle"
                  variant="gray"
                  size="large"
                />
                <Text variant={Variant.Main} align="center">
                  No items added
                </Text>
              </Stack>
            )}

            {!!items.length && (
              <ScrollContainer>
                {items.some(({rx}) => rx) && (
                  <Stack gutter={Gutter.S} style={{marginBottom: 16}}>
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Prescriptions
                    </Text>
                    {items
                      .filter(({rx}) => rx)
                      .map(item => (
                        <RowItem
                          key={item.invoiceItemId}
                          title={formatMedication(item.description2 || '')}
                          subtitle={item.receiptDescription || ''}
                          rightIcons={invoiceItemIcons(item)}
                          rightText={`$${item.price.toFixed(2)}`}
                          selected={multiselect.isSelected(item.invoiceItemId)}
                          {...createClickHandlersForItem(item)}
                        />
                      ))}
                  </Stack>
                )}
                {items.some(({rx}) => !rx) && (
                  <Stack gutter={Gutter.S}>
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Over the Counter
                    </Text>
                    {items
                      .filter(({rx}) => !rx)
                      .map(item => (
                        <RowItem
                          key={item.invoiceItemId}
                          inactive={
                            item.itemTypeID === 16 &&
                            invoice.pseCheckResult === 1
                          }
                          variant="generic"
                          title={formatMedication(item.description || '')}
                          subtitle={item.itemNumber || ''}
                          rightIcons={invoiceItemIcons(item)}
                          rightText={`$${item.price.toFixed(2)}`}
                          rightBadgeColor="success"
                          rightBadgeText={
                            item.discountPercent
                              ? `${(item.discountPercent * 100).toFixed(0)}%`
                              : ''
                          }
                          rightStrikeText={
                            item.price !== item.listPrice && item.discount === 0
                              ? `$${item.listPrice.toFixed(2)}`
                              : ''
                          }
                          selected={multiselect.isSelected(item.invoiceItemId)}
                          quantity={item.quantity.toString()}
                          {...createClickHandlersForItem(item)}
                        />
                      ))}
                  </Stack>
                )}
              </ScrollContainer>
            )}

            <Stack style={{marginTop: 0}}>
              <PriceFooter
                totals={totals}
                style={{marginBottom: 16, marginTop: 0}}
              />
              <FooterGroup>
                <Button
                  variant={BV.Secondary}
                  flex
                  onClick={() => navigate('otc-search')}
                >
                  OTC Search
                </Button>
                <Button
                  flex
                  disabled={!items.length}
                  onClick={() => {
                    onUpdateInvoice({
                      isSynced: false,
                      taxableSubTotal: totals.taxableSubTotal,
                      discount: totals.discount,
                      subTotal: totals.subTotal,
                      totalSale: totals.transactionTotal,
                      totalTax: totals.salesTax,
                      qhpRxAmount: totals.qhpRxAmount,
                      qhpAmount: totals.qhpAmount,
                      qhpRxQty: totals.qhpRxQty,
                      qhpOtherAmount: totals.qhpOtherAmount,
                      qhpOtherQty: totals.qhpOtherQty,
                      taxes: taxes
                        .map(
                          t =>
                            ({
                              ...t,
                              isDeleted: true,
                              isSynced: false,
                            } as InvoiceTax & OfflineSynced),
                        )
                        .concat(
                          totals.invoiceTaxes.filter(
                            ({taxAmount}) => !!taxAmount,
                          ),
                        ),
                    } as Partial<Invoice> & OfflineSynced);

                    const url =
                      (isNplex && invoice.pseCheckResult !== 1) ||
                      isControlledSubstance ||
                      isRequiredAge
                        ? './payments/id-check'
                        : showCounsel || showHipaa || showRelationship
                        ? './payments/compliance'
                        : './payments/customer-payment';
                    return navigate(url, {
                      state: {animatePanel: true},
                    });
                  }}
                >
                  Accept Payment
                </Button>
              </FooterGroup>
            </Stack>
          </Stack>

          <RightSidebar>
            <Row justify="space-between" gutter={Gutter.None}>
              <ActionButton
                type="button"
                icon="CashRegister"
                text="Price"
                data-testid="price-button"
                disabled={
                  !single ||
                  !invoice.items.some(
                    ({invoiceItemId, rx}) => !rx && invoiceItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = invoice.items.find(
                    ({invoiceItemId, rx}) => !rx && invoiceItemId === single,
                  );
                  setNumpadNumber(item ? item.price.toFixed(2) : '0.00');
                  setShowChangePriceNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="Percent2"
                text="Discount"
                data-testid="discount-button"
                disabled={
                  !single ||
                  !invoice.items.some(
                    ({invoiceItemId, rx}) => !rx && invoiceItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = invoice.items.find(
                    ({invoiceItemId, rx}) => !rx && invoiceItemId === single,
                  );
                  setNumpadNumber(
                    item ? (item.discountPercent * 100).toFixed(0) : '0',
                  );
                  setShowDiscountNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="Quantity"
                text="Quantity"
                data-testid="quantity-button"
                disabled={
                  !single ||
                  !invoice.items.some(
                    ({invoiceItemId, rx}) => !rx && invoiceItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = invoice.items.find(
                    ({invoiceItemId, rx}) => !rx && invoiceItemId === single,
                  );
                  setNumpadNumber(item ? item.quantity.toString() : '0');
                  setShowQuantityNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="XCircle"
                text="Remove"
                disabled={multiselect.size === 0}
                onClick={() => {
                  onUpdateInvoice(prevInvoice => ({
                    items: prevInvoice.items.map(invoiceItem =>
                      multiselect.isSelected(invoiceItem.invoiceItemId)
                        ? {...invoiceItem, isDeleted: true, isSynced: false}
                        : invoiceItem,
                    ),
                  }));
                  logUserSelection(InvoiceLogTypes.RemoveItem, {
                    item: items.filter(({invoiceItemId}) =>
                      multiselect.isSelected(invoiceItemId),
                    ),
                  });

                  multiselect.reset();
                }}
              />
            </Row>
          </RightSidebar>
        </Row>

        {single && (
          <>
            <NumberPanel
              title="Discount"
              type="percent"
              number={numpadNumber}
              setNumber={n => setNumpadNumber(Number(n) > 100 ? '100' : n)}
              defaultValue="0%"
              onClear={() => setNumpadNumber('0')}
              onConfirm={() => {
                const item = items.find(
                  ({invoiceItemId}) => invoiceItemId === single,
                );
                if (!item) {
                  return;
                }
                const percent = Number(numpadNumber.replace('%', '')) / 100;
                const updatedItem = updateDiscount(item, percent);
                track(AnalyticType.ItemDiscount, {
                  item: {
                    productId: item.itemNumber || '',
                    name: item.description || '',
                  },
                  discount: percent,
                  oldPrice: item.itemListPrice,
                  price: updatedItem.extension,
                  type: item.rx ? 'prescription' : 'otc',
                });
                onUpdateInvoiceItem(item, {
                  ...updatedItem,
                });
                setShowDiscountNumpad(false);
              }}
              onCancel={() => {
                setShowDiscountNumpad(false);
              }}
              open={showDiscountNumpad}
            />

            <NumberPanel
              title="Quantity"
              number={numpadNumber}
              setNumber={n =>
                setNumpadNumber(Number(n) > 99 ? '99' : Number(n) < 1 ? '1' : n)
              }
              onClear={() => setNumpadNumber('')}
              onConfirm={() => {
                const item = items.find(
                  ({invoiceItemId}) => invoiceItemId === single,
                );
                if (!item) {
                  return;
                }

                const quantity = Number(numpadNumber);
                const updatedItem = updateQuantity(item, quantity);
                onUpdateInvoiceItem(item, {
                  ...updatedItem,
                });
                logUserSelection(InvoiceLogTypes.UpdateQuantity, {
                  item: item.description || '',
                  value: quantity,
                });
                setShowQuantityNumpad(false);
              }}
              onCancel={() => {
                setShowQuantityNumpad(false);
              }}
              open={showQuantityNumpad}
            />
            {items.some(item => !item.rx) && (
              <NumberPanel
                title="Price Change"
                type="price"
                number={numpadNumber}
                setNumber={setNumpadNumber}
                defaultValue="$0.00"
                onClear={() => setNumpadNumber('0')}
                onConfirm={() => {
                  const item = items.find(
                    ({invoiceItemId}) => invoiceItemId === single,
                  );
                  if (!item) {
                    return;
                  }
                  const price = Number(numpadNumber);
                  const updatedItem = updatePrice(item, price);
                  track(AnalyticType.PriceChange, {
                    item: {
                      productId: item.itemNumber || '',
                      name: item.description || '',
                    },
                    oldPrice: item.itemListPrice,
                    price,
                  });
                  onUpdateInvoiceItem(item, {
                    ...updatedItem,
                  });
                  setShowChangePriceNumpad(false);
                }}
                onCancel={() => {
                  setShowChangePriceNumpad(false);
                }}
                open={showChangePriceNumpad}
              />
            )}
          </>
        )}

        <Modal
          visible={deleteModalOpen}
          icon="Trash"
          color="error"
          onCancel={() => setDeleteModalOpen(false)}
          onContinue={() => {
            onRemoveInvoice(invoice);
            logUserSelection(InvoiceLogTypes.RemoveInvoice, {
              item: invoiceId,
            });
            setCurrentInvoiceId('');
            return navigate('/sales');
          }}
          title="Delete This Transaction"
          subtitle="This transaction will be deleted and cannot be recovered. Would you like to continue?"
        />
      </Stack>
      {!showChangePriceNumpad && !showQuantityNumpad && !showDiscountNumpad && (
        <PageBarcodeScanner onScan={onScan} />
      )}
    </>
  );
}

export default memo(Transaction);
