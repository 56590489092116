/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvoiceIdentification,
    InvoiceIdentificationFromJSON,
    InvoiceIdentificationToJSON,
    InvoiceIdentificationRequest,
    InvoiceIdentificationRequestFromJSON,
    InvoiceIdentificationRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGetRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDeleteRequest {
    sessionId: string;
    invoiceId: string;
    invoiceIdentificationId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPutRequest {
    sessionId: string;
    invoiceId: string;
    invoiceIdentificationRequest?: InvoiceIdentificationRequest;
}

/**
 * 
 */
export class InvoiceIdentificationsApi extends runtime.BaseAPI {

    /**
     * Gets an  invoiceIdentifications for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGetRequest): Promise<runtime.ApiResponse<InvoiceIdentification>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/identification`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceIdentificationFromJSON(jsonValue));
    }

    /**
     * Gets an  invoiceIdentifications for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGetRequest): Promise<InvoiceIdentification> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an invoiceIdentification
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDelete.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDelete.');
        }

        if (requestParameters.invoiceIdentificationId === null || requestParameters.invoiceIdentificationId === undefined) {
            throw new runtime.RequiredError('invoiceIdentificationId','Required parameter requestParameters.invoiceIdentificationId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/identification/{invoiceIdentificationId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoiceIdentificationId"}}`, encodeURIComponent(String(requestParameters.invoiceIdentificationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an invoiceIdentification
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDelete(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationInvoiceIdentificationIdDeleteRaw(requestParameters);
    }

    /**
     * Inserts or updates a invoiceIdentification
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPutRequest): Promise<runtime.ApiResponse<InvoiceIdentification>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPut.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/identification`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceIdentificationRequestToJSON(requestParameters.invoiceIdentificationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceIdentificationFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a invoiceIdentification
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPut(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPutRequest): Promise<InvoiceIdentification> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdIdentificationPutRaw(requestParameters);
        return await response.value();
    }

}
