import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {useGlobalData} from '../../../../../contexts/GlobalDataProvider';
import {useLog, UserLogTypes} from '../../../../../contexts/LoggingProvider';
import {COMPLIANCE_DATA_KEY} from '../../../../../utils/mappers';
import {
  InvoiceExtra,
  InvoiceItem,
} from '@emporos/api-enterprise/src/gen-session';
import {OfflineSynced} from '../../../../../api/common';
import useInvoice from '../../../../../hooks/useInvoice';
import {useConfig} from '../../../../../contexts/ConfigProvider';
import {withChildPage} from '../../../../../hocs/withChildPage';
import {Compliance} from './Compliance';

export const COUNSEL_LATER = 'counsel_later';
export const NO_COUNSELING = 'no_counseling';

export const YES_NUM = 0;
export const NO_NUM = 1;
export const YES = 'yes';
export const NO = 'no';

export interface ComplianceForm {
  counseling: string | null;
  hipaa: string | null;
  relationship: string | null;
}

export const EMPTY_COMPLIANCE = {
  counseling: null,
  hipaa: null,
  relationship: null,
};

export enum NavigateTo {
  IDCheck,
  Transactions,
}

const mapExtrasFromCompliance = (
  extras: Array<InvoiceExtra>,
  form: ComplianceForm,
): Array<InvoiceExtra> => {
  return extras.map(extra =>
    extra.dataKey === COMPLIANCE_DATA_KEY
      ? {
          ...extra,
          isSynced: false,
          dataValue: JSON.stringify({...EMPTY_COMPLIANCE, ...form}),
        }
      : extra,
  );
};

function ComplianceIntegration({
  navigate,
  location,
}: RouteComponentProps<{
  location: {
    state: {
      animatePanel?: boolean;
    };
  };
}>): JSX.Element {
  const {settingsResult, relationshipsResult} = useGlobalData();
  const {logUserSelection} = useLog();
  const {NodeEnv} = useConfig();

  const {invoice, updateInvoice} = useInvoice();

  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<ComplianceIntegration /> must have a `navigate` prop.' + NodeEnv ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
    assert(invoice, 'Missing invoice');
    assert(settingsResult && settingsResult.data, 'Missing settingsResult');
    assert(
      relationshipsResult && relationshipsResult.data,
      'Missing relationshipsResult',
    );
  }

  return (
    <Compliance
      invoice={invoice}
      defaultAnimatePanel={!location?.state.animatePanel}
      settings={settingsResult.data}
      relationships={relationshipsResult.data}
      navigate={navigate}
      onBack={navigateTo => {
        switch (navigateTo) {
          case NavigateTo.IDCheck:
            navigate('../id-check');
            break;
          case NavigateTo.Transactions:
            // time for animation to complete, then navigate
            setTimeout(() => navigate('../../'), 400);
        }
      }}
      onSubmit={(form: ComplianceForm) => {
        logUserSelection(UserLogTypes.ComplianceForm, {
          data: form,
        });
        const {counseling, hipaa, relationship} = form;

        updateInvoice({
          extras: mapExtrasFromCompliance(invoice.extras, form),
          items: invoice.items.map(item =>
            item.rx
              ? ({
                  ...item,
                  isSynced: false,
                  counselIndicator: counseling && counseling !== NO_COUNSELING,
                  counselPromptIndicator: !!counseling,
                  counselLater: counseling === COUNSEL_LATER,
                  hipaaCompliant: hipaa === YES ? 1 : 0,
                  hipaaRelationship: relationship,
                  rxRelationship: relationship,
                } as InvoiceItem & OfflineSynced)
              : item,
          ),
        });

        return navigate('../customer-payment');
      }}
    />
  );
}

export default memo(withChildPage(ComplianceIntegration));
