import {
  Customer,
  InvoicePayment,
} from '@emporos/api-enterprise/src/gen-session';
import {PaymentType} from '@emporos/api-enterprise/src/gen/index';
import {
  AllIcons,
  Card,
  Gutter,
  PriceFooter,
  RowItem,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import Text, {Variant} from '@emporos/components/src/Text';
import {navigate, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import moment from 'moment';
import {Dispatch, memo, SetStateAction} from 'react';
import {useGlobalData} from '../../../../contexts/GlobalDataProvider';
import {useSettings} from '../../../../contexts/SettingsProvider';
import {useTransactionsState} from '../../../../contexts/TransactionsStateProvider';
import useInvoice from '../../../../hooks/useInvoice';
import useTotals, {TotalsResult} from '../../../../hooks/useTotals';
import {buildCustomerCardProps} from '../../../../utils/customer';
import {displayPaymentNumber, getPaymentIcon, getPaymentType} from './utils';

interface Props {
  customer?: Customer;
  processedPayments: InvoicePayment[];
  pendingPayments: InvoicePayment[];
  voidedPayments: InvoicePayment[];
  totals: TotalsResult;
  paymentTenders: PaymentType[];
  selectedPayment: string;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  creditCardPendingPaymentExpirationMinutes: number;
}

const PaymentsSidebarIntegration = (_: RouteComponentProps): JSX.Element => {
  const {paymentTendersResult} = useGlobalData();
  const {selectedPayment, setSelectedPayment} = useTransactionsState();
  const {creditCardPendingPaymentExpirationMinutes} = useSettings();

  const {
    invoice,
    processedPayments,
    pendingPayments,
    voidedPayments,
  } = useInvoice();
  const {totals} = useTotals();

  /* istanbul ignore next */
  if (true) {
    assert(invoice, 'Missing invoice');
    assert(
      paymentTendersResult && paymentTendersResult.data,
      'Missing paymentTendersResult',
    );
  }

  const {customer} = invoice;
  const paymentTenders = paymentTendersResult.data as PaymentType[];

  return (
    <PaymentSidebar
      customer={customer}
      processedPayments={processedPayments}
      pendingPayments={pendingPayments}
      voidedPayments={voidedPayments}
      totals={totals}
      paymentTenders={paymentTenders}
      selectedPayment={selectedPayment}
      setSelectedPayment={setSelectedPayment}
      creditCardPendingPaymentExpirationMinutes={
        creditCardPendingPaymentExpirationMinutes
      }
    />
  );
};

export const PaymentSidebar = ({
  customer,
  processedPayments,
  pendingPayments,
  voidedPayments,
  totals,
  paymentTenders,
  selectedPayment,
  setSelectedPayment,
  creditCardPendingPaymentExpirationMinutes,
}: Props): JSX.Element => {
  const customCardsProps = customer ? buildCustomerCardProps(customer) : {};
  const isPendingPaymentSelected = pendingPayments.some(
    ({invoicePaymentId}) => invoicePaymentId === selectedPayment,
  );

  const leftIcon = (
    recordStatus: string,
    paymentTypeID: number,
  ): keyof typeof AllIcons => {
    if (recordStatus === 'OverCharged') {
      return 'Warning';
    }
    return getPaymentIcon(paymentTypeID || 0, paymentTenders);
  };

  const getPathname = () => {
    const pathname = window.location.pathname;
    if (pathname.includes('customer-payment')) {
      return navigate(
        `/sales/transactions/payments/customer-payment/payment-info`,
      );
    } else if (pathname.includes('acknowledgements')) {
      return navigate(
        `/sales/transactions/payments/acknowledgements/payment-info`,
      );
    } else if (pathname.includes('receipts')) {
      return navigate(`/sales/transactions/payments/receipts/payment-info`);
    } else if (pathname.includes('complete')) {
      return navigate(`/sales/transactions/payments/complete/payment-info`);
    }
  };

  const onClickPayment = (invoicePaymentId: string) => {
    if (isPendingPaymentSelected) {
      return;
    }
    if (selectedPayment === invoicePaymentId) {
      setSelectedPayment('');
      return;
    }
    setSelectedPayment(invoicePaymentId);
    return getPathname();
  };

  return (
    <>
      <Card
        size="small"
        style={{marginBottom: 16}}
        customerInfo={customCardsProps}
      />

      <ScrollContainer style={{padding: '0 4px 4px', margin: '0 -4px'}}>
        <Stack gutter={Gutter.S}>
          <Text
            variant={Variant.Caption}
            style={{paddingLeft: 16, paddingBottom: 6, marginBottom: '-12px'}}
          >
            Processed Payments
          </Text>

          {!!processedPayments.length ? (
            processedPayments.map(p => {
              const {amount, invoicePaymentId, recordStatus, paymentTypeID} = p;
              return (
                <RowItem
                  key={invoicePaymentId}
                  selected={selectedPayment === invoicePaymentId}
                  onClick={() => onClickPayment(invoicePaymentId)}
                  title={getPaymentType(paymentTypeID || 0, paymentTenders)}
                  subtitle={displayPaymentNumber(p, paymentTenders)}
                  variant="payment"
                  leftIcon={leftIcon(recordStatus, paymentTypeID || 0)}
                  rightText={`$${amount.toFixed(2)}`}
                />
              );
            })
          ) : (
            <RowItem title="No Payments" variant="inverted" />
          )}

          {!!pendingPayments.length && (
            <>
              <Text
                variant={Variant.Caption}
                style={{
                  paddingLeft: 16,
                  paddingBottom: 6,
                  marginBottom: '-12px',
                  marginTop: 16,
                }}
              >
                Pending Payments
              </Text>
              {pendingPayments.map((p: InvoicePayment) => {
                const {amount, invoicePaymentId} = p;
                return (
                  <RowItem
                    key={invoicePaymentId}
                    selected={selectedPayment === invoicePaymentId}
                    onClick={() => onClickPayment(invoicePaymentId)}
                    title="Credit Card"
                    subtitle={`Expires: ${moment(p.createdOn)
                      .add(creditCardPendingPaymentExpirationMinutes, 'minutes')
                      .format('hh:mmA')}`}
                    variant="payment"
                    leftIcon="Warning"
                    rightText={`$${amount.toFixed(2)}`}
                  />
                );
              })}
            </>
          )}

          {!!voidedPayments.length && (
            <>
              <Text
                variant={Variant.Caption}
                style={{
                  paddingLeft: 16,
                  paddingBottom: 6,
                  marginBottom: '-12px',
                  marginTop: 16,
                }}
              >
                Voided Payments
              </Text>

              {voidedPayments.map(p => {
                const {
                  amount,
                  invoicePaymentId,
                  recordStatus,
                  paymentTypeID,
                } = p;
                return (
                  <RowItem
                    key={invoicePaymentId}
                    selected={selectedPayment === invoicePaymentId}
                    onClick={() => onClickPayment(invoicePaymentId)}
                    title="Credit Card"
                    subtitle={displayPaymentNumber(p, paymentTenders)}
                    variant="payment"
                    leftIcon={leftIcon(recordStatus, paymentTypeID || 0)}
                    rightText={`-$${amount.toFixed(2)}`}
                  />
                );
              })}
            </>
          )}
        </Stack>
      </ScrollContainer>
      <PriceFooter totals={totals} size="small" />
    </>
  );
};

export default memo(PaymentsSidebarIntegration);
