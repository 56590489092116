import {memo} from 'react';
import {RouteComponentProps} from '@reach/router';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../../../contexts/AnalyticsProvider';
import {Invoice} from '@emporos/api-enterprise/src/gen-session';
import assert from 'assert';
import CompleteTransaction from './CompleteTransaction';
import {withRootPage} from '../../../../../hocs/withChildPage';
import useInvoice from '../../../../../hooks/useInvoice';
import {useGlobalData} from '../../../../../contexts/GlobalDataProvider';
import {
  OfflineCustomer,
  OfflineInvoice,
  OfflineSynced,
} from '../../../../../api/common';
import {useConfig} from '../../../../../contexts/ConfigProvider';

function calculateFirstFillPercent(invoice: Invoice) {
  const {items, customer} = invoice;
  const totalFirstFillRx = (customer as OfflineCustomer)?.prescriptions?.filter(
    // Checking solely on fill will not work once we are fully integrated with Epic
    ({fill}) => fill === '00',
  );
  const firstFillPurchased = items.filter(({fill, rx}) => rx && fill === '00');
  if (totalFirstFillRx?.length) {
    return (firstFillPurchased.length / totalFirstFillRx.length) * 100;
  }
}

function CompleteTransactionIntegration({
  navigate,
}: RouteComponentProps): JSX.Element {
  const {track} = useAnalyticsProvider();
  const {invoice, updateInvoice} = useInvoice();
  const {taxGroupsResult} = useGlobalData();
  const {NodeEnv} = useConfig();

  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<CompleteTransaction /> must have a `navigate` prop.' + NodeEnv ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
    assert(invoice, 'Missing invoice');
  }

  return (
    <CompleteTransaction
      onBack={() => navigate('../receipts')}
      onCustomerInfo={() => navigate('customer-info')}
      onComplete={() => {
        assert(taxGroupsResult && taxGroupsResult.data, 'Missing taxes');
        updateInvoice({
          isCompleted: true,
          isSynced: true,
        } as Partial<OfflineInvoice> & OfflineSynced);
        track(AnalyticType.OrderCompleted, {
          total: invoice.totalSale,
          firstFillPickupPercent: calculateFirstFillPercent(invoice),
          products: invoice.items
            .filter(({rx}) => !rx)
            .map(({description, itemNumber, price, quantity}) => {
              return {
                category: 'OTC',
                name: description || '',
                price,
                productId: itemNumber || '',
                quantity,
              };
            }),
          saleDuration: +new Date() - +invoice.saleDate,
        });

        setTimeout(() => navigate('/sales'), 400);
      }}
    />
  );
}

export default memo(withRootPage(CompleteTransactionIntegration));
