import {
  Customer,
  CustomerAccount,
} from '@emporos/api-enterprise/src/gen-session';
import {
  Button,
  Variant as BV,
  Gutter,
  Modal,
  Row,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import SelectCustom from '@emporos/components/src/SelectCustom';
import Text, {Variant as TV, Variant} from '@emporos/components/src/Text';
import moment from 'moment';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {ars, formatCustomerName} from '../../../../../utils/customer';
import BillBoard from './BillBoard';
import {PaymentRef as Handle} from './Types';

const createCustomerAccount = (
  props?: Partial<CustomerAccount>,
): CustomerAccount => ({
  accountId: 0,
  customerId: 0,
  accountTypeId: 0,
  accountType: '',
  isPrimary: false,
  accountNumber: '',
  creditLimit: 0,
  balance: 0,
  currentBalance: 0,
  past30Balance: 0,
  past60Balance: 0,
  over90Balance: 0,
  creditHoldIndicator: false,
  ...props,
});

const NEW_ACCOUNT_ID = '0';

interface Props {
  arCreditLimit: number;
  customer: Customer;
  amount: number;
  totalDue: number;
  onChangeCustomer(): void;
  onChangeActivePayment(account: CustomerAccount): void;
  onCreateAR(): Promise<CustomerAccount | undefined>;
  setAmount: Dispatch<SetStateAction<number>>;
}

const CustomerRow = styled(Row)`
  display: flex;
  border: ${({theme}) => `1px solid ${theme.colors.gray_300}`};
  height: 80px;
  border-radius: 8px;
  padding: 16px;
`;

enum AccountType {
  Pending,
  Existing,
}

function isAcceptablePayment(
  account: CustomerAccount,
  amount: number,
  totalDue: number,
) {
  return account.creditLimit - account.balance >= amount && amount <= totalDue;
}
export default forwardRef<Handle, Props>(function ARPayment(
  {
    arCreditLimit,
    customer,
    amount,
    totalDue,
    onChangeCustomer,
    onChangeActivePayment,
    onCreateAR,
    setAmount,
  }: Props,
  ref,
): JSX.Element {
  const [isConfirmModalActive, setConfirmModal] = useState(false);
  const [selected, setSelected] = useState<
    [AccountType, CustomerAccount] | null
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const arAccountPromise = useRef<any>(null);

  const onPayWithAr = useCallback(
    async function onConfirmPayment() {
      if (arAccountPromise.current && selected) {
        return arAccountPromise.current({
          paymentId: selected[1].accountNumber,
          amountApproved: amount,
          customerAccount: selected[1],
        });
      }

      if (!selected) {
        return Promise.reject(
          new TypeError(
            'Attempted to pay with AR without having an AR account selected',
          ),
        );
      } else if (selected[1].creditLimit - selected[1].balance < amount) {
        return Promise.reject(
          new TypeError('Selected account has insufficient funds.'),
        );
      }
      if (selected[0] === AccountType.Pending) {
        setConfirmModal(true);
        // this returns back the differed callback.
        return await new Promise(resolve => {
          arAccountPromise.current = resolve;
        });
      }

      return Promise.resolve({
        paymentId: selected[1].accountNumber,
        amountApproved: amount,
        customerAccount: selected[1],
      });
    },
    [amount, selected],
  );

  useImperativeHandle(ref, () => ({
    onConfirmPayment: onPayWithAr,
    isAcceptablePayment() {
      return selected
        ? isAcceptablePayment(selected[1], amount, totalDue)
        : false;
    },
  }));

  /**
   * The `<CustomerPayment />` component has the ability to update the current
   * `customer` (will call), so we must respond to that customer by updating our
   * payment options.
   */
  const accounts = useMemo(
    () =>
      customer.accounts.length
        ? ars(customer)
        : [
            createCustomerAccount({
              balance: 0,
              accountNumber: NEW_ACCOUNT_ID,
              creditLimit: arCreditLimit,
            }),
          ],
    [customer.accounts],
  );

  /**
   * `onChangeActivePayment` is a prop from the CustomerPayment manager
   * component to listen to the lifecyle of this component and call
   * `ref.isAcceptablePayment()` to determine if the submit button is enabled or
   * not. This is an awkward React pattern, but enables us to delegate the
   * implementation of payment-specific logic within each payment type.
   */
  useEffect(() => {
    if (selected) {
      onChangeActivePayment(selected[1]);
    }
  }, [amount, selected]);

  useEffect(() => {
    setSelected(null);
  }, [customer]);

  return (
    <>
      <ScrollContainer data-testid="AR">
        <Stack gutter={Gutter.L} style={{flex: 1}}>
          <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
          <CustomerRow justify="space-between" align="center">
            <Stack gutter={Gutter.XXS}>
              <Text variant={TV.Main}>{formatCustomerName(customer)}</Text>
              <Text variant={TV.SubMainLight}>
                DOB: {moment.utc(customer.dob).format('MM/DD/YYYY')}
              </Text>
            </Stack>
            <Button
              variant={BV.Link}
              onClick={onChangeCustomer}
              style={{padding: 0}}
            >
              Change
            </Button>
          </CustomerRow>
          <Row>
            <SelectCustom<CustomerAccount>
              data-testid="select-accounts"
              label="AR Accounts"
              value={selected ? selected[1] : null}
              options={accounts}
              getOptionValue={({accountId}) => accountId.toString()}
              onChange={customerAccount => {
                if (customerAccount.accountNumber === NEW_ACCOUNT_ID) {
                  setSelected([AccountType.Pending, customerAccount]);
                } else {
                  setSelected([AccountType.Existing, customerAccount]);
                }
              }}
              renderOption={({accountNumber, balance, creditLimit}) => (
                <Stack gutter={Gutter.XXS} style={{flex: 1}}>
                  <Text variant={Variant.Main}>
                    {accountNumber === NEW_ACCOUNT_ID
                      ? 'Create New Account'
                      : `Available: $${(creditLimit - balance).toFixed(2)}`}
                  </Text>
                  <Text variant={Variant.SubMainLight}>
                    Limit: ${creditLimit.toFixed(2)}
                    {accountNumber !== NEW_ACCOUNT_ID &&
                      ` • Account: ${accountNumber}`}
                  </Text>
                </Stack>
              )}
            />
          </Row>
        </Stack>
      </ScrollContainer>
      <Modal
        data-testid="Modal__CreateArAccount"
        visible={isConfirmModalActive}
        icon="Bill"
        color="primary"
        onCancel={() => {
          arAccountPromise.current = null;
          setConfirmModal(false);
        }}
        onContinue={async () => {
          setConfirmModal(false);
          const customerAccount = await onCreateAR();
          if (customerAccount) {
            setSelected([AccountType.Existing, customerAccount]);
            arAccountPromise.current({
              paymentId: customerAccount.accountNumber,
              amountApproved: amount,
              customerAccount: customerAccount,
            });
          }
        }}
        title="Create New AR Account"
        subtitle="This will create a new account for the customer. Would you like to continue?"
      />
    </>
  );
});
