import {Redirect} from '@reach/router';
import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useMounted} from '@emporos/components';
import {useCustomerSearch} from '../../../contexts/CustomerSearchProvider';
import {useGlobalData} from '../../../contexts/GlobalDataProvider';
import {useNetworkAvailable} from '../../../contexts/NetworkAvailableProvider';
import {
  Invoice,
  useTransactionsState,
} from '../../../contexts/TransactionsStateProvider';
import {withChildPage, WithPageProps} from '../../../hocs/withChildPage';
import {useCustomerWillCall} from '../../../hooks/useCustomerWillCall';
import {usePendingInvoice} from '../../../hooks/usePendingInvoice';

import CustomerInfoPanel from './CustomerInfoPanel';
import {ItemSearchState} from '../../sales/transactions/otc-search';
import {mapInvoiceCustomer} from '../../../utils/mappers';
import {WillCallRequest} from '@emporos/api-enterprise/src/gen';
import {OfflineCustomer, OfflineInvoice} from '../../../api/common';

interface Props {
  canAddCustomer?: boolean;
}

function isInitialSearchState(state: unknown): state is ItemSearchState {
  return typeof state === 'object' && state !== null && 'rx' in state;
}

function CustomerInfoIntegrationWillCall({
  canAddCustomer = true,
  location,
  navigate,
}: PropsWithChildren<WithPageProps<Props>>) {
  const online = useNetworkAvailable();
  const {currentInvoiceId} = useTransactionsState();

  if (!currentInvoiceId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {customer: searchCustomer, setCustomer} = useCustomerSearch();
  const {
    run: runCustomerWillCall,
    customer: willCallCustomer,
    loading: willCallLoading,
  } = useCustomerWillCall();
  const {run: putWillCall} = useCustomerWillCall();
  const {pmsName, barcodesResult, barcodeName} = useGlobalData();
  const {
    invoice,
    invoiceChanged,
    commit,
    tryAttachCustomer,
  } = usePendingInvoice();

  const [initialRx, setInitialRx] = useState(
    isInitialSearchState(location?.state) ? location?.state : undefined,
  );

  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          )
        : [],
    [barcodesResult],
  );
  const customer = useMemo(() => invoice.customer, [invoice]);

  useEffect(() => {
    if (searchCustomer) {
      tryAttachCustomer(searchCustomer);
    }
  }, [searchCustomer]);

  useEffect(() => {
    if (willCallCustomer) {
      tryAttachCustomer(willCallCustomer);
    }
  }, [willCallCustomer]);

  useEffect(() => {
    if (initialRx && !customer) {
      runCustomerWillCall({
        ...initialRx,
        partial: initialRx.partial || '0',
      });
    }
  }, [initialRx, customer]);

  const mounted = useMounted();
  useEffect(() => {
    if (
      // We are within initial mount
      !mounted &&
      // and we have an existing customer
      customer &&
      customer.code &&
      // and the customer has no prescriptions loaded
      (customer as OfflineCustomer).prescriptions === undefined
    ) {
      // load customer prescriptions
      putWillCall({customerCode: customer.code}).then(result => {
        const {data} = result;
        if (!data) {
          return;
        }
        const {
          customers: [customer],
          prescriptions,
        } = data;
        // and immediately commit prescriptions to invoice
        if (customer) {
          commit({
            customer: mapInvoiceCustomer(customer, prescriptions),
          });
        }
      });
    }
  }, [invoice, customer, mounted]);

  const initialPrescription = useMemo(() => {
    if (initialRx) {
      return initialRx;
    }

    return null;
  }, [initialRx]);

  const onUnresolvedRxScan = useCallback(
    (willCallRequest: WillCallRequest) => {
      setInitialRx({
        rx: willCallRequest.rx || '',
        fill: willCallRequest.fill || '',
        // TODO refactor WillCall
        site: '0' /*willCallRequest.siteID*/,
        partial: willCallRequest.partial || '',
      });
      if (!customer) {
        runCustomerWillCall(willCallRequest);
      }
    },
    [customer],
  );

  const onAddCustomerClick = useCallback(
    () => canAddCustomer && navigate && navigate('add-customer'),
    [],
  );

  const onCancel = useCallback(() => {
    canAddCustomer && setCustomer(null);
    navigate && navigate('../');
  }, []);

  const onSave = useCallback(
    (updates: Partial<Invoice>) => {
      commit(updates);
      navigate && navigate('../');
    },
    [navigate, commit],
  );

  return (
    <CustomerInfoPanel
      barcodeComponents={barcodeComponents}
      forceCanSave={invoiceChanged}
      initialPrescription={initialPrescription}
      invoiceChanged={invoiceChanged}
      online={online}
      invoice={invoice as OfflineInvoice}
      loadingCustomer={willCallLoading}
      pmsName={pmsName}
      viewOnly={!canAddCustomer}
      onAddCustomerClick={canAddCustomer ? onAddCustomerClick : undefined}
      onCancel={onCancel}
      onSave={onSave}
      onUnresolvedRxScan={onUnresolvedRxScan}
    />
  );
}

export default memo(withChildPage(CustomerInfoIntegrationWillCall));
