import {
  AuthenticationProvider as OIDCAuthenticationProvider,
  oidcLog,
} from '@axa-fr/react-oidc-context';
import {Intermission, StackProvider, theme} from '@emporos/components';
import {Redirect, Router} from '@reach/router';
import styled, {ThemeProvider} from 'styled-components';
import {oidcCallbackComponents, oidcConfiguration} from './auth/config';
import GlobalStyles from './components/GlobalStyles';
import config from './config';
import {AlertStateProvider} from './contexts/AlertStateProvider';
import {AnalyticsProvider} from './contexts/AnalyticsProvider';
import {AuthenticationProvider} from './contexts/AuthenticationProvider';
import {BarcodeScannerProvider} from './contexts/BarcodeScannerProvider';
import {BetaFeatureProvider} from './contexts/BetaFeatureProvider';
import {CardReaderCredentialsProvider} from './contexts/CardReaderCredentialsProvider';
import {ConfigProvider} from './contexts/ConfigProvider';
import {GlobalDataProvider} from './contexts/GlobalDataProvider';
import {LoggingProvider} from './contexts/LoggingProvider';
import {NetworkAvailableProvider} from './contexts/NetworkAvailableProvider';
import {SettingsProvider} from './contexts/SettingsProvider';
import {SyncSessionProvider} from './contexts/SyncSessionProvider';
import TransactionsConfigProvider, {
  useTransactionsConfig,
} from './contexts/TransactionsConfigProvider';
import {TransactionsStateProvider} from './contexts/TransactionsStateProvider';
import CloseSession from './pages/login/close-session/index';
import CreateSession from './pages/login/create-session/index';
import {Routes} from './routes';

const {NodeEnv} = config;

const Background = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

/* istanbul ignore next: we don’t care about testing log level configuration */
const loggerLevel =
  NodeEnv === 'production'
    ? oidcLog.ERROR
    : process.env.REACT_APP_OIDC_LOG_LEVEL
    ? oidcLog[
        process.env.REACT_APP_OIDC_LOG_LEVEL as
          | 'NONE'
          | 'ERROR'
          | 'WARN'
          | 'INFO'
          | 'DEBUG'
      ]
    : oidcLog.DEBUG;

function ConfigureOrLoadSession() {
  const {
    session,
    loading: sessionLoading,
    ready,
    hardLoadingSession,
    sessionClosed,
  } = useTransactionsConfig();

  if (!hardLoadingSession && (sessionLoading || !ready)) {
    return <Intermission />;
  }

  if (!session) {
    return sessionClosed ? (
      <Router>
        <CloseSession path="/login/close-session" />
        <Redirect from="/" to="/login/close-session" default noThrow />
      </Router>
    ) : (
      <Router>
        <CreateSession path="/login/create-session" />
        <Redirect from="/" to="/login/create-session" default noThrow />
      </Router>
    );
  }

  return (
    <TransactionsStateProvider>
      <GlobalDataProvider>
        <SyncSessionProvider>
          <CardReaderCredentialsProvider>
            <BarcodeScannerProvider>
              <Routes />
            </BarcodeScannerProvider>
          </CardReaderCredentialsProvider>
        </SyncSessionProvider>
      </GlobalDataProvider>
    </TransactionsStateProvider>
  );
}

export default function App(): JSX.Element {
  return (
    <ConfigProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Background>
          <SettingsProvider>
            <NetworkAvailableProvider>
              <LoggingProvider>
                <AnalyticsProvider>
                  <AlertStateProvider>
                    <StackProvider>
                      <OIDCAuthenticationProvider
                        configuration={oidcConfiguration}
                        isEnabled={NodeEnv !== 'test'}
                        loggerLevel={loggerLevel}
                        {...oidcCallbackComponents}
                      >
                        <AuthenticationProvider>
                          <TransactionsConfigProvider>
                            <BetaFeatureProvider>
                              <ConfigureOrLoadSession />
                            </BetaFeatureProvider>
                          </TransactionsConfigProvider>
                        </AuthenticationProvider>
                      </OIDCAuthenticationProvider>
                    </StackProvider>
                  </AlertStateProvider>
                </AnalyticsProvider>
              </LoggingProvider>
            </NetworkAvailableProvider>
          </SettingsProvider>
        </Background>
      </ThemeProvider>
    </ConfigProvider>
  );
}
