import {PropsWithChildren} from 'react';

import {CustomerSearchProvider} from '../../../contexts/CustomerSearchProvider';
import {WithPageProps} from '../../../hocs/withChildPage';
import CustomerInfoIntegrationWillCall from './CustomerInfoIntegrationWillCall';
import CustomerInfoIntegration from './CustomerInfoIntegration';

interface WillCallProps {
  canAddCustomer?: boolean;
}

export function CustomerInfoWillCall(
  props: PropsWithChildren<WithPageProps<WillCallProps>>,
): JSX.Element {
  return (
    <CustomerSearchProvider>
      <CustomerInfoIntegrationWillCall {...props} />
    </CustomerSearchProvider>
  );
}

export {CustomerInfoIntegration as CustomerInfo};
