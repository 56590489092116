import {PseItem} from '@emporos/api-enterprise/src/gen';
import {Invoice} from '@emporos/api-enterprise/src/gen-session';

export const mapItemMilligrams = (invoice: Invoice): Array<PseItem> =>
  invoice.items
    .filter(item => item.controlGrams > 0)
    .map(({controlGrams, description, itemNumber, quantity}) => ({
      boxes: quantity,
      description: description || '',
      milligramsPerBox: controlGrams,
      number: (itemNumber || 0).toString(),
    }));
