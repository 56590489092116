import React, {memo, PropsWithoutRef} from 'react';
import styled, {DefaultTheme} from 'styled-components';
// import {useLongPress} from './hooks/useLongPress';
import {
  AllIcons,
  Gutter,
  Icon,
  Icons,
  IconSize,
  IndicatorBadge,
  IndicatorQuantity,
  Row,
  Stack,
} from './';
import Text, {Variant as TVar} from './Text';

export type RowType =
  | 'payment'
  | 'generic'
  | 'inverted'
  | 'not-ready'
  | 'unavailable';

export type Colors = 'success' | 'primary' | 'purple' | 'warning' | 'indigo';

export interface Props {
  variant?: RowType;
  title?: string;
  subtitle?: string;
  leftIcon?: keyof typeof AllIcons;
  leftIconColor?: Colors;
  rightIcons?: Array<keyof typeof Icons>;
  rightText?: string;
  rightBadgeText?: string;
  rightBadgeColor?: Colors;
  rightStrikeText?: string;
  selected?: boolean;
  quantity?: string | number;
  inactive?: boolean;
  noClick?: boolean;
  onClick?: () => void;
  onLongClick?: () => void;
}

const StyledRowItem = styled(Row)<Props>`
  padding: 16px;
  border-radius: 8px;
  border: 2px solid
    ${({theme, selected, variant, inactive}) =>
      borderColor(theme, selected, variant, inactive)};
  background-color: ${({theme, variant, selected, inactive}) =>
    backgroundColor(theme, variant, selected, inactive)};
  box-shadow: ${({theme, variant}) =>
    variant === 'payment' ? theme.shadows.shadow_2 : ''};
  cursor: ${({inactive, noClick}) =>
    inactive || noClick ? 'initial' : 'pointer'};
  pointer-events: ${({inactive, noClick}) =>
    inactive || noClick ? 'none' : 'initial'};
  min-height: 84px;
  flex-wrap: nowrap;
  transition: border-color 75ms ease-out, background-color 75ms ease-out;
  opacity: ${({inactive}) => (inactive ? 0.5 : 1)};
`;

const backgroundColor = (
  theme: DefaultTheme,
  variant?: RowType,
  selected?: boolean,
  inactive?: boolean,
) => {
  switch (variant) {
    case 'payment':
      return selected ? theme.colors.blue_20 : theme.colors.white;
    case 'not-ready':
      return theme.colors.warning_20;
    case 'unavailable':
      return theme.colors.error_20;
    case 'inverted':
      return 'transparent';
    default:
      return selected ? theme.colors.blue_20 : theme.colors.gray_100;
  }
};

const borderColor = (
  theme: DefaultTheme,
  selected?: boolean,
  variant?: string,
  inactive?: boolean,
) => {
  if (selected && inactive && variant === 'generic') {
    return theme.colors.blue;
  } else if (!selected && variant === 'inverted') {
    return theme.colors.gray_200;
  } else if (!selected) {
    return 'transparent';
  }

  switch (variant) {
    case 'not-ready':
      return theme.colors.warning;
    case 'unavailable':
      return theme.colors.error;
    default:
      return theme.colors.blue;
  }
};

const rightIconType = (variant?: RowType) => {
  switch (variant) {
    case 'not-ready':
      return 'warning';
    case 'unavailable':
      return 'error';
    default:
      return 'primary';
  }
};

const leftIconType = (leftIcon: keyof typeof AllIcons) => {
  switch (leftIcon) {
    case 'CreditCard':
      return 'primary';
    case 'Bill':
    case 'IdCard':
      return 'indigo';
    case 'Check':
    case 'Home':
      return 'purple';
    case 'Warning':
    case 'MoneyBag':
      return 'warning';
    case 'Cash':
    default:
      return 'success';
  }
};
const leftIconSize = (leftIcon: keyof typeof AllIcons) => {
  switch (leftIcon) {
    case 'Amex':
    case 'ApplePay':
    case 'DinersClub':
    case 'Discover':
    case 'GooglePay':
    case 'Maestro':
    case 'Mastercard':
    case 'Paypal':
    case 'Stripe':
    case 'Verifone':
    case 'Visa':
      return IconSize.Payment;
    default:
      return IconSize.L;
  }
};

const priceStyles = (
  rightIcons?: Array<keyof typeof Icons>,
  variant?: RowType,
  strike?: boolean,
  rightText?: string,
) => {
  return {
    whiteSpace: 'nowrap',
    width: rightIcons && rightIcons.length ? 85 : 'auto',
    color:
      (variant === 'payment' || variant === 'generic') &&
      rightText?.includes('-$')
        ? 'rgba(255,74,112,1.00)'
        : '',
    textDecorationLine: strike ? 'line-through' : '',
  };
};

const IconRow = styled(Row)<Props>`
  width: 144px;
  flex-wrap: no-wrap;
  @media (max-width: 900px) {
    width: 72px;
  }
  & > * {
    margin-left: 0.75rem;
    @media (max-width: 900px) {
      margin-top: 0.5rem;
      flex-wrap: wrap;
    }
    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }
  }
`;

const IconWrapper = styled.div`
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RowItem = memo(
  ({
    leftIcon,
    rightIcons,
    rightText,
    rightBadgeText,
    rightBadgeColor = 'success',
    rightStrikeText,
    title,
    subtitle,
    leftIconColor,
    variant = 'generic',
    onClick,
    onLongClick,
    quantity,
    ...props
  }: Props & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    return (
      <StyledRowItem
        align="center"
        variant={variant}
        data-testid={`rowItem-${variant}`}
        onClick={onClick}
        // TODO: something in useLongPress interferes with mouse usage on touch devices
        // {...useLongPress(onClick, onLongClick)}
        {...props}
      >
        {leftIcon && (
          <IconWrapper>
            <Icon
              icon={leftIcon}
              variant={leftIconColor ? leftIconColor : leftIconType(leftIcon)}
              size={leftIconSize(leftIcon)}
            />
          </IconWrapper>
        )}
        {quantity && variant === 'generic' && (
          <IndicatorQuantity text={quantity} />
        )}
        <Stack gutter={Gutter.XXS} style={{marginRight: 'auto'}}>
          <Text variant={TVar.Main}>{title}</Text>

          {subtitle && <Text variant={TVar.SubMainLight}>{subtitle}</Text>}
        </Stack>

        {rightIcons && variant !== 'payment' && (
          <IconRow gutter={Gutter.None} justify="end">
            {rightIcons.map((icon, i) => (
              <Icon icon={icon} variant={rightIconType(variant)} key={i} />
            ))}
          </IconRow>
        )}

        {rightText && (
          <Stack gutter={Gutter.XXS}>
            <Text
              variant={TVar.Main}
              style={priceStyles(rightIcons, variant, false, rightText)}
              align="right"
            >
              {rightText}
            </Text>

            {rightBadgeText && (
              <Stack align="end" gutter={Gutter.XXS}>
                <IndicatorBadge
                  variant={rightBadgeColor ? rightBadgeColor : 'primary'}
                  text={rightBadgeText}
                />
              </Stack>
            )}

            {rightStrikeText && (
              <Text
                variant={TVar.MainLight}
                style={priceStyles(rightIcons, variant, true)}
                align="right"
              >
                {rightStrikeText}
              </Text>
            )}
          </Stack>
        )}
      </StyledRowItem>
    );
  },
);
