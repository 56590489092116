/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentOption
 */
export interface PaymentOption {
    /**
     * 
     * @type {number}
     * @memberof PaymentOption
     */
    paymentOptionId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentOption
     */
    constantName: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOption
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOption
     */
    description: string;
}

export function PaymentOptionFromJSON(json: any): PaymentOption {
    return PaymentOptionFromJSONTyped(json, false);
}

export function PaymentOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentOptionId': json['paymentOptionId'],
        'constantName': json['constantName'],
        'displayName': json['displayName'],
        'description': json['description'],
    };
}

export function PaymentOptionToJSON(value?: PaymentOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentOptionId': value.paymentOptionId,
        'constantName': value.constantName,
        'displayName': value.displayName,
        'description': value.description,
    };
}


