/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignatureType
 */
export interface SignatureType {
    /**
     * 
     * @type {number}
     * @memberof SignatureType
     */
    signatureTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof SignatureType
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureType
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureType
     */
    dataVersion?: string | null;
}

export function SignatureTypeFromJSON(json: any): SignatureType {
    return SignatureTypeFromJSONTyped(json, false);
}

export function SignatureTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatureType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signatureTypeId': json['signatureTypeId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
    };
}

export function SignatureTypeToJSON(value?: SignatureType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signatureTypeId': value.signatureTypeId,
        'name': value.name,
        'description': value.description,
        'dataVersion': value.dataVersion,
    };
}


