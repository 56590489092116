import {memo} from 'react';
import {RouteComponentProps} from '@reach/router';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../../../contexts/AnalyticsProvider';
import assert from 'assert';
import Acknowledgements from './Acknowledgements';
import useInvoice from '../../../../../hooks/useInvoice';
import {useSignatures} from '../../../../../hooks/useSignatures';
import {
  mapInvoiceSignature,
  mapSignatureImage,
} from '../../../../../utils/mappers';
import {withChildPage} from '../../../../../hocs/withChildPage';
import {OfflineInvoice, OfflineSynced} from '../../../../../api/common';
import {InvoiceSignature} from '@emporos/api-enterprise/src/gen-session/index';
import {useConfig} from '../../../../../contexts/ConfigProvider';

function AcknowledgementsIntegration({navigate}: RouteComponentProps) {
  const {track} = useAnalyticsProvider();
  const {invoice, updateInvoice} = useInvoice();
  const signatureTypes = useSignatures();
  const {NodeEnv} = useConfig();

  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<Acknowledgements /> must have a `navigate` prop.' + NodeEnv ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
  }

  const _signatureImage = (invoice as OfflineInvoice).signatureImage;
  const signatureImage = _signatureImage
    ? _signatureImage
    : mapSignatureImage();

  return (
    <Acknowledgements
      onBack={() => navigate('../customer-payment')}
      onAccept={(imageData, pointData, touchless) => {
        updateInvoice({
          signatureImage: {
            ...signatureImage,
            pointData: pointData.map(group => group.map(({x, y}) => ({x, y}))),
            // if touchless don't sync signatureImage
            isSynced: touchless,
          },
          signatures: invoice.signatures
            .map(
              s =>
                ({
                  ...s,
                  isDeleted: true,
                  isSynced: false,
                } as InvoiceSignature & OfflineSynced),
            )
            .concat(
              signatureTypes.map(type =>
                mapInvoiceSignature(
                  invoice.invoiceId,
                  touchless,
                  type,
                  touchless ? undefined : signatureImage.signatureImageId,
                ),
              ),
            ),
        } as Partial<OfflineInvoice>);
        track(AnalyticType.SignatureType, {
          type: `${!touchless ? 'touchless' : 'signature'}`,
        });

        return navigate('../receipts');
      }}
      navigate={navigate}
    />
  );
}

export default memo(withChildPage(AcknowledgementsIntegration));
