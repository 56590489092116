import {PseCheckApi} from '@emporos/api-enterprise/src/gen';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {AuthClaim} from '../../../../../auth/const';
import {useAuthentication} from '../../../../../contexts/AuthenticationProvider';
import {useGlobalData} from '../../../../../contexts/GlobalDataProvider';
import {useTransactionsState} from '../../../../../contexts/TransactionsStateProvider';
import useInvoice from '../../../../../hooks/useInvoice';
import {useConfig} from '../../../../../contexts/ConfigProvider';
import {withChildPage} from '../../../../../hocs/withChildPage';
import IdCheck from './IdCheck';
import useOpenApi from '../../../../../api/useOpenApi';
import {useAlertState} from '../../../../../contexts/AlertStateProvider';

function IdCheckIntegration({
  navigate,
}: RouteComponentProps<{
  location: {
    state: {
      animatePanel?: boolean;
    };
  };
}>) {
  const {barcodesResult, settingsResult} = useGlobalData();
  const {notification} = useAlertState();
  const {
    session: {siteId, stationId},
  } = useTransactionsState();
  const {user} = useAuthentication();
  const userId = user?.profile[AuthClaim.UserId] || '';
  const {loading: loadingPseCheck, run: putPseCheck} = useOpenApi(
    PseCheckApi,
    'clientPseCheckPut',
  );
  const {NodeEnv} = useConfig();

  const {invoice, updateInvoice} = useInvoice();

  assert(
    navigate,
    '<IdCheck /> must have a `navigate` prop.' + NodeEnv === 'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );
  assert(invoice, 'Missing invoice');
  assert(settingsResult && settingsResult.data, 'Missing settings');
  assert(barcodesResult && barcodesResult.data, 'Missing barcodes');

  return (
    <IdCheck
      onUpdateInvoice={updateInvoice}
      invoice={invoice}
      barcodes={barcodesResult.data}
      onBack={() => {
        navigate('/sales/transactions');
      }}
      loadingPseCheck={loadingPseCheck}
      putPseCheck={putPseCheck}
      navigate={navigate}
      settings={settingsResult.data}
      siteID={String(siteId)}
      stationID={String(stationId)}
      userID={userId}
      notification={notification}
    />
  );
}

export default memo(withChildPage(IdCheckIntegration));
