/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvoiceSignature,
    InvoiceSignatureFromJSON,
    InvoiceSignatureToJSON,
    InvoiceSignatureRequest,
    InvoiceSignatureRequestFromJSON,
    InvoiceSignatureRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGetRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDeleteRequest {
    sessionId: string;
    invoiceId: string;
    invoiceSignatureId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGetRequest {
    sessionId: string;
    invoiceId: string;
    invoiceSignatureId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPutRequest {
    sessionId: string;
    invoiceId: string;
    invoiceSignatureRequest?: InvoiceSignatureRequest;
}

/**
 * 
 */
export class InvoiceSignaturesApi extends runtime.BaseAPI {

    /**
     * Gets a list of invoiceSignatures for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGetRequest): Promise<runtime.ApiResponse<Array<InvoiceSignature>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/signatures`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceSignatureFromJSON));
    }

    /**
     * Gets a list of invoiceSignatures for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGetRequest): Promise<Array<InvoiceSignature>> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an invoiceSignature
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDelete.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDelete.');
        }

        if (requestParameters.invoiceSignatureId === null || requestParameters.invoiceSignatureId === undefined) {
            throw new runtime.RequiredError('invoiceSignatureId','Required parameter requestParameters.invoiceSignatureId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/signatures/{invoiceSignatureId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoiceSignatureId"}}`, encodeURIComponent(String(requestParameters.invoiceSignatureId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an invoiceSignature
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDelete(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdDeleteRaw(requestParameters);
    }

    /**
     * Gets an invoiceSignature by session id, invoice id and invoiceSignature id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGetRequest): Promise<runtime.ApiResponse<InvoiceSignature>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGet.');
        }

        if (requestParameters.invoiceSignatureId === null || requestParameters.invoiceSignatureId === undefined) {
            throw new runtime.RequiredError('invoiceSignatureId','Required parameter requestParameters.invoiceSignatureId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/signatures/{invoiceSignatureId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoiceSignatureId"}}`, encodeURIComponent(String(requestParameters.invoiceSignatureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceSignatureFromJSON(jsonValue));
    }

    /**
     * Gets an invoiceSignature by session id, invoice id and invoiceSignature id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGetRequest): Promise<InvoiceSignature> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesInvoiceSignatureIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts or updates a invoiceSignature
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPutRequest): Promise<runtime.ApiResponse<InvoiceSignature>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPut.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/signatures`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceSignatureRequestToJSON(requestParameters.invoiceSignatureRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceSignatureFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a invoiceSignature
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPut(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPutRequest): Promise<InvoiceSignature> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdSignaturesPutRaw(requestParameters);
        return await response.value();
    }

}
