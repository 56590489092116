import {
  Button,
  FixedHeader,
  FooterGroup,
  Gutter,
  Header,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import {memo} from 'react';

function SettingsSupport(_: RouteComponentProps): JSX.Element {
  return (
    <Stack gutter={Gutter.None} style={{height: '100%'}}>
      <FixedHeader>
        <Header title="Support" />
      </FixedHeader>
      <ScrollContainer style={{paddingBottom: 16}}>
        <Stack></Stack>
      </ScrollContainer>
      <FooterGroup>
        <Button flex>Okay</Button>
      </FooterGroup>
    </Stack>
  );
}

export default memo(SettingsSupport);
