/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SignatureImage,
    SignatureImageFromJSON,
    SignatureImageFromJSONTyped,
    SignatureImageToJSON,
    SignatureType,
    SignatureTypeFromJSON,
    SignatureTypeFromJSONTyped,
    SignatureTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvoiceSignature
 */
export interface InvoiceSignature {
    /**
     * 
     * @type {string}
     * @memberof InvoiceSignature
     */
    invoiceSignatureId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSignature
     */
    invoiceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSignature
     */
    touchless?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSignature
     */
    signatureImageId?: string | null;
    /**
     * 
     * @type {SignatureImage}
     * @memberof InvoiceSignature
     */
    signatureImage?: SignatureImage;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSignature
     */
    signatureTypeId: number;
    /**
     * 
     * @type {SignatureType}
     * @memberof InvoiceSignature
     */
    signatureType: SignatureType;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSignature
     */
    dataVersion?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceSignature
     */
    createdOn: Date;
}

export function InvoiceSignatureFromJSON(json: any): InvoiceSignature {
    return InvoiceSignatureFromJSONTyped(json, false);
}

export function InvoiceSignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceSignature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceSignatureId': json['invoiceSignatureId'],
        'invoiceId': json['invoiceId'],
        'touchless': !exists(json, 'touchless') ? undefined : json['touchless'],
        'signatureImageId': !exists(json, 'signatureImageId') ? undefined : json['signatureImageId'],
        'signatureImage': !exists(json, 'signatureImage') ? undefined : SignatureImageFromJSON(json['signatureImage']),
        'signatureTypeId': json['signatureTypeId'],
        'signatureType': SignatureTypeFromJSON(json['signatureType']),
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
        'createdOn': (new Date(json['createdOn'])),
    };
}

export function InvoiceSignatureToJSON(value?: InvoiceSignature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceSignatureId': value.invoiceSignatureId,
        'invoiceId': value.invoiceId,
        'touchless': value.touchless,
        'signatureImageId': value.signatureImageId,
        'signatureImage': SignatureImageToJSON(value.signatureImage),
        'signatureTypeId': value.signatureTypeId,
        'signatureType': SignatureTypeToJSON(value.signatureType),
        'dataVersion': value.dataVersion,
        'createdOn': (value.createdOn.toISOString()),
    };
}


