/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SessionPeripheral,
    SessionPeripheralFromJSON,
    SessionPeripheralToJSON,
    SessionPeripheralRequest,
    SessionPeripheralRequestFromJSON,
    SessionPeripheralRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdPeripheralsGetRequest {
    sessionId: string;
    includeChildren?: boolean;
}

export interface ClientCacheSessionsSessionIdPeripheralsPeripheralIdDeleteRequest {
    sessionId: string;
    peripheralId: number;
}

export interface ClientCacheSessionsSessionIdPeripheralsPutRequest {
    sessionId: string;
    sessionPeripheralRequest?: SessionPeripheralRequest;
}

/**
 * 
 */
export class SessionPeripheralsApi extends runtime.BaseAPI {

    /**
     * A session peripheral reference represents a peripheral that a session uses.
     * Gets a list of session peripheral references
     */
    async clientCacheSessionsSessionIdPeripheralsGetRaw(requestParameters: ClientCacheSessionsSessionIdPeripheralsGetRequest): Promise<runtime.ApiResponse<Array<SessionPeripheral>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdPeripheralsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/Peripherals`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionPeripheralFromJSON));
    }

    /**
     * A session peripheral reference represents a peripheral that a session uses.
     * Gets a list of session peripheral references
     */
    async clientCacheSessionsSessionIdPeripheralsGet(requestParameters: ClientCacheSessionsSessionIdPeripheralsGetRequest): Promise<Array<SessionPeripheral>> {
        const response = await this.clientCacheSessionsSessionIdPeripheralsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * A session peripheral reference represents a peripheral that a session uses.
     * Removes a session peripheral reference
     */
    async clientCacheSessionsSessionIdPeripheralsPeripheralIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdPeripheralsPeripheralIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdPeripheralsPeripheralIdDelete.');
        }

        if (requestParameters.peripheralId === null || requestParameters.peripheralId === undefined) {
            throw new runtime.RequiredError('peripheralId','Required parameter requestParameters.peripheralId was null or undefined when calling clientCacheSessionsSessionIdPeripheralsPeripheralIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/Peripherals/{peripheralId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"peripheralId"}}`, encodeURIComponent(String(requestParameters.peripheralId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A session peripheral reference represents a peripheral that a session uses.
     * Removes a session peripheral reference
     */
    async clientCacheSessionsSessionIdPeripheralsPeripheralIdDelete(requestParameters: ClientCacheSessionsSessionIdPeripheralsPeripheralIdDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdPeripheralsPeripheralIdDeleteRaw(requestParameters);
    }

    /**
     */
    async clientCacheSessionsSessionIdPeripheralsPutRaw(requestParameters: ClientCacheSessionsSessionIdPeripheralsPutRequest): Promise<runtime.ApiResponse<SessionPeripheral>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdPeripheralsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/Peripherals`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SessionPeripheralRequestToJSON(requestParameters.sessionPeripheralRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionPeripheralFromJSON(jsonValue));
    }

    /**
     */
    async clientCacheSessionsSessionIdPeripheralsPut(requestParameters: ClientCacheSessionsSessionIdPeripheralsPutRequest): Promise<SessionPeripheral> {
        const response = await this.clientCacheSessionsSessionIdPeripheralsPutRaw(requestParameters);
        return await response.value();
    }

}
