/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PeripheralType,
    PeripheralTypeFromJSON,
    PeripheralTypeToJSON,
    PeripheralTypeRequest,
    PeripheralTypeRequestFromJSON,
    PeripheralTypeRequestToJSON,
} from '../models';

export interface ClientPeripheralsTypesPeripheralTypeIdGetRequest {
    peripheralTypeId: number;
}

export interface ClientPeripheralsTypesPostRequest {
    peripheralTypeRequest?: PeripheralTypeRequest;
}

export interface ClientPeripheralsTypesPutRequest {
    peripheralTypeRequest?: PeripheralTypeRequest;
}

/**
 * 
 */
export class PeripheralTypesApi extends runtime.BaseAPI {

    /**
     * Gets PeripheralTypes.
     */
    async clientPeripheralsTypesGetRaw(): Promise<runtime.ApiResponse<Array<PeripheralType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/peripherals/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeripheralTypeFromJSON));
    }

    /**
     * Gets PeripheralTypes.
     */
    async clientPeripheralsTypesGet(): Promise<Array<PeripheralType>> {
        const response = await this.clientPeripheralsTypesGetRaw();
        return await response.value();
    }

    /**
     * Gets a peripheral type by id
     */
    async clientPeripheralsTypesPeripheralTypeIdGetRaw(requestParameters: ClientPeripheralsTypesPeripheralTypeIdGetRequest): Promise<runtime.ApiResponse<PeripheralType>> {
        if (requestParameters.peripheralTypeId === null || requestParameters.peripheralTypeId === undefined) {
            throw new runtime.RequiredError('peripheralTypeId','Required parameter requestParameters.peripheralTypeId was null or undefined when calling clientPeripheralsTypesPeripheralTypeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/peripherals/types/{peripheralTypeId}`.replace(`{${"peripheralTypeId"}}`, encodeURIComponent(String(requestParameters.peripheralTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeripheralTypeFromJSON(jsonValue));
    }

    /**
     * Gets a peripheral type by id
     */
    async clientPeripheralsTypesPeripheralTypeIdGet(requestParameters: ClientPeripheralsTypesPeripheralTypeIdGetRequest): Promise<PeripheralType> {
        const response = await this.clientPeripheralsTypesPeripheralTypeIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * For inserts, only the Description property needs to be filled in.
     * Inserts a peripheral type
     */
    async clientPeripheralsTypesPostRaw(requestParameters: ClientPeripheralsTypesPostRequest): Promise<runtime.ApiResponse<PeripheralType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/peripherals/types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PeripheralTypeRequestToJSON(requestParameters.peripheralTypeRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeripheralTypeFromJSON(jsonValue));
    }

    /**
     * For inserts, only the Description property needs to be filled in.
     * Inserts a peripheral type
     */
    async clientPeripheralsTypesPost(requestParameters: ClientPeripheralsTypesPostRequest): Promise<PeripheralType> {
        const response = await this.clientPeripheralsTypesPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a peripheral type
     */
    async clientPeripheralsTypesPutRaw(requestParameters: ClientPeripheralsTypesPutRequest): Promise<runtime.ApiResponse<PeripheralType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/peripherals/types`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PeripheralTypeRequestToJSON(requestParameters.peripheralTypeRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeripheralTypeFromJSON(jsonValue));
    }

    /**
     * Updates a peripheral type
     */
    async clientPeripheralsTypesPut(requestParameters: ClientPeripheralsTypesPutRequest): Promise<PeripheralType> {
        const response = await this.clientPeripheralsTypesPutRaw(requestParameters);
        return await response.value();
    }

}
