import React, {memo} from 'react';
import styled from 'styled-components';
import Text, {Variant} from './Text';

const once = (fn: () => JSX.Element) => {
  let result: JSX.Element;
  return () => {
    if (result) {
      return result;
    }
    result = fn();
    return result;
  };
};

interface Props {
  text: string;
}

const Container = styled.div`
  ${({theme}) => `
    background-color: ${theme.colors.gray_200};
    padding: 6px 16px;
  `}
`;

export const Divider = memo(function Divider({text}: Props) {
  return (
    <Container>
      <Text variant={Variant.Caption}>{text}</Text>
    </Container>
  );
});

const Line = styled.div`
  ${({theme}) => `
    background-color: ${theme.colors.gray_200};
    height: 1px;
  `}
`;

export const DividerLine: () => JSX.Element = once(() => <Line />);
