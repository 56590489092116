import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 326px;
  background-color: ${({theme}) => theme.colors.gray_100};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px 16px;
`;

export const SmallSidebarWrapper = styled.div<{padding?: boolean}>`
  height: 100%;
  width: 253px;
  background-color: ${({theme}) => theme.colors.gray_100};
  border-radius: 12px;
  padding: ${({padding}) => (padding ? '20px' : 0)};
`;

export const RemovePadding = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

export const ScrollContainer = styled.div`
  flex: 1;
  overflow: auto;
  padding-bottom: 16px;
`;

// TODO: refactor this away
export const FixedHeader = styled.div`
  background-color: transparent;
  padding-bottom: 12px;
`;
