import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
  memo,
} from 'react';
import styled from 'styled-components';
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCanvas from 'react-signature-canvas';
import {defaultTo} from 'lodash';

export interface SignatureHandle {
  clear: () => void;
  on: () => void;
  off: () => void;
  isEmpty: () => boolean;
  getImage: () => string | undefined;
  getPoints: () => SignaturePad.Point[][] | undefined;
  _onChange: () => void;
}

export interface SignatureProps {
  onChange: () => void;
}

const StyledSignature = styled.div`
  width: fit-content;
  border: 1px solid ${({theme}) => theme.colors.gray_200};
  border-radius: 12px;
  width: 100%;
  height: 100%;
  max-width: 760px;
  max-height: 400px;

  canvas {
    width: 100%;
    height: 100%;
    min-height: 350px;
  }
`;

const Signature: ForwardRefRenderFunction<SignatureHandle, SignatureProps> = (
  {onChange},
  ref,
) => {
  const signatureRef = useRef<ReactSignatureCanvas>(null);

  useImperativeHandle(ref, () => ({
    clear: () => signatureRef.current?.clear(),
    on: () => signatureRef.current?.on(),
    off: () => signatureRef.current?.off(),
    isEmpty: () => defaultTo(signatureRef.current?.isEmpty(), true),
    getImage: () => signatureRef.current?.getCanvas().toDataURL('image/png'),
    getPoints: () => signatureRef.current?.toData(),
    // this is only exposed for testing purposes
    _onChange: () => onChange(),
  }));

  return (
    <StyledSignature>
      <SignatureCanvas ref={signatureRef} onEnd={onChange} />
    </StyledSignature>
  );
};
export default memo(forwardRef(Signature));
