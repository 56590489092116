/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface SignatureImageRequest
 */
export interface SignatureImageRequest {
    /**
     * 
     * @type {string}
     * @memberof SignatureImageRequest
     */
    signatureImageId: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureImageRequest
     */
    signatureImageType: string;
    /**
     * 
     * @type {number}
     * @memberof SignatureImageRequest
     */
    serverSignatureId: number;
    /**
     * 
     * @type {string}
     * @memberof SignatureImageRequest
     */
    imageData?: string | null;
    /**
     * 
     * @type {Array<Array<Point>>}
     * @memberof SignatureImageRequest
     */
    pointData?: Array<Array<Point>> | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureImageRequest
     */
    dataVersion?: string | null;
}

export function SignatureImageRequestFromJSON(json: any): SignatureImageRequest {
    return SignatureImageRequestFromJSONTyped(json, false);
}

export function SignatureImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatureImageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signatureImageId': json['signatureImageId'],
        'signatureImageType': json['signatureImageType'],
        'serverSignatureId': json['serverSignatureId'],
        'imageData': !exists(json, 'imageData') ? undefined : json['imageData'],
        'pointData': !exists(json, 'pointData') ? undefined : json['pointData'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
    };
}

export function SignatureImageRequestToJSON(value?: SignatureImageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signatureImageId': value.signatureImageId,
        'signatureImageType': value.signatureImageType,
        'serverSignatureId': value.serverSignatureId,
        'imageData': value.imageData,
        'pointData': value.pointData,
        'dataVersion': value.dataVersion,
    };
}


