import {memo} from 'react';
import styled from 'styled-components';

export enum Variant {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  Main = 'Main',
  MainLight = 'MainLight',
  SubMain = 'SubMain',
  SubMainLight = 'SubMainLight',
  Caption = 'Caption',
  Body = 'Body',
  Touchless = 'Touchless',
}
export enum Intent {
  Alert = 'Alert',
}
interface Props {
  variant?: Variant;
  intent?: Intent;
  align?: 'right' | 'left' | 'center';
}
const keyForVariant = (variant: Variant | void) => {
  switch (variant) {
    case Variant.T1:
      return 'Title1';
    case Variant.T2:
      return 'Title2';
    case Variant.T3:
      return 'Title3';
    case Variant.Main:
      return 'Main';
    case Variant.MainLight:
      return 'MainLight';
    case Variant.SubMain:
      return 'SubMain';
    case Variant.SubMainLight:
      return 'SubMainLight';
    case Variant.Caption:
      return 'Caption';
    case Variant.Touchless:
      return 'Touchless';
    case Variant.Body:
    default:
      return 'Body';
  }
};

const Text = styled.span<Props>`
  display: block;
  color: ${({variant, intent, theme}) =>
    intent === Intent.Alert
      ? theme.colors.error
      : theme.typography[keyForVariant(variant)].color};
  font-family: ${({variant, theme}) =>
    theme.typography[keyForVariant(variant)].fontFamily};
  font-size: ${({variant, theme}) =>
    theme.typography[keyForVariant(variant)].fontSize};
  font-weight: ${({variant, theme}) =>
    theme.typography[keyForVariant(variant)].fontWeight};
  line-height: ${({variant, theme}) =>
    theme.typography[keyForVariant(variant)].lineHeight};
  text-transform: ${({variant, theme}) =>
    theme.typography[keyForVariant(variant)].textTransform};
  margin-top: 0;
  margin-bottom: 0;
  ${({align}) => {
    switch (align) {
      case 'center':
      case 'left':
      case 'right':
        return `text-align: ${align};`;
      default:
        return '';
    }
  }}
`;

export default memo(Text);
