import {
  Button,
  Gutter,
  IndicatorCircle,
  Row,
  Stack,
  Variant,
} from '@emporos/components';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import {memo} from 'react';
import styled from 'styled-components';

interface Props {
  onLogout: () => void;
  onNewSession: () => void;
}

const CloseSessionWrapper = styled.div`
  background: ${({theme}) => theme.colors.white};
  border: ${({theme}) => `1px solid ${theme.colors.gray_300}`};
  border-radius: 16px;
  height: auto;
  padding: 36px;
  width: 432px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MainWrapper = styled.div`
  background: ${({theme}) => theme.colors.white};
  height: calc(100vh - 68px);
  display: flex;
`;

function CloseSession({onLogout, onNewSession}: Props): JSX.Element {
  return (
    <MainWrapper>
      <CloseSessionWrapper>
        <Stack justify="center" gutter={Gutter.XL}>
          <Row justify="center">
            <IndicatorCircle icon="Checkmark" size="large" variant="success" />
          </Row>
          <Row justify="center">
            <Stack align="center" gutter={Gutter.XXS}>
              <Text align="center" variant={TV.T3}>
                Session Closed
              </Text>

              <Text align="center" variant={TV.MainLight}>
                Everything has been sent to Enterprise. Please logout or start a
                new session.
              </Text>
            </Stack>
          </Row>
          <Row
            justify="space-between"
            gutter={Gutter.XL}
            style={{marginTop: '36px'}}
          >
            <Button flex variant={Variant.Secondary} onClick={onNewSession}>
              New Session
            </Button>
            <Button flex onClick={onLogout}>
              Logout
            </Button>
          </Row>
        </Stack>
      </CloseSessionWrapper>
    </MainWrapper>
  );
}

export default memo(CloseSession);
