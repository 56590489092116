import React, {memo} from 'react';
import {ScanResult, Symbology} from '@emporos/barcodes';
import {useWindowResize} from '@emporos/components';
import {
  Barcode as ScanditBarcode,
  ScanResult as ScanditScanResult,
} from 'scandit-sdk';
import {BarcodeScanner, FloatingWrapper} from './';

export type FloatingScannerProps = {
  enabled?: boolean;
  onScan: (result: ScanResult) => void;
  onScanError: (error: Error) => void;
  onError: (error: Error) => void;
  enabledSymbologies?: Array<Symbology>;
  licenseKey?: string;
};

const symbologyByScanditSymbology: Partial<Record<
  ScanditBarcode.Symbology,
  Symbology
>> = {
  [ScanditBarcode.Symbology.CODE128]: Symbology.Code128,
  [ScanditBarcode.Symbology.CODE39]: Symbology.Code39,
  [ScanditBarcode.Symbology.PDF417]: Symbology.PDF417,
  [ScanditBarcode.Symbology.MICRO_PDF417]: Symbology.PDF417,
  [ScanditBarcode.Symbology.AZTEC]: Symbology.Aztec,
  [ScanditBarcode.Symbology.EAN13]: Symbology.EAN13,
  [ScanditBarcode.Symbology.EAN8]: Symbology.EAN8,
  [ScanditBarcode.Symbology.MICRO_QR]: Symbology.MicroQR,
  [ScanditBarcode.Symbology.UPCA]: Symbology.UPCA,
  [ScanditBarcode.Symbology.UPCE]: Symbology.UPCE,
  [ScanditBarcode.Symbology.QR]: Symbology.QR,
  [ScanditBarcode.Symbology.MICRO_QR]: Symbology.MicroQR,
};

function mapScanditSymbologyToSymbology(
  symbology: ScanditBarcode.Symbology,
): Symbology | null {
  return symbologyByScanditSymbology[symbology] || null;
}

function mapSymbologyToScanditSymbology(
  symbology: Symbology,
): ScanditBarcode.Symbology | null {
  const entry = Object.entries(symbologyByScanditSymbology).find(
    ([key, value]) => value === symbology,
  );
  return entry ? (entry[0] as ScanditBarcode.Symbology) : null;
}

export const FloatingScanner = memo(
  ({
    enabled = true,
    onScan,
    onScanError,
    onError,
    enabledSymbologies = Object.values(Symbology),
    licenseKey,
  }: FloatingScannerProps): JSX.Element => {
    const {width, height} = useWindowResize();
    const _onScan = (scanResult: ScanditScanResult) => {
      const result: ScanResult = {
        raw: scanResult.barcodes[0].data,
        prefix: null,
        suffix: null,
        symbology: mapScanditSymbologyToSymbology(
          scanResult.barcodes[0].symbology,
        ),
      };

      onScan(result);
    };

    return (
      <FloatingWrapper
        icon="Barcode"
        originX={width - 116}
        originY={height - 206}
        visible={enabled}
      >
        <BarcodeScanner
          isScanning={enabled}
          onScan={_onScan}
          onScanError={onScanError}
          onError={onError}
          enabledSymbologies={enabledSymbologies
            .map(mapSymbologyToScanditSymbology)
            .filter((x: unknown): x is ScanditBarcode.Symbology => Boolean(x))}
          licenseKey={licenseKey}
        />
      </FloatingWrapper>
    );
  },
);
