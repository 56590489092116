import {
  Customer,
  CustomerAccount,
  CustomerCreditCard,
  OtcItem,
  Prescription,
  TaxGroup,
} from '@emporos/api-enterprise/src/gen';
import {
  CustomerAccount as SessionCustomerAccount,
  CustomerCreditCard as SessionCustomerCreditCard,
  Invoice,
  InvoiceExtra,
  InvoiceIdentification,
  InvoiceItem,
  InvoicePayment,
  InvoiceSignature,
  InvoiceTax,
  SignatureImage,
  SignatureType,
} from '@emporos/api-enterprise/src/gen-session';
import {v4 as uuid} from 'uuid';
import {formatMedication} from './string';
import {OfflineCustomer, OfflineInvoice, OfflineSynced} from '../api/common';
import {EMPTY_COMPLIANCE} from '../pages/sales/transactions/payments/compliance';

export const COMPLIANCE_DATA_KEY = 'compliance';

export const mapCustomerAccount = (
  customerId: number,
  customerAccount: CustomerAccount,
): SessionCustomerAccount => ({
  accountId: 0,
  accountType: customerAccount.accountType,
  customerId,
  accountNumber: customerAccount.accountNumber,
  balance: customerAccount.balance,
  balanceDate: customerAccount.balanceDate,
  creditHoldIndicator: customerAccount.creditHoldIndicator,
  creditLimit: customerAccount.creditLimit,
  currentBalance: customerAccount.currentBalance,
  over90Balance: customerAccount.over90Balance,
  past30Balance: customerAccount.past30Balance,
  past60Balance: customerAccount.past60Balance,
  isPrimary: customerAccount.isPrimary,
  accountTypeId: customerAccount.accountTypeId,
});

/**
 * This function is responsible for converting API style PascalCased data to
 * camelCased code. There should be *zero* data model changes from going through
 * this transformation.
 */
const mapCustomerCreditCard = (
  creditCard: CustomerCreditCard,
): SessionCustomerCreditCard => ({
  paymentInformationId: creditCard.paymentInformationId,
  paymentTypeId: creditCard.paymentTypeId,
  cardType: creditCard.cardType,
  member: creditCard.member,
  expiration: creditCard.expiration,
  isPrimary: creditCard.isPrimary,
  last4OfCard: creditCard.last4OfCard || '',
  token: creditCard.token || '',
});

export const mapInvoiceCustomer = (
  customer: Customer,
  prescriptions?: Array<Prescription>,
): OfflineCustomer => {
  const {notes, accounts, creditCards, ...props} = customer;
  return {
    ...props,
    creditCards: creditCards.map(mapCustomerCreditCard),
    accounts: accounts.map(a => mapCustomerAccount(customer.id, a)),
    notes: notes,
    prescriptions: prescriptions?.map(p => {
      p.fill = p.fill?.length === 1 ? `0${p.fill}` : p.fill;
      return p;
    }),
  };
};

export const mapInvoice = (sessionId: string, saleSiteID: number): Invoice => {
  const invoiceId = uuid();
  return {
    invoiceId,
    sessionId,
    saleSiteID,
    saleDate: new Date(),
    subTotal: 0,
    taxableSubTotal: 0,
    totalCost: 0,
    discount: 0,
    totalTax: 0,
    totalSale: 0,
    qhpRxQty: 0,
    qhpRxAmount: 0,
    qhpOtherQty: 0,
    qhpOtherAmount: 0,
    qhpCount: 0,
    qhpAmount: 0,
    qhpPartial: false,
    station: 0,
    drawerTransactionID: 0,
    employeeID: 0,
    taxExemptIndicator: false,
    xFileIndicator: false,
    sourceID: 0,
    onHoldAtServerIndicator: false,
    salesAuditIndicator: false,
    onDeliveryBypassCounselPrompt: false,
    onDeliveryBypassHIPAAPrompt: false,
    items: [],
    payments: [],
    taxes: [],
    signatures: [],
    extras: [mapInvoiceExtraCompliance(invoiceId)],
    pseCheckResult: 0,
    status: 0,
    isVerified: false,
    serverTransactionID: 0,
    serverTransactionNumber: 0,
    recordStatus: '',
  } as OfflineInvoice;
};

const mapInvoiceExtraCompliance = (invoiceId: string): InvoiceExtra => ({
  invoiceId,
  rowId: uuid(),
  dataKey: COMPLIANCE_DATA_KEY,
  dataValue: JSON.stringify(EMPTY_COMPLIANCE),
});

export const mapOTCInvoiceItem = (
  invoiceId: string,
  otcItem: OtcItem,
): InvoiceItem => ({
  invoiceId,
  invoiceItemId: uuid(),
  itemNumber: otcItem.itemNumber,
  itemID: otcItem.id,
  itemTypeID: otcItem.itemType.id,
  description: formatMedication(otcItem.description || ''),
  quantity: 1,
  price: otcItem.listPrice,
  originalPrice: otcItem.listPrice,
  listPrice: otcItem.listPrice,
  itemListPrice: otcItem.listPrice,
  extension: otcItem.listPrice,
  extensionBeforeDiscount: otcItem.listPrice,
  saleType: 'Sale',
  taxableIndicator: otcItem.taxableIndicator,
  discountableIndicator: otcItem.discountIndicator,
  qhpIndicator: otcItem.qhpIndicator,
  qhpPrivateIndicator: otcItem.qhpPrivateIndicator,
  controlGrams: otcItem.controlGrams,
  counselPromptIndicator: otcItem.counselPromptIndicator,
  requiredAge: otcItem.requiredAge || 0,
  updateInventoryIndicator: otcItem.updateInventoryIndicator,
  departmentID: otcItem.departmentId || 0,
  recordStatus: 'Active',
  // attachmentIndicator is true if the item has attachements
  attachmentIndicator: otcItem.attachments
    ? otcItem.attachments.length > 0
    : false,
  proofOfIdRequired: otcItem.proofOfIdRequired,
  proofIDIndicator: otcItem.proofOfIdRequired,
  signatureIndicator: otcItem.signatureIndicator,
  taxGroupID: otcItem.taxGroupId,
  serverDetailID: 0,
  serverTransactionID: 0,
  discountPercent: 0,
  discount: 0,
  priceChangeIndicator: false,
  noSafetyCapIndicator: false,
  unitOfMeasure: '',
  saleUnitOfMeasure: 0,
  counselRequiredIndicator: false,
  captureRelationshipIndicator: false,
  hipaaCompliant: 0,
  inventoryGroupID: 0,
  relatedDetailID: 0,
  familyDetailID: 0,
  parentDetailID: 0,
  kitIndicator: false,
  saleUnitOfMeasureID: 0,
  unitOfMeasureRatio: 0,
  serialNumberIndicator: false,
  rentalIndicator: false,
  rentalPrice: 0,
  rentalQuantity: 0,
  timeUnitOfMeasureID: 0,
  employeeID: 0,
  pricingMethod: '',
  relatedDetailID2: 0,
  saleIndicator: true,
  cashDisbursementReasonID: 0,
  discountReasonID: 0,
  returnReasonID: 0,
  vendor: '',
  priceChangeReasonID: 0,
  type2BarcodeIndicator: false,
  weighedItemIndicator: false,
  departmentKeyIndicator: false,
  priceQuantity: 0,
  manualWeighedIndicator: false,
  commitStockIndicator: false,
  priceLevelID: 0,
  eventCodeID: 0,
  priceGroupID: 0,
  displayPrice: 0,
  vendorID: 0,
  detailReference: '',
  negativeInventoryIndicator: false,
  createItemNumber: 0,
  directShipmentIndicator: false,
  discountType: '',
  itemCrossReference: '',
  rxCustomerID: 0,
  categoryID: 0,
  subCategoryID: 0,
  deaClass: 0,
  plan1: '',
  planAmount1: 0,
  plan2: '',
  planAmount2: 0,
  plan3: '',
  planAmount3: 0,
  plan4: '',
  planAmount4: 0,
  plan5: '',
  planAmount5: 0,
  prescriptionID: 0,
  ebtIndicator: false,
  wicIndicator: false,
  rxRelationship: '',
  pickupAllEmployeeID: 0,
  customerMRN: '',
  rxBarcodeScanData: '',
  controlledSubstanceIndicator: false,
  counselLater: false,
  counselorID: 0,
  counselIndicator: false,
  offerCounsel: false,
  dispensable: false,
  receiptDescription: '',
  medicationStatus: 0,
  requiresHIPAACompliance: false,
  discontinuedIndicator: false,
});

export const mapPrescriptionInvoiceItem = (
  invoiceId: string,
  prescription: Prescription,
): InvoiceItem => ({
  invoiceId,
  invoiceItemId: uuid(),
  itemNumber: prescription.itemNumber,
  itemID: 0,
  itemTypeID: 14,
  description: prescription.receiptDescription,
  description2: formatMedication(prescription.description || ''),
  receiptDescription: prescription.receiptDescription,
  quantity: 1,
  price: prescription.price,
  originalPrice: prescription.price,
  listPrice: prescription.price,
  itemListPrice: prescription.price,
  extension: prescription.price,
  extensionBeforeDiscount: prescription.price,
  saleType: 'Sale',
  taxableIndicator: !!prescription.taxableIndicator,
  discountableIndicator: false,
  qhpIndicator: true,
  qhpPrivateIndicator: false,
  controlGrams: 0,
  counselPromptIndicator: false,
  requiredAge: 0,
  updateInventoryIndicator: false,
  recordStatus: 'Delivery',
  attachmentIndicator: false,
  proofOfIdRequired: false,
  proofIDIndicator: !!prescription.proofIDIndicator,
  signatureIndicator: false,
  rx: prescription.rx || '',
  rxCode: prescription.rxCode || '',
  serverDetailID: 0,
  serverTransactionID: 0,
  fill: prescription.fill || '',
  partial: Number(prescription.partial),
  site: prescription.site || '',
  offerCounsel: false,
  medicationStatus: 1,
  noSafetyCapIndicator: !!prescription.noSafetyCapIndicator,
  captureRelationshipIndicator: !!prescription.captureRelationshipIndicator,
  counselRequiredIndicator: !!prescription.counselRequiredIndicator,
  discontinuedIndicator: !!prescription.discontinuedIndicator,
  dispensable: !!prescription.dispensable,
  saleIndicator: true,
  requiresHIPAACompliance: true,
  cost: 0,
  discountPercent: 0,
  discount: 0,
  quantityReturned: 0,
  taxGroupID: 0,
  priceChangeIndicator: false,
  unitOfMeasure: '',
  saleUnitOfMeasure: 0,
  hipaaCompliant: 0,
  hipaaRelationship: '',
  departmentID: 0,
  inventoryGroupID: 0,
  relatedDetailID: 0,
  familyDetailID: 0,
  parentDetailID: 0,
  kitIndicator: false,
  saleUnitOfMeasureID: 0,
  unitOfMeasureRatio: 0,
  serialNumberIndicator: false,
  rentalIndicator: false,
  rentalPrice: 0,
  rentalQuantity: 0,
  timeUnitOfMeasureID: 0,
  employeeID: 0,
  pricingMethod: '',
  relatedDetailID2: 0,
  cashDisbursementReasonID: 0,
  discountReasonID: 0,
  returnReasonID: 0,
  vendor: '',
  priceChangeReasonID: 0,
  type2BarcodeIndicator: false,
  weighedItemIndicator: false,
  departmentKeyIndicator: false,
  priceQuantity: 0,
  manualWeighedIndicator: false,
  commitStockIndicator: false,
  priceLevelID: 0,
  eventCodeID: 0,
  priceGroupID: 0,
  displayPrice: 0,
  vendorID: 0,
  detailReference: '',
  negativeInventoryIndicator: false,
  createItemNumber: 0,
  directShipmentIndicator: false,
  discountType: '',
  itemCrossReference: '',
  rxCustomerID: 0,
  categoryID: 0,
  subCategoryID: 0,
  deaClass: 0,
  plan1: '',
  planAmount1: 0,
  plan2: '',
  planAmount2: 0,
  plan3: '',
  planAmount3: 0,
  plan4: '',
  planAmount4: 0,
  plan5: '',
  planAmount5: 0,
  prescriptionID: 0,
  ebtIndicator: false,
  wicIndicator: false,
  rxRelationship: '',
  pickupAllEmployeeID: 0,
  customerMRN: '',
  rxBarcodeScanData: '',
  controlledSubstanceIndicator: false,
  counselLater: false,
  counselorID: 0,
  counselIndicator: false,
});

export const mapInvoiceIdentification = (
  invoiceId: string,
): InvoiceIdentification => {
  const invoiceIdentificationId = uuid();
  return {
    invoiceId,
    invoiceIdentificationId,
    idNumber: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: null,
    address1: '',
    address2: '',
    city: '',
    state: null,
    zipCode: '',
    notes: '',
    idTypeID: 1,
    idTypeCode: 'DL',
    relationshipId: 0,
    relationship: '',
  };
};

export const mapInvoiceTax = (
  invoiceId: string,
  taxGroup: TaxGroup,
): InvoiceTax => {
  return {
    invoiceId,
    invoiceTaxId: uuid(),
    taxID: 0,
    transactionID: 0,
    taxAmount: 0,
    taxRateID: taxGroup.taxRateId,
    taxRate: taxGroup.taxRate,
    taxableSubTotal: 0,
    taxGroupID: taxGroup.id,
  };
};

export const mapSignatureImage = (): SignatureImage =>
  ({
    signatureImageId: uuid(),
    serverSignatureId: 10,
    signatureImageType: 'points',
    imageData: '',
    pointData: [],
    isSynced: false,
  } as SignatureImage & OfflineSynced);

export const mapInvoiceSignature = (
  invoiceId: string,
  touchless: boolean,
  signatureType: SignatureType,
  signatureImageId?: string,
): InvoiceSignature =>
  ({
    invoiceId,
    invoiceSignatureId: uuid(),
    touchless,
    signatureImageId,
    signatureTypeId: signatureType.signatureTypeId,
    signatureType,
    createdOn: new Date(),
    isSynced: false,
  } as InvoiceSignature & OfflineSynced);

export const mapInvoicePayment = (
  invoiceId: string,
  paymentTypeID: number,
  amount: number,
  updates?: Partial<InvoicePayment>,
): InvoicePayment => ({
  invoiceId,
  invoicePaymentId: uuid(),
  serverPaymentID: 0,
  paymentTypeID,
  transactionID: 0,
  paymentNumber: '',
  amount,
  amountReturned: 0,
  epsReference: '',
  cardMember: '',
  recordStatus: 'Active',
  changeIndicator: false,
  approvalCode: '',
  relatedDetailID: 0,
  relatedDetailID2: 0,
  purCustReference: '',
  cashBack: 0,
  cardToken: '',
  avsStreetMatch: '',
  avsZipMatch: '',
  cvMatch: '',
  street: '',
  zipcode: '',
  state: '',
  cvNumber: 0,
  signatureStatus: 0,
  signatureProcessor: '',
  sigType: '',
  swipeType: '',
  qhpPartial: false,
  qhpRxQuantity: 0,
  qhpRxAmount: 0,
  qhpOtherQuantity: 0,
  qhpOtherAmount: 0,
  fsaRequestedAmount: 0,
  fsaApprovedAmount: 0,
  fsaBalanceAmount: 0,
  voiceAuthIndicator: false,
  hostProcessor: '',
  smartPayProcessor: '',
  smartPayUserID: 0,
  arAccountTypeID: 0,
  ccofPaymentInfoID: 0,
  originalPaymentID: 0,
  noSigCapRequiredLImitAmount: 0,
  autoChargeIndicator: false,
  sigCapSentToProcessor: false,
  createdOn: new Date(),
  ...updates,
});
