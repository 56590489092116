import {useCallback} from 'react';
import {
  TransactionResponse,
  transformCayanToTransactionResponse,
  transformCayanToVoidResponse,
  transformCayanVaultToTransactionResponse,
  VoidRequest,
} from '../pages/sales/transactions/payments/customer-payment/Types';
import {useCardReaderCredentials} from '../contexts/CardReaderCredentialsProvider';
import {
  CayanClient,
  CayanConfig,
  CayanTransactionResponse,
  DeviceBusy,
  DeviceNotReady,
  DeviceUnreachable,
  IdleScreen,
  SKUDisplay,
  UnableToCancelTransaction,
  UnableToVoidOrder,
  VoidResponse,
} from '@emporos/card-payments';
import useOpenApi from '../api/useOpenApi';
import useOpenApiSession from '../api/useOpenApiSession';
import {PaymentsApi} from '@emporos/api-enterprise/src/gen';
import {
  InvoicePayment,
  InvoicePaymentsApi,
} from '@emporos/api-enterprise/src/gen-session';
import useInvoice from './useInvoice';
import {mapInvoicePayment} from '../utils/mappers';
import {getPaymentTypeIdByDescriptor} from '../pages/sales/transactions/payments/utils';
import {useGlobalData} from '../contexts/GlobalDataProvider';
import assert from 'assert';

export interface PaymentProvider {
  checkDevice: () => Promise<void>;
  stageTransaction: (request: PaymentRequest) => Promise<InvoicePayment>;
  initiateTransaction: (
    transportKey: string,
    amount: number,
  ) => Promise<TransactionResponse>;
  cancelTransaction: () => Promise<string>;
  voidTransaction: (request: VoidRequest) => Promise<VoidResponse>;
  getTransactionDetails: (
    payment: InvoicePayment,
  ) => Promise<TransactionResponse>;
  vaultTransaction: (request: PaymentRequest) => Promise<InvoicePayment>;
}

export interface PaymentRequest {
  totalAmount: number;
  qhpAmount: number;
  cardToken?: string;
  paymentTypeId?: number | null;
  last4OfCard?: string;
  paymentInformationId?: number;
}

const usePaymentProvider = (): PaymentProvider => {
  const {credentials} = useCardReaderCredentials();
  const {invoice} = useInvoice();
  const {paymentTendersResult} = useGlobalData();
  const {run: postVoid} = useOpenApi(PaymentsApi, 'clientPaymentVoidPost');
  const {run: postDetails} = useOpenApi(PaymentsApi, 'clientPaymentDetailPost');
  const {run: postPayment} = useOpenApiSession(
    InvoicePaymentsApi,
    'clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPost',
  );

  const checkDevice = useCallback(async () => {
    try {
      const status = await client().checkStatus();
      if (status instanceof Error) {
        throw status;
      }
      if (status.CurrentScreen === SKUDisplay) {
        throw Error(DeviceBusy);
      } else if (status.CurrentScreen !== IdleScreen) {
        throw Error(DeviceNotReady);
      }
    } catch (err) {
      throw Error(DeviceUnreachable);
    }
  }, [JSON.stringify(credentials)]);

  const stageTransaction = useCallback(
    async (request: PaymentRequest): Promise<InvoicePayment> => {
      assert(
        paymentTendersResult && paymentTendersResult.data,
        'Missing payment tenders',
      );
      return await postPayment({
        sessionId: invoice.sessionId,
        invoiceId: invoice.invoiceId,
        invoicePaymentRequest: mapInvoicePayment(
          invoice.invoiceId,
          getPaymentTypeIdByDescriptor(
            'UnknownCard',
            paymentTendersResult.data,
          ) || 0,
          request.totalAmount,
          {
            recordStatus: 'Pending',
            qhpRxAmount: request.qhpAmount,
            qhpOtherAmount: 0,
          },
        ),
      });
    },
    [postPayment, paymentTendersResult],
  );

  const initiateTransaction = useCallback(
    async (transportKey: string, amount: number) => {
      const initiated = await client().initiateTransaction(transportKey);
      /* istanbul ignore next */
      if (initiated instanceof Error) {
        throw initiated;
      } else {
        return transformCayanToTransactionResponse(
          initiated as CayanTransactionResponse,
          amount,
        );
      }
    },
    [JSON.stringify(credentials)],
  );

  const getTransactionDetails = useCallback(
    async (payment: InvoicePayment) => {
      if (!payment.cardToken) {
        throw Error(DeviceUnreachable);
      }
      const result = await postDetails({
        paymentDetailRequest: {
          siteId: Number(credentials.config.siteId),
          transportKey: payment.cardToken,
        },
      });
      if (!result?.data) {
        throw Error();
      }
      return transformCayanVaultToTransactionResponse(result?.data);
    },
    [postDetails],
  );

  const cancelTransaction = useCallback(async () => {
    try {
      const result = await client().cancel();
      if (result.Status === 'Denied') {
        throw Error(UnableToCancelTransaction);
      }
    } catch (err) {
      throw Symbol.for('TransactionAbortSignal');
    }
    return 'Okay';
  }, [JSON.stringify(credentials)]);

  const vaultTransaction = useCallback(
    async (request: PaymentRequest) => {
      assert(
        paymentTendersResult && paymentTendersResult.data,
        'Missing payment tenders',
      );
      if (!request.paymentTypeId) throw request;

      return await postPayment({
        sessionId: invoice.sessionId,
        invoiceId: invoice.invoiceId,
        invoicePaymentRequest: mapInvoicePayment(
          invoice.invoiceId,
          request.paymentTypeId,
          request.totalAmount,
          {
            recordStatus: 'Active',
            qhpRxAmount: request.qhpAmount,
            qhpOtherAmount: 0,
            cardToken: request.cardToken,
            paymentNumber: request.last4OfCard,
            ccofPaymentInfoID: request.paymentInformationId,
          },
        ),
      });
    },
    [postPayment],
  );

  const voidTransaction = useCallback(
    async (request: VoidRequest) => {
      const voidRequest = {
        siteId: Number(credentials.config.siteId),
        transactionId: request.orderId,
        referenceNumber: request.transactionId,
        terminalId: '',
        userId: request.clerkId,
      };

      const stagedRaw = await postVoid({paymentVoidRequest: voidRequest});

      if (stagedRaw.data?.status === 'Unknown') {
        throw new Error(UnableToVoidOrder);
      }
      return transformCayanToVoidResponse(stagedRaw);
    },
    [postVoid],
  );

  const client = () => CayanClient.create(credentials.config as CayanConfig);

  return {
    checkDevice,
    stageTransaction,
    initiateTransaction,
    getTransactionDetails,
    vaultTransaction,
    cancelTransaction,
    voidTransaction,
  };
};

export default usePaymentProvider;
