import React, {memo, PropsWithoutRef} from 'react';
import styled, {css} from 'styled-components';
import {Icon, Icons, IconSize} from './';

// slint-disable-next-line @eslint-typescript/no-empty-func
function noop() {}

interface Props {
  icon: keyof typeof Icons;
  text: string;
  disabled?: boolean;
}

const hoverStyles = css<{disabled?: boolean}>`
  border-color: ${({theme, disabled}): string | false =>
    !disabled && theme.colors.blue};
  background-color: ${({theme, disabled}): string | false =>
    !disabled && theme.colors.blue_20};
  color: ${({theme, disabled}): string | false =>
    !disabled && theme.colors.blue};
  box-shadow: ${({disabled}): string | false => !disabled && 'none'};
`;

const StyledButton = styled.button<{disabled?: boolean}>`
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 8px;
  color: ${({theme}): string => theme.colors.blue};
  font-family: ${({theme}): string => theme.typography.SubMainLight.fontFamily};
  font-size: ${({theme}): string => theme.typography.SubMainLight.fontSize};
  font-weight: 500;
  box-shadow: ${({theme}): string => theme.shadows.shadow_2};
  cursor: ${({disabled}): string => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}): string => (disabled ? '0.5' : '1')};
  transition: all 250ms ease;

  span {
    margin-top: 5px;
  }
  &:focus {
    outline: none;
  }
  &:active,
  &.selected {
    ${hoverStyles}
  }
  @media (hover: hover) {
    &:hover {
      ${hoverStyles}
    }
  }
`;

export const ButtonSquareText = memo(
  ({
    text,
    ...props
  }: Props & PropsWithoutRef<JSX.IntrinsicElements['button']>) => {
    return (
      <StyledButton {...props}>
        <Icon icon={props.icon} size={IconSize.L} variant="primary" />
        <span>{text}</span>
      </StyledButton>
    );
  },
);

export const InputSquare = memo(
  ({
    text,
    name,
    value,
    checked,
    onChange,
    className,
    disabled,
    ...props
  }: Props & PropsWithoutRef<JSX.IntrinsicElements['input']>) => {
    return (
      <StyledButton
        as="label"
        className={(className || '') + (checked ? ' selected' : '')}
        disabled={disabled}
        {...(props as unknown)}
      >
        <Icon icon={props.icon} size={IconSize.L} variant="primary" />
        <span>{text}</span>
        <input
          type="radio"
          name={name}
          disabled={disabled}
          value={value}
          checked={checked}
          onChange={disabled ? noop : onChange}
          hidden
        />
      </StyledButton>
    );
  },
);
