/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerAccount,
    CustomerAccountFromJSON,
    CustomerAccountFromJSONTyped,
    CustomerAccountToJSON,
    CustomerCreditCard,
    CustomerCreditCardFromJSON,
    CustomerCreditCardFromJSONTyped,
    CustomerCreditCardToJSON,
    CustomerPhone,
    CustomerPhoneFromJSON,
    CustomerPhoneFromJSONTyped,
    CustomerPhoneToJSON,
} from './';

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    mrn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    lastName: string;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    dob?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    email?: string | null;
    /**
     * 
     * @type {Array<CustomerPhone>}
     * @memberof Customer
     */
    phones: Array<CustomerPhone>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Customer
     */
    notes: Array<string>;
    /**
     * 
     * @type {Array<CustomerAccount>}
     * @memberof Customer
     */
    accounts: Array<CustomerAccount>;
    /**
     * 
     * @type {Array<CustomerCreditCard>}
     * @memberof Customer
     */
    creditCards: Array<CustomerCreditCard>;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    readonly showHIPAA: boolean;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    discountReasonID?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    badCheckIndicator?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    creditHoldIndicator?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    primaryARId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    safetyCapsIndicator?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    safetyCapsDate?: Date | null;
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'mrn': !exists(json, 'mrn') ? undefined : json['mrn'],
        'firstName': json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'lastName': json['lastName'],
        'dob': !exists(json, 'dob') ? undefined : (json['dob'] === null ? null : new Date(json['dob'])),
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phones': ((json['phones'] as Array<any>).map(CustomerPhoneFromJSON)),
        'notes': json['notes'],
        'accounts': ((json['accounts'] as Array<any>).map(CustomerAccountFromJSON)),
        'creditCards': ((json['creditCards'] as Array<any>).map(CustomerCreditCardFromJSON)),
        'showHIPAA': json['showHIPAA'],
        'discountPercent': !exists(json, 'discountPercent') ? undefined : json['discountPercent'],
        'discountReasonID': !exists(json, 'discountReasonID') ? undefined : json['discountReasonID'],
        'badCheckIndicator': !exists(json, 'badCheckIndicator') ? undefined : json['badCheckIndicator'],
        'creditHoldIndicator': !exists(json, 'creditHoldIndicator') ? undefined : json['creditHoldIndicator'],
        'primaryARId': !exists(json, 'primaryARId') ? undefined : json['primaryARId'],
        'safetyCapsIndicator': !exists(json, 'safetyCapsIndicator') ? undefined : json['safetyCapsIndicator'],
        'safetyCapsDate': !exists(json, 'safetyCapsDate') ? undefined : (json['safetyCapsDate'] === null ? null : new Date(json['safetyCapsDate'])),
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'mrn': value.mrn,
        'firstName': value.firstName,
        'middleName': value.middleName,
        'lastName': value.lastName,
        'dob': value.dob === undefined ? undefined : (value.dob === null ? null : value.dob.toISOString()),
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'email': value.email,
        'phones': ((value.phones as Array<any>).map(CustomerPhoneToJSON)),
        'notes': value.notes,
        'accounts': ((value.accounts as Array<any>).map(CustomerAccountToJSON)),
        'creditCards': ((value.creditCards as Array<any>).map(CustomerCreditCardToJSON)),
        'discountPercent': value.discountPercent,
        'discountReasonID': value.discountReasonID,
        'badCheckIndicator': value.badCheckIndicator,
        'creditHoldIndicator': value.creditHoldIndicator,
        'primaryARId': value.primaryARId,
        'safetyCapsIndicator': value.safetyCapsIndicator,
        'safetyCapsDate': value.safetyCapsDate === undefined ? undefined : (value.safetyCapsDate === null ? null : value.safetyCapsDate.toISOString()),
    };
}


