import React, {PropsWithoutRef, memo} from 'react';
import styled from 'styled-components';

interface Props {
  text: string | number; // TODO: only 1-99
}

const StyledBadge = styled.span<Props>`
  display: flex;
  width: 22px;
  min-width: 22px; // to prevent it getting squashed by long item names
  height: 22px;
  align-items: center;
  justify-content: center;
  font-family: ${({theme}) => theme.typography.Caption.fontFamily};
  font-weight: ${({theme}) => theme.typography.Caption.fontWeight};
  font-size: ${({theme}) => theme.typography.Caption.fontSize};
  text-transform: ${({theme}) => theme.typography.Caption.textTransform};
  line-height: ${({theme}) => theme.typography.Caption.lineHeight};
  background-color: ${({theme}) => theme.colors.steel};
  border-radius: 50%;
  color: ${({theme}) => theme.colors.white};
`;

export const IndicatorQuantity = memo(
  (props: Props & PropsWithoutRef<JSX.IntrinsicElements['span']>) => {
    return (
      <StyledBadge data-testid="quantity-badge" {...props}>
        {props.text}
      </StyledBadge>
    );
  },
);
