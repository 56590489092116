import {PaymentType} from '@emporos/api-enterprise/src/gen';
import {
  CustomerCreditCard,
  Invoice,
  InvoicePayment,
} from '@emporos/api-enterprise/src/gen-session';
import {DeviceUnreachable, Processor} from '@emporos/card-payments';
import {Button, Modal, Row, Select, Stack} from '@emporos/components';
import {NavigateFn} from '@reach/router';
import moment from 'moment';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {Credentials} from '../../../../../contexts/CardReaderCredentialsProvider';
import {
  AlertLogTypes,
  ApiLogTypes,
  useLog,
} from '../../../../../contexts/LoggingProvider';
import {UpdateInvoiceFn} from '../../../../../hooks/useInvoice';
import {PaymentProvider} from '../../../../../hooks/usePaymentProvider';
import BillBoard from './BillBoard';
import HandleTransactionResponse from './HandleTransactionResponse';
import {PaymentRef as Handle, HandleTransactionResponseRef} from './Types';

interface Props {
  cardsOnFile: Array<CustomerCreditCard>;
  showVantiv?: boolean;
  showHostedPayment?: boolean;
  creditCardPendingPaymentExpirationMinutes: number;
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  totalDue: number;
  qhpAmount: number;
  deviceCredentials: Credentials;
  onContinue: () => void;
  onChangeActivePayment: () => void;
  navigate: NavigateFn;
  paymentTenders: PaymentType[];
  paymentProvider: PaymentProvider;
  invoice: Invoice;
  updateInvoice: UpdateInvoiceFn;
}

export enum DeviceStatus {
  Unknown,
  Disconnected,
  Connecting,
  Connected,
  DeviceError,
  Timeout,
  DeviceUnreachable,
  AppCanceled,
  UserCanceled,
  PaymentError,
  PaymentProcessing,
  CCOFPaymentProcessing,
  PaymentDeclined,
  PaymentWarning,
  PaymentExpired,
  PaymentSuccess,
  PartialPaymentSuccess,
  CancelTransactionFailed,
  CheckStatus,
}
const HOSTED_PAYMENT = 'HostedPayment';
export function cardToOption(c: CustomerCreditCard): string {
  return `${c.cardType} **** ${c.last4OfCard} ${moment(c.expiration).format(
    'YYYY-MM',
  )}`;
}
export default forwardRef<Handle, Props>(function CreditCard(
  {
    cardsOnFile,
    showVantiv,
    showHostedPayment,
    creditCardPendingPaymentExpirationMinutes,
    amount,
    totalDue,
    qhpAmount,
    setAmount,
    deviceCredentials,
    onContinue,
    onChangeActivePayment,
    navigate,
    paymentTenders,
    invoice,
    updateInvoice,
    setSelectedPayment,
    paymentProvider: {
      checkDevice,
      stageTransaction,
      initiateTransaction,
      cancelTransaction,
      getTransactionDetails,
      vaultTransaction,
    },
  }: Props,
  ref,
): JSX.Element {
  const {logAlert, logApi} = useLog();
  const [status, setStatus] = useState(DeviceStatus.Unknown);
  const [value, setValue] = useState<Processor | string>(
    deviceCredentials.processor,
  );
  const [pendingPayment, setPendingPayment] = useState<InvoicePayment | null>(
    null,
  );
  const handleRef = useRef<HandleTransactionResponseRef | null>(null);

  useEffect(() => {
    onChangeActivePayment();
  }, [value]);

  const logPaymentResult = useCallback(
    (result: string, status?: 'info' | 'error') => {
      logAlert(
        ApiLogTypes.CreditPaymentResult,
        {result},
        status ? status : 'info',
      );
    },
    [logAlert],
  );

  const onPay = useCallback(
    async function onConfirmPayment() {
      setPendingPayment(null);
      switch (value) {
        case Processor.Vantiv:
        case Processor.Cayan:
          {
            try {
              logApi(ApiLogTypes.CreditPaymentPending, {info: 'Cayan'});

              setStatus(DeviceStatus.Connecting);
              logAlert(AlertLogTypes.DeviceConnecting);
              await checkDevice();
              setStatus(DeviceStatus.Connected);
              logAlert(AlertLogTypes.DeviceConnected);

              const pendingPayment = await stageTransaction({
                totalAmount: Number(amount),
                qhpAmount,
              });
              updateInvoice(prevInvoice => ({
                payments: prevInvoice.payments.concat(pendingPayment),
              }));
              setPendingPayment(pendingPayment);
              if (!pendingPayment.cardToken) {
                throw pendingPayment;
              }
              const result = await initiateTransaction(
                pendingPayment.cardToken,
                Number(amount),
              );
              handleRef.current?.handleTransactionResponse(
                result,
                pendingPayment,
              );
            } catch (e) {
              // if we get back a TransactionAbortSignal message, we know that we
              // called the cancelTransaction() message
              if (e === Symbol.for('TransactionAbortSignal')) {
                return null;
              }
              if ((e as Error).message === DeviceUnreachable) {
                setStatus(DeviceStatus.DeviceUnreachable);
                return null;
              }
              setStatus(DeviceStatus.Timeout);
            }
          }
          break;
        default: {
          setStatus(DeviceStatus.CCOFPaymentProcessing);
          const card = cardsOnFile.find(f => f.token === value);
          /* istanbul ignore next */
          if (!card) {
            throw new Error('Unable to find card');
          }
          logApi(ApiLogTypes.CreditPaymentPending, {
            info: 'Using card from file',
          });
          try {
            const result = await vaultTransaction({
              totalAmount: Number(amount),
              qhpAmount,
              cardToken: card.token,
              paymentTypeId: card.paymentTypeId,
              last4OfCard: card.last4OfCard,
              paymentInformationId: card.paymentInformationId,
            });
            if (!result.jsonPaymentProcessorResponse) {
              throw result;
            }
            const status = JSON.parse(result.jsonPaymentProcessorResponse)
              .Status;
            if (status !== 'Approved') {
              throw result;
            }
            updateInvoice(prevInvoice => ({
              payments: prevInvoice.payments.concat(result),
            }));
            setStatus(DeviceStatus.PaymentSuccess);
          } catch (e) {
            setStatus(DeviceStatus.PaymentError);
          }
        }
      }
      return null;
    },
    [amount, value, logPaymentResult, pendingPayment],
  );

  const onCheckStatus = useCallback(async () => {
    if (!pendingPayment) return;
    try {
      setStatus(DeviceStatus.CheckStatus);
      const result = await getTransactionDetails(pendingPayment);
      handleRef.current?.handleTransactionResponse(result, pendingPayment);
    } catch (err) {
      setStatus(DeviceStatus.Timeout);
    }
  }, [pendingPayment]);

  useImperativeHandle(ref, () => ({
    onConfirmPayment: onPay,
    isAcceptablePayment: () => value !== HOSTED_PAYMENT,
  }));

  const _onContinue = useCallback(
    function _onContinue() {
      setStatus(DeviceStatus.Unknown);
      onContinue();
    },
    [setStatus, onContinue],
  );

  const onCancel = useCallback(
    function onCancel() {
      setStatus(DeviceStatus.Unknown);
    },
    [setStatus],
  );

  const onCancelTransaction = useCallback(
    async function onCancelTransaction() {
      try {
        await cancelTransaction();
        setStatus(DeviceStatus.AppCanceled);
      } catch (err) {
        setStatus(DeviceStatus.CancelTransactionFailed);
      }
    },
    [setStatus],
  );

  const optionsValue = () => {
    const values: (Processor | string)[] = [Processor.Cayan];
    if (showVantiv) {
      values.push(Processor.Vantiv);
    }
    if (showHostedPayment) {
      values.push(HOSTED_PAYMENT);
    }
    return [...values, ...cardsOnFile.map(c => c.token)];
  };

  const optionsText = () => {
    const texts = ['Cayan Device'];
    if (showVantiv) {
      texts.push('Vantiv Device');
    }
    if (showHostedPayment) {
      texts.push('Hosted Payment Portal');
    }
    return [...texts, ...cardsOnFile.map(cardToOption)];
  };

  return (
    <>
      <Stack style={{flex: 1}}>
        <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
        <Row style={{marginTop: 36}}>
          <Select
            options={optionsValue()}
            optionsText={optionsText()}
            value={value}
            onChangeValue={setValue}
            label="Credit Card"
          />
        </Row>
        {value === HOSTED_PAYMENT && (
          <Row>
            <Button
              onClick={() => navigate('hosted-payment', {state: {amount}})}
              style={{flex: 1}}
            >
              Launch Payment Portal
            </Button>
          </Row>
        )}
      </Stack>
      <Modal
        data-testid="Modal__DeviceUnreachable"
        visible={status === DeviceStatus.DeviceUnreachable}
        icon="Warning"
        color="warning"
        onCancel={onCancel}
        onContinue={onPay}
        buttonText="Retry"
        title="Device Unreachable"
        subtitle="The device could not be reached, please check your payment device settings."
      />
      <Modal
        data-testid="Modal__DeviceError"
        visible={status === DeviceStatus.Timeout}
        icon="Warning"
        color="warning"
        onCancel={onCancel}
        onContinue={onCheckStatus}
        buttonText="Check Status"
        title="Payment Device Timeout"
        subtitle="Something didn’t work with the payment device. Would you like to try again?"
      />
      <Modal
        visible={status === DeviceStatus.Connected}
        data-testid="Modal__Connected"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Connection is Established"
        subtitle="Connection has been established. Please complete payment on the device."
        buttonText="Cancel"
        onContinue={onCancelTransaction}
      />
      <Modal
        visible={status === DeviceStatus.CCOFPaymentProcessing}
        data-testid="Modal__CCOFPaymentProcessing"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Processing CCOF Payment"
        subtitle="The payment is currently processing. Please hold tight."
      />
      <HandleTransactionResponse
        onCancel={onCancel}
        onContinue={_onContinue}
        invoice={invoice}
        updateInvoice={updateInvoice}
        paymentTenders={paymentTenders}
        status={status}
        setStatus={setStatus}
        setSelectedPayment={setSelectedPayment}
        navigate={navigate}
        ref={handleRef}
        creditCardPendingPaymentExpirationMinutes={
          creditCardPendingPaymentExpirationMinutes
        }
      />
    </>
  );
});
