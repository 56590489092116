import React, {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
import {ColorType, Icons, mapColor, PaymentIcons} from './';

export enum IconSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  Payment = 'Payment',
}
const iconSize = (size: IconSize) => {
  switch (size) {
    case IconSize.XS:
      return '12px';
    case IconSize.S:
      return '16px';
    case IconSize.L:
      return '30px';
    case IconSize.XL:
      return '36px';
    case IconSize.Payment:
      return '44px';
    case IconSize.M:
    default:
      return '24px';
  }
};

export const AllIcons = {
  ...Icons,
  ...PaymentIcons,
};
interface IconProps {
  icon: keyof typeof AllIcons;
  variant?: ColorType;
  size?: IconSize;
}

const StyledIcon = styled.div<IconProps>`
  display: inline-block;
  width: fit-content;
  color: ${({theme, variant}) => mapColor(theme, variant)};

  svg {
    display: block;
  }
`;

export const Icon = memo(
  ({
    size = IconSize.M,
    variant = 'black',
    ...props
  }: IconProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const Icon = AllIcons[props.icon];
    return (
      <StyledIcon variant={variant} {...props}>
        <Icon
          width={iconSize(size)}
          height={size === IconSize.Payment ? '32px' : iconSize(size)}
        />
      </StyledIcon>
    );
  },
);
