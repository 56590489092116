/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerSearchCriteria
 */
export interface CustomerSearchCriteria {
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchCriteria
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CustomerSearchCriteria
     */
    dob?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSearchCriteria
     */
    phone?: string | null;
}

export function CustomerSearchCriteriaFromJSON(json: any): CustomerSearchCriteria {
    return CustomerSearchCriteriaFromJSONTyped(json, false);
}

export function CustomerSearchCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSearchCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'dob': !exists(json, 'dob') ? undefined : (json['dob'] === null ? null : new Date(json['dob'])),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function CustomerSearchCriteriaToJSON(value?: CustomerSearchCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dob': value.dob === undefined ? undefined : (value.dob === null ? null : value.dob.toISOString()),
        'phone': value.phone,
    };
}


