import {OtcItem} from '@emporos/api-enterprise/src/gen';
import {
  IndicatorCircle,
  RowItem,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import Text, {Variant} from '@emporos/components/src/Text';
import TextInput from '@emporos/components/src/TextInput';
import VirtualizedList from '@emporos/components/src/VirtualizedList';
import {useState} from 'react';
import {formatMedication} from '../../../../utils/string';
import {otcIcons} from '../../../../utils/rowIcons';

interface OTCSearchProps {
  lockPseItems?: boolean;
  otcItems: Array<OtcItem> | void;
  scrollToIndex?: number;
  selectedOTCItems: Array<OtcItem>;
  setSelectedOTCItems: (otcItems: Array<OtcItem>) => void;
}

export default function OtcSearch({
  lockPseItems = false,
  otcItems,
  scrollToIndex,
  selectedOTCItems,
  setSelectedOTCItems,
}: OTCSearchProps): JSX.Element {
  const [searchText, setSearchText] = useState('');

  const filteredOtcItems = otcItems
    ? otcItems.filter(
        (item: OtcItem) =>
          item.description?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.itemNumber.includes(searchText),
      )
    : [];

  function isSelected(otcItem: OtcItem) {
    return selectedOTCItems.some(
      selectedOtcItem => selectedOtcItem.itemNumber === otcItem.itemNumber,
    );
  }

  return (
    <Stack style={{marginTop: 24, height: '100%'}} data-testid="OTCSearch">
      <TextInput
        icon="Search"
        label="Search"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        onClear={() => setSearchText('')}
        data-testid="search-otc"
      />

      {filteredOtcItems.length > 0 ? (
        <ScrollContainer style={{marginTop: 20}}>
          <VirtualizedList<OtcItem>
            data={filteredOtcItems}
            listHeight={window.innerHeight - 348}
            rowHeight={96}
            renderItem={(item: OtcItem) => (
              <RowItem
                inactive={lockPseItems && item.itemType.id === 16}
                title={formatMedication(item.description || '')}
                subtitle={item.itemNumber}
                rightText={`$${item.listPrice.toFixed(2)}`}
                rightIcons={otcIcons(item)}
                key={item.id}
                selected={isSelected(item)}
                onClick={() =>
                  isSelected(item)
                    ? setSelectedOTCItems(
                        selectedOTCItems.filter(
                          selectedOtcItem =>
                            selectedOtcItem.itemNumber !== item.itemNumber,
                        ),
                      )
                    : setSelectedOTCItems(selectedOTCItems.concat(item))
                }
              />
            )}
            scrollIndex={scrollToIndex}
          />
        </ScrollContainer>
      ) : (
        <Stack align="center" justify="center" style={{flex: 1}}>
          <IndicatorCircle icon="Search" variant="gray" size="large" />
          <Text variant={Variant.Main} align="center">
            No items found
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
