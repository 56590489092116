/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentVaultRequest
 */
export interface PaymentVaultRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentVaultRequest
     */
    siteId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    credentialType: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentVaultRequest
     */
    qhpAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentVaultRequest
     */
    totalAmount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    transactionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    terminalId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    purchaseOrderNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    rawTransactionStagingResponse?: string | null;
}

export function PaymentVaultRequestFromJSON(json: any): PaymentVaultRequest {
    return PaymentVaultRequestFromJSONTyped(json, false);
}

export function PaymentVaultRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentVaultRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteId': json['siteId'],
        'credentialType': json['credentialType'],
        'token': json['token'],
        'qhpAmount': json['qhpAmount'],
        'totalAmount': json['totalAmount'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'terminalId': json['terminalId'],
        'userId': json['userId'],
        'purchaseOrderNumber': !exists(json, 'purchaseOrderNumber') ? undefined : json['purchaseOrderNumber'],
        'rawTransactionStagingResponse': !exists(json, 'rawTransactionStagingResponse') ? undefined : json['rawTransactionStagingResponse'],
    };
}

export function PaymentVaultRequestToJSON(value?: PaymentVaultRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteId': value.siteId,
        'credentialType': value.credentialType,
        'token': value.token,
        'qhpAmount': value.qhpAmount,
        'totalAmount': value.totalAmount,
        'transactionId': value.transactionId,
        'terminalId': value.terminalId,
        'userId': value.userId,
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'rawTransactionStagingResponse': value.rawTransactionStagingResponse,
    };
}


