import {createContext, PropsWithChildren, useContext} from 'react';
import config, {ConfigShape} from '../config';

const context = createContext(config);

type Props = PropsWithChildren<unknown>;

export function ConfigProvider(props: Props): JSX.Element {
  return <context.Provider value={config}>{props.children}</context.Provider>;
}

export function useConfig(): ConfigShape {
  return useContext(context);
}
