import {RowSidebarStatus} from '@emporos/components';
import TabBar from '@emporos/components/src/TabBar';
import {
  RouteComponentProps,
  Router,
  useLocation,
  useNavigate,
} from '@reach/router';
import {Fragment, memo, useState} from 'react';
import {useGlobalData} from '../contexts/GlobalDataProvider';
import {NavigationLogTypes, useLog} from '../contexts/LoggingProvider';
import {getInvoiceStatus} from '../contexts/SyncSessionProvider';
import {
  Invoice,
  useTransactionsState,
} from '../contexts/TransactionsStateProvider';
import CompletedSidebar from '../pages/sales/completed/CompletedSidebar';
import SalesSidebar from '../pages/sales/SalesSidebar';
import SettingsSidebar from '../pages/sales/settings/SettingsSidebar';
import PaymentsSidebar from '../pages/sales/transactions/payments/PaymentsSidebar';

const COMPLETED_TAB = 1;
export const fullHeightStack = {minHeight: '100%'};
export const behindTabBarStackingContext = {
  position: 'relative' as const,
  zIndex: 0,
  flex: 1,
  overflowY: 'auto' as const,
};

function indexToUrl(index: number): string {
  switch (index) {
    case 0:
      return '/sales';
    case 1:
      return '/sales/completed';
    case 2:
      return '/sales/settings';
    default:
      return '';
  }
}

export const invoiceSynced = (invoice: Invoice): RowSidebarStatus =>
  getInvoiceStatus(invoice) === 'success' ? 'synced' : 'unsynced';

function Navigation({
  onNavigationRequest,
}: RouteComponentProps & {
  onNavigationRequest(index: number): void;
}) {
  const {pathname} = useLocation();
  const {loading} = useGlobalData();
  const active = /settings/.test(pathname)
    ? 2
    : /completed/.test(pathname)
    ? 1
    : 0;

  return (
    <div
      style={{
        position: 'absolute' as const,
        bottom: 36,
        zIndex: 1,
        left: 40,
      }}
    >
      <TabBar
        active={active}
        onSelect={onNavigationRequest}
        items={[
          {
            icon: 'CashRegister',
          },
          {
            icon: 'CheckmarkClipboard',
            disabled: loading,
          },
          {icon: 'Cog'},
        ]}
      />
    </div>
  );
}

function Sidebar() {
  const navigate = useNavigate();
  const [requestedUrl, setRequestedUrl] = useState<null | string>(null);
  const {logUserSelection} = useLog();
  const {setCurrentInvoiceId} = useTransactionsState();
  const {pathname} = useLocation();

  function onNavigationRequest(index: number) {
    setRequestedUrl(indexToUrl(index));
    logUserSelection(NavigationLogTypes.UserNavigationRequested, {
      url: indexToUrl(index),
    });
    if (index === COMPLETED_TAB) {
      setCurrentInvoiceId('');
    }
  }

  function onCancelNavigation() {
    logUserSelection(NavigationLogTypes.UserNavigationCancelled, {
      url: requestedUrl,
    });
    setRequestedUrl(null);
  }

  function onConfirmNavigation(url?: string) {
    if (typeof url === 'string') {
      logUserSelection(NavigationLogTypes.UserNavigating, {url});
      setRequestedUrl(null);
      return navigate(url);
    }
    if (requestedUrl) {
      logUserSelection(NavigationLogTypes.UserNavigating, {
        url: requestedUrl,
      });
      setRequestedUrl(null);
      return navigate(requestedUrl);
    }
  }

  return (
    <>
      <Router primary={false} component={Fragment}>
        <CompletedSidebar
          requestedUrl={requestedUrl}
          onCancelNavigation={onCancelNavigation}
          onConfirmNavigation={onConfirmNavigation}
          path="completed/*"
        />
        <SettingsSidebar
          requestedUrl={requestedUrl}
          onCancelNavigation={onCancelNavigation}
          onConfirmNavigation={onConfirmNavigation}
          path="settings/*"
        />
        <SalesSidebar
          default
          requestedUrl={requestedUrl}
          onConfirmNavigation={onConfirmNavigation}
        />
        <PaymentsSidebar path="transactions/payments/*" />
      </Router>
      {!/payments/.test(pathname) && (
        <Navigation default onNavigationRequest={onNavigationRequest} />
      )}
    </>
  );
}

export default memo(Sidebar);
