import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY';

export const isBeforeNow = (date: string): boolean =>
  moment(date, DATE_FORMAT, true).isBefore(moment());

export const isAfterNow = (date: string): boolean =>
  moment(date, DATE_FORMAT, true).isAfter(moment());

export const diffYears = (date: string): number =>
  moment().diff(moment(date, DATE_FORMAT, true), 'years');

// Ensures two different dates are the same (used when verifying patient birthdays)
export const isSame = (customerInput: string, dateToCompare: string): boolean =>
  moment(moment(customerInput).format(DATE_FORMAT)).isSame(
    moment(dateToCompare).format(DATE_FORMAT),
  );

// Ensures that a date being entered is formatted correctly
export const isValid = (date: string): boolean =>
  moment(date, DATE_FORMAT).format(DATE_FORMAT) === date;
