import * as yup from 'yup';
import {ObjectSchema} from 'yup';
import mapIdCheckIndicators from './IdCheckIndicators';
import {diffYears, isAfterNow, isBeforeNow} from '../../../../../utils/dates';
import {Invoice} from '@emporos/api-enterprise/src/gen-session';

const mapSchema = (invoice: Invoice): ObjectSchema => {
  const idNumber = yup.string().max(50).required();
  let firstName = yup.string().max(50);
  const middleName = yup.string().max(50);
  let lastName = yup.string().max(50);
  let dob = yup
    .string()
    .required()
    .test(
      'dob',
      'Invalid dob',
      _dateOfBirth => !!_dateOfBirth && isBeforeNow(_dateOfBirth),
    );
  const idExpirationDate = yup
    .string()
    .required()
    .test(
      'idExpirationDate',
      'Invalid idExpirationDate',
      _expirationDate => !!_expirationDate && isAfterNow(_expirationDate),
    );
  let address1 = yup
    .string()
    .matches(/^[A-Za-z0-9- ,.'#/]*$/)
    .max(100);
  const address2 = yup
    .string()
    .matches(/^[A-Za-z0-9- ,.'#/]*$/)
    .max(100);
  let city = yup.string().max(50);
  let state = yup.string().nullable();
  const zipCode = yup
    .string()
    .matches(/^\d{5}$/)
    .required();
  const gender = yup.string().nullable();

  const {isNplex, isRequiredAge, age} = mapIdCheckIndicators(invoice);

  if (isNplex) {
    firstName = firstName.required();
    lastName = lastName.required();
    address1 = address1.required();
    city = city.required();
    state = state.required();
  }

  if (isRequiredAge && age) {
    dob = dob.test(
      'dob',
      '',
      _dateOfBirth => !!_dateOfBirth && diffYears(_dateOfBirth) >= age,
    );
  }

  return yup.object().shape({
    idNumber,
    firstName,
    middleName,
    lastName,
    dob,
    idExpirationDate,
    address1,
    address2,
    city,
    state,
    zipCode,
    gender,
  });
};

export default mapSchema;
