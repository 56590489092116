/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionPeripheralRequest
 */
export interface SessionPeripheralRequest {
    /**
     * 
     * @type {number}
     * @memberof SessionPeripheralRequest
     */
    peripheralId: number;
    /**
     * 
     * @type {string}
     * @memberof SessionPeripheralRequest
     */
    dataVersion?: string | null;
}

export function SessionPeripheralRequestFromJSON(json: any): SessionPeripheralRequest {
    return SessionPeripheralRequestFromJSONTyped(json, false);
}

export function SessionPeripheralRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionPeripheralRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'peripheralId': json['peripheralId'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
    };
}

export function SessionPeripheralRequestToJSON(value?: SessionPeripheralRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'peripheralId': value.peripheralId,
        'dataVersion': value.dataVersion,
    };
}


