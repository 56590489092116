import {PaymentsApi, PaymentType} from '@emporos/api-enterprise/src/gen';
import {Invoice, InvoicePayment} from '@emporos/api-enterprise/src/gen-session';
import {
  AlertMessage,
  Button,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  Icons,
  Modal,
  PaymentCard,
  Stack,
} from '@emporos/components';
import {NavigateFn, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import moment from 'moment';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useOpenApi from '../../../../../api/useOpenApi';
import {AuthClaim} from '../../../../../auth/const';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../../../contexts/AnalyticsProvider';
import {useAuthentication} from '../../../../../contexts/AuthenticationProvider';
import {useGlobalData} from '../../../../../contexts/GlobalDataProvider';
import {
  AlertLogTypes,
  ApiLogTypes,
  useLog,
} from '../../../../../contexts/LoggingProvider';
import {useSettings} from '../../../../../contexts/SettingsProvider';
import {useTransactionsState} from '../../../../../contexts/TransactionsStateProvider';
import {withChildPage, WithPageProps} from '../../../../../hocs/withChildPage';
import useInvoice, {UpdateInvoiceFn} from '../../../../../hooks/useInvoice';
import useSession from '../../../../../hooks/useSession';
import {
  displayPaymentNumber,
  getPaymentIconType,
  getPaymentType,
  getSimplePaymentIcon,
} from '../utils';
import {DeviceStatus} from './CreditCard';
import HandleTransactionResponse from './HandleTransactionResponse';
import {
  HandleTransactionResponseRef,
  TransactionResponse,
  transformCayanVaultToTransactionResponse,
} from './Types';

interface IntegrationProps extends RouteComponentProps {
  canVoid: boolean;
}

interface Props {
  payment?: InvoicePayment;
  creditCardPendingPaymentExpirationMinutes: number;
  paymentTenders: PaymentType[];
  onCancel: () => void;
  onVoid?: () => Promise<void>;
  onGetDetails: () => Promise<TransactionResponse>;
  invoice: Invoice;
  updateInvoice: UpdateInvoiceFn;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  navigate: NavigateFn;
}

enum M {
  None,
  Confirm,
  Error,
}

const PaymentInfoIntegration = withChildPage(
  ({canVoid, navigate}: IntegrationProps) => {
    const {invoice, updateInvoice} = useInvoice();
    const {creditCardPendingPaymentExpirationMinutes} = useSettings();
    const {paymentTendersResult} = useGlobalData();
    const {siteId} = useSession();
    const {user} = useAuthentication();
    const userId = user?.profile[AuthClaim.UserId] || '';
    const {track} = useAnalyticsProvider();
    const {selectedPayment, setSelectedPayment} = useTransactionsState();
    const {run: voidPayment} = useOpenApi(PaymentsApi, 'clientPaymentVoidPost');
    const {run: getDetails} = useOpenApi(
      PaymentsApi,
      'clientPaymentDetailPost',
    );

    const payment = invoice.payments.find(
      ({invoicePaymentId}) => selectedPayment === invoicePaymentId,
    );

    assert(
      paymentTendersResult && paymentTendersResult.data,
      'Missing Payment Tenders',
    );
    assert(navigate, 'Missing navigate');

    useEffect(() => {
      if (!selectedPayment) {
        navigate('../');
      }
    }, [selectedPayment]);

    const onVoid = async () => {
      console.log('payment', payment);
      if (!payment) return;

      const selectedPaymentType = getPaymentType(
        payment.paymentTypeID || 0,
        paymentTendersResult.data || [],
      );
      track(AnalyticType.VoidPaymentType, {
        total: payment.amount,
        revenueType: selectedPaymentType,
      });
      switch (selectedPaymentType) {
        case 'Credit Card':
          if (!payment.epsReference) return;
          const result = await voidPayment({
            paymentVoidRequest: {
              siteId,
              userId,
              referenceNumber: payment.epsReference,
              terminalId: '',
              transactionId: String(invoice.serverTransactionID),
            },
          });
          if (result.data?.status !== 'Approved') {
            return Promise.reject();
          }
          break;
        // Things we are pretty sure are fine
        case 'Cash':
        case 'Check':
          break;
        default:
          console.warn(
            'Should we do special handling for ',
            selectedPaymentType,
          );
      }
      return onVoidComplete();
    };

    const onVoidComplete = () => {
      assert(payment && payment.paymentTypeID);
      assert(paymentTendersResult && paymentTendersResult.data);
      const isCreditCard =
        getPaymentType(payment.paymentTypeID, paymentTendersResult.data) ===
        'Credit Card';
      updateInvoice(prevInvoice => ({
        payments: prevInvoice.payments.map(p => ({
          ...p,
          ...(p.invoicePaymentId === selectedPayment && {
            isSynced: false,
            ...(isCreditCard ? {recordStatus: 'Voided'} : {isDeleted: true}),
          }),
        })),
      }));
      return onCancel();
    };

    const onGetDetails = async () => {
      if (!payment?.cardToken) throw payment;

      const result = await getDetails({
        paymentDetailRequest: {siteId, transportKey: payment.cardToken},
      });
      if (!result.data) {
        throw result;
      }
      return transformCayanVaultToTransactionResponse(result.data);
    };

    const onCancel = () => {
      setSelectedPayment('');
      return navigate('../');
    };

    return (
      <PaymentInfo
        payment={payment}
        creditCardPendingPaymentExpirationMinutes={
          creditCardPendingPaymentExpirationMinutes
        }
        paymentTenders={paymentTendersResult.data}
        onCancel={onCancel}
        onVoid={canVoid ? onVoid : undefined}
        onGetDetails={onGetDetails}
        invoice={invoice}
        updateInvoice={updateInvoice}
        setSelectedPayment={setSelectedPayment}
        navigate={navigate}
      />
    );
  },
);

export function PaymentInfo({
  payment,
  creditCardPendingPaymentExpirationMinutes,
  paymentTenders,
  onCancel,
  onVoid,
  invoice,
  updateInvoice,
  onGetDetails,
  setSelectedPayment,
  navigate,
}: Props): JSX.Element {
  const {logApi, logAlert} = useLog();
  const [modal, setModal] = useState(M.None);
  const [status, setStatus] = useState(DeviceStatus.Unknown);
  const handleRef = useRef<HandleTransactionResponseRef | null>(null);

  const onVoidCancel = useCallback(() => {
    setModal(M.None);
  }, []);

  const onVoidPayment = useCallback(async () => {
    setModal(M.None);
    if (!onVoid) {
      return;
    }
    try {
      logApi(ApiLogTypes.VoidPending);
      await onVoid();
    } catch (err) {
      logAlert(AlertLogTypes.Void, {info: 'Error'}, 'error');
      setModal(M.Error);
    }
  }, [onVoid]);

  const onGetDetailsTransaction = async () => {
    try {
      setStatus(DeviceStatus.CheckStatus);
      const result = await onGetDetails();
      handleRef.current?.handleTransactionResponse(result, payment);
    } catch (e) {
      setStatus(DeviceStatus.Unknown);
    }
  };

  const getIcon = (
    recordStatus: string,
    paymentTypeID: number,
  ): keyof typeof Icons => {
    if (recordStatus === 'OverCharged' || recordStatus === 'Pending') {
      return 'Warning';
    }
    return getSimplePaymentIcon(paymentTypeID, paymentTenders);
  };

  const getMessage = (recordStatus: string): string => {
    if (recordStatus === 'OverCharged') {
      return 'This payment was approved for more than the requested amount. Please return this payment and try again.';
    } else if (recordStatus === 'Pending') {
      return 'This payment could not be verified. Please verify the status of the payment.';
    } else {
      return '';
    }
  };

  return (
    <>
      {payment && (
        <Stack style={{height: '100%'}}>
          <Header title="Payment Info" />

          {['OverCharged', 'Pending'].includes(payment.recordStatus) && (
            <AlertMessage
              icon="Warning"
              message={getMessage(payment.recordStatus)}
              variant="warning"
            />
          )}
          <Stack
            style={{
              flex: 1,
            }}
            justify="center"
            align="center"
            gutter={Gutter.L}
          >
            <PaymentCard
              icon={getIcon(payment.recordStatus, payment.paymentTypeID || 0)}
              color={
                payment.recordStatus !== 'OverCharged'
                  ? getPaymentIconType(
                      payment.paymentTypeID || 0,
                      paymentTenders,
                    )
                  : 'warning'
              }
              title={`${getPaymentType(
                payment.paymentTypeID || 0,
                paymentTenders,
              )}: $${payment.amount.toFixed(2)}`}
              subtitle={displayPaymentNumber(payment, paymentTenders)}
              dateTime={moment(payment.createdOn).format('MM/DD/YYYY - hh:mmA')}
            />
          </Stack>
          <FooterGroup>
            <Button
              flex
              variant={BV.Secondary}
              disabled={['OverCharged', 'Pending'].includes(
                payment.recordStatus,
              )}
              onClick={onCancel}
            >
              Back
            </Button>
            <Button
              flex
              variant={
                payment.recordStatus === 'Pending' ? BV.Primary : BV.Danger
              }
              disabled={
                !onVoid || !['Active', 'Pending'].includes(payment.recordStatus)
              }
              onClick={() =>
                payment.recordStatus === 'Pending'
                  ? onGetDetailsTransaction()
                  : setModal(M.Confirm)
              }
            >
              {payment.recordStatus === 'Pending'
                ? 'Check Status'
                : 'Return Payment'}
            </Button>
          </FooterGroup>
        </Stack>
      )}

      <Modal
        visible={modal === M.Confirm}
        icon="Warning"
        color="error"
        onCancel={onVoidCancel}
        onContinue={onVoidPayment}
        buttonText="Return"
        title="Return Payment"
        subtitle="This cannot be undone. Are you sure you want to return this payment?"
      />
      <Modal
        visible={modal === M.Error}
        icon="Warning"
        color="error"
        onCancel={onVoidCancel}
        onContinue={onVoidPayment}
        buttonText="Retry"
        title="Return Payment Failed"
        subtitle="Return payment has failed. Reversal was not able to be processed."
      />
      <HandleTransactionResponse
        onCancel={onCancel}
        onContinue={onCancel}
        invoice={invoice}
        creditCardPendingPaymentExpirationMinutes={
          creditCardPendingPaymentExpirationMinutes
        }
        updateInvoice={updateInvoice}
        paymentTenders={paymentTenders}
        status={status}
        setStatus={setStatus}
        ref={handleRef}
        setSelectedPayment={setSelectedPayment}
        navigate={navigate}
      />
    </>
  );
}

export default function PaymentInfoPage(
  props: PropsWithChildren<WithPageProps<IntegrationProps>>,
): JSX.Element {
  return <PaymentInfoIntegration {...props} />;
}
