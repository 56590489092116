/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PseIdentification,
    PseIdentificationFromJSON,
    PseIdentificationFromJSONTyped,
    PseIdentificationToJSON,
    PseItem,
    PseItemFromJSON,
    PseItemFromJSONTyped,
    PseItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface PseCheckRequest
 */
export interface PseCheckRequest {
    /**
     * 
     * @type {PseIdentification}
     * @memberof PseCheckRequest
     */
    identification: PseIdentification;
    /**
     * 
     * @type {Array<PseItem>}
     * @memberof PseCheckRequest
     */
    items: Array<PseItem>;
}

export function PseCheckRequestFromJSON(json: any): PseCheckRequest {
    return PseCheckRequestFromJSONTyped(json, false);
}

export function PseCheckRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PseCheckRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identification': PseIdentificationFromJSON(json['identification']),
        'items': ((json['items'] as Array<any>).map(PseItemFromJSON)),
    };
}

export function PseCheckRequestToJSON(value?: PseCheckRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identification': PseIdentificationToJSON(value.identification),
        'items': ((value.items as Array<any>).map(PseItemToJSON)),
    };
}


