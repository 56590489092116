import {
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
} from '@emporos/api-enterprise/src/gen';
import {ScanResult} from '@emporos/barcodes';
import {
  Button,
  Variant as BV,
  Gutter,
  Header,
  IndicatorCircle,
  Row,
  RowItem,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import TextInput from '@emporos/components/src/TextInput';
import {debounce} from 'lodash';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useLog, UserLogTypes} from '../../../../../contexts/LoggingProvider';
import {formatNamePart} from '../../../../../utils/string';

export interface EmployeeSearchProps {
  onSearch: (query: string) => Promise<EligibilitySearchMatchListApiResponse>;
  onAddCustomer: (customer: EligibilitySearchMatch) => Promise<void>;
  onCancel: () => void;
  online: boolean;
}

export default function EmployeeSearch({
  onSearch,
  onAddCustomer,
  onCancel,
  online,
}: EmployeeSearchProps): JSX.Element {
  const {logUserSelection} = useLog();
  const [eligibilities, setEligibilities] = useState<EligibilitySearchMatch[]>(
    [],
  );
  const [selectedEligibility, setSelectedEligibility] = useState<
    EligibilitySearchMatch
  >();
  const [query, setQuery] = useState('');
  const [searchInputLoading, setSearchInputLoading] = useState(false);
  const [addButtonLoading, setAddButtonLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    if (online) {
      return;
    }
    setHasSearched(false);
  }, [online]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setQuery(query);
    onSearchDebounced(query);
  };

  const onScan = (scanResult: ScanResult) => {
    const query = scanResult.raw;
    logUserSelection(UserLogTypes.ScannedBadge);
    setQuery(query);
    onSearchDebounced(query);
  };

  const onSearchDebounced = useCallback(
    debounce(
      async query => {
        setSearchInputLoading(true);

        if (query.length < 3) {
          setEligibilities([]);
          setHasSearched(false);
        } else {
          try {
            const response = await onSearch(query);
            setEligibilities(response.data || []);
          } catch (e) {
            setEligibilities([]);
          }
          setHasSearched(true);
        }

        setSearchInputLoading(false);
      },
      500,
      {leading: true},
    ),
    [setEligibilities],
  );

  return (
    <Stack>
      <Header style={{paddingTop: 10}} title="Select Employee" />
      <TextInput
        loading={searchInputLoading}
        label="Name, Employee Number or Badge ID"
        icon="IdCard"
        value={query}
        onChange={onChange}
        onClear={() => {
          setQuery('');
          setEligibilities([]);
          setHasSearched(false);
          setSelectedEligibility(undefined);
        }}
        data-testid="search-customer"
        disabled={!online}
      />

      <ScrollContainer>
        {(!online || !eligibilities.length) && (
          <Stack
            align="center"
            justify="center"
            gutter={Gutter.XL}
            style={{height: '100%'}}
          >
            {online ? (
              <IndicatorCircle
                icon={hasSearched ? 'X' : 'Search'}
                variant={hasSearched ? 'error' : 'gray'}
                size="large"
              />
            ) : (
              <IndicatorCircle
                icon="NetworkNone"
                variant="warning"
                size="large"
              />
            )}
            <Text variant={TV.Main}>
              {!online
                ? 'Cannot search without a connection'
                : hasSearched
                ? 'No customer found'
                : 'Search for customer above'}
            </Text>
          </Stack>
        )}
        {online && !!eligibilities.length && (
          <Stack gutter={Gutter.S}>
            {eligibilities.map(eligibility => {
              return (
                <RowItem
                  key={eligibility.employeeNumber}
                  title={`${formatNamePart(
                    eligibility.firstName,
                  )} ${formatNamePart(eligibility.lastName)}`}
                  subtitle={`Employee: ${eligibility.employeeNumber} • Badge: ${eligibility.badgeNumber}`}
                  selected={
                    eligibility.employeeNumber ===
                    selectedEligibility?.employeeNumber
                  }
                  onClick={() =>
                    !addButtonLoading && setSelectedEligibility(eligibility)
                  }
                  rightText={eligibility.eligible ? '' : 'Ineligible'}
                  inactive={!eligibility.eligible}
                />
              );
            })}
          </Stack>
        )}
      </ScrollContainer>
      <Row gutter={Gutter.XL}>
        <Button variant={BV.Secondary} onClick={() => onCancel()} flex>
          Cancel
        </Button>
        <Button
          loading={addButtonLoading}
          disabled={!selectedEligibility || !online}
          flex
          data-testid="add-selectedEligibility"
          onClick={async () => {
            if (!selectedEligibility) {
              return;
            }
            setAddButtonLoading(true);
            try {
              await onAddCustomer(selectedEligibility);
            } finally {
              setAddButtonLoading(false);
              setQuery('');
              setEligibilities([]);
              setHasSearched(false);
              setSelectedEligibility(undefined);
            }
          }}
        >
          Add Customer
        </Button>
      </Row>
      <PageBarcodeScanner onScan={onScan} />
    </Stack>
  );
}
