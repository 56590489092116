import {
  CustomersApi,
  CustomerSearchMatch,
} from '@emporos/api-enterprise/src/gen';
import {Redirect, RouteComponentProps} from '@reach/router';
import {memo, useCallback, useEffect} from 'react';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../contexts/AnalyticsProvider';
import {useCustomerSearch} from '../../../contexts/CustomerSearchProvider';
import {ApiLogTypes, useLog} from '../../../contexts/LoggingProvider';
import {useNetworkAvailable} from '../../../contexts/NetworkAvailableProvider';
import {useTransactionsState} from '../../../contexts/TransactionsStateProvider';
import {withChildPage} from '../../../hocs/withChildPage';
import {useCustomerWillCall} from '../../../hooks/useCustomerWillCall';
import {mapInvoiceCustomer} from '../../../utils/mappers';
import {activeInvoiceCustomersIds} from '../../../utils/customer';
import CustomerSearch from './CustomerSearch';
import useSession from '../../../hooks/useSession';
import useOpenApi from '../../../api/useOpenApi';
import moment from 'moment';

interface Props {
  addToAr?: boolean;
}

function AddCustomer(props: Props & RouteComponentProps) {
  const {track} = useAnalyticsProvider();
  const {setCustomer} = useCustomerSearch();
  const {logApi} = useLog();
  const online = useNetworkAvailable();
  const {session, currentInvoiceId} = useTransactionsState();
  const {pendingInvoices} = useSession();
  const {addToAr, navigate, location} = props;
  const {siteId, stationId} = session;

  if (!currentInvoiceId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {run: getCustomers} = useOpenApi(CustomersApi, 'clientCustomersGet');
  const {run: getCustomerById} = useOpenApi(
    CustomersApi,
    'clientCustomersCustomerIdGet',
  );
  const {run: putWillCall, customer} = useCustomerWillCall();
  const onSearch = useCallback(
    async (name: string, dob: string) => {
      logApi(ApiLogTypes.CustomerPending);
      return getCustomers({
        name,
        dob: moment(dob, 'MM/DD/YYYY').toDate(),
        siteId: siteId,
        stationId: stationId,
      });
    },
    [logApi, getCustomers, siteId, stationId],
  );
  const onCancel = useCallback(() => navigate && navigate('../'), [navigate]);
  const onWillCallFailed = useCallback(
    async ({id}: CustomerSearchMatch) => {
      const customerInfo = await getCustomerById({
        customerId: id as number,
      });
      if (customerInfo?.data) {
        setCustomer(mapInvoiceCustomer(customerInfo.data));
        navigate &&
          navigate('../', {
            replace: true,
          });
      }
    },
    [getCustomerById],
  );
  const tryLoadCustomer = useCallback(
    async (match: CustomerSearchMatch) => {
      if (!match.customerCode) {
        throw new Error(
          'Failed to load customer: customer code is required to perform will call',
        );
      }
      return putWillCall({
        customerCode: match.customerCode,
      }).catch(() => onWillCallFailed(match));
    },
    [putWillCall],
  );

  useEffect(() => {
    if (customer) {
      setCustomer(customer);
      navigate &&
        navigate('../', {
          replace: true,
        });
      track(AnalyticType.CustomerSearch, {page: location?.pathname || ''});
    }
  }, [customer]);

  return (
    <CustomerSearch
      addToAr={addToAr}
      onSearch={onSearch}
      onAddCustomer={tryLoadCustomer}
      onCancel={onCancel}
      online={online}
      activeInvoiceCustomerIds={activeInvoiceCustomersIds(pendingInvoices)}
    />
  );
}

export default memo(withChildPage(AddCustomer));
