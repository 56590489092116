import {
  Gutter,
  FixedHeader,
  Header,
  RadioButtons,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import Input from '@emporos/components/src/TextInput';
import {RouteComponentProps} from '@reach/router';
import {memo} from 'react';
import {
  BetaFeatures,
  useBetaFeatures,
} from '../../../../contexts/BetaFeatureProvider';
import {useConfig} from '../../../../contexts/ConfigProvider';
import {useSettings} from '../../../../contexts/SettingsProvider';

function SettingsDeveloper(_: RouteComponentProps): JSX.Element {
  const {toggleFlag, ...flags} = useBetaFeatures();
  const {NodeEnv} = useConfig();
  const {
    creditCardDeviceRequestTimeoutSeconds,
    setCreditCardDeviceRequestTimeoutSeconds,
    creditCardPendingPaymentExpirationMinutes,
    setCreditCardPendingPaymentExpirationMinutes,
  } = useSettings();

  return (
    <Stack gutter={Gutter.None} style={{height: '100%'}} as="form">
      <FixedHeader>
        <Header title="Developer" />
      </FixedHeader>
      <ScrollContainer>
        {(process.env.REACT_APP_ENABLE_BETA_FEATURES === 'enabled' ||
          NodeEnv !== 'production') && (
          <>
            <Stack style={{flex: 1, paddingBottom: 16}} />
            <Stack style={{marginBottom: 16}}>
              <RadioButtons
                name="Beta Features"
                type="checkbox"
                onChange={event => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const value = event.currentTarget.value as keyof BetaFeatures;
                  toggleFlag(value);
                }}
                options={Object.keys(flags).map(flag => ({
                  label: flag,
                  value: flag,
                  checked: !!flags[flag as keyof typeof flags],
                }))}
              />
            </Stack>
          </>
        )}
        <Stack>
          <Input
            type="number"
            label="Credit Card Device Request Timeout (Seconds)"
            value={creditCardDeviceRequestTimeoutSeconds}
            onChange={event =>
              setCreditCardDeviceRequestTimeoutSeconds(
                Number(event.target.value || 0),
              )
            }
          />
          <Input
            type="number"
            label="Credit Card Pending Payment Expiration (Minutes)"
            value={creditCardPendingPaymentExpirationMinutes}
            onChange={event =>
              setCreditCardPendingPaymentExpirationMinutes(
                Number(event.target.value || 0),
              )
            }
          />
        </Stack>
      </ScrollContainer>
    </Stack>
  );
}

export default memo(SettingsDeveloper);
