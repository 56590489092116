import assert from 'assert';
import {useCallback, useEffect, useState} from 'react';
import moment from 'moment';

const tryLoad = <T>(key: string): T | null => {
  try {
    const item = window.localStorage.getItem(key);
    return item
      ? JSON.parse(item, (key, value) => {
          if (
            [
              'saleDate',
              'drawerDate',
              'dob',
              'safetyCapsDate',
              'balanceDate',
              'expiration',
              'salesAuditDate',
              'idExpirationDate',
              'adjInsDate',
              'adjudicationDate',
              'expirationDate',
              'createdOn',
            ].includes(key) &&
            moment(value).isValid()
          ) {
            return moment(value).toDate();
          }
          return value;
        })
      : null;
  } catch (err) {
    return null;
  }
};

export const useLocalStorage = <T>(
  key: string | null,
): [T | null, (t: T | null) => void] => {
  const [storedValue, setStoredValue] = useState(() =>
    key === null ? null : tryLoad<T>(key),
  );
  const setValue = useCallback(
    (value: T | null) => {
      assert(
        key !== null,
        'Failed to update value in LocalStorage: key must not be null',
      );

      try {
        if (value === null) {
          setStoredValue(null);
          window.localStorage.removeItem(key);
        } else {
          const valueToStore =
            value instanceof Function ? value(storedValue) : value;
          setStoredValue(valueToStore);
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (err) {
        console.error(err);
      }
    },
    [key],
  );

  useEffect(() => {
    if (key !== null) {
      setStoredValue(tryLoad<T>(key));
    }
  }, [key]);

  return [storedValue, setValue];
};

export default useLocalStorage;
