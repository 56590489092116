import {
  BarcodeComponent,
  Prescription,
  WillCallRequest,
} from '@emporos/api-enterprise/src/gen';
import {Invoice, InvoiceItem} from '@emporos/api-enterprise/src/gen-session';
import {CompleteBarcodeComponent, decode, ScanResult} from '@emporos/barcodes';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  IndicatorCircle,
  Numberpad,
  PendingChangesModal,
  Row,
  RowItem,
  ScrollContainer,
  SegmentSlider,
  Stack,
  Variant,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import TextInput from '@emporos/components/src/TextInput';
import VerificationModal from '@emporos/components/src/VerificationModal';
import assert from 'assert';
import {memo, useCallback, useEffect, useState} from 'react';
import {
  OfflineCustomer,
  OfflineInvoice,
  OfflineSynced,
} from '../../../api/common';
import {useAlertState} from '../../../contexts/AlertStateProvider';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../contexts/AnalyticsProvider';
import {useBetaFeatures} from '../../../contexts/BetaFeatureProvider';
import {
  AlertLogTypes,
  InvoiceLogTypes,
  useLog,
  UserLogTypes,
} from '../../../contexts/LoggingProvider';
// TODO: this component should not know how we communicate with the API. This
// should be moved up towards the http integration layer
import {ars, normalizeCustomer, pds} from '../../../utils/customer';
import {createInvoicePrescriptionReducer} from '../../../utils/invoice';
import {formatMedication} from '../../../utils/string';
import {ManualRXFormData} from '../../sales/transactions';
import WillCallPanel from './WillCallPanel';

interface Props {
  barcodeComponents?: BarcodeComponent[];
  forceCanSave?: boolean;
  initialPrescription?: ManualRXFormData | null;
  invoice: OfflineInvoice;
  invoiceChanged?: boolean;
  loadingCustomer?: boolean;
  online?: boolean;
  pmsName?: string;
  taxGroupId?: number;
  viewOnly?: boolean;
  onAddCustomerClick?: () => void;
  onCancel: () => void;
  onSave: (updates: Partial<Invoice>) => void;
  onUnresolvedRxScan?: (willCallParams: WillCallRequest) => void;
}

const findRx = (prescriptions: Prescription[], item: ManualRXFormData) =>
  prescriptions.find(
    ({fill, partial, rx, site}) =>
      fill === item.fill &&
      partial === item.partial &&
      rx === item.rx &&
      site === item.site,
  );

const willCallError = new Error(
  'Customer prescription info could not be pulled down. Please try again.',
);
const mismatchedError = new Error(
  'This prescription doesn’t match the customer attached to the sale.',
);
const notFoundError = new Error(
  'This prescription cannot be found on the customer’s Will Call.',
);
const doubleScanError = (medication: string) => {
  const doubleScan = new Error(
    `${formatMedication(medication)} has already been scanned on this sale.`,
  );
  doubleScan.name = 'Prescription Already Scanned';
  return doubleScan;
};

const Footer = (onCancel: () => void, canSave: boolean, loading: boolean) => {
  return (
    <div style={{marginTop: 'auto'}}>
      <FooterGroup>
        <Button
          type="button"
          onClick={onCancel}
          variant={canSave ? Variant.Danger : Variant.Secondary}
          flex
        >
          Cancel
        </Button>
        <Button
          variant={Variant.Primary}
          type="submit"
          flex
          loading={loading}
          disabled={!canSave}
        >
          Save
        </Button>
      </FooterGroup>
    </div>
  );
};

function CustomerInfoPanel({
  barcodeComponents,
  forceCanSave = false,
  initialPrescription,
  invoice,
  invoiceChanged,
  loadingCustomer = false,
  online,
  pmsName,
  viewOnly = false,
  onCancel,
  onSave,
  onAddCustomerClick,
  onUnresolvedRxScan,
}: Props): JSX.Element | null {
  const {notification, reset} = useAlertState();
  const {track} = useAnalyticsProvider();
  // To ensure feature flag prevents verification functionality
  const {verificationModal} = useBetaFeatures();
  const {logAlert, logUserSelection} = useLog();
  const [active, setActive] = useState(0);
  const [invoiceNotes, setInvoiceNotes] = useState(invoice.notes || '');
  const [roomNumber, setRoomNumber] = useState(invoice.roomNumber || '');
  const [selectedRX, setSelectedPrescriptions] = useState<Array<string>>([]);
  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const {customer} = invoice;
  const invoicePrescriptions = invoice.items.reduce((acc, {itemNumber, rx}) => {
    if (rx) {
      itemNumber && acc.push(itemNumber);
    }
    return acc;
  }, [] as string[]);
  const canSave =
    forceCanSave ||
    (customer &&
      !(customer as OfflineCustomer)?.prescriptions &&
      invoiceChanged) ||
    (!viewOnly &&
      selectedRX.length &&
      selectedRX.every(rx => !invoicePrescriptions.includes(rx))) ||
    (invoice.roomNumber || '') !== roomNumber ||
    (invoice.notes || '') !== invoiceNotes;

  const arAccounts = customer ? ars(customer) : [];
  const pdAccounts = customer ? pds(customer) : [];

  useEffect(() => {
    if (initialPrescription && customer) {
      handleRxScan(initialPrescription);
    }
  }, [initialPrescription, customer]);

  const getPrescriptionItemNumber = (
    manualRxFormData: ManualRXFormData,
  ): string => {
    const prescriptions = (customer as OfflineCustomer | undefined)
      ?.prescriptions;

    if (!prescriptions) {
      willCallError.name = 'Will Call Error';
      throw willCallError;
    }

    const prescription = findRx(prescriptions, manualRxFormData);
    console.log(prescriptions, manualRxFormData);

    if (!prescription) {
      if (invoiceChanged) {
        notFoundError.name = 'Prescription Not Found';
        throw notFoundError;
      } else {
        mismatchedError.name = "Customer Doesn't Match";
        throw mismatchedError;
      }
    }

    const {itemNumber, description} = prescription;
    assert(itemNumber, 'Missing itemNumber');
    logUserSelection(UserLogTypes.ScannedRX, {
      result: prescription.description,
    });

    if (
      invoicePrescriptions.includes(itemNumber) ||
      (selectedRX && selectedRX.includes(itemNumber))
    ) {
      throw doubleScanError(description || '');
    }

    return itemNumber;
  };

  const addPrescriptionItemNumber = (itemNumber: string) => {
    setActive(0);
    setSelectedPrescriptions(curr => [...curr, itemNumber]);
  };

  const handleManualRxFormData = useCallback(
    (manualRxFormData: ManualRXFormData) =>
      addPrescriptionItemNumber(getPrescriptionItemNumber(manualRxFormData)),
    [invoicePrescriptions, selectedRX],
  );

  const handleRxScan = useCallback(
    (item: ManualRXFormData) => {
      if (Number(active) !== 0) {
        setActive(0);
      }

      if (customer) {
        try {
          handleManualRxFormData(item);
        } catch (error) {
          switch ((error as Error).name) {
            case 'Will Call Error':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'warning',
                icon: 'Warning',
              });
              // Not placing tracking here since API related errors are tracked in alerts provider
              break;
            case 'Prescription Not Found':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'error',
                icon: 'X',
              });
              track(AnalyticType.UserError, {
                message:
                  'User scanned prescription that no longer applies to the customer attached to the sale.',
              });
              break;
            case "Customer Doesn't Match":
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'error',
                icon: 'X',
              });
              track(AnalyticType.UserError, {
                message:
                  'User scanned prescription that does not match the customer attached to the sale.',
              });
              break;
            case 'Prescription Already Scanned':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'warning',
                icon: 'Warning',
              });
              track(AnalyticType.UserError, {
                message: (error as Error).message,
              });
              break;
            default:
              notification({
                title: 'Error Occurred',
                description:
                  'Uh-oh! Something isn’t right with the Emporos API.',
                type: 'warning',
                icon: 'Warning',
              });
              // Not placing tracking here since API related errors are tracked in alerts provider
              break;
          }
        }
      } else if (!loadingCustomer) {
        if (Number(active) !== 0) {
          // Attempt to fetch a customer if the will call screen is available and
          // not already fetching
          onUnresolvedRxScan &&
            onUnresolvedRxScan({
              //siteID: item.site,
              //stationID: '',
              rx: item.rx,
              fill: item.fill,
              partial: item.partial,
            });
        } else {
          notification({
            title: 'Failed to Scan',
            description:
              'Cannot scan prescriptions without an internet connection.',
            type: 'error',
            icon: 'X',
          });
        }
      } else {
        notification({
          title: 'Will Call Loading',
          description:
            "We're on it! Please wait while we fetch customer information.",
          type: 'warning',
          icon: 'Warning',
        });
      }
    },
    [handleManualRxFormData, customer, loadingCustomer],
  );

  const addItems = useCallback(() => {
    let items: InvoiceItem[] = [...invoice.items];
    const rx = (customer as OfflineCustomer)?.prescriptions
      ?.filter(({itemNumber}) => itemNumber && selectedRX.includes(itemNumber))
      .map(p => ({...p, medicationStatus: 1}));
    if (rx?.length) {
      items = rx.reduce(createInvoicePrescriptionReducer(invoice), items);
      logUserSelection(InvoiceLogTypes.AddItem, {item: items});
      setShowVerificationModal(false);
    }
    onSave({
      isVerified:
        (invoice as OfflineInvoice).isVerified || (rx && rx.length > 0),
      items,
      notes: invoiceNotes,
      roomNumber,
      isSynced: false,
    } as Partial<Invoice> & OfflineSynced);
    logUserSelection(InvoiceLogTypes.CustomerUpdated);
  }, [
    customer,
    invoice,
    invoiceNotes,
    roomNumber,
    selectedRX,
    logUserSelection,
  ]);

  const _onCancel = useCallback(() => {
    if (canSave) {
      setShowPendingChangesModal(true);
      logAlert(AlertLogTypes.PendingChanges, {
        location: 'CustomerInfoPanel',
      });
    } else {
      onCancel();
    }
  }, [onCancel, canSave]);

  const onScan = useCallback(
    (scanResult: ScanResult) => {
      if (barcodeComponents && pmsName) {
        const item = decode(
          barcodeComponents as CompleteBarcodeComponent[],
          scanResult.raw,
          pmsName,
        );
        reset();
        if (item.rx && item.partial && item.fill && item.site) {
          handleRxScan(item as ManualRXFormData);
        }
      }
    },
    [barcodeComponents, pmsName, handleRxScan],
  );

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setShowPendingChangesModal(false);
    // Show the verification modal first if selecting prescriptions and the
    // invoice is not verified
    if (selectedRX.length && !invoice?.isVerified && verificationModal) {
      logAlert(AlertLogTypes.Verification);
      setShowVerificationModal(true);
    } else {
      addItems();
    }
  }

  return (
    <>
      <Stack
        style={{height: '100%'}}
        data-testid="CustomerInfoPanel"
        as="form"
        onSubmit={onSubmit}
      >
        <Header title="Customer Info">
          <SegmentSlider
            onSelectIndex={setActive}
            active={active}
            items={['Will Call', 'Payments', 'Notes']}
          />
        </Header>

        {active === 0 ? (
          <>
            <Row
              gutter={Gutter.XXL}
              style={{overflow: 'hidden', height: '100%'}}
              noWrap
              data-testid="CustomerInfoTab"
            >
              <Stack style={{width: '100%', flex: 1}}>
                <WillCallPanel
                  invoice={invoice}
                  customerInfo={customer}
                  loadingCustomer={loadingCustomer}
                  prescriptions={(customer as OfflineCustomer)?.prescriptions}
                  selectedRX={selectedRX}
                  viewOnly={viewOnly}
                  handleAddCustomerClick={onAddCustomerClick}
                  setSelectedPrescriptions={setSelectedPrescriptions}
                  online={!!online}
                />
              </Stack>
            </Row>

            {Footer(_onCancel, canSave, loadingCustomer)}
          </>
        ) : active === 1 ? (
          <>
            <ScrollContainer>
              <Stack
                style={{flex: 1, height: '100%'}}
                data-testid="CustomerPaymentsTab"
              >
                {customer && (
                  <Stack gutter={Gutter.S}>
                    <Text
                      variant={TV.Caption}
                      style={{paddingLeft: 16, marginBottom: -6}}
                    >
                      Credit Cards on File
                    </Text>
                    {!customer?.creditCards?.length ? (
                      <RowItem
                        title="None"
                        variant="inverted"
                        data-testid="no-cc"
                      />
                    ) : (
                      customer.creditCards.map((cc, i) => (
                        <RowItem
                          key={i}
                          title="Credit Card"
                          subtitle={cc.cardType + ' - ' + cc.last4OfCard}
                          leftIcon="CreditCard"
                          noClick
                        />
                      ))
                    )}

                    <Text
                      variant={TV.Caption}
                      style={{paddingLeft: 16, marginBottom: -6}}
                    >
                      AR Accounts
                    </Text>
                    {!arAccounts.length ? (
                      <RowItem
                        title="None"
                        variant="inverted"
                        data-testid="no-ar"
                      />
                    ) : (
                      arAccounts.map(ar => (
                        <RowItem
                          key={ar.accountNumber}
                          title={ar.accountNumber}
                          subtitle={`Limit: $${ar.creditLimit.toFixed(2)}`}
                          rightText={`Due: $${ar.balance.toFixed(2)}`}
                          leftIcon="Bill"
                          leftIconColor="indigo"
                          noClick
                        />
                      ))
                    )}

                    <Text
                      variant={TV.Caption}
                      style={{paddingLeft: 16, marginBottom: -6}}
                    >
                      PD Accounts
                    </Text>
                    {!pdAccounts.length ? (
                      <RowItem
                        title="None"
                        variant="inverted"
                        data-testid="no-pd"
                      />
                    ) : (
                      pdAccounts.map(pd => (
                        <RowItem
                          key={pd.accountNumber}
                          title={pd.accountNumber}
                          subtitle={`Limit: $${pd.creditLimit.toFixed(2)}`}
                          rightText={`Due: $${pd.balance.toFixed(2)}`}
                          leftIcon="IdCard"
                          leftIconColor="indigo"
                          noClick
                        />
                      ))
                    )}
                  </Stack>
                )}
                {!customer && (
                  <Stack
                    align="center"
                    justify="center"
                    style={{height: '100%'}}
                  >
                    <IndicatorCircle icon="User" variant="gray" size="large" />
                    <Text variant={TV.Main} align="center">
                      Add customer to see payments
                    </Text>
                  </Stack>
                )}
              </Stack>
            </ScrollContainer>
            {Footer(_onCancel, canSave, loadingCustomer)}
          </>
        ) : (
          <>
            <Row
              data-testid="CustomerNotesTab"
              gutter={Gutter.XXL}
              style={{overflow: 'hidden', height: '100%'}}
              noWrap
            >
              <Stack style={{marginTop: '5px', width: '100%', flex: 1}}>
                {customer && (
                  <TextInput
                    {...{as: 'textarea', rows: '5'}}
                    placeholder="Customer Notes"
                    name="customerNotes"
                    value={customer?.notes.join('\n')}
                    disabled={true}
                  />
                )}
                <TextInput
                  {...{as: 'textarea', rows: '5'}}
                  placeholder="Transaction Notes"
                  name="transactionNotes"
                  value={invoiceNotes}
                  onChange={event => setInvoiceNotes(event.target.value)}
                />
                {Footer(_onCancel, canSave, loadingCustomer)}
              </Stack>
              <Numberpad
                title="Room Number"
                number={roomNumber}
                setNumber={setRoomNumber}
                onClear={() => setRoomNumber('')}
              />
            </Row>
          </>
        )}
      </Stack>
      {Number(active) === 0 && !viewOnly && (
        <PageBarcodeScanner onScan={onScan} />
      )}
      {verificationModal && customer && (
        <VerificationModal
          customerInfo={normalizeCustomer(customer)}
          visible={showVerificationModal}
          onCancel={() => setShowVerificationModal(false)}
          onContinue={addItems}
        />
      )}
      <PendingChangesModal
        visible={showPendingChangesModal}
        onCancel={() => setShowPendingChangesModal(false)}
        onContinue={onCancel}
      />
    </>
  );
}

export default memo(CustomerInfoPanel);
