/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CashDrawer
 */
export interface CashDrawer {
    /**
     * 
     * @type {number}
     * @memberof CashDrawer
     */
    cashDrawerId: number;
    /**
     * 
     * @type {string}
     * @memberof CashDrawer
     */
    cashDrawerNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashDrawer
     */
    cashDrawerName?: string | null;
}

export function CashDrawerFromJSON(json: any): CashDrawer {
    return CashDrawerFromJSONTyped(json, false);
}

export function CashDrawerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CashDrawer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cashDrawerId': json['cashDrawerId'],
        'cashDrawerNumber': !exists(json, 'cashDrawerNumber') ? undefined : json['cashDrawerNumber'],
        'cashDrawerName': !exists(json, 'cashDrawerName') ? undefined : json['cashDrawerName'],
    };
}

export function CashDrawerToJSON(value?: CashDrawer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cashDrawerId': value.cashDrawerId,
        'cashDrawerNumber': value.cashDrawerNumber,
        'cashDrawerName': value.cashDrawerName,
    };
}


