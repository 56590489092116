import {BarcodeCollection} from '@emporos/api-enterprise/src/gen';
import {Invoice} from '@emporos/api-enterprise/src/gen-session';
import {CompleteBarcodeComponent, decode, ScanResult} from '@emporos/barcodes';
import {Redirect, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useMemo} from 'react';
import {useAlertState} from '../../../contexts/AlertStateProvider';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../contexts/AnalyticsProvider';
import {useGlobalData} from '../../../contexts/GlobalDataProvider';
import {
  NavigationLogTypes,
  useLog,
  UserLogTypes,
} from '../../../contexts/LoggingProvider';
import {useTransactionsState} from '../../../contexts/TransactionsStateProvider';
import {withRootPage} from '../../../hocs/withChildPage';
import {useMultiSelect} from '../../../hooks/useMultiSelect';
import {createInvoiceOtcReducer} from '../../../utils/invoice';
import {ItemSearchState} from './otc-search';
import Transaction from './Transaction';
import useSession from '../../../hooks/useSession';
import useInvoice from '../../../hooks/useInvoice';
import useTotals from '../../../hooks/useTotals';
import {useConfig} from '../../../contexts/ConfigProvider';
import {barcode} from './utils';

export interface ManualRXFormData {
  rx: string;
  fill: string;
  site: string;
  partial?: string;
  price?: string;
  rxId?: string;
  mrn?: string;
  plan1?: string;
  plan2?: string;
  plan3?: string;
  plan4?: string;
  plan5?: string;
}

const EMPTY_BARCODES: BarcodeCollection = {
  barcodeComponents: [],
  pmsBarcodeMappings: [],
};

function TransactionIntegration({
  navigate,
}: RouteComponentProps): JSX.Element | null {
  const {NodeEnv} = useConfig();

  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<Transaction /> must have a `navigate` prop.' + NodeEnv === 'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
  }
  const {logUserSelection} = useLog();
  const {currentInvoiceId, setCurrentInvoiceId} = useTransactionsState();
  const {updateSession} = useSession();
  const {
    barcodesResult,
    barcodeName,
    pmsName,
    otcItemsResult,
    settingsResult,
  } = useGlobalData();

  if (!currentInvoiceId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();

  const multiselect = useMultiSelect<string>();

  const {invoice, updateInvoice, updateInvoiceItem, canDelete} = useInvoice();
  const {totals} = useTotals();

  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          )
        : [],
    [barcodesResult],
  );

  function onScan(scanResult: ScanResult) {
    if (!(pmsName && barcodesResult && navigate)) {
      return;
    }

    const _barcode = barcode(scanResult);
    const item = decode(
      barcodeComponents as CompleteBarcodeComponent[],
      _barcode,
      pmsName,
    );
    const isRx = Boolean(item.rx && item.partial && item.fill && item.site);

    if (isRx) {
      logUserSelection(NavigationLogTypes.UserNavigating, {
        url: 'sales/transactions/customer',
        info: 'Scanned RX item.',
      });
      navigate(`customer`, {
        state: item as ItemSearchState,
      });
    } else if (otcItemsResult && otcItemsResult.data) {
      const otcItem = otcItemsResult.data.find(
        otcItem => otcItem.itemNumber === _barcode,
      );

      if (otcItem && invoice) {
        logUserSelection(UserLogTypes.ScannedOTC, {
          result: otcItem.description,
        });
        if (otcItem.itemType.id === 16 && invoice.pseCheckResult === 1) {
          notification({
            title: 'PSE Items Locked',
            description:
              'The user has already passed the NPLEx check and these items cannot be edited.',
            type: 'warning',
            icon: 'Warning',
          });
        } else {
          const items = [otcItem].reduce(
            createInvoiceOtcReducer(invoice),
            invoice.items || [],
          );
          updateInvoice({
            items,
          });
        }
      } else {
        notification({
          title: 'Item Not Found',
          description: 'Item couldn’t be found in the database.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.ScanningError, {
          message: 'Scanned OTC item couldn’t be found in the database.',
        });
      }
    }
  }

  return (
    <Transaction
      invoice={invoice}
      canDeleteInvoice={canDelete}
      totals={totals}
      settings={(settingsResult && settingsResult.data) || []}
      otcItems={(otcItemsResult && otcItemsResult.data) || []}
      barcodes={(barcodesResult && barcodesResult.data) || EMPTY_BARCODES}
      multiselect={multiselect}
      navigate={navigate}
      onRemoveInvoice={({invoiceId}: Invoice) => {
        updateSession(prevSession => ({
          invoices: prevSession.invoices.map(invoice =>
            invoice.invoiceId === invoiceId
              ? {...invoice, isDeleted: true, isSynced: false}
              : invoice,
          ),
        }));
      }}
      onUpdateInvoice={updateInvoice}
      onUpdateInvoiceItem={updateInvoiceItem}
      setCurrentInvoiceId={setCurrentInvoiceId}
      onScan={onScan}
    />
  );
}

export default memo(withRootPage(TransactionIntegration));
