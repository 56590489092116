import {StrictMode} from 'react';
import {render} from 'react-dom';
import App from './App';
import config from './config';
import {VERSION} from './Version';

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// we don’t run serviceWorkers in development or test envs so ignore this from
// coverage metrics
/* istanbul ignore next */
if (config.NodeEnv === 'production') {
  if ('serviceWorker' in navigator) {
    import('workbox-window').then(async ({Workbox, messageSW}) => {
      console.log('Creating new Workbox(/service-worker.js)');
      const wb = new Workbox('/service-worker.js');
      wb.addEventListener('activated', event => {
        // `event.isUpdate` will be true if another version of the service
        // worker was controlling the page when this version was registered.
        if (event.isUpdate) {
          console.log('Service worker updated in another window.');
        } else {
          console.log('Service worker activated for the first time!');
        }
      });

      wb.addEventListener('message', event => {
        if (event.data.type === 'CACHE_UPDATED') {
          const {updatedURL} = event.data.payload;

          console.log(`A newer version of ${updatedURL} is available!`);
        }
      });

      wb.register().then(reg => {
        if (!reg) {
          console.error('Service worker failed to register');
          return;
        }
        wb.addEventListener('waiting', showSkipWaitingPrompt);
        function showSkipWaitingPrompt() {
          console.log(
            `A new service worker has installed, but it can’t activate ` +
              `until all tabs running the current version have fully unloaded.`,
          );
          if (window.confirm('There is an update available. Update now?')) {
            wb.addEventListener('controlling', () => {
              window.location.reload();
            });

            if (reg) {
              if (reg.waiting) {
                messageSW(reg.waiting, {type: 'SKIP_WAITING'});
              } else {
                window.location.reload();
              }
            }
          }
        }
      });
    });
  }
} else {
  console.log(`Application ServiceWorker version ${VERSION}`);
}
