import {PaymentType} from '@emporos/api-enterprise/src/gen';
import {
  Button,
  ButtonShape,
  Gutter,
  Header,
  IndicatorCircle,
  PaymentCard,
  PriceFooter,
  Row,
  RowItem,
  ScrollContainer,
  SegmentSlider,
  Select,
  ButtonSize as Size,
  Stack,
} from '@emporos/components';
import Text, {Variant as TV, Variant} from '@emporos/components/src/Text';
import TextInput from '@emporos/components/src/TextInput';
import {Link} from '@reach/router';
import moment from 'moment';
import {memo, useState} from 'react';
import styled from 'styled-components';
import {BetaFeatureSwitch} from '../../../contexts/BetaFeatureProvider';
import {Invoice} from '../../../contexts/TransactionsStateProvider';
import {TotalsResult} from '../../../hooks/useTotals';
import {formatCustomerName} from '../../../utils/customer';
import {invoiceItemIcons} from '../../../utils/rowIcons';
import {formatMedication} from '../../../utils/string';
import {
  displayPaymentNumber,
  getPaymentIconType,
  getPaymentType,
  getSimplePaymentIcon,
} from '../transactions/payments/utils';
import img from './completed-landing.png';

const ITEMS_TAB = 0;
const PAYMENTS_TAB = 1;
const RECEIPT_TAB = 2;
const RECEIPT_EMAIL_TAB = 0;

const CardRow = styled(Row)`
  height: 100%;
  flex: 1;
  & > * {
    margin: 18px;
  }
`;

const Footer = memo(function Footer({
  overTheCounter,
  prescriptionCopay,
  salesTax,
  qhpAmount,
  transactionTotal,
}: TotalsResult) {
  return (
    <PriceFooter
      totals={{
        overTheCounter,
        prescriptionCopay,
        salesTax,
        qhpAmount,
        transactionTotal,
      }}
    />
  );
});

interface Props {
  currentInvoiceId: string;
  invoice: Invoice;
  totals: TotalsResult;
  paymentTenders: PaymentType[];
  onUpdateInvoice(updates: Partial<Invoice>): void;
  onEmailReceipt(): void;
  onPrintReceipt(): void;
}
export default function Completed({
  currentInvoiceId,
  invoice,
  totals,
  paymentTenders,
  onEmailReceipt,
  onPrintReceipt,
}: Props): JSX.Element {
  const [active, setActive] = useState(0);
  const [receiptActive, setReceiptActive] = useState(0);
  const {serverTransactionID, saleDate} = invoice;

  if (!currentInvoiceId || !invoice) {
    return (
      <Stack
        align="center"
        justify="center"
        style={{height: 'calc(100vh - 72px)'}}
      >
        <img
          src={img}
          alt={'Completed transactions home page. Select an action.'}
        />
      </Stack>
    );
  }

  const {items, customer, payments, emailReceipt} = invoice;

  const prescriptions = items.filter(({rx}) => rx);
  const otcItems = items.filter(({rx}) => !rx);

  return (
    <Stack gutter={Gutter.None} style={{height: '100%'}}>
      <Header
        title={customer ? formatCustomerName(customer) : 'General Sale'}
        badgeText={invoice.roomNumber || ''}
      >
        <SegmentSlider
          items={['Items', 'Payment', 'Receipt']}
          onSelectIndex={setActive}
          active={active}
        />
        <ButtonShape
          //  we are using styled-components `as` override to use a real link here, with ButtonShape styling
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          as={Link}
          to="customer-info"
          size={Size.Small}
          icon="User"
          data-testid="customer-info-btn"
        />
      </Header>

      {active === ITEMS_TAB && (
        <Row
          gutter={Gutter.XXL}
          style={{height: '100%', marginTop: 20, minHeight: 0}}
          noWrap
        >
          <Stack style={{flex: 1}}>
            <ScrollContainer>
              {!!prescriptions.length && (
                <Stack gutter={Gutter.S} style={{marginBottom: 16}}>
                  <Text
                    variant={TV.Caption}
                    style={{marginBottom: '-4px', paddingLeft: 16}}
                  >
                    Prescriptions
                  </Text>
                  {prescriptions.map(item => (
                    <RowItem
                      key={item.invoiceItemId}
                      title={formatMedication(item.description2 || '')}
                      subtitle={item.receiptDescription || ''}
                      rightIcons={invoiceItemIcons(item)}
                      rightText={`$${item.price.toFixed(2)}`}
                      noClick
                      data-testid="rowItem-generic-rx"
                    />
                  ))}
                </Stack>
              )}

              {!!otcItems.length && (
                <Stack gutter={Gutter.S}>
                  <Text
                    variant={TV.Caption}
                    style={{marginBottom: '-4px', paddingLeft: 16}}
                  >
                    Over the Counter
                  </Text>
                  {otcItems.map(item => (
                    <RowItem
                      key={item.invoiceItemId}
                      variant="generic"
                      title={formatMedication(item.description || '')}
                      subtitle={item.itemNumber || ''}
                      rightText={`$${item.price.toFixed(2)}`}
                      rightIcons={invoiceItemIcons(item)}
                      quantity={item.quantity.toString()}
                      noClick
                    />
                  ))}
                </Stack>
              )}
            </ScrollContainer>

            <Footer {...totals} />
          </Stack>
        </Row>
      )}

      {active === PAYMENTS_TAB && (
        <>
          <ScrollContainer>
            <CardRow justify="center" align="center" gutter={Gutter.None}>
              {payments.map((p, index) => {
                const {amount, paymentTypeID, createdOn, recordStatus} = p;
                const isCreditCardVoided =
                  recordStatus === 'Voided' &&
                  getPaymentType(paymentTypeID || 0, paymentTenders) ===
                    'Credit Card';

                return (
                  <PaymentCard
                    key={index}
                    icon={getSimplePaymentIcon(
                      paymentTypeID || 0,
                      paymentTenders,
                    )}
                    color={getPaymentIconType(
                      paymentTypeID || 0,
                      paymentTenders,
                    )}
                    title={
                      isCreditCardVoided
                        ? `Void: -$${amount.toFixed(2)}`
                        : `${getPaymentType(
                            paymentTypeID || 0,
                            paymentTenders,
                          )}: $${amount.toFixed(2)}`
                    }
                    subtitle={displayPaymentNumber(p, paymentTenders)}
                    dateTime={moment(createdOn).format('MM/DD/YYYY - hh:mmA')}
                  />
                );
              })}
            </CardRow>
          </ScrollContainer>

          <Footer {...totals} />
        </>
      )}

      {active === RECEIPT_TAB && (
        <Stack align="center" style={{width: '100%', flex: 1}}>
          <SegmentSlider
            items={['Email', 'Print']}
            onSelectIndex={setReceiptActive}
            active={receiptActive}
          />

          {receiptActive === RECEIPT_EMAIL_TAB ? (
            <>
              <Stack
                justify="center"
                align="center"
                gutter={Gutter.XXL}
                style={{flex: 1}}
              >
                {serverTransactionID && (
                  <Stack justify="center" align="center" gutter={Gutter.XS}>
                    <Text variant={Variant.T2}>{serverTransactionID}</Text>
                    <Text variant={Variant.Main}>
                      {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                    </Text>
                  </Stack>
                )}
                <Row>
                  <Stack justify="center" align="center" gutter={Gutter.L}>
                    <IndicatorCircle
                      icon="Email"
                      variant="primary"
                      size="large"
                    />
                    <TextInput
                      name="email"
                      label="Email"
                      value={emailReceipt || ''}
                      style={{width: 422}}
                    />
                  </Stack>
                </Row>
              </Stack>

              <Row style={{width: '100%'}}>
                <Button flex onClick={onEmailReceipt}>
                  Send Email
                </Button>
              </Row>
            </>
          ) : (
            <BetaFeatureSwitch feature="printing">
              {enabled =>
                enabled ? (
                  <Stack
                    justify="center"
                    align="center"
                    gutter={Gutter.L}
                    style={{flex: 1}}
                  >
                    <IndicatorCircle
                      icon="Receipt"
                      variant="warning"
                      size="large"
                    />
                    <Select
                      label="Printer"
                      options={['Front Desk']}
                      value=""
                      onChangeValue={() => console.log('Unimplemented')}
                      style={{width: 372, flex: 0}}
                    />
                    <Row style={{width: '100%'}}>
                      <Button flex onClick={onPrintReceipt}>
                        Print Receipt
                      </Button>
                    </Row>
                  </Stack>
                ) : (
                  <Text>Printing is not supported during Early Access</Text>
                )
              }
            </BetaFeatureSwitch>
          )}
        </Stack>
      )}
    </Stack>
  );
}
