/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Station
 */
export interface Station {
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    stationId: number;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    siteId: number;
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof Station
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Station
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Station
     */
    type?: string | null;
}

export function StationFromJSON(json: any): Station {
    return StationFromJSONTyped(json, false);
}

export function StationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Station {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stationId': json['stationId'],
        'siteId': json['siteId'],
        'number': json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function StationToJSON(value?: Station | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stationId': value.stationId,
        'siteId': value.siteId,
        'number': value.number,
        'name': value.name,
        'description': value.description,
        'type': value.type,
    };
}


