import {PaymentType, Setting} from '@emporos/api-enterprise/src/gen';
import {AcceptedPaymentTypes, getPaymentType} from '../utils';
import {Invoice} from '@emporos/api-enterprise/src/gen-session';

interface Acknowledgements {
  rxMessage?: string;
  arMessage?: string;
  ccMessage?: string;
  sudafedMessage?: string;
  noSafetyCapMessage?: string;
}
function calculateAmount(
  paymentType: AcceptedPaymentTypes,
  payments: Invoice['payments'],
  paymentTenders: PaymentType[],
) {
  return (
    '$' +
    payments
      .filter(
        p =>
          p.paymentTypeID &&
          getPaymentType(p.paymentTypeID, paymentTenders) === paymentType,
      )
      .reduce((acc, p) => acc + p.amount, 0)
      .toFixed(2)
  );
}

const mapAcknowledgement = (
  {items, payments}: Invoice,
  settingsResult: Setting[],
  paymentTenders: PaymentType[],
): Acknowledgements => {
  return {
    rxMessage:
      (items.some(({rx}) => rx) &&
        settingsResult.find(({name}) => name === 'RXMessage')?.value) ||
      undefined,
    arMessage:
      (payments.some(
        ({paymentTypeID}) =>
          paymentTypeID &&
          getPaymentType(paymentTypeID, paymentTenders) === 'AR',
      ) &&
        (settingsResult.find(s => s.name === 'ARMessage')?.value || '').replace(
          '[tender_amount]',
          calculateAmount('AR', payments, paymentTenders),
        )) ||
      undefined,
    ccMessage:
      (!!payments?.some(
        ({paymentTypeID}) =>
          paymentTypeID &&
          getPaymentType(paymentTypeID, paymentTenders) === 'Credit Card',
      ) &&
        (settingsResult.find(s => s.name === 'CCMessage')?.value || '').replace(
          '[tender_amount]',
          calculateAmount('Credit Card', payments, paymentTenders),
        )) ||
      undefined,
    sudafedMessage:
      (items.some(({itemTypeID}) => itemTypeID === 16) &&
        (
          settingsResult.find(s => s.name === 'SudafedMessage')?.value || ''
        ).replace('~True', '')) ||
      undefined,
    noSafetyCapMessage:
      (settingsResult.find(s => s.name === 'NoSafetyCapEnabled')?.value ===
        '1' &&
        items.some(
          ({noSafetyCapIndicator, rx}) => rx && noSafetyCapIndicator,
        ) &&
        settingsResult.find(s => s.name === 'NoSafetyCapMessage')?.value) ||
      undefined,
  };
};
export default mapAcknowledgement;
