import {withChildPage, WithPageProps} from '../../../hocs/withChildPage';
import {useNetworkAvailable} from '../../../contexts/NetworkAvailableProvider';
import useInvoice from '../../../hooks/useInvoice';
import useTotals from '../../../hooks/useTotals';
import {OfflineInvoice} from '../../../api/common';
import CustomerInfoPanel from './CustomerInfoPanel';
import {memo} from 'react';

interface Props {
  viewOnly?: boolean;
}

function CustomerInfoIntegration({viewOnly}: WithPageProps<Props>) {
  const online = useNetworkAvailable();
  const {invoice, updateInvoice} = useInvoice();
  const {taxGroupId} = useTotals();

  return (
    <CustomerInfoPanel
      online={online}
      invoice={invoice as OfflineInvoice}
      taxGroupId={taxGroupId}
      viewOnly={viewOnly}
      onCancel={() => {
        history.back();
      }}
      onSave={updates => {
        updateInvoice(updates);
        history.back();
      }}
    />
  );
}

export default memo(withChildPage(CustomerInfoIntegration));
