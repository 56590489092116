import {Stack} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import {memo, useEffect} from 'react';
import {useTransactionsState} from '../../contexts/TransactionsStateProvider';
import img from './sales-landing.png';

function Home(_: RouteComponentProps): JSX.Element {
  const {setCurrentInvoiceId} = useTransactionsState();

  useEffect(() => setCurrentInvoiceId(''), []);

  return (
    <Stack
      align="center"
      justify="center"
      style={{height: 'calc(calc(var(--vh, 1vh) * 100 - 72px)'}}
    >
      <img src={img} alt={'Sales home page. Select an action.'} />
    </Stack>
  );
}

export default memo(Home);
