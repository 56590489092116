/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvoicePayment,
    InvoicePaymentFromJSON,
    InvoicePaymentToJSON,
    InvoicePaymentRequest,
    InvoicePaymentRequestFromJSON,
    InvoicePaymentRequestToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGetRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDeleteRequest {
    sessionId: string;
    invoiceId: string;
    invoicePaymentId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGetRequest {
    sessionId: string;
    invoiceId: string;
    invoicePaymentId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPostRequest {
    sessionId: string;
    invoiceId: string;
    invoicePaymentRequest?: InvoicePaymentRequest;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPutRequest {
    sessionId: string;
    invoiceId: string;
    invoicePaymentRequest?: InvoicePaymentRequest;
}

/**
 * 
 */
export class InvoicePaymentsApi extends runtime.BaseAPI {

    /**
     * Gets a list of invoicePayments for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGetRequest): Promise<runtime.ApiResponse<Array<InvoicePayment>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/payments`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoicePaymentFromJSON));
    }

    /**
     * Gets a list of invoicePayments for an Invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGetRequest): Promise<Array<InvoicePayment>> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an invoicePayment
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDelete.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDelete.');
        }

        if (requestParameters.invoicePaymentId === null || requestParameters.invoicePaymentId === undefined) {
            throw new runtime.RequiredError('invoicePaymentId','Required parameter requestParameters.invoicePaymentId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/payments/{invoicePaymentId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoicePaymentId"}}`, encodeURIComponent(String(requestParameters.invoicePaymentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an invoicePayment
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDelete(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdDeleteRaw(requestParameters);
    }

    /**
     * Gets an invoicePayment by session id, invoice id  and invoicePayment id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGetRequest): Promise<runtime.ApiResponse<InvoicePayment>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGet.');
        }

        if (requestParameters.invoicePaymentId === null || requestParameters.invoicePaymentId === undefined) {
            throw new runtime.RequiredError('invoicePaymentId','Required parameter requestParameters.invoicePaymentId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/payments/{invoicePaymentId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))).replace(`{${"invoicePaymentId"}}`, encodeURIComponent(String(requestParameters.invoicePaymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicePaymentFromJSON(jsonValue));
    }

    /**
     * Gets an invoicePayment by session id, invoice id  and invoicePayment id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGetRequest): Promise<InvoicePayment> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsInvoicePaymentIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts or updates a invoicePayment
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPostRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPostRequest): Promise<runtime.ApiResponse<InvoicePayment>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPost.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/payments`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoicePaymentRequestToJSON(requestParameters.invoicePaymentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicePaymentFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a invoicePayment
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPost(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPostRequest): Promise<InvoicePayment> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an invoicePayment
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPutRequest): Promise<runtime.ApiResponse<InvoicePayment>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPut.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/payments`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoicePaymentRequestToJSON(requestParameters.invoicePaymentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicePaymentFromJSON(jsonValue));
    }

    /**
     * Updates an invoicePayment
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPut(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPutRequest): Promise<InvoicePayment> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdPaymentsPutRaw(requestParameters);
        return await response.value();
    }

}
