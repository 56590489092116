import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, PropsWithChildren, useCallback} from 'react';
import {useBetaFeatures} from '../../../../../contexts/BetaFeatureProvider';
import {useCardReaderCredentials} from '../../../../../contexts/CardReaderCredentialsProvider';
import {useCustomerSearch} from '../../../../../contexts/CustomerSearchProvider';
import {useGlobalData} from '../../../../../contexts/GlobalDataProvider';
import {ApiLogTypes, useLog} from '../../../../../contexts/LoggingProvider';
import {useNetworkAvailable} from '../../../../../contexts/NetworkAvailableProvider';
import {withChildPage} from '../../../../../hocs/withChildPage';
import CustomerPayment from './CustomerPayment';
import useInvoice from '../../../../../hooks/useInvoice';
import useTotals from '../../../../../hooks/useTotals';
import {useSignatures} from '../../../../../hooks/useSignatures';
import {useTransactionsState} from '../../../../../contexts/TransactionsStateProvider';
import {mapCustomerAccount} from '../../../../../utils/mappers';
import {useConfig} from '../../../../../contexts/ConfigProvider';
import {
  CustomerAccountsApi,
  CustomersApi,
  EligibilityApi,
} from '@emporos/api-enterprise/src/gen';
import useOpenApi from '../../../../../api/useOpenApi';
import usePaymentProvider from '../../../../../hooks/usePaymentProvider';
import {useSettings} from '../../../../../contexts/SettingsProvider';

function CustomerPaymentIntegration({
  navigate,
}: PropsWithChildren<RouteComponentProps>): JSX.Element {
  const {creditCardsOnFile, vantiv, hostedPayment} = useBetaFeatures();
  const {creditCardPendingPaymentExpirationMinutes} = useSettings();
  const {credentials} = useCardReaderCredentials();
  const {customer, refresh} = useCustomerSearch();
  const {
    settingsResult,
    paymentTendersResult,
    paymentOptionsResult,
  } = useGlobalData();
  const {invoice, updateInvoice, processedPayments} = useInvoice();
  const {session, setSelectedPayment} = useTransactionsState();
  const signatureTypes = useSignatures();
  const {totals} = useTotals();
  const {logApi} = useLog();
  const online = useNetworkAvailable();
  const {NodeEnv} = useConfig();
  const {run: getEmployee} = useOpenApi(
    CustomersApi,
    'clientCustomersEmployeeGet',
  );
  const {run: getEligibility} = useOpenApi(
    EligibilityApi,
    'clientEligibilityGet',
  );
  const {run: postAR} = useOpenApi(
    CustomerAccountsApi,
    'customersCustomerIdAccountsArPost',
  );
  const paymentProvider = usePaymentProvider();

  const onGetCustomerClick = useCallback(() => {
    navigate && navigate('add-customer');
  }, [navigate]);

  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<CustomerPayment /> must have a `navigate` prop.' + NodeEnv ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
    assert(settingsResult && settingsResult.data, 'Missing settingsResult');
    assert(
      paymentTendersResult && paymentTendersResult.data,
      'Missing paymentTendersResult',
    );
    assert(
      paymentOptionsResult && paymentOptionsResult.data,
      'Missing paymentOptionsResult',
    );
    assert(invoice, 'Missing invoice');
  }

  return (
    <CustomerPayment
      showCreditCardsOnFile={creditCardsOnFile}
      showVantiv={vantiv}
      showHostedPayment={hostedPayment}
      creditCardPendingPaymentExpirationMinutes={
        creditCardPendingPaymentExpirationMinutes
      }
      isOnline={online}
      navigate={navigate}
      credentials={credentials}
      customer={customer}
      invoice={invoice}
      processedPayments={processedPayments}
      totals={totals}
      settings={settingsResult.data}
      paymentTenders={paymentTendersResult.data}
      paymentOptions={paymentOptionsResult.data}
      updateInvoice={updateInvoice}
      setSelectedPayment={setSelectedPayment}
      onSearchEligibility={query => {
        logApi(ApiLogTypes.EligibilityPending);
        return getEligibility({q: query});
      }}
      onGetCustomer={onGetCustomerClick}
      onGetEmployee={eligibility => {
        logApi(ApiLogTypes.EmployeePending);
        return getEmployee({
          employeeNumber: eligibility.employeeNumber || '',
        });
      }}
      onCreateAR={async () => {
        if (!customer) {
          return undefined;
        }
        const response = await postAR({
          customerId: customer.id || 0,
          siteId: session.siteId,
          stationId: session.stationId,
        });
        await refresh();
        return response.data
          ? mapCustomerAccount(customer.id, response.data)
          : undefined;
      }}
      signatureTypes={signatureTypes}
      paymentProvider={paymentProvider}
    />
  );
}

export default memo(withChildPage(CustomerPaymentIntegration));
