import {OtcItem, Prescription} from '@emporos/api-enterprise/src/gen';
import {InvoiceItem} from '@emporos/api-enterprise/src/gen-session';
import {Icons} from '@emporos/components';

type ArrayOfIcons = Array<keyof typeof Icons>;

export function otcIcons(otcItem: OtcItem): ArrayOfIcons {
  const icons: ArrayOfIcons = [];

  (otcItem.proofOfIdRequired || otcItem.itemType.id === 16) &&
    icons.push('IdCard');
  otcItem.counselPromptIndicator && icons.push('MessageDouble');
  (otcItem.qhpIndicator || otcItem.qhpPrivateIndicator) &&
    icons.push('BadgeDollar');
  otcItem.taxableIndicator && icons.push('BadgePercent');

  return icons;
}

export function prescriptionIcons(prescription: Prescription): ArrayOfIcons {
  const icons: ArrayOfIcons = [];

  prescription.proofIDIndicator && icons.push('IdCard');
  prescription.counselRequiredIndicator && icons.push('MessageDouble');
  prescription.taxableIndicator && icons.push('BadgePercent');

  return icons;
}

export function invoiceItemIcons(invoiceItem: InvoiceItem): ArrayOfIcons {
  const icons: ArrayOfIcons = [];

  (invoiceItem.proofIDIndicator || invoiceItem.itemTypeID === 16) &&
    icons.push('IdCard');
  (invoiceItem.counselPromptIndicator ||
    invoiceItem.counselRequiredIndicator) &&
    icons.push('MessageDouble');
  (invoiceItem.qhpIndicator || invoiceItem.qhpPrivateIndicator) &&
    icons.push('BadgeDollar');
  invoiceItem.taxableIndicator && icons.push('BadgePercent');

  return icons;
}
