import React, {ChangeEvent, PropsWithoutRef, memo} from 'react';
import styled from 'styled-components';
import {Icon, IconSize} from './';

interface RadioOptions extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  label: string;
  value: string;
}
interface Props extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  name: string;
  options: RadioOptions[];
  onChange: <HTMLInputElement>(event: ChangeEvent<HTMLInputElement>) => void;
}

const StyledRadio = styled.input`
  appearance: none;
  margin: 0;
  height: 36px;
  width: 36px;
  border: 2px solid ${({theme}) => theme.colors.blue};
  border-radius: 50%;
  cursor: pointer;
  margin-right: 12px;
  transition: box-shadow 250ms ease;

  &:checked {
    background-color: ${({theme}) => theme.colors.blue};
  }
  &:focus {
    outline: none;
    box-shadow: ${({theme}) => `0 0 0 4px ${theme.colors.blue_20}`};
  }
`;
const StyledLabel = styled.label`
  font-family: ${({theme}) => theme.typography.Main.fontFamily};
  font-size: ${({theme}) => theme.typography.Main.fontSize};
  font-weight: ${({theme}) => theme.typography.Main.fontWeight};
  color: ${({theme}) => theme.colors.blue};
  cursor: pointer;
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
`;
const IconWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 20px;
  left: 10px;
`;

export const RadioButtons = memo(({name, options, ...props}: Props) => {
  return (
    <div>
      {options.map((opt, i) => (
        <RadioWrapper key={i}>
          <StyledRadio
            type="radio"
            id={opt.value}
            name={name}
            {...props}
            {...opt}
          />
          <StyledLabel htmlFor={opt.value}>{opt.label}</StyledLabel>

          <IconWrapper>
            <Icon icon="Checkmark" size={IconSize.S} variant="inverted" />
          </IconWrapper>
        </RadioWrapper>
      ))}
    </div>
  );
});
