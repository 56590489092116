import {
  Button,
  ButtonShape,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  IndicatorCircle,
  Page,
  ButtonSize as Size,
  Stack,
} from '@emporos/components';
import Text, {Variant} from '@emporos/components/src/Text';
import {useState} from 'react';

interface Props {
  onBack(): void;
  onComplete(): void;
  onCustomerInfo(): void;
}
export default function CompleteTransaction({
  onBack,
  onComplete,
  onCustomerInfo,
}: Props): JSX.Element {
  const [animatePanel, setAnimatePanel] = useState(true);

  return (
    <Page level={2} open={animatePanel}>
      <Stack gutter={Gutter.L} style={{flex: 1}}>
        <Header title="Complete Transaction">
          <ButtonShape
            size={Size.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={onCustomerInfo}
          />
        </Header>

        <Stack justify="center" align="center" style={{flex: 1}}>
          <IndicatorCircle icon="Checkmark" size="large" variant="success" />
          <Text variant={Variant.T2} align="center">
            All done!
          </Text>
        </Stack>

        <FooterGroup>
          <Button variant={BV.Secondary} onClick={onBack} flex>
            Back
          </Button>
          <Button
            onClick={() => {
              onComplete();
              setAnimatePanel(false);
            }}
            flex
          >
            Complete Transaction
          </Button>
        </FooterGroup>
      </Stack>
    </Page>
  );
}
