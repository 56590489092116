/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerAccountApiResponse,
    CustomerAccountApiResponseFromJSON,
    CustomerAccountApiResponseToJSON,
} from '../models';

export interface CustomersCustomerIdAccountsArPostRequest {
    customerId: number;
    siteId?: number;
    stationId?: number;
}

export interface CustomersCustomerIdAccountsPdPostRequest {
    customerId: number;
    siteId?: number;
    stationId?: number;
}

/**
 * 
 */
export class CustomerAccountsApi extends runtime.BaseAPI {

    /**
     * Creates an AR account for a customer and returns it
     */
    async customersCustomerIdAccountsArPostRaw(requestParameters: CustomersCustomerIdAccountsArPostRequest): Promise<runtime.ApiResponse<CustomerAccountApiResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersCustomerIdAccountsArPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/customers/{customerId}/accounts/ar`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAccountApiResponseFromJSON(jsonValue));
    }

    /**
     * Creates an AR account for a customer and returns it
     */
    async customersCustomerIdAccountsArPost(requestParameters: CustomersCustomerIdAccountsArPostRequest): Promise<CustomerAccountApiResponse> {
        const response = await this.customersCustomerIdAccountsArPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a PD account for a customer and returns it
     */
    async customersCustomerIdAccountsPdPostRaw(requestParameters: CustomersCustomerIdAccountsPdPostRequest): Promise<runtime.ApiResponse<CustomerAccountApiResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersCustomerIdAccountsPdPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/customers/{customerId}/accounts/pd`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAccountApiResponseFromJSON(jsonValue));
    }

    /**
     * Creates a PD account for a customer and returns it
     */
    async customersCustomerIdAccountsPdPost(requestParameters: CustomersCustomerIdAccountsPdPostRequest): Promise<CustomerAccountApiResponse> {
        const response = await this.customersCustomerIdAccountsPdPostRaw(requestParameters);
        return await response.value();
    }

}
