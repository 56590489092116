import {
  CustomerAccount,
  CustomerApiResponse,
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
  PaymentType,
} from '@emporos/api-enterprise/src/gen';
import {
  Customer as InvoiceCustomer,
  CustomerAccount as InvoiceCustomerAccount,
} from '@emporos/api-enterprise/src/gen-session';
import {
  Button,
  Variant as BV,
  Gutter,
  Page,
  Row,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import SelectCustom from '@emporos/components/src/SelectCustom';
import Text, {Variant as TV, Variant} from '@emporos/components/src/Text';
import moment from 'moment';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import styled from 'styled-components';
import {formatCustomerName, pds} from '../../../../../utils/customer';
import {mapInvoiceCustomer} from '../../../../../utils/mappers';
import {getPaymentTypeId} from '../utils';
import BillBoard from './BillBoard';
import EmployeeSearch from './EmployeeSearch';
import {PaymentRef as Handle} from './Types';

interface Props {
  isOnline: boolean;
  customer: InvoiceCustomer;
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
  paymentTenders: PaymentType[];
  onChangeActivePayment(account: CustomerAccount): void;
  onSearchEligibility: (
    query: string,
  ) => Promise<EligibilitySearchMatchListApiResponse>;
  onGetEmployee: (
    customer: EligibilitySearchMatch,
  ) => Promise<CustomerApiResponse>;
}

const CustomerRow = styled(Row)`
  display: flex;
  border: ${({theme}) => `1px solid ${theme.colors.gray_300}`};
  height: 80px;
  border-radius: 8px;
  padding: 16px;
`;

function isAcceptablePayment(
  account: CustomerAccount,
  amount: number,
  totalDue: number,
) {
  return account.creditLimit - account.balance >= amount && amount <= totalDue;
}

export default forwardRef<Handle, Props>(function PDPayment(
  {
    isOnline,
    customer: _customer,
    amount,
    setAmount,
    totalDue,
    paymentTenders,
    onChangeActivePayment,
    onSearchEligibility,
    onGetEmployee,
  },
  ref,
): JSX.Element {
  const [searchPanelOpen, setSearchPanelOpen] = useState(false);
  const [customer, setCustomer] = useState<InvoiceCustomer>(_customer);
  const [
    customerAccount,
    setCustomerAccount,
  ] = useState<InvoiceCustomerAccount | null>(null);

  useImperativeHandle(ref, () => ({
    onConfirmPayment() {
      if (!customerAccount) {
        return Promise.reject(
          new TypeError(
            'Attempted to pay with PD without having a PD account selected',
          ),
        );
      } else if (!isAcceptablePayment(customerAccount, amount, totalDue)) {
        return Promise.reject(
          new TypeError('Selected account has insufficient funds.'),
        );
      }
      return Promise.resolve({
        paymentId: customerAccount.accountNumber,
        amountApproved: amount,
        paymentTypeId: getPaymentTypeId('PD', paymentTenders),
      });
    },
    isAcceptablePayment() {
      return customerAccount
        ? isAcceptablePayment(customerAccount, amount, totalDue)
        : false;
    },
  }));

  /**
   * `onChangeActivePayment` is a prop from the CustomerPayment manager
   * component to listen to the lifecyle of this component and call
   * `ref.isAcceptablePayment()` to determine if the submit button is enabled or
   * not. This is an awkward React pattern, but enables us to delegate the
   * implementation of payment-specific logic within each payment type.
   */
  useEffect(() => {
    if (customerAccount) {
      onChangeActivePayment(customerAccount);
    }
  }, [customerAccount]);

  return (
    <ScrollContainer>
      <Stack gutter={Gutter.L} style={{flex: 1}}>
        <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
        <CustomerRow justify="space-between" align="center">
          {customer ? (
            <>
              <Stack gutter={Gutter.XXS}>
                <Text variant={TV.Main}>{formatCustomerName(customer)}</Text>
                <Text variant={TV.SubMainLight}>
                  DOB: {moment.utc(customer.dob).format('MM/DD/YYYY')}
                </Text>
              </Stack>
              <Button
                variant={BV.Link}
                onClick={() => setSearchPanelOpen(true)}
                style={{padding: 0}}
              >
                Change
              </Button>
            </>
          ) : (
            <>
              <Stack gutter={Gutter.XXS}>
                <Text variant={TV.Main}>Employee</Text>
              </Stack>
              <Button
                variant={BV.Link}
                onClick={() => setSearchPanelOpen(true)}
                style={{padding: 0}}
              >
                Select
              </Button>
            </>
          )}
        </CustomerRow>
        <Row>
          <SelectCustom<InvoiceCustomerAccount>
            data-testid="select-accounts"
            label="PD Accounts"
            value={customerAccount}
            options={customer?.accounts ? pds(customer) : []}
            getOptionValue={({accountId = ''}) => accountId.toString()}
            onChange={customerAccount => {
              setCustomerAccount(customerAccount);
            }}
            renderOption={({accountNumber, balance = 0, creditLimit = 0}) => (
              <Stack gutter={Gutter.XXS} style={{flex: 1}}>
                <Text variant={Variant.Main}>
                  {`Available: $${(creditLimit - balance).toFixed(2)}`}
                </Text>
                <Text variant={Variant.SubMainLight}>
                  {`Limit: $${creditLimit.toFixed(
                    2,
                  )} • Account: ${accountNumber}`}
                </Text>
              </Stack>
            )}
            placeholder={customer ? undefined : 'No Account'}
            disabled={!Boolean(customer)}
          />
        </Row>
      </Stack>

      <Page level={3} open={searchPanelOpen} style={{left: 36, zIndex: 1}}>
        <EmployeeSearch
          online={isOnline}
          onAddCustomer={eligibility =>
            onGetEmployee(eligibility).then(response => {
              if (!response.data) {
                return;
              }
              setCustomer(mapInvoiceCustomer(response.data));
              setCustomerAccount(null);
              setSearchPanelOpen(false);
            })
          }
          onCancel={() => setSearchPanelOpen(false)}
          onSearch={onSearchEligibility}
        />
      </Page>
    </ScrollContainer>
  );
});
