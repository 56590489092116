import React, {memo, useRef} from 'react';
import {animated, useSpring} from 'react-spring';
import styled from 'styled-components';
import {Icon, Icons} from './';

export interface TabBarItem {
  icon: keyof typeof Icons;
  disabled?: boolean;
}

interface Props {
  items: Array<TabBarItem>;
  active: number;
  onSelect(select: number): void;
  'data-testid'?: string;
}

const Container = styled.div`
  background: ${({theme}) => theme.colors.white};
  border-radius: 12px;
  display: inline-flex;
  flex-direction: row;
  padding: 10px;
  box-shadow: ${({theme}) => {
    return theme.shadows.shadow_8;
  }};
`;

const Item = styled.button<{active: boolean}>`
  border: 0;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 50px;
  transition: all 300ms ease-out;
  cursor: pointer;

  /* ensure a stacking context above the background */
  position: relative;
  z-index: 1;

  &:focus {
    outline: none;
  }
`;

const BackgroundDiv = styled.div`
  background: ${({theme}) => theme.colors.gray_100};
  border-radius: 8px;
  position: absolute;
  width: 75px;
  height: 50px;
`;
const Background = animated(BackgroundDiv);

function TabBar({items, active, onSelect, 'data-testid': dataTestId}: Props) {
  const ref = useRef(null);
  const spring = useSpring({
    value: active,
    from: {value: 0},
  });
  return (
    <Container data-testid={dataTestId || 'TabBar'}>
      {items.map(({icon, disabled}, index) => {
        return (
          <Item
            onClick={_ => onSelect(index)}
            active={active === index}
            ref={active === index ? ref : null}
            key={index}
            disabled={disabled}
          >
            <Icon icon={icon} variant={active === index ? 'primary' : 'gray'} />
          </Item>
        );
      })}
      <Background
        style={{
          transform: spring.value.to(o => `translateX(${o * 75}px)`),
        }}
      />
    </Container>
  );
}
export default memo(TabBar);
