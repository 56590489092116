/**
 * The <VerificationModal /> provides a UX flow where a customer is requied to
 * enter their birthday to confirm it is them.
 */
import {isSame, isValid} from '@emporos/pos/src/utils/dates';
import {NormalizedCustomer} from '@emporos/pos/src/utils/customer';
import {yupResolver} from '@hookform/resolvers/yup';
import moment from 'moment';
import React, {PropsWithoutRef, memo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {ObjectSchema} from 'yup';
import * as yup from 'yup';
import {Modal} from './';
import TextInput from './TextInput';

interface CustomerDOB {
  dob: string;
}

interface Props {
  customerInfo: NormalizedCustomer;
  visible: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

const dobSchema = (): ObjectSchema => {
  const dob = yup
    .string()
    .test(
      'dob',
      'Invalid dob',
      _dateOfBirth => !!_dateOfBirth && isValid(_dateOfBirth),
    );

  return yup.object().shape({
    dob,
  });
};

function VerificationModal({
  customerInfo,
  visible,
  onCancel,
  onContinue,
}: Props & PropsWithoutRef<JSX.IntrinsicElements['div']>) {
  const [dobError, setDobError] = useState(false);

  const schema = dobSchema();
  const {register, errors, watch, control, formState} = useForm<CustomerDOB>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const birthdayInput = watch('dob');

  return (
    <Modal
      buttonText="Verify"
      data-testid="Verification__Modal"
      disableSubmit={!formState.isDirty || !formState.isValid || !!errors.dob}
      icon="IdCard"
      color="primary"
      subtitle={`Please verify DOB for ${customerInfo.firstName} ${customerInfo.lastName}.`}
      title="Verification needed to continue."
      visible={visible}
      onCancel={() => {
        setDobError(false);
        onCancel && onCancel();
      }}
      onContinue={() => {
        setDobError(false);
        if (
          isSame(
            birthdayInput,
            moment.utc(customerInfo.dob).format('MM/DD/YYYY'),
          )
        ) {
          onContinue();
        } else {
          setDobError(true);
        }
      }}
    >
      <Controller
        name="dob"
        control={control}
        defaultValue=""
        render={({onChange, value}) => (
          <TextInput
            style={{margin: '24px 0 0 0'}}
            autoFocus
            ref={register}
            label="DOB"
            mask="99/99/9999"
            name="dob"
            data-testid="dob"
            isError={dobError}
            onChange={e => {
              if (dobError) {
                setDobError(false);
              }
              onChange(e.target.value);
            }}
            value={value}
            inputMode="numeric"
          />
        )}
      />
    </Modal>
  );
}
export default memo(VerificationModal);
