import {
  Peripheral,
  PeripheralsApi,
  PeripheralTypesApi,
  Site,
  Station,
} from '@emporos/api-enterprise/src/gen';
import {
  SessionPeripheral,
  SessionPeripheralsApi,
} from '@emporos/api-enterprise/src/gen-session';
import {
  Button,
  Variant as BV,
  FixedHeader,
  FooterGroup,
  Gutter,
  Header,
  Modal,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {isEqual} from 'lodash';
import {FormEvent, memo, useEffect, useState} from 'react';
import useOpenApi from '../../../api/useOpenApi';
import useOpenApiSession from '../../../api/useOpenApiSession';
import {useAlertState} from '../../../contexts/AlertStateProvider';
import {useTransactionsConfig} from '../../../contexts/TransactionsConfigProvider';
import useSession from '../../../hooks/useSession';
import {findSessionPaymentDevice} from '../../../utils/session';
import CreateSession, {
  isSessionConfigCompleted,
  NONE_PERIPHERAL,
  SessionConfig,
} from '../../login/create-session/CreateSession';

function SettingsHome(_: RouteComponentProps): JSX.Element {
  const {notification} = useAlertState();
  const {
    closeSession,
    closeSessionLoading,
    peripherals,
    setPeripherals,
    peripheralTypes,
    setPeripheralTypes,
    session,
    setSession,
  } = useTransactionsConfig();
  const {canClose} = useSession();
  const [config, setConfig] = useState<SessionConfig>({
    site: null,
    station: null,
    paymentDevice: null,
    till: null,
    tillStartingAmount: null,
  });
  const [originalConfig, setOriginalConfig] = useState<SessionConfig>();
  const [enableConfirm, setEnableConfirm] = useState(false);
  const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState(false);
  const [openSalesModal, setOpenSalesModal] = useState(false);
  const {run: runApiFetchPeripherals} = useOpenApi(
    PeripheralsApi,
    'clientPeripheralsGet',
    [{}],
  );
  const {run: putPeripheral} = useOpenApi(
    PeripheralsApi,
    'clientPeripheralsPut',
  );
  const {run: getPeripheralTypes} = useOpenApi(
    PeripheralTypesApi,
    'clientPeripheralsTypesGet',
    [],
  );
  const {
    loading: deletePeripheralLoading,
    run: deletePeripheralFromSession,
  } = useOpenApiSession(
    SessionPeripheralsApi,
    'clientCacheSessionsSessionIdPeripheralsPeripheralIdDelete',
  );
  const {loading, run: updatePeripheral} = useOpenApiSession(
    SessionPeripheralsApi,
    'clientCacheSessionsSessionIdPeripheralsPut',
  );

  assert(
    session !== null,
    'Internal Error: tried to render Settings without a session',
  );

  const {site, startingCashBalance, station, till} = session;

  useEffect(() => {
    if (site) {
      if (!peripherals.length || !peripheralTypes.length) {
        fetchSiteDependencies(site);
      }
    }
  }, []);

  useEffect(() => {
    if (peripheralTypes.length) {
      const paymentDevice = findSessionPaymentDevice(session, peripheralTypes);
      // If we can find a payment device and the device is not already
      // in the list of peripherals set the peripherals and session payment device
      if (
        paymentDevice &&
        !peripherals.find(p => p.peripheralId === paymentDevice.peripheralId)
      ) {
        // Adding session payment device to list of peripherals
        // since we're only getting list of unassigned peripherals.
        // This is so users can update the IP address of the session payment device.
        setPeripherals([...peripherals, paymentDevice]);
      }
      const _config = {
        ...config,
        site,
        station: {...(station as Station)},
        till,
        paymentDevice,
        tillStartingAmount: startingCashBalance || 0,
      };
      setConfig(_config);
      setOriginalConfig(_config);
    }
  }, [peripherals, peripheralTypes, session]);

  useEffect(() => {
    setEnableConfirm(!isEqual(originalConfig, config));
  }, [config]);

  const addSessionPaymentDevice = async (paymentDevice: Peripheral) => {
    let sessionPeripherals: SessionPeripheral[] = [];
    // Skip adding peripheral to session if 'None' is selected
    if (paymentDevice !== NONE_PERIPHERAL) {
      // Add new payment device
      sessionPeripherals = await Promise.all(
        [paymentDevice].map(async peripheral => {
          const sessionPeripheral = await updatePeripheral({
            sessionId: session.sessionId,
            sessionPeripheralRequest: peripheral,
          });
          // Assign the peripheral as if it were returned from the PUT request.
          return {...sessionPeripheral, peripheral};
        }),
      );
    }
    return sessionPeripherals;
  };

  const updatePaymentDevice = async (paymentDevice: Peripheral) => {
    assert(
      session !== null,
      'Internal Error: called updatePaymentDevice() with no active session.',
    );
    let sessionPeripherals: SessionPeripheral[] = [];
    try {
      const sessionPaymentDevice = findSessionPaymentDevice(
        session,
        peripheralTypes,
      );
      if (sessionPaymentDevice) {
        // Remove previous payment device if session payment device is changed
        if (paymentDevice.peripheralId !== sessionPaymentDevice.peripheralId) {
          await deletePeripheralFromSession({
            sessionId: session.sessionId,
            peripheralId: sessionPaymentDevice.peripheralId,
          });
          sessionPeripherals = await addSessionPaymentDevice(paymentDevice);
        } else {
          // Update the current payment device with new values
          const result = await putPeripheral({
            peripheralRequest: paymentDevice,
          });
          const updatedPaymentDevice = {
            ...paymentDevice,
            dataVersion: result.dataVersion,
          };
          setConfig({
            site: config.site as Site,
            station: config.station as Station,
            paymentDevice: updatedPaymentDevice,
            till: config.till,
            tillStartingAmount: config.tillStartingAmount,
          });
          setPeripherals(prevPeripherals =>
            prevPeripherals.map(p =>
              p.peripheralId === paymentDevice.peripheralId
                ? updatedPaymentDevice
                : p,
            ),
          );
          // Update current session payment device with result
          sessionPeripherals = session.sessionPeripherals.map(sp =>
            sp.peripheral?.peripheralId === paymentDevice.peripheralId
              ? {...sp, peripheral: updatedPaymentDevice}
              : sp,
          );
        }
      } else {
        // Add session payment device if there was none before.
        sessionPeripherals = await addSessionPaymentDevice(paymentDevice);
      }
      notification({
        type: 'success',
        icon: 'Checkmark',
        title: 'Session Updated',
        description: 'Your changes have been saved and updated successfully.',
      });
    } catch (error) {
      notification({
        type: 'error',
        icon: 'X',
        title: 'Session Could Not Be Updated',
        description: 'Your changes could not be saved. Please try again.',
      });
      return;
    }

    setSession({
      ...session,
      sessionPeripherals,
    });
  };

  const onCloseSession = () => {
    if (!canClose) {
      setOpenSalesModal(true);
      return;
    }
    setOpenConfirmCloseModal(true);
  };

  const onConfirm = async (event: FormEvent) => {
    event.preventDefault();
    if (isSessionConfigCompleted(config) && !!config.paymentDevice) {
      await updatePaymentDevice(config.paymentDevice);
    }
  };

  const loadPeripherals = async (site: Site) => {
    const newPeripherals = await runApiFetchPeripherals({
      siteId: site.siteId,
      includeAssigned: false,
    });
    if (newPeripherals) {
      setPeripherals(newPeripherals);
    }
  };

  const loadPeripheralTypes = async () => {
    const peripheralTypes = await getPeripheralTypes();
    if (peripheralTypes) {
      setPeripheralTypes(peripheralTypes);
    }
  };

  const fetchSiteDependencies = async (site: Site) => {
    try {
      await loadPeripherals(site);
      await loadPeripheralTypes();
    } catch (error) {
      notification({
        type: 'warning',
        icon: 'Warning',
        title: 'Failed To Load Site Info',
        description:
          'Something went wrong while fetching site details. Please check your internet connection and try reloading the app.',
      });
    }
  };

  return (
    <>
      <Stack
        as="form"
        onSubmit={onConfirm}
        gutter={Gutter.None}
        style={{height: '100%'}}
      >
        <FixedHeader>
          <Header title="My Session" />
        </FixedHeader>
        <ScrollContainer style={{paddingBottom: 16}}>
          <CreateSession
            configSession
            loading={closeSessionLoading || loading || deletePeripheralLoading}
            sites={[site]}
            stations={[{...(station as Station)}]}
            config={config}
            onChange={setConfig}
            peripherals={peripherals}
            tills={[till]}
          />
        </ScrollContainer>
        <FooterGroup>
          <Button
            type="button"
            variant={BV.Danger}
            flex
            onClick={onCloseSession}
            loading={closeSessionLoading}
          >
            Close Session
          </Button>
          <Button
            flex
            type="submit"
            loading={loading || deletePeripheralLoading}
            disabled={!enableConfirm}
          >
            Confirm
          </Button>
        </FooterGroup>
      </Stack>
      <Modal
        visible={openConfirmCloseModal}
        data-testid="Modal__ConfirmClose"
        icon="CashRegister"
        color="primary"
        title="Close My Session"
        subtitle="Please confirm you want to close your session. You cannot reopen this session once closed."
        buttonText="Confirm"
        onCancel={() => setOpenConfirmCloseModal(false)}
        onContinue={closeSession}
      />
      <Modal
        visible={openSalesModal}
        data-testid="Modal__OpenSales"
        icon="Warning"
        color="error"
        title="Session Has Open or Unsynced Sales"
        subtitle="Your session still has sales that are open or unsynced. Please complete or resync all transactions first."
        buttonText="Okay"
        onContinue={() => setOpenSalesModal(false)}
      />
    </>
  );
}

export default memo(SettingsHome);
