import {
  Peripheral,
  PeripheralsApi,
  Site,
  Station,
  StationsApi,
  TillsApi,
} from '@emporos/api-enterprise/src/gen';
import {CashDrawer} from '@emporos/api-enterprise/src/gen-session';
import {Page, PageWrapper} from '@emporos/components';
import {Navbar} from '@emporos/components-pos';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import useOpenApi from '../../../api/useOpenApi';
import {AuthClaim} from '../../../auth/const';
import {useAlertState} from '../../../contexts/AlertStateProvider';
import {useAuthentication} from '../../../contexts/AuthenticationProvider';
import {useSyncSession} from '../../../contexts/SyncSessionProvider';
import {useTransactionsConfig} from '../../../contexts/TransactionsConfigProvider';
import CreateSession, {
  isSessionConfigCompleted,
  NONE_PERIPHERAL,
  SessionConfig,
} from './CreateSession';
import SessionSidebar from './SessionSidebar';

const MainWrapper = styled.div`
  display: flex;
  height: calc(100vh - 68px);
  height: calc(var(--vh, 1vh) * 100 - 68px);
`;

function CreateSessionContainer(_props: RouteComponentProps) {
  const {user, logout} = useAuthentication();
  const {sessionStatus, syncSession} = useSyncSession();
  const {
    createSession,
    createSessionLoading,
    peripherals,
    setPeripherals,
    sites,
  } = useTransactionsConfig();
  const {notification} = useAlertState();
  const [config, setConfig] = useState<SessionConfig>({
    site: null,
    station: null,
    paymentDevice: null,
    till: null,
    tillStartingAmount: null,
  });
  const [stations, setStations] = useState<Station[]>([]);
  const [tills, setTills] = useState<CashDrawer[]>([]);
  const {run: getStations} = useOpenApi(StationsApi, 'clientStationsSiteIdGet');
  const {run: getPeripherals} = useOpenApi(
    PeripheralsApi,
    'clientPeripheralsGet',
  );
  const {run: putPeripherals} = useOpenApi(
    PeripheralsApi,
    'clientPeripheralsPut',
  );
  const {run: runApiFetchTills} = useOpenApi(TillsApi, 'clientTillsGet');

  const updatePeripheral = async (paymentDevice: Peripheral) => {
    let result: Peripheral;
    if (paymentDevice !== NONE_PERIPHERAL) {
      try {
        result = await putPeripherals({
          peripheralRequest: paymentDevice,
        });
        setPeripherals(prevPeripherals =>
          prevPeripherals.map(p =>
            p.peripheralId === paymentDevice.peripheralId
              ? {...paymentDevice, dataVersion: result.dataVersion}
              : p,
          ),
        );
        return result;
      } catch (e) {
        notification({
          type: 'error',
          icon: 'X',
          title: 'Update Payment Device Failed',
          description:
            "We couldn't update your payment device with your selections.",
        });
        return;
      }
    }
  };

  const onConfirm = useCallback(async () => {
    if (!isSessionConfigCompleted(config)) {
      return;
    }
    const {site, station, till, paymentDevice, tillStartingAmount} = config;
    const result = await updatePeripheral(paymentDevice);
    const updatedPaymentDevice = result
      ? {
          ...paymentDevice,
          dataVersion: result?.dataVersion,
        }
      : undefined;
    return createSession(
      site,
      station,
      till,
      tillStartingAmount,
      updatedPaymentDevice,
    );
  }, [config]);

  const fetchSiteDependencies = async (site: Site) => {
    const {data: stations} = await getStations({
      siteId: site.siteId,
    });
    const peripherals = await getPeripherals({
      siteId: site.siteId,
      includeAssigned: false,
    });
    const {data: tills} = await runApiFetchTills({
      siteId: site.siteId,
    });

    if (!(stations && peripherals && tills)) {
      notification({
        type: 'warning',
        icon: 'Warning',
        title: 'Failed To Load Site Info',
        description:
          'Something went wrong while fetching site details. Please check your internet connection and try reloading the app.',
      });
      return;
    }

    setStations(stations);
    setPeripherals(peripherals);
    setTills(tills);
    setConfig({
      ...config,
      site,
      station: null,
      till: null,
      paymentDevice: null,
      tillStartingAmount: null,
    });
  };

  useEffect(() => {
    if (config.site) {
      fetchSiteDependencies(config.site);
    }
  }, [config.site]);

  assert(
    user !== null,
    'Internal Error: tried to render CreateSession without an authenticated user',
  );

  return (
    <>
      <Navbar
        username={user.profile[AuthClaim.Name]}
        onSessionStatus={syncSession}
        sessionStatus={sessionStatus}
        isLoadingData={false}
        onLogout={logout}
        hideSessionStatus={true}
      />
      <MainWrapper>
        <SessionSidebar
          fullName={user.profile[AuthClaim.Name]}
          site={config.site}
          station={config.station}
          till={config.till}
          paymentDevice={config.paymentDevice}
        />
        <PageWrapper style={{backgroundColor: 'transparent'}}>
          <Page level={1}>
            <CreateSession
              loading={createSessionLoading}
              sites={sites}
              stations={stations}
              config={config}
              onChange={setConfig}
              peripherals={peripherals}
              tills={tills}
              onLogout={logout}
              onConfirm={onConfirm}
            />
          </Page>
        </PageWrapper>
      </MainWrapper>
    </>
  );
}

export default memo(CreateSessionContainer);
