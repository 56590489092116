/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    CustomerAccount,
    CustomerAccountFromJSON,
    CustomerAccountFromJSONTyped,
    CustomerAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerAccountApiResponse
 */
export interface CustomerAccountApiResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof CustomerAccountApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {CustomerAccount}
     * @memberof CustomerAccountApiResponse
     */
    data?: CustomerAccount;
}

export function CustomerAccountApiResponseFromJSON(json: any): CustomerAccountApiResponse {
    return CustomerAccountApiResponseFromJSONTyped(json, false);
}

export function CustomerAccountApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAccountApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : CustomerAccountFromJSON(json['data']),
    };
}

export function CustomerAccountApiResponseToJSON(value?: CustomerAccountApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': CustomerAccountToJSON(value.data),
    };
}


