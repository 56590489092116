import {PaymentType} from '@emporos/api-enterprise/src/gen';
import {InvoicePayment} from '@emporos/api-enterprise/src/gen-session';
import {AllIcons, ColorType, Icons} from '@emporos/components';
import {memoize} from 'lodash';

export type AcceptedPaymentTypes =
  | 'Cash'
  | 'Check'
  | 'Credit Card'
  | 'PD'
  | 'AR'
  | 'UDP'
  | '<unknown>';

// TODO: does this mapping come from an API response we need to account for?
const mapDescriptorToType: Record<string, string> = {
  Cash: 'Cash',
  Check: 'Check',
  Visa: 'Credit Card',
  Disc: 'Credit Card',
  Amex: 'Credit Card',
  MC: 'Credit Card',
  AR: 'AR',
  'AR 3rd Party': 'AR',
  'AR On Account': 'AR',
  'AR INS Charge': 'AR',
  'AR DME Charge': 'AR',
  'Payroll Deduct': 'PD',
  User1: 'UDP',
  User2: 'UDP',
  User3: 'UDP',
  User4: 'UDP',
  User5: 'UDP',
  User6: 'UDP',
  Debit: 'Credit Card',
};
/* istanbul ignore next  */
function assertUnreachable(_x: never): never {
  throw new TypeError(
    'This should be unreachable. If you experience this please contact the Emporos customer support team.',
  );
}
const paymentTypes = memoize(function paymentTypes(
  paymentTenders: PaymentType[],
) {
  return paymentTenders.reduce((map, current) => {
    map.set(
      current.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mapDescriptorToType[current.paymentDescriptor] || '<unknown>',
    );
    return map;
  }, new Map<number, AcceptedPaymentTypes>());
});

export function getPaymentType(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): AcceptedPaymentTypes {
  const types = paymentTypes(paymentTenders);
  return types.get(paymentTypeId) || '<unknown>';
}

export function getPaymentIcon(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): keyof typeof AllIcons {
  const types = paymentTypes(paymentTenders);
  const type = types.get(paymentTypeId) || '<unknown>';
  switch (type) {
    case 'Cash':
      return 'Cash';
    case 'AR':
      return 'Bill';
    case 'Check':
      return 'Check';
    case 'Credit Card':
      switch (getPaymentDescriptor(paymentTypeId, paymentTenders)) {
        case 'MC':
          return 'Mastercard';
        case 'Disc':
          return 'Discover';
        case 'Amex':
          return 'Amex';
        case 'Visa':
          return 'Visa';
        case 'Debit':
          return 'CreditCard';
        default:
          return 'CreditCard';
      }
    case 'PD':
      return 'IdCard';
    case 'UDP':
      return 'MoneyBag';
    case '<unknown>':
      return 'MoneyBag';
    /* istanbul ignore next */
    default:
      assertUnreachable(type);
  }
}

export function getSimplePaymentIcon(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): keyof typeof Icons {
  const types = paymentTypes(paymentTenders);
  const type = types.get(paymentTypeId) || '<unknown>';
  switch (type) {
    case 'Cash':
      return 'Cash';
    case 'AR':
      return 'Bill';
    case 'Check':
      return 'Check';
    case 'Credit Card':
      return 'CreditCard';
    case 'PD':
      return 'IdCard';
    case 'UDP':
      return 'MoneyBag';
    case '<unknown>':
      return 'MoneyBag';
    /* istanbul ignore next */
    default:
      assertUnreachable(type);
  }
}

export function getPaymentIconType(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): ColorType {
  const types = paymentTypes(paymentTenders);
  const type = types.get(paymentTypeId) || '<unknown>';
  switch (type) {
    case 'Cash':
      return 'success';
    case 'Check':
      return 'purple';
    case 'Credit Card':
      return 'primary';
    case 'PD':
    case 'AR':
      return 'indigo';
    case 'UDP':
      return 'warning';
    case '<unknown>':
      return 'warning';
    /* istanbul ignore next */
    default:
      assertUnreachable(type);
  }
}

export function getPaymentTypeId(
  type: AcceptedPaymentTypes,
  paymentTenders: PaymentType[],
): number | undefined {
  const descriptor = Object.keys(mapDescriptorToType).find(
    key => mapDescriptorToType[key] === type,
  );
  return paymentTenders.find(({paymentDescriptor}) => {
    return paymentDescriptor === descriptor;
  })?.id;
}

export function getPaymentDescriptor(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): string | null | undefined {
  return paymentTenders.find(({id}) => {
    return id === paymentTypeId;
  })?.paymentDescriptor;
}

export function getPaymentTypeIdByCardType(
  cardType: string,
  paymentTenders: PaymentType[],
): number | undefined {
  switch (cardType) {
    case '1':
      return getPaymentTypeIdByDescriptor('Amex', paymentTenders);
    case '2':
      return getPaymentTypeIdByDescriptor('Disc', paymentTenders);
    case '3':
      return getPaymentTypeIdByDescriptor('MC', paymentTenders);
    case '4':
      return getPaymentTypeIdByDescriptor('Visa', paymentTenders);
    case '5':
      return getPaymentTypeIdByDescriptor('Debit', paymentTenders);
    default:
      return undefined;
  }
}

export function getPaymentTypeIdByPaymentType(
  type: string,
  paymentTenders: PaymentType[],
): number | undefined {
  return paymentTenders.find(({paymentType}) => paymentType === type)?.id;
}

export function getPaymentTypeIdByDescriptor(
  descriptor: string,
  paymentTenders: PaymentType[],
): number | undefined {
  return paymentTenders.find(
    ({paymentDescriptor}) => paymentDescriptor === descriptor,
  )?.id;
}

export function displayPaymentNumber(
  {paymentTypeID, paymentNumber, amountReturned}: InvoicePayment,
  paymentTenders: PaymentType[],
): string {
  if (!paymentTypeID) {
    return '';
  }

  const paymentType = getPaymentType(paymentTypeID, paymentTenders);
  switch (paymentType) {
    case 'Cash':
      return `Change: $${(amountReturned || 0).toFixed(2)}`;
    case 'Credit Card':
      const descriptor = getPaymentDescriptor(paymentTypeID, paymentTenders);
      if (descriptor) {
        return [descriptor, paymentNumber].join(' - ');
      }
      return '';
    default:
      return paymentNumber || '';
  }
}
