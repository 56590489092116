/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerApiResponse,
    CustomerApiResponseFromJSON,
    CustomerApiResponseToJSON,
    CustomerSearchCriteria,
    CustomerSearchCriteriaFromJSON,
    CustomerSearchCriteriaToJSON,
    CustomerSearchMatchListApiResponse,
    CustomerSearchMatchListApiResponseFromJSON,
    CustomerSearchMatchListApiResponseToJSON,
} from '../models';

export interface ClientCustomersCustomerCodePatchRequest {
    customerCode: string;
    siteId?: number;
    stationId?: number;
}

export interface ClientCustomersCustomerIdGetRequest {
    customerId: number;
}

export interface ClientCustomersEmployeeGetRequest {
    employeeNumber?: string;
}

export interface ClientCustomersGetRequest {
    siteId: number;
    stationId: number;
    includesChildren?: boolean;
    includesDeleted?: boolean;
    sort?: Array<string>;
    page?: number;
    pageSize?: number;
    name?: string;
    dob?: Date;
    phone?: string;
}

export interface ClientCustomersSearchPostRequest {
    siteId: number;
    stationId: number;
    page?: number;
    pageSize?: number;
    customerSearchCriteria?: CustomerSearchCriteria;
}

/**
 * 
 */
export class CustomersApi extends runtime.BaseAPI {

    /**
     * Synchronizes and returns PMS customer.
     */
    async clientCustomersCustomerCodePatchRaw(requestParameters: ClientCustomersCustomerCodePatchRequest): Promise<runtime.ApiResponse<CustomerApiResponse>> {
        if (requestParameters.customerCode === null || requestParameters.customerCode === undefined) {
            throw new runtime.RequiredError('customerCode','Required parameter requestParameters.customerCode was null or undefined when calling clientCustomersCustomerCodePatch.');
        }

        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/customers/{customerCode}`.replace(`{${"customerCode"}}`, encodeURIComponent(String(requestParameters.customerCode))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerApiResponseFromJSON(jsonValue));
    }

    /**
     * Synchronizes and returns PMS customer.
     */
    async clientCustomersCustomerCodePatch(requestParameters: ClientCustomersCustomerCodePatchRequest): Promise<CustomerApiResponse> {
        const response = await this.clientCustomersCustomerCodePatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets a customer.
     */
    async clientCustomersCustomerIdGetRaw(requestParameters: ClientCustomersCustomerIdGetRequest): Promise<runtime.ApiResponse<CustomerApiResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling clientCustomersCustomerIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets a customer.
     */
    async clientCustomersCustomerIdGet(requestParameters: ClientCustomersCustomerIdGetRequest): Promise<CustomerApiResponse> {
        const response = await this.clientCustomersCustomerIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets CustomerDtos.
     */
    async clientCustomersEmployeeGetRaw(requestParameters: ClientCustomersEmployeeGetRequest): Promise<runtime.ApiResponse<CustomerApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.employeeNumber !== undefined) {
            queryParameters['employeeNumber'] = requestParameters.employeeNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/customers/employee`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets CustomerDtos.
     */
    async clientCustomersEmployeeGet(requestParameters: ClientCustomersEmployeeGetRequest): Promise<CustomerApiResponse> {
        const response = await this.clientCustomersEmployeeGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets Customers.
     */
    async clientCustomersGetRaw(requestParameters: ClientCustomersGetRequest): Promise<runtime.ApiResponse<CustomerSearchMatchListApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientCustomersGet.');
        }

        if (requestParameters.stationId === null || requestParameters.stationId === undefined) {
            throw new runtime.RequiredError('stationId','Required parameter requestParameters.stationId was null or undefined when calling clientCustomersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includesChildren !== undefined) {
            queryParameters['IncludesChildren'] = requestParameters.includesChildren;
        }

        if (requestParameters.includesDeleted !== undefined) {
            queryParameters['IncludesDeleted'] = requestParameters.includesDeleted;
        }

        if (requestParameters.sort) {
            queryParameters['Sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        if (requestParameters.dob !== undefined) {
            queryParameters['Dob'] = (requestParameters.dob as any).toISOString();
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['Phone'] = requestParameters.phone;
        }

        if (requestParameters.siteId !== undefined) {
            queryParameters['SiteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['StationId'] = requestParameters.stationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerSearchMatchListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets Customers.
     */
    async clientCustomersGet(requestParameters: ClientCustomersGetRequest): Promise<CustomerSearchMatchListApiResponse> {
        const response = await this.clientCustomersGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns matching Customers.
     */
    async clientCustomersSearchPostRaw(requestParameters: ClientCustomersSearchPostRequest): Promise<runtime.ApiResponse<CustomerSearchMatchListApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientCustomersSearchPost.');
        }

        if (requestParameters.stationId === null || requestParameters.stationId === undefined) {
            throw new runtime.RequiredError('stationId','Required parameter requestParameters.stationId was null or undefined when calling clientCustomersSearchPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/customers/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerSearchCriteriaToJSON(requestParameters.customerSearchCriteria),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerSearchMatchListApiResponseFromJSON(jsonValue));
    }

    /**
     * Returns matching Customers.
     */
    async clientCustomersSearchPost(requestParameters: ClientCustomersSearchPostRequest): Promise<CustomerSearchMatchListApiResponse> {
        const response = await this.clientCustomersSearchPostRaw(requestParameters);
        return await response.value();
    }

}
