/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StationListApiResponse,
    StationListApiResponseFromJSON,
    StationListApiResponseToJSON,
} from '../models';

export interface ClientStationsGetRequest {
    includesChildren?: boolean;
    includesDeleted?: boolean;
    sort?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ClientStationsSiteIdGetRequest {
    siteId: number;
    includesChildren?: boolean;
    includesDeleted?: boolean;
    sort?: Array<string>;
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class StationsApi extends runtime.BaseAPI {

    /**
     * Gets Mobile Web Stations.
     */
    async clientStationsGetRaw(requestParameters: ClientStationsGetRequest): Promise<runtime.ApiResponse<StationListApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.includesChildren !== undefined) {
            queryParameters['IncludesChildren'] = requestParameters.includesChildren;
        }

        if (requestParameters.includesDeleted !== undefined) {
            queryParameters['IncludesDeleted'] = requestParameters.includesDeleted;
        }

        if (requestParameters.sort) {
            queryParameters['Sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/stations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StationListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets Mobile Web Stations.
     */
    async clientStationsGet(requestParameters: ClientStationsGetRequest): Promise<StationListApiResponse> {
        const response = await this.clientStationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets unused Mobile Web stations for a site.
     */
    async clientStationsSiteIdGetRaw(requestParameters: ClientStationsSiteIdGetRequest): Promise<runtime.ApiResponse<StationListApiResponse>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling clientStationsSiteIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includesChildren !== undefined) {
            queryParameters['IncludesChildren'] = requestParameters.includesChildren;
        }

        if (requestParameters.includesDeleted !== undefined) {
            queryParameters['IncludesDeleted'] = requestParameters.includesDeleted;
        }

        if (requestParameters.sort) {
            queryParameters['Sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/stations/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StationListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets unused Mobile Web stations for a site.
     */
    async clientStationsSiteIdGet(requestParameters: ClientStationsSiteIdGetRequest): Promise<StationListApiResponse> {
        const response = await this.clientStationsSiteIdGetRaw(requestParameters);
        return await response.value();
    }

}
