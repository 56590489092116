import {Page, PageWrapper, SidebarWrapper} from '@emporos/components';
import {Navbar} from '@emporos/components-pos';
import {Redirect, RouteComponentProps, useLocation} from '@reach/router';
import * as React from 'react';
import {useEffect} from 'react';
import {animated, useSpring} from 'react-spring';
import styled from 'styled-components';
import {AuthClaim} from '../auth/const';
import {useAlertState} from '../contexts/AlertStateProvider';
import {useAuthentication} from '../contexts/AuthenticationProvider';
import {useGlobalData} from '../contexts/GlobalDataProvider';
import {NavigationLogTypes, useLog} from '../contexts/LoggingProvider';
import {useSyncSession} from '../contexts/SyncSessionProvider';
import {useTransactionsConfig} from '../contexts/TransactionsConfigProvider';
import {useTransactionsState} from '../contexts/TransactionsStateProvider';
import Sidebar from './Sidebar';

const MainWrapper = styled.div`
  display: flex;
  height: calc(100vh - 68px);
  height: calc(var(--vh, 1vh) * 100 - 68px);
`;

interface Props {
  children?: React.ReactElement | React.ReactElement[];
}

export default function MainLayout({
  children,
}: RouteComponentProps & Props): JSX.Element {
  const {logout, user} = useAuthentication();
  const {logUserSelection} = useLog();
  const {loading} = useGlobalData();
  const {reset} = useAlertState();
  const {syncing, syncSession, sessionStatus} = useSyncSession();
  const {hardLoadingSession} = useTransactionsConfig();
  const transition = useSpring({opacity: 1, from: {opacity: 0}});
  const {pathname} = useLocation();
  const {currentInvoiceId} = useTransactionsState();

  useEffect(() => {
    reset();
  }, [location]);

  useEffect(() => {
    logUserSelection(NavigationLogTypes.UserNavigating, {
      url: location.pathname,
    });
  }, [location.pathname]);

  if (!user) {
    return <Redirect to="/login" noThrow />;
  }

  if (!currentInvoiceId && /payments/.test(pathname)) {
    return <Redirect to="/sales" noThrow />;
  }

  return (
    <>
      <Navbar
        username={user?.profile[AuthClaim.Name] || ''}
        onSessionStatus={syncSession}
        sessionStatus={sessionStatus}
        onLogout={logout}
        isLoadingData={loading || syncing || hardLoadingSession}
      />
      <MainWrapper>
        <SidebarWrapper
          as={animated.div}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          style={transition as any}
        >
          <Sidebar />
        </SidebarWrapper>
        <PageWrapper style={{backgroundColor: 'transparent'}}>
          <Page open>{children}</Page>
        </PageWrapper>
      </MainWrapper>
    </>
  );
}
