import {startCase, toLower} from 'lodash';
import React, {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
//TODO: import button Variant when uncommenting Remove button
import {Button, Gutter, IndicatorCircle, Row, Size, Stack} from './';
import Text, {Variant} from './Text';

interface CardProps {
  onAddClick?: () => void;
  onRemoveClick?: () => void;
  size?: 'small' | 'default';
  disabled?: boolean;
  loading?: boolean;
  customerInfo?: CustomerProps;
  receiptInfo?: ReceiptProps;
  variant?: 'customer' | 'receipt';
}

export interface CustomerProps {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  mrn?: string;
  address?: string;
  phone?: string;
}

// TODO: actual property names
interface ReceiptProps {
  transactionNumber?: string;
  date?: string;
  total?: string;
  paymentType?: string;
  customer?: string;
}

const StyledCard = styled(Stack)<CardProps>`
  display: ${({size}) => (size === 'small' ? 'inline-block' : 'block')};
  padding: 16px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.colors.gray_100};
  width: ${({size}) => (size === 'small' ? 'auto' : '100%')};
  border: ${({theme}) => `2px solid ${theme.colors.gray_200}`};
`;

function formatName(firstName: string = '', lastName: string = '') {
  return (
    startCase(toLower(firstName)) +
    ' ' +
    startCase(toLower(lastName))
  ).trim();
}

// TODO: clean up test ids
export const Card = memo(
  ({
    customerInfo,
    receiptInfo,
    onAddClick,
    onRemoveClick,
    disabled,
    size = 'default',
    variant = 'customer',
    loading = false,
    ...props
  }: CardProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const showAddButton =
      (variant === 'receipt'
        ? !receiptInfo?.transactionNumber
        : !(customerInfo?.firstName && customerInfo?.lastName)) &&
      onAddClick &&
      size === 'default';
    return (
      <StyledCard data-testid="customer-card" size={size} {...props}>
        <Row gutter={Gutter.None} justify="space-between" noWrap>
          <Stack>
            <Text variant={Variant.T3} data-testid="customercard-name">
              {variant === 'receipt'
                ? (receiptInfo?.transactionNumber &&
                    `#${receiptInfo?.transactionNumber}`) ||
                  'No Receipt'
                : ((customerInfo?.firstName || customerInfo?.lastName) &&
                    formatName(
                      customerInfo?.firstName,
                      customerInfo?.lastName,
                    )) ||
                  'No Customer'}
            </Text>
          </Stack>
          <Stack align="end">
            {showAddButton && (
              <Button
                disabled={disabled}
                icon="PlusCircle"
                size={Size.Small}
                type="button"
                onClick={onAddClick}
                loading={loading}
              >
                {variant === 'receipt' ? 'Find Receipt' : 'Add Customer'}
              </Button>
            )}
            {/* TODO: temporarily hidden until ready to implement functionality */}
            {/* {!showAddButton && size !== 'small' && (
            <Button
              variant={V.Danger}
              size={Size.Small}
              style={{border: '2px solid #E6E7E8'}}
              // gray3
              onClick={onRemoveClick}
            >
              Remove
            </Button>
          )} */}
          </Stack>
        </Row>
        <Row gutter={Gutter.None} style={{marginTop: 0}}>
          <Stack
            gutter={Gutter.S}
            style={{marginRight: '2.25rem', marginTop: '.75rem'}}
          >
            <Row gutter={Gutter.XS} align="center" noWrap>
              <IndicatorCircle
                size="small"
                variant="gray"
                icon={variant === 'receipt' ? 'Calendar' : 'Birthday'}
              />
              <Text
                variant={Variant.SubMainLight}
                style={{whiteSpace: 'nowrap'}}
                data-testid="customercard-dob"
              >
                {variant === 'receipt'
                  ? receiptInfo?.date || 'N/A'
                  : customerInfo?.dateOfBirth || 'N/A'}
              </Text>
            </Row>
            {size !== 'small' && (
              <Row gutter={Gutter.XS} align="center" noWrap>
                <IndicatorCircle
                  size="small"
                  variant="gray"
                  icon={variant === 'receipt' ? 'CashRegister' : 'IdCard'}
                />
                <Text
                  variant={Variant.SubMainLight}
                  data-testid="customercard-mrn"
                >
                  {variant === 'receipt'
                    ? receiptInfo?.paymentType || 'N/A'
                    : customerInfo?.mrn || 'N/A'}
                </Text>
              </Row>
            )}
          </Stack>
          {size !== 'small' && (
            <Stack gutter={Gutter.S} style={{marginTop: '.75rem'}}>
              <Row gutter={Gutter.XS} align="center" noWrap>
                <IndicatorCircle
                  size="small"
                  variant="gray"
                  icon={variant === 'receipt' ? 'Cash' : 'Home'}
                />
                <Text
                  variant={Variant.SubMainLight}
                  data-testid="customercard-address"
                >
                  {variant === 'receipt'
                    ? (receiptInfo?.total && `$${receiptInfo?.total}`) || 'N/A'
                    : customerInfo?.address || 'N/A'}
                </Text>
              </Row>
              <Row gutter={Gutter.XS} align="center" noWrap>
                <IndicatorCircle
                  size="small"
                  variant="gray"
                  icon={variant === 'receipt' ? 'User' : 'Phone'}
                />
                <Text
                  variant={Variant.SubMainLight}
                  data-testid="customercard-phone"
                >
                  {variant === 'receipt'
                    ? receiptInfo?.customer || 'N/A'
                    : customerInfo?.phone || 'N/A'}
                </Text>
              </Row>
            </Stack>
          )}
        </Row>
      </StyledCard>
    );
  },
);
