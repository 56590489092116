import {Invoice, Session} from '@emporos/api-enterprise/src/gen-session';
import assert from 'assert';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import {useTransactionsConfig} from './TransactionsConfigProvider';

export type {Session, Invoice};

export type SessionUpdates =
  | Partial<Session>
  | ((prevSession: Session) => Partial<Session>);

export type InvoiceUpdates =
  | Partial<Invoice>
  | ((prevInvoice: Invoice) => Partial<Invoice>);
export interface Context {
  currentTransactionIndex?: number;
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  currentInvoiceId: string;
  setCurrentInvoiceId: Dispatch<SetStateAction<string>>;
  selectedPayment: string;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

export const TransactionsContext = createContext<Context>({
  session: (null as unknown) as Session,
  setSession: noop,
  currentInvoiceId: '',
  setCurrentInvoiceId: noop,
  selectedPayment: '',
  setSelectedPayment: noop,
});

export function TransactionsStateProvider(props: {
  children?: React.ReactNode;
}): JSX.Element {
  const {session, setSession} = useTransactionsConfig();
  const [currentInvoiceId, setCurrentInvoiceId] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');

  assert(
    session !== null,
    'Internal Error: rendered session app tree without active session',
  );

  return (
    <TransactionsContext.Provider
      value={{
        session,
        setSession: setSession as Dispatch<SetStateAction<Session>>,
        currentInvoiceId,
        setCurrentInvoiceId,
        selectedPayment,
        setSelectedPayment,
      }}
    >
      {props.children}
    </TransactionsContext.Provider>
  );
}

export const useTransactionsState = (): Context =>
  useContext(TransactionsContext);
