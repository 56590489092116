import {PaymentType} from '@emporos/api-enterprise/src/gen';
import {Row, Select, Stack} from '@emporos/components';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import BillBoard from './BillBoard';
import {PaymentRef as Handle} from './Types';

interface Props {
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
  setPaymentTypeId: (v: number) => void;
  udps: Array<PaymentType>;
  onChangeActivePayment(): void;
}

export default forwardRef<Handle, Props>(function UDPayment(
  {amount, setAmount, totalDue, setPaymentTypeId, udps, onChangeActivePayment},
  ref,
): JSX.Element {
  const [id, setId] = useState<number | null>(null);

  useImperativeHandle(ref, () => ({
    async onConfirmPayment() {
      return null;
    },
    isAcceptablePayment() {
      return !!id;
    },
  }));

  useEffect(() => {
    onChangeActivePayment();
  }, [id]);

  return (
    <Stack style={{flex: 1}}>
      <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
      <Row style={{marginTop: 36}}>
        <Select
          name="udp"
          options={udps.map(t => t?.id)}
          optionsText={udps.map(t => t?.paymentType || 'Undefined')}
          value={id}
          onChangeValue={value => {
            setPaymentTypeId(value);
            setId(value);
          }}
          label="User Defined Payments"
        />
      </Row>
    </Stack>
  );
});
