/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Agent,
    AgentFromJSON,
    AgentFromJSONTyped,
    AgentToJSON,
    PseCheckResult,
    PseCheckResultFromJSON,
    PseCheckResultFromJSONTyped,
    PseCheckResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface PseCheckResponse
 */
export interface PseCheckResponse {
    /**
     * 
     * @type {Array<Agent>}
     * @memberof PseCheckResponse
     */
    agents: Array<Agent>;
    /**
     * 
     * @type {string}
     * @memberof PseCheckResponse
     */
    checkId: string;
    /**
     * 
     * @type {PseCheckResult}
     * @memberof PseCheckResponse
     */
    result: PseCheckResult;
}

export function PseCheckResponseFromJSON(json: any): PseCheckResponse {
    return PseCheckResponseFromJSONTyped(json, false);
}

export function PseCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PseCheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agents': ((json['agents'] as Array<any>).map(AgentFromJSON)),
        'checkId': json['checkId'],
        'result': PseCheckResultFromJSON(json['result']),
    };
}

export function PseCheckResponseToJSON(value?: PseCheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agents': ((value.agents as Array<any>).map(AgentToJSON)),
        'checkId': value.checkId,
        'result': PseCheckResultToJSON(value.result),
    };
}


