import {Redirect, Router} from '@reach/router';
import * as React from 'react';
import NotFound from '../pages/NotFound';
import CompletedTransaction from '../pages/sales/completed';
import Sales from '../pages/sales';
import IdCheck from '../pages/sales/transactions/payments/id-check';
import Compliance from '../pages/sales/transactions/payments/compliance';
import CustomerPayment from '../pages/sales/transactions/payments/customer-payment';
import PaymentInfo from '../pages/sales/transactions/payments/customer-payment/PaymentInfo';
import Acknowledgements from '../pages/sales/transactions/payments/acknowledgements';
import Receipts from '../pages/sales/transactions/payments/receipts';
import CompleteTransaction from '../pages/sales/transactions/payments/complete-transaction';
import AcknowledgementsInfo from '../pages/sales/transactions/payments/acknowledgements/info';
import Transaction from '../pages/sales/transactions';
import OtcSearch from '../pages/sales/transactions/otc-search';
import AddCustomer from '../pages/shared/add-customer';
import {
  CustomerInfo,
  CustomerInfoWillCall,
} from '../pages/shared/customer-info';
import MainLayout from './MainLayout';
import SettingsHome from '../pages/sales/settings';
import SettingsSupport from '../pages/sales/settings/support';
import SettingsDeveloper from '../pages/sales/settings/developer';
import HostedPayment from '../pages/sales/transactions/payments/customer-payment/HostedPayment';

export function Routes(): JSX.Element {
  return (
    <Router>
      <MainLayout path="sales">
        <NotFound default />
        <Sales path="/" />

        <Transaction path="transactions">
          <CustomerInfoWillCall path="customer">
            <AddCustomer path="add-customer" />
          </CustomerInfoWillCall>
          <OtcSearch path="otc-search">
            <AddCustomer path="add-customer" />
          </OtcSearch>

          <IdCheck path="payments/id-check">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
          </IdCheck>
          <Compliance path="payments/compliance">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
          </Compliance>
          <CustomerPayment path="payments/customer-payment">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <AddCustomer path="add-customer" addToAr />
            <PaymentInfo path="payment-info" canVoid={true} />
            <HostedPayment path="hosted-payment" />
          </CustomerPayment>
          <Acknowledgements path="payments/acknowledgements">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <PaymentInfo path="payment-info" canVoid={false} />
            <AcknowledgementsInfo path="info" />
          </Acknowledgements>
          <Receipts path="payments/receipts">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <PaymentInfo path="payment-info" canVoid={false} />
          </Receipts>
          <CompleteTransaction path="payments/complete">
            <CustomerInfoWillCall path="customer-info" canAddCustomer={false} />
            <PaymentInfo path="payment-info" canVoid={false} />
          </CompleteTransaction>
        </Transaction>

        <CompletedTransaction path="completed">
          <CustomerInfo path="customer-info" viewOnly />
        </CompletedTransaction>

        <SettingsHome path="settings" />
        <SettingsSupport path="settings/support" />
        <SettingsDeveloper path="settings/developer" />
      </MainLayout>

      <Redirect from="*" to="/sales" noThrow />
    </Router>
  );
}
