/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Total record count
     * @type {number}
     * @memberof Pagination
     */
    count: number;
    /**
     * Current page
     * @type {number}
     * @memberof Pagination
     */
    page: number;
    /**
     * Total number of pages
     * @type {number}
     * @memberof Pagination
     */
    pages: number;
    /**
     * Used for navigation
     * @type {boolean}
     * @memberof Pagination
     */
    readonly hasPreviousPage: boolean;
    /**
     * used for navigation
     * @type {boolean}
     * @memberof Pagination
     */
    readonly hasNextPage: boolean;
}

export function PaginationFromJSON(json: any): Pagination {
    return PaginationFromJSONTyped(json, false);
}

export function PaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'page': json['page'],
        'pages': json['pages'],
        'hasPreviousPage': json['hasPreviousPage'],
        'hasNextPage': json['hasNextPage'],
    };
}

export function PaginationToJSON(value?: Pagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'page': value.page,
        'pages': value.pages,
    };
}


