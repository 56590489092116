import {memo} from 'react';
import {RouteComponentProps} from '@reach/router';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../../../contexts/AnalyticsProvider';
import {Invoice} from '../../../../../contexts/TransactionsStateProvider';
import assert from 'assert';
import useInvoice from '../../../../../hooks/useInvoice';
import {withChildPage} from '../../../../../hocs/withChildPage';
import {OfflineSynced} from '../../../../../api/common';
import {useConfig} from '../../../../../contexts/ConfigProvider';
import {Receipts} from './Receipt';

export interface EmailForm {
  email: string;
}

function ReceiptsIntegration({navigate}: RouteComponentProps) {
  const {track} = useAnalyticsProvider();
  const {invoice, updateInvoice} = useInvoice();
  const {NodeEnv} = useConfig();

  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<ReceiptsIntegration /> must have a `navigate` prop.' + NodeEnv ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
    assert(invoice, 'Missing invoice');
  }

  const trackReceiptType = (receiptType: string) =>
    track(AnalyticType.ReceiptType, {receiptType});

  const onNext = () => {
    trackReceiptType('none');
    navigate('../complete');
  };
  const onSubmit = (data: EmailForm) => {
    trackReceiptType('email');
    updateInvoice({emailReceipt: data.email, isSynced: false} as Partial<
      Invoice
    > &
      OfflineSynced);

    return navigate('../complete');
  };
  return (
    <Receipts
      invoice={invoice}
      onPrint={() => {
        trackReceiptType('printed');
        return navigate('../complete');
      }}
      onNext={onNext}
      onSubmit={onSubmit}
      navigate={navigate}
    />
  );
}

export default memo(withChildPage(ReceiptsIntegration));
