/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    CashDrawer,
    CashDrawerFromJSON,
    CashDrawerFromJSONTyped,
    CashDrawerToJSON,
} from './';

/**
 * 
 * @export
 * @interface CashDrawerListApiResponse
 */
export interface CashDrawerListApiResponse {
    /**
     * 
     * @type {string}
     * @memberof CashDrawerListApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof CashDrawerListApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {Array<CashDrawer>}
     * @memberof CashDrawerListApiResponse
     */
    data?: Array<CashDrawer> | null;
}

export function CashDrawerListApiResponseFromJSON(json: any): CashDrawerListApiResponse {
    return CashDrawerListApiResponseFromJSONTyped(json, false);
}

export function CashDrawerListApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CashDrawerListApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(CashDrawerFromJSON)),
    };
}

export function CashDrawerListApiResponseToJSON(value?: CashDrawerListApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(CashDrawerToJSON)),
    };
}


