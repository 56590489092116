import {
  WillCallApi,
  WillCallRequest,
  WillCallResponseBodyApiResponse,
} from '@emporos/api-enterprise/src/gen';
import {Customer} from '@emporos/api-enterprise/src/gen-session';
import {useCallback, useMemo} from 'react';
import {ApiLogTypes, useLog} from '../contexts/LoggingProvider';
import {useTransactionsState} from '../contexts/TransactionsStateProvider';
import {mapInvoiceCustomer} from '../utils/mappers';
import useOpenApi, {UseOpenApiHook} from '../api/useOpenApi';

interface UseCustomerWillCall
  extends Omit<UseOpenApiHook<WillCallApi, 'clientWillCallPut'>, 'run'> {
  run: (param: WillCallRequest) => Promise<WillCallResponseBodyApiResponse>;
}

export function useCustomerWillCall(): UseCustomerWillCall & {
  customer: Customer | null;
} {
  const {session} = useTransactionsState();
  const {logApi} = useLog();
  const hook = useOpenApi(WillCallApi, 'clientWillCallPut');
  const {run: _run, data} = hook;
  const {siteId, stationId} = session;
  const boundRun: (
    param: WillCallRequest,
  ) => Promise<WillCallResponseBodyApiResponse> = useCallback(
    param => {
      logApi(ApiLogTypes.CustomerPending);
      return _run({
        siteId: siteId,
        stationId: stationId,
        willCallRequest: param,
      });
    },
    [siteId, stationId, logApi, _run],
  );
  const customer = useMemo(
    () =>
      data && data.data?.customers[0]
        ? mapInvoiceCustomer(data.data?.customers[0], data.data.prescriptions)
        : null,
    [data],
  );

  return {...hook, run: boundRun, customer};
}
