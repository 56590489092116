import {IndicatorCircle, Stack} from '@emporos/components';
import Text, {Variant as TV} from '@emporos/components/src/Text';

export default function Paid(): JSX.Element {
  return (
    <Stack justify="center" align="center" style={{flex: 1}}>
      <IndicatorCircle variant="success" icon="Checkmark" size="large" />
      <Text variant={TV.T2} align="center">
        All Paid!
      </Text>
    </Stack>
  );
}
