import {OfflineInvoice, OfflineSynced} from '../api/common';
import {Invoice} from '@emporos/api-enterprise/src/gen-session/index';

export const filterDeletedInvoiceChildren = (
  invoice: OfflineInvoice,
): Invoice =>
  ({
    ...invoice,
    items: invoice.items.filter(item => !(item as OfflineSynced).isDeleted),
    payments: invoice.payments.filter(
      payment => !(payment as OfflineSynced).isDeleted,
    ),
    taxes: invoice.taxes.filter(tax => !(tax as OfflineSynced).isDeleted),
    signatures: invoice.signatures.filter(
      signature => !(signature as OfflineSynced).isDeleted,
    ),
    extras: invoice.extras.filter(tax => !(tax as OfflineSynced).isDeleted),
    identification:
      invoice.identification &&
      !(invoice.identification as OfflineSynced).isDeleted
        ? invoice.identification
        : undefined,
    signatureImage:
      invoice.signatureImage &&
      !(invoice.signatureImage as OfflineSynced).isDeleted
        ? invoice.signatureImage
        : undefined,
  } as OfflineInvoice);
