import {
  CustomerSearchMatch,
  CustomerSearchMatchListApiResponse,
} from '@emporos/api-enterprise/src/gen';
import {Customer} from '@emporos/api-enterprise/src/gen-session';
import {
  Button,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  IndicatorCircle,
  Row,
  RowItem,
  ScrollContainer,
  Stack,
} from '@emporos/components';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import TextInput from '@emporos/components/src/TextInput';
import moment from 'moment';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {formatNamePart, formatPhoneNumber} from '../../../utils/string';

interface Props {
  activeInvoiceCustomerIds: Array<number>;
  addToAr?: boolean;
  onSearch: (
    name: string,
    dob: string,
  ) => Promise<CustomerSearchMatchListApiResponse>;
  onAddCustomer: (customer: CustomerSearchMatch) => Promise<unknown>;
  onCancel: () => void;
  online: boolean;
  invoiceCustomer?: Customer;
}

export default function CustomerSearch({
  activeInvoiceCustomerIds,
  addToAr = false,
  onSearch,
  onAddCustomer,
  onCancel,
  online,
  invoiceCustomer,
}: Props): JSX.Element {
  const [customers, setCustomers] = useState<Array<CustomerSearchMatch>>([]);
  const [
    selectedCustomer,
    setSelectedCustomer,
  ] = useState<CustomerSearchMatch | null>(null);
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [addButtonLoading, setAddButtonLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    if (online) {
      return;
    }
    setHasSearched(false);
  }, [online]);

  return (
    <Stack>
      <Header style={{paddingTop: 10}} title="Add Customer" />
      <Row
        gutter={Gutter.XL}
        justify="space-between"
        as="form"
        onSubmit={async (event: React.BaseSyntheticEvent) => {
          event.preventDefault();
          setSearchLoading(true);
          try {
            const response = await onSearch(name, dob);
            response.data && setCustomers(response.data);
          } catch (e) {
            setCustomers([]);
          }
          setHasSearched(true);
          setSearchLoading(false);
        }}
      >
        <TextInput
          style={{flex: 1.5}}
          label="Last Name"
          icon="User"
          value={name}
          onChange={e => setName(e.target.value)}
          onClear={() => {
            setName('');
            setCustomers([]);
            setHasSearched(false);
            setSelectedCustomer(null);
          }}
          disabled={!online}
        />
        <TextInput
          style={{flex: 1, minWidth: 180}}
          label="DOB"
          icon="Birthday"
          value={dob}
          mask="99/99/9999"
          onChange={e => setDob(e.target.value)}
          onClear={() => {
            setDob('');
            setCustomers([]);
            setHasSearched(false);
            setSelectedCustomer(null);
          }}
          disabled={!online}
          inputMode="numeric"
        />
        <Button
          type="submit"
          variant={BV.Secondary}
          disabled={!name || !moment(dob, 'MM/DD/YYYY', true).isValid()}
          loading={searchLoading}
          style={{minWidth: 165}}
        >
          Search
        </Button>
      </Row>

      <ScrollContainer>
        {(!online || !customers.length) && (
          <Stack
            align="center"
            justify="center"
            gutter={Gutter.XL}
            style={{height: '100%'}}
          >
            {online ? (
              <IndicatorCircle
                icon={hasSearched ? 'X' : 'Search'}
                variant={hasSearched ? 'error' : 'gray'}
                size="large"
                data-testid={hasSearched ? 'icon-X' : 'Search'}
              />
            ) : (
              <IndicatorCircle
                icon="NetworkNone"
                variant="warning"
                size="large"
              />
            )}
            <Text variant={TV.Main}>
              {!online
                ? 'Cannot search without a connection'
                : hasSearched
                ? 'No customer found'
                : 'Search for customer above'}
            </Text>
          </Stack>
        )}
        {online && !!customers.length && (
          <Stack gutter={Gutter.S}>
            {customers.map(customer => {
              const customerExists =
                // This will allow us to reattach a customer to an AR payment
                // in the case they are accidentally switched out with another customer.
                !addToAr &&
                activeInvoiceCustomerIds.includes(customer.id) &&
                customer.id !== invoiceCustomer?.id;

              return (
                <RowItem
                  key={customer.id}
                  title={`${formatNamePart(
                    customer.firstName,
                  )} ${formatNamePart(customer.lastName)}`}
                  subtitle={`DOB: ${
                    customer.dob
                      ? moment.utc(customer.dob).format('MM/DD/YYYY')
                      : ''
                  } • Phone: ${formatPhoneNumber(customer.homePhone || '')}`}
                  selected={customer.id === selectedCustomer?.id}
                  onClick={() =>
                    !addButtonLoading &&
                    setSelectedCustomer(
                      selectedCustomer?.id === customer.id ? null : customer,
                    )
                  }
                  rightText={`MRN: ${customer.customerCode}`}
                  rightBadgeText={customerExists ? 'Active' : ''}
                  inactive={customerExists}
                />
              );
            })}
          </Stack>
        )}
      </ScrollContainer>
      <FooterGroup>
        <Button variant={BV.Secondary} onClick={() => onCancel()} flex>
          Cancel
        </Button>
        <Button
          loading={addButtonLoading}
          disabled={!selectedCustomer || !online}
          flex
          data-testid="add-customer"
          onClick={async () => {
            if (!selectedCustomer) {
              return;
            }
            setAddButtonLoading(true);
            try {
              await onAddCustomer(selectedCustomer);
            } finally {
              setAddButtonLoading(false);
            }
          }}
        >
          Add Customer
        </Button>
      </FooterGroup>
    </Stack>
  );
}
