import {
  Peripheral,
  PeripheralType,
} from '@emporos/api-enterprise/src/gen-session';
import {Session} from '../contexts/TransactionsStateProvider';

export const findPaymentPeripheralType = (
  peripheralTypes: PeripheralType[],
): PeripheralType | null =>
  peripheralTypes.find(pt => pt.description === 'Payment Device') || null;

export const findTillPeripheralType = (
  peripheralTypes: PeripheralType[],
): PeripheralType | null =>
  peripheralTypes.find(pt => pt.description === 'Till') || null;

export const findPaymentDevice = (
  peripherals: Peripheral[],
  peripheralTypes: PeripheralType[],
): Peripheral | null => {
  const peripheralType = findPaymentPeripheralType(peripheralTypes);
  const paymentDevice = peripherals.find(
    ({peripheralTypeId}) =>
      peripheralTypeId === peripheralType?.peripheralTypeId,
  );
  return paymentDevice
    ? {...paymentDevice, ...(peripheralType && {peripheralType})}
    : null;
};

export const findSessionPaymentDevice = (
  session: Session,
  peripheralTypes: PeripheralType[],
): Peripheral | null =>
  findPaymentDevice(
    session.sessionPeripherals.map(sp => sp.peripheral as Peripheral),
    peripheralTypes,
  );
