import {Redirect} from '@reach/router';
import {memo, useCallback, useEffect, useMemo} from 'react';
import {useCustomerSearch} from '../../../../contexts/CustomerSearchProvider';
import {useGlobalData} from '../../../../contexts/GlobalDataProvider';
import {InvoiceLogTypes, useLog} from '../../../../contexts/LoggingProvider';
import {useNetworkAvailable} from '../../../../contexts/NetworkAvailableProvider';
import {useTransactionsState} from '../../../../contexts/TransactionsStateProvider';
import {withChildPage} from '../../../../hocs/withChildPage';
import {usePendingInvoice} from '../../../../hooks/usePendingInvoice';
import {createInvoiceOtcReducer} from '../../../../utils/invoice';
import OtcItemsPanel, {AddItemsResult} from './OtcItemsPanel';
import {InvoiceItem} from '@emporos/api-enterprise/src/gen-session';
import {OfflineInvoice} from '../../../../api/common';

const OtcSearchPage = withChildPage(function (props) {
  const {logUserSelection} = useLog();
  const {navigate} = props;
  const {currentInvoiceId} = useTransactionsState();

  if (!currentInvoiceId) {
    return <Redirect to="/sales" noThrow />;
  }

  const online = useNetworkAvailable();
  const {
    pmsName,
    barcodesResult,
    barcodeName,
    otcItemsResult,
  } = useGlobalData();
  const {customer: searchCustomer} = useCustomerSearch();
  const onCancel = useCallback(() => navigate('../'), [navigate]);
  const {
    invoice,
    commit,
    tryAttachCustomer,
    invoiceChanged,
  } = usePendingInvoice();
  const customer = useMemo(() => invoice.customer, [invoice]);
  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          )
        : [],
    [barcodesResult],
  );

  useEffect(() => {
    if (searchCustomer) {
      tryAttachCustomer(searchCustomer);
    }
  }, [searchCustomer]);

  const onAdd = useCallback(
    (addItemsResult: AddItemsResult) => {
      const {otc} = addItemsResult;
      let items: InvoiceItem[] = [...invoice.items];
      if (otc) {
        items = otc.reduce(createInvoiceOtcReducer(invoice), items);
      }
      logUserSelection(InvoiceLogTypes.AddItem, {item: items});
      commit({items});
      navigate('../');
    },
    [logUserSelection, navigate, invoice, customer],
  );

  return (
    <OtcItemsPanel
      barcodeComponents={barcodeComponents}
      currentInvoiceId={currentInvoiceId}
      invoice={invoice as OfflineInvoice}
      invoiceChanged={invoiceChanged}
      onAdd={onAdd}
      onCancel={onCancel}
      otcItems={(otcItemsResult && otcItemsResult.data) || []}
      online={online}
      pmsName={pmsName}
      title="OTC Search"
    />
  );
});

export default memo(OtcSearchPage);
