import {
  Gutter,
  RemovePadding,
  Row,
  RowSidebar,
  Stack,
} from '@emporos/components';
import Text, {Variant} from '@emporos/components/src/Text';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useEffect, useState} from 'react';
import config from '../../../config';
import {
  behindTabBarStackingContext,
  fullHeightStack,
} from '../../../routes/Sidebar';

function SettingsSidebar({
  requestedUrl,
  onConfirmNavigation,
  navigate,
}: RouteComponentProps & {
  requestedUrl: string | null;
  onCancelNavigation(): void;
  onConfirmNavigation(): void;
}) {
  const [selected, setSelected] = useState<
    'my-session' | 'developer' | 'support'
  >('my-session');

  /* istanbul ignore next */
  if (true) {
    assert(
      navigate,
      '<SettingsSidebar /> must have a `navigate` prop.' + config.NodeEnv ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
  }
  // autoconfirm navigation
  useEffect(() => {
    if (requestedUrl) {
      onConfirmNavigation();
    }
  }, [requestedUrl, onConfirmNavigation]);
  return (
    <Stack gutter={Gutter.XL} style={fullHeightStack}>
      <Row align="center">
        <Text variant={Variant.T1}>Settings</Text>
      </Row>
      <RemovePadding style={behindTabBarStackingContext}>
        <RowSidebar
          title={'My Session'}
          icon="CashRegister"
          iconColor="primary"
          selected={selected === 'my-session'}
          onClick={() => {
            setSelected('my-session');
            return navigate('/sales/settings');
          }}
        />
        {config.NodeEnv !== 'production' && (
          <RowSidebar
            title={'Developer'}
            icon="Desktop"
            iconColor="purple"
            selected={selected === 'developer'}
            onClick={() => {
              setSelected('developer');
              return navigate('/sales/settings/developer');
            }}
          />
        )}
        <RowSidebar
          title={'Support'}
          icon="Support"
          iconColor="warning"
          selected={selected === 'support'}
          onClick={() => {
            setSelected('support');
            return navigate('/sales/settings/support');
          }}
        />
      </RemovePadding>
    </Stack>
  );
}

export default memo(SettingsSidebar);
