import {
  CashDrawer,
  CustomersApi,
  PeripheralTypesApi,
  Site,
  SitesApi,
  Station,
} from '@emporos/api-enterprise/src/gen';
import {
  Customer,
  Peripheral,
  PeripheralType,
  SessionPeripheralsApi,
  SessionsApi,
  SignatureImage,
} from '@emporos/api-enterprise/src/gen-session';
import {OfflineInvoice, OfflineSession, OfflineSynced} from '../api/common';
import assert from 'assert';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {AuthClaim} from '../auth/const';
import {markRecursivelySynced} from './SyncSessionProvider';
import useLocalStorage from '../hooks/useLocalStorage';
import {useAlertState} from './AlertStateProvider';
import {useAuthentication} from './AuthenticationProvider';
import {Session} from './TransactionsStateProvider';
import {navigate} from '@reach/router';
import useOpenApi from '../api/useOpenApi';
import useOpenApiSession from '../api/useOpenApiSession';

export type TransactionsConfigContextValue = {
  session: Session | null;
  sites: Site[];
  peripherals: Peripheral[];
  setPeripherals: Dispatch<SetStateAction<Peripheral[]>>;
  peripheralTypes: PeripheralType[];
  setPeripheralTypes: Dispatch<SetStateAction<PeripheralType[]>>;
  setSession: Dispatch<SetStateAction<Session | null>>;
  loading: boolean;
  ready: boolean;
  createSessionLoading: boolean;
  createSession(
    site: Site,
    station: Station,
    till: CashDrawer,
    tillStartingAmount: number,
    paymentDevice?: Peripheral,
  ): Promise<Session | null>;
  closeSessionLoading: boolean;
  closeSession(): void;
  loadUserSession(): void;
  hardLoadingSession: boolean;
  sessionClosed: boolean;
  setSessionClosed: Dispatch<SetStateAction<boolean>>;
};
type Props = PropsWithChildren<unknown>;

// eslint-disable-next-line
function noop() {}

export const transactionsConfigContext = createContext<
  TransactionsConfigContextValue
>({
  session: null,
  sites: [],
  peripherals: [],
  setPeripherals: noop,
  peripheralTypes: [],
  setPeripheralTypes: noop,
  setSession: x => x,
  loading: true,
  ready: false,
  createSessionLoading: false,
  createSession: () => Promise.resolve(null),
  closeSessionLoading: false,
  closeSession: noop,
  loadUserSession: noop,
  hardLoadingSession: false,
  sessionClosed: false,
  setSessionClosed: x => x,
});

type Entity<IdKey extends string | void = void> = {
  dataVersion?: string | null;
} & (IdKey extends string ? Record<IdKey, string> : unknown) &
  OfflineSynced;

function reconcileEntity<E extends Entity>(left?: E, right?: E): E | undefined {
  if (!right && left) {
    return {...left, isSynced: true};
  }
  if (!left && right) {
    return {...right, isSynced: false};
  }

  if (!(right && left)) {
    return;
  }

  return left?.dataVersion === right?.dataVersion
    ? {...right, isSynced: false}
    : {...left, isSynced: true};
}

function reconcileEntities<K extends string, E extends Entity<K>>(
  remote: E[],
  local: E[],
  entityIdKey: K,
) {
  const result: E[] = [];

  local = local.slice();

  for (let i = 0; i < remote.length; i++) {
    const entityRemote = remote[i];
    const entityRemoteId = entityRemote[entityIdKey];
    const entityLocalIndex = local.findIndex(
      e => e[entityIdKey] === entityRemoteId,
    );
    const entityLocal = local[entityLocalIndex];

    if (
      entityLocal === undefined ||
      entityRemote.dataVersion !== entityLocal.dataVersion
    ) {
      // Take remote if local does not contain entity, or there is a data version
      // mismatch
      result.push({...entityRemote, isSynced: true});
    } else {
      // Take local if data version is identical
      result.push({...entityLocal});
    }

    if (entityLocalIndex > -1) {
      local.splice(entityLocalIndex, 1);
    }
  }

  // Take remaining local entities (i.e. not present in remote)
  for (let i = 0; i < local.length; i++) {
    result.push({...local[i]});
  }

  return result;
}

function reconcileSession(
  remote: Session,
  local: Session | null,
): OfflineSession {
  if (local === null || remote.dataVersion !== local.dataVersion) {
    remote.invoices.forEach(markRecursivelySynced);
    return {...remote, triggerSync: false};
  }

  let invoices = reconcileEntities(
    remote.invoices,
    local.invoices,
    'invoiceId',
  );

  invoices = invoices.map(invoice => {
    const invoiceLocal = local.invoices.find(
      i => i.invoiceId === invoice.invoiceId,
    );
    if (invoiceLocal) {
      const invoiceRemote = remote.invoices.find(
        i => i.invoiceId === invoice.invoiceId,
      );
      if (!invoiceRemote) {
        return invoiceLocal;
      }
      const invoiceFinal = {...invoice};
      invoiceFinal.items = reconcileEntities(
        invoiceRemote.items,
        invoiceLocal.items,
        'invoiceItemId',
      );
      invoiceFinal.extras = reconcileEntities(
        invoiceRemote.extras,
        invoiceLocal.extras,
        'rowId',
      );
      invoiceFinal.payments = reconcileEntities(
        invoiceRemote.payments,
        invoiceLocal.payments,
        'invoicePaymentId',
      );
      invoiceFinal.taxes = reconcileEntities(
        invoiceRemote.taxes,
        invoiceLocal.taxes,
        'invoiceTaxId',
      );
      invoiceFinal.signatures = reconcileEntities(
        invoiceRemote.signatures,
        invoiceLocal.signatures,
        'invoiceSignatureId',
      );
      invoiceFinal.identification = reconcileEntity(
        invoiceRemote.identification,
        invoiceLocal.identification,
      );
      (invoice as OfflineInvoice).signatureImage = reconcileEntity(
        invoiceRemote.signatures[0]?.signatureImage,
        (invoiceLocal as OfflineInvoice).signatureImage,
      );
      return invoiceFinal;
    }
    return invoice;
  });

  return {...remote, invoices, triggerSync: true};
}

export default function TransactionsConfigProvider(props: Props): JSX.Element {
  const {user} = useAuthentication();
  const {notification} = useAlertState();
  const [localSession, setLocalSession] = useLocalStorage<Session>(
    user ? `emporos/session/${user.profile[AuthClaim.UserId]}` : null,
  );
  const [session, setSession] = useState<Session | null>(localSession);
  const [sessionClosed, setSessionClosed] = useState<boolean>(false);
  const [sites, setSites] = useState<Site[]>([]);
  const [peripherals, setPeripherals] = useState<Peripheral[]>([]);
  const [peripheralTypes, setPeripheralTypes] = useState<PeripheralType[]>([]);
  const [hardLoadingSession, setHardLoadingSession] = useState(false);
  const [loadingSession, setLoadingSession] = useState(true);

  const {run: getSites} = useOpenApi(SitesApi, 'clientSitesGet', [{}]);
  const {run: getMySession} = useOpenApiSession(
    SessionsApi,
    'clientCacheSessionsMyGet',
  );
  const {run: getCustomer} = useOpenApi(
    CustomersApi,
    'clientCustomersCustomerIdGet',
  );
  const {run: closeSession, loading: closeSessionLoading} = useOpenApiSession(
    SessionsApi,
    'clientCacheSessionsMyClosePost',
  );
  const {run: createSession, loading: createSessionLoading} = useOpenApiSession(
    SessionsApi,
    'clientCacheSessionsPost',
  );
  const {run: getPeripheralTypes} = useOpenApi(
    PeripheralTypesApi,
    'clientPeripheralsTypesGet',
    [],
  );
  const {run: updatePeripheral} = useOpenApiSession(
    SessionPeripheralsApi,
    'clientCacheSessionsSessionIdPeripheralsPut',
  );

  const _createSession = useCallback(
    async (
      site: Site,
      station: Station,
      till: CashDrawer,
      tillStartAmount: number,
      paymentDevice?: Peripheral,
    ) => {
      let remote: Session;
      try {
        // Create a new session.
        remote = await createSession({
          siteId: site.siteId,
          stationId: station.stationId,
          tillId: till.cashDrawerId,
          startingCashBalance: tillStartAmount,
        });
        // Create session peripherals with selected payment device (and any
        // periphal types supported in the future).
        if (paymentDevice) {
          remote.sessionPeripherals = await Promise.all(
            [paymentDevice].map(async peripheral => {
              const sessionPeripheral = await updatePeripheral({
                sessionId: remote.sessionId,
                sessionPeripheralRequest: peripheral,
              });
              return {...sessionPeripheral, peripheral};
            }),
          );
        }
      } catch (error) {
        notification({
          type: 'error',
          icon: 'X',
          title: 'Open Session Failed',
          description: "We couldn't create a session with your selections.",
        });
        return null;
      }

      setSession(remote);

      return remote;
    },
    [],
  );
  const _closeSession = useCallback(async () => {
    assert(
      session !== null,
      'Internal Error: called closeSession() with no active session.',
    );

    if (
      session.invoices
        .filter(invoice => !(invoice as OfflineSynced).isDeleted)
        .some(invoice => invoice.status !== 2)
    ) {
      return;
    }

    try {
      await closeSession();
      setSessionClosed(true);
      setSession(null);
      return navigate('/sales');
    } catch (error) {
      notification({
        type: 'error',
        icon: 'X',
        title: 'Close Session Failed',
        description:
          "We couldn't close your session. Please check your internet connection and try reloading the app.",
      });
    }
  }, [session]);
  const loadUserSession = async (): Promise<Session | null> => {
    return getMySession({})
      .then(async sessions => {
        let next = sessions[0];
        if (next && 'sessionId' in next) {
          next = reconcileSession(next, session);
          next.invoices.forEach(invoice => {
            if (invoice.signatures[0]?.signatureImage) {
              (invoice as OfflineInvoice).signatureImage = {
                ...invoice.signatures[0].signatureImage,
                isSynced: true,
              } as OfflineSynced & SignatureImage;
            }
          });
          await Promise.all(
            next.invoices.map(async invoice => {
              const {customerId} = invoice;
              if (!customerId) {
                return Promise.resolve();
              }
              const {data, error} = await getCustomer({customerId});
              if (error) {
                return Promise.reject(error);
              }
              invoice.customer = data as Customer;
            }),
          );
          setSession(next);
        } else {
          // Clear local session if the corresponding server session is closed.
          setSession(null);
        }
        return next || null;
      })
      .catch(error => {
        // Clear local session if the corresponding server session is closed.
        setSession(null);
        return error;
      });
  };
  const loadSites = async () => {
    const {data} = await getSites({});
    if (data) {
      setSites(data);
    }
  };
  const loadPeripheralTypes = async () => {
    const peripheralTypes = await getPeripheralTypes();
    if (peripheralTypes) {
      setPeripheralTypes(peripheralTypes);
    }
  };
  const _loadUserSession = useCallback(() => {
    setHardLoadingSession(true);
    loadUserSession().finally(() => {
      setHardLoadingSession(false);
    });
  }, [loadUserSession]);

  const initialize = async () => {
    if (!loadingSession) {
      setLoadingSession(true);
    }
    // Adding a try catch here because loadUserSession was silently failing
    try {
      await Promise.all([
        loadUserSession(),
        loadSites(),
        loadPeripheralTypes(),
      ]);
    } catch (err) {
      notification({
        type: 'error',
        icon: 'X',
        title: 'Session Failed to Load',
        description:
          'Please check your internet connection and reload the app.',
      });
    }
  };

  const value: TransactionsConfigContextValue = useMemo(
    () => ({
      session,
      sites,
      peripherals,
      setPeripherals,
      peripheralTypes,
      setPeripheralTypes,
      setSession,
      loading: loadingSession,
      ready: !loadingSession,
      createSession: _createSession,
      createSessionLoading,
      closeSession: _closeSession,
      closeSessionLoading,
      sessionClosed,
      setSessionClosed,
      loadUserSession: _loadUserSession,
      hardLoadingSession,
    }),
    [
      session,
      sites,
      peripherals,
      setPeripherals,
      peripheralTypes,
      setPeripheralTypes,
      setSession,
      loadingSession,
      createSessionLoading,
      _createSession,
      closeSessionLoading,
      _closeSession,
      hardLoadingSession,
      sessionClosed,
      setSessionClosed,
    ],
  );

  useEffect(() => setLocalSession(session), [session]);

  useEffect(() => {
    if (user) {
      initialize().then(() => {
        setLoadingSession(false);
      });
    }
  }, [user]);

  return (
    <transactionsConfigContext.Provider value={value}>
      {props.children}
    </transactionsConfigContext.Provider>
  );
}

export function useTransactionsConfig(): TransactionsConfigContextValue {
  return useContext(transactionsConfigContext);
}
