/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OtcItemAttachment
 */
export interface OtcItemAttachment {
    /**
     * 
     * @type {number}
     * @memberof OtcItemAttachment
     */
    itemId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OtcItemAttachment
     */
    itemNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OtcItemAttachment
     */
    quantity?: number | null;
}

export function OtcItemAttachmentFromJSON(json: any): OtcItemAttachment {
    return OtcItemAttachmentFromJSONTyped(json, false);
}

export function OtcItemAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtcItemAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemId': !exists(json, 'itemId') ? undefined : json['itemId'],
        'itemNumber': !exists(json, 'itemNumber') ? undefined : json['itemNumber'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}

export function OtcItemAttachmentToJSON(value?: OtcItemAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemId': value.itemId,
        'itemNumber': value.itemNumber,
        'quantity': value.quantity,
    };
}


