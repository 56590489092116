import React, {memo} from 'react';
import styled, {css, DefaultTheme} from 'styled-components';
import {Gutter, Icon, Icons, IconSize, IndicatorCircle, Row, Stack} from './';
import Text, {Variant} from './Text';

export type AlertType = 'success' | 'warning' | 'error' | 'general';

export interface AlertBannerProps {
  description: string;
  icon: keyof typeof Icons;
  title: string;
  // TODO: change this to variant
  type?: AlertType;
  onDismiss: () => void;
}

const mapColor = (theme: DefaultTheme, type?: AlertType) => {
  switch (type) {
    case 'success':
      return `${theme.colors.success}`;
    case 'warning':
      return `${theme.colors.warning}`;
    case 'error':
      return `${theme.colors.error}`;
    case 'general':
    default:
      return `${theme.colors.blue}`;
  }
};

const indicatorCircle = (icon: keyof typeof Icons, type?: string) => {
  switch (type) {
    case 'success':
      return <IndicatorCircle icon={icon} variant="success" />;
    case 'warning':
      return <IndicatorCircle icon={icon} variant="warning" />;
    case 'error':
      return <IndicatorCircle icon={icon} variant="error" />;
    case 'general':
    default:
      return <IndicatorCircle icon={icon} variant="primary" />;
  }
};

const StyledAlert = styled.div<{type?: AlertType}>`
  align-items: center;
  align-self: flex-end;
  background: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.gray_200};
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.shadows.shadow_16};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  padding: 16px;
  position: relative;
  width: 430px;

  &::before {
    background: ${({theme, type}) => mapColor(theme, type)};
    border-radius: 8px 0 0 8px;
    content: '';
    height: 100%;
    width: 11px;
    position: absolute;
    left: -1px;
    bottom: 0;
  }

  .alert-banner-icon {
    margin-left: 10px;
  }

  .alert-content {
    max-width: 288px;
    padding-right: 1px;
  }
`;

const hoverStyles = css`
  background-color: ${({theme}) => theme.colors.gray_100};
  transition: background-color 200ms ease-out;
  border-radius: 0 8px 8px 0;
`;

const CloseButton = styled.button`
  align-items: center;
  color: ${({theme}) => theme.colors.black};
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  margin: 0;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 100%;
  min-width: 56px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 200ms ease-out;
  &::before {
    background-color: ${({theme}) => theme.colors.gray_200};
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  &:focus {
    outline: none;
  }
  &:active {
    ${hoverStyles}
  }
  @media (hover: hover) {
    &:hover {
      ${hoverStyles}
    }
  }
`;

export const AlertBanner = memo(
  ({
    onDismiss,
    description,
    icon,
    title,
    type,
    ...props
  }: AlertBannerProps &
    React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    return (
      <StyledAlert type={type} data-testid="alert-banner" {...props}>
        <Row align="center" noWrap>
          <div className="alert-banner-icon">{indicatorCircle(icon, type)}</div>
          <Stack className="alert-content" gutter={Gutter.XXS}>
            <Text variant={Variant.Main}>{title}</Text>
            <Text variant={Variant.SubMainLight}>{description}</Text>
          </Stack>
        </Row>
        <CloseButton
          aria-label="Dimiss alert"
          type="button"
          onClick={onDismiss}
        >
          <Icon icon="X" size={IconSize.S} />
        </CloseButton>
      </StyledAlert>
    );
  },
);
