import {
  Button,
  ButtonShape,
  FooterGroup,
  Gutter,
  Header,
  IndicatorCircle,
  Row,
  ScrollContainer,
  SegmentSlider,
  Select,
  ButtonSize as Size,
  Stack,
} from '@emporos/components';
import Text, {Variant} from '@emporos/components/src/Text';
import TextInput from '@emporos/components/src/TextInput';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavigateFn} from '@reach/router';
import moment from 'moment';
import {memo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useBetaFeatures} from '../../../../../contexts/BetaFeatureProvider';
import {Invoice} from '../../../../../contexts/TransactionsStateProvider';
import {EmailForm} from './index';

interface Props {
  invoice: Invoice;
  navigate: NavigateFn;
  onPrint(): void;
  onNext(): void;
  onSubmit(data: EmailForm): void;
}

const schema = yup.object().shape({email: yup.string().email().required()});

export function Receipts({
  invoice,
  onNext,
  onPrint,
  onSubmit,
  navigate,
}: Props): JSX.Element {
  const [active, setActive] = useState(0);
  const {register, watch, control, handleSubmit, formState} = useForm<
    EmailForm
  >({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const printingEnabled = useBetaFeatures().printing;
  const availableItems = printingEnabled
    ? ['Email', 'Print', 'None']
    : ['Email', 'None'];
  const EMAIL_INDEX = 0;
  const PRINT_INDEX = printingEnabled ? 1 : -1;
  const {serverTransactionID, saleDate} = invoice;

  return (
    <Stack gutter={Gutter.L} align="center" style={{flex: 1}}>
      <Header title="Receipt Delivery" style={{alignSelf: 'stretch'}}>
        <ButtonShape
          size={Size.Small}
          icon="User"
          data-testid="customer-info-btn"
          onClick={() => navigate('customer-info')}
        />
      </Header>

      <SegmentSlider
        onSelectIndex={setActive}
        active={active}
        items={availableItems}
      />

      {active === EMAIL_INDEX ? (
        <Stack
          style={{height: '100%', width: '100%', minHeight: 0}}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ScrollContainer>
            <Stack
              justify="center"
              align="center"
              gutter={Gutter.XXL}
              style={{flex: 1, height: '100%'}}
            >
              {serverTransactionID ? (
                <Stack justify="center" align="center" gutter={Gutter.XS}>
                  <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                  <Text variant={Variant.Main}>
                    {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                  </Text>
                </Stack>
              ) : null}
              <Row>
                <Stack justify="center" align="center">
                  <IndicatorCircle
                    icon="Email"
                    size="large"
                    variant="primary"
                  />
                  <Controller
                    defaultValue=""
                    as={
                      <TextInput
                        autoComplete="off"
                        label="Email"
                        value={watch('email')}
                        ref={register}
                        style={{width: 422}}
                      />
                    }
                    name="email"
                    control={control}
                  />
                </Stack>
              </Row>
            </Stack>
          </ScrollContainer>

          <FooterGroup>
            <Button type="submit" disabled={!formState.isValid} flex>
              Send Email
            </Button>
          </FooterGroup>
        </Stack>
      ) : active === PRINT_INDEX ? (
        <Stack style={{height: '100%', width: '100%'}}>
          <Stack
            gutter={Gutter.L}
            justify="center"
            align="center"
            style={{flex: 1}}
          >
            <IndicatorCircle icon="Receipt" size="large" variant="warning" />
            <Select
              label="Printer"
              options={['Front Desk']}
              value={null}
              onChangeValue={() => console.log('Unimplemented')}
              style={{width: 372, flex: 0}}
            />
          </Stack>
          <FooterGroup>
            <Button onClick={onPrint} flex>
              Print Receipt
            </Button>
          </FooterGroup>
        </Stack>
      ) : (
        <Stack style={{height: '100%', width: '100%'}} data-testid="no-receipt">
          <Stack
            gutter={Gutter.L}
            justify="center"
            align="center"
            style={{flex: 1}}
          >
            <IndicatorCircle icon="X" size="large" variant="gray" />
            <Text variant={Variant.T2}>No receipt</Text>
          </Stack>
          <FooterGroup>
            <Button onClick={onNext} flex>
              Next
            </Button>
          </FooterGroup>
        </Stack>
      )}
    </Stack>
  );
}

export default memo(Receipts);
