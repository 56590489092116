import {InvoiceItem} from '@emporos/api-enterprise/src/gen-session';
import {round} from './number';
import assert from 'assert';
import {OfflineSynced} from '../api/common';

export const updateDiscount = (
  invoiceItem: InvoiceItem,
  percent: number,
): InvoiceItem => {
  assert(
    percent >= 0 && percent <= 1,
    'Percentage should: 0 <= percentage <= 1',
  );
  const _discount = discount(
    percent,
    invoiceItem.listPrice,
    invoiceItem.quantity,
  );

  return {
    ...invoiceItem,
    isSynced: false,
    price: invoiceItem.listPrice,
    discountPercent: percent,
    discount: _discount,
    extension: extension(
      invoiceItem.listPrice,
      invoiceItem.quantity,
      _discount,
    ),
    extensionBeforeDiscount: extensionBeforeDiscount(
      invoiceItem.listPrice,
      invoiceItem.quantity,
    ),
  } as InvoiceItem & OfflineSynced;
};

export const updatePrice = (
  invoiceItem: InvoiceItem,
  price: number,
): InvoiceItem =>
  ({
    ...invoiceItem,
    isSynced: false,
    price,
    discountPercent: 0,
    discount: 0,
    extension: extension(price, invoiceItem.quantity, 0),
    extensionBeforeDiscount: extensionBeforeDiscount(
      price,
      invoiceItem.quantity,
    ),
  } as InvoiceItem & OfflineSynced);

export const updateQuantity = (
  invoiceItem: InvoiceItem,
  quantity: number,
): InvoiceItem => {
  const _discount = discount(
    invoiceItem.discountPercent,
    invoiceItem.price,
    quantity,
  );

  return {
    ...invoiceItem,
    isSynced: false,
    quantity,
    discount: _discount,
    extension: extension(invoiceItem.price, quantity, _discount),
    extensionBeforeDiscount: extensionBeforeDiscount(
      invoiceItem.price,
      quantity,
    ),
  } as InvoiceItem & OfflineSynced;
};

const discount = (percent: number, price: number, quantity: number) =>
  round(percent * price * quantity);

const extension = (price: number, quantity: number, discount: number): number =>
  price * quantity - discount;

const extensionBeforeDiscount = (price: number, quantity: number): number =>
  price * quantity;
