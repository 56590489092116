import React, {memo, useEffect, useRef, useState} from 'react';
import {
  Barcode,
  BarcodePicker,
  configure,
  ScanResult,
  ScanSettings,
  SymbologySettings,
} from 'scandit-sdk';
import styled from 'styled-components';

const DEFAULT_LICENSE_KEY =
  'AalgNxFQMbTUHiTUsSneBEQ6NGjcFBzdqHsSh+h7f3uHc9WoJ0QZc8VVTT+ScMi30lmmUuUw1HjWR7P0gDDtrkAMuOGSboDYKFpDWSwKNSmDY4EqsE6dCVlvQlj5RkH/pmAU3wVZhizPQO+vyACoEkcwx/QfBE/tOkPqIvJPiH6sjpSSlM2gKOFmJGt1+0uyY8jN2vUOs42ntUHxpZ7Y0XY1z+fEY5k9TUae2MBVg+EwyohnPDAxWyYsF/CLrFUmWll8cc9hYP8SBMwHHI5Rbz27cqSpYzkfs0oRtiJmPlZyY1auSJBDwDZvXvwCcKZaxnxWziD50QsZIc03JQ/yLa3tiiIB3nyk6RKEpP4/+G3cbJfkfFfKA808ZX+UUkSPlRBhOe/a3ekgT/nNrjJBZ3Iuz7r5cEwm7C66qJKB2vsaJWAQKTKE706kQACl+1szsCZ2RNhY7C5MGdLTnkHM+ul/m3pY7Z8KiGBWR1PFysnpLcHDlUMu5beZQ427kiuGT2ia/VnbN1SySfDcECGnz6xUQUlIVh4zcTmhsrPHV+bbfx1MxdGMNNo0Jz+sJ1sPw46Ib2sh4uXxo2eAfu/MkJ/TBo/sinpJKmteDxPL1QgOEllBmWX2dy+U8DfzFzB1NvGbtzDX17RQw/MD09/WZD3eGglrXGibT5cd5ShyHN/EoH14NYu27BSIWNbc4mIPFYtZq0O0Dd+EUwQMua7egh+N/5OvBbusDpqcBNdi4XM+4G2ylMRJX4fzDAuUwn9zv0PNj6ajPcaeUxmKIFiYeF1I+2BOOQpJTPdN1hyo5lGkDTtqA9fz5iy8vYwQRFIH5KOXsIUgTHpTZJA=';

export const DEFAULT_SYMBOLOGIES = [
  Barcode.Symbology.EAN13,
  Barcode.Symbology.UPCA,
  Barcode.Symbology.EAN8,
  Barcode.Symbology.UPCE,
  Barcode.Symbology.PDF417,
  Barcode.Symbology.MICRO_PDF417,
  Barcode.Symbology.CODE39,
  Barcode.Symbology.CODE128,
  Barcode.Symbology.QR,
  Barcode.Symbology.MICRO_QR,
  Barcode.Symbology.AZTEC,
];

const DEFAULT_SETTINGS = {
  guiStyle: BarcodePicker.GuiStyle.LASER,
  videoFit: BarcodePicker.ObjectFit.COVER,
  playSoundOnScan: true,
  enableCameraSwitcher: false,
  enableTorchToggle: false,
};

export interface Props {
  onScan: (result: ScanResult) => void;
  onScanError: (error: Error) => void;
  onError: (error: Error) => void;
  // TODO: this should be made non-optional once we add this to the Enterprise
  // settings API
  licenseKey?: string;
  enabledSymbologies?: Array<Barcode.Symbology>;
  height?: number | string;
  isScanning?: boolean;
}
const StyledBarcodeScanner = styled.div`
  height: 100%;

  .scandit {
    &.scandit-container {
      border-radius: 12px;
    }
    .scandit-video {
      border-radius: 12px;
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

let configured = false;
async function configureOnce(licenseKey: string) {
  if (configured) {
    return;
  }
  configured = true;
  await configure(licenseKey, {
    engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/',
  });
}
async function initializePicker(
  element: HTMLElement,
  licenseKey: string,
  enabledSymbologies: Array<Barcode.Symbology>,
) {
  await configureOnce(licenseKey);
  const scanSettings = initializeSettings(enabledSymbologies);

  return await BarcodePicker.create(element, {
    ...DEFAULT_SETTINGS,
    scanSettings,
  });
}
function initializeSettings(
  enabledSymbologies: Array<Barcode.Symbology>,
): ScanSettings {
  const scanSettings = new ScanSettings({
    codeDuplicateFilter: 1000,
    enabledSymbologies,
  });
  scanSettings
    .getSymbologySettings(Barcode.Symbology.UPCA)
    .enableExtensions(SymbologySettings.Extension.REMOVE_LEADING_ZERO);
  scanSettings
    .getSymbologySettings(Barcode.Symbology.UPCE)
    .enableExtensions([
      SymbologySettings.Extension.RETURN_AS_UPCA,
      SymbologySettings.Extension.REMOVE_LEADING_UPCA_ZERO,
    ]);
  return scanSettings;
}

export const BarcodeScanner = memo(
  ({
    onScan,
    onScanError,
    onError,
    enabledSymbologies = DEFAULT_SYMBOLOGIES,
    height = '100%',
    licenseKey = DEFAULT_LICENSE_KEY,
    // this isn’t used in application code anywhere...
    isScanning = false,
    ...props
  }: Props): JSX.Element => {
    const [picker, setPicker] = useState<null | BarcodePicker>(null);
    const pickerRef = useRef<null | BarcodePicker>();
    const barcodePickerDiv = useRef<HTMLDivElement>(null);

    const removeScanditVideoElement = () =>
      document
        .querySelectorAll('.scandit-video')
        .forEach(video => video.remove());

    useEffect(
      function onMount() {
        const element = barcodePickerDiv.current;
        /* istanbul ignore next */
        if (!element) {
          throw new Error(`There was an error with the React framework`);
        }

        // immediately invoke the lazy creation of the picker
        (async function sketchInit() {
          try {
            const picker = await initializePicker(
              element,
              licenseKey,
              enabledSymbologies,
            );
            if (barcodePickerDiv.current) {
              setPicker(picker);
            } else {
              removeScanditVideoElement();
              picker.destroy();
            }
          } catch (error) {
            // TODO: provide UI for possible error cases:
            // AbortError
            // LibraryNotConfiguredError
            // NoCameraAvailableError
            // NoOriginElementError
            // NotAllowedError
            // NotFoundError
            // NotReadableError
            // SecurityError
            // UnsupportedBrowserError;
            onError(error as Error);
          }
        })();

        return () => {
          const p = pickerRef.current;
          if (!p) {
            // We unmounted while the picker was being initialized.
            // picker.destroy() should be called above.
          } else {
            removeScanditVideoElement();
            p.destroy();
          }
        };
      },
      // TODO:
      // * onError only applies to init so maybe that’s okay
      // * enabledSymbologies is updated through a direct hook
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        /*onError, enabledSymbologies*/
      ],
    );

    useEffect(() => {
      pickerRef.current = picker;
      if (picker) {
        picker.applyScanSettings(initializeSettings(enabledSymbologies));
      }
    }, [picker, enabledSymbologies]);

    useEffect(
      function setHandlers() {
        if (picker) {
          picker.removeAllListeners('scan').removeAllListeners('scanError');
          picker.on('scan', onScan).on('scanError', onScanError);
        }
      },
      [picker, onScan, onScanError],
    );

    useEffect(() => {
      if (!picker) return;
      if (isScanning) {
        picker.resumeScanning();
      } else {
        picker.pauseScanning();
      }
    }, [picker, isScanning]);

    return (
      <Container {...props} style={{height: height}}>
        <StyledBarcodeScanner ref={barcodePickerDiv} />
      </Container>
    );
  },
);
