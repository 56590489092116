import React, {memo, PropsWithoutRef} from 'react';
import styled, {css} from 'styled-components';
import {Icon, Icons, IconSize} from './';

export enum Shape {
  Circle = 'Circle',
  Square = 'Square',
}
export enum ButtonSize {
  Standard = 'Standard',
  Small = 'Small',
  Large = 'Large',
}

interface Props {
  shape?: Shape;
  size?: ButtonSize;
  icon?: keyof typeof Icons;
  disabled?: boolean;
  flex?: boolean;
  inverted?: boolean;
}

const hoverStyles = css<Props>`
  border-color: ${({theme, disabled, inverted}): string | false =>
    !disabled ? (inverted ? theme.colors.indigo : theme.colors.blue) : false};
  background-color: ${({theme, disabled, inverted}): string | false =>
    !disabled
      ? inverted
        ? theme.colors.indigo
        : theme.colors.blue_20
      : false};
  color: ${({theme, disabled, inverted}): string | false =>
    !disabled ? (inverted ? theme.colors.white : theme.colors.blue) : false};
`;

const StyledButton = styled.button<Props>`
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({size}): string => {
    switch (size) {
      case ButtonSize.Large:
        return '130px';
      case ButtonSize.Small:
        return '50px';
      case ButtonSize.Standard:
      default:
        return '80px';
    }
  }};
  width: ${({size}): string => {
    switch (size) {
      case ButtonSize.Large:
        return '130px';
      case ButtonSize.Small:
        return '50px';
      case ButtonSize.Standard:
      default:
        return '80px';
    }
  }};
  flex: ${({flex}) => (flex ? '1' : 'initial')};
  background-color: ${({theme, inverted}): string =>
    inverted ? theme.colors.blue : theme.colors.gray_100};
  border-radius: ${({shape}): string =>
    shape === Shape.Circle ? '50%' : '8px'};
  color: ${({theme, inverted}): string =>
    inverted ? theme.colors.white : theme.colors.blue};
  font-size: ${({theme, icon}): string => {
    return !icon ? theme.typography.Title1.fontSize : '';
  }};
  font-weight: bold;
  cursor: ${({disabled}): string => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}): string => (disabled ? '0.5' : '1')};
  transition: all 250ms ease;

  &:focus {
    outline: none;
  }
  &:active {
    ${hoverStyles}
  }
  @media (hover: hover) {
    &:hover {
      ${hoverStyles}
    }
  }
`;

export const ButtonShape = memo(
  ({
    shape = Shape.Square,
    size = ButtonSize.Standard,
    ...props
  }: Props & PropsWithoutRef<JSX.IntrinsicElements['button']>) => {
    return (
      <StyledButton shape={shape} size={size} {...props}>
        {props.icon ? (
          <Icon
            icon={props.icon}
            size={size === ButtonSize.Small ? IconSize.M : IconSize.L}
            variant={props.inverted ? 'inverted' : 'primary'}
          />
        ) : (
          props.children
        )}
      </StyledButton>
    );
  },
);
