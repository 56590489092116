import * as runtime from '@emporos/api-enterprise/src/gen-session/runtime';
import {
  Configuration,
  FetchParams,
  RequestContext,
  ResponseContext,
} from '@emporos/api-enterprise/src/gen-session';
import {useCallback, useEffect, useState} from 'react';
import {
  FetchApiParams,
  MethodKeyOf,
  MethodParametersOf,
  MethodReturnTypeOf,
  PromiseResolveType,
} from './common';
import {useAuthentication} from '@emporos/pos/src/contexts/AuthenticationProvider';
import {invoke} from 'lodash';
import endpointsConfig from './endpoints-config';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '@emporos/pos/src/contexts/AnalyticsProvider';
import {useAlertState} from '@emporos/pos/src/contexts/AlertStateProvider';
import config from '../config';

export type ClientConstructor<T> = {new (config: Configuration): T};

export type UseOpenApiHook<
  T extends runtime.BaseAPI,
  K extends MethodKeyOf<T>
> = {
  run: (...params: MethodParametersOf<T, K>) => MethodReturnTypeOf<T, K>;
  loading: boolean;
  data?: PromiseResolveType<MethodReturnTypeOf<T, K>>;
  error?: string;
};

const useOpenApi = <T extends runtime.BaseAPI, K extends MethodKeyOf<T>>(
  clientConstructor: ClientConstructor<T>,
  method: K,
  initialParams?: MethodParametersOf<T, K>,
): UseOpenApiHook<T, K> => {
  const {user} = useAuthentication();
  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    PromiseResolveType<MethodReturnTypeOf<T, K>>
  >();
  const [error, setError] = useState<string>('');

  const dependency =
    initialParams?.length === 1 ? JSON.stringify(initialParams) : null;

  useEffect(() => {
    if (initialParams) {
      run(...initialParams).catch(e => console.error(e));
    }
  }, [dependency]);

  const tokenPreMiddleware = useCallback(
    async (context: RequestContext): Promise<FetchParams | void> => {
      const {headers: _headers} = context.init;
      const headers = new Headers(_headers);
      headers.append('Authorization', `Bearer ${user?.access_token}`);
      return {
        url: context.url,
        init: {...context.init, headers: headers},
      };
    },
    [user],
  );

  const errorPostMiddleWare = useCallback(
    async (context: ResponseContext): Promise<Response | void> => {
      const {response} = context;
      if (!response.ok) {
        setError(response.statusText);
        setLoading(false);
        throw response;
      }
    },
    [],
  );

  const offlinePostMiddleware = useCallback(
    async (context: ResponseContext): Promise<Response | void> => {
      const {url, response, init} = context;
      const {method} = init;

      const offline = endpointConfig(url, method)?.offline;
      if (offline) {
        await global.caches
          ?.open('api-offline-cache')
          .then(async cache => await cache.put(url, response.clone()))
          .catch(failSilently);
      }
    },
    [],
  );

  const run = useCallback(
    async (...params: MethodParametersOf<T, K>): MethodReturnTypeOf<T, K> => {
      let deferred: (request: FetchApiParams) => void;
      const deferRequest = new Promise<FetchApiParams>(resolve => {
        deferred = resolve;
      });
      const client = new clientConstructor(
        new Configuration({
          basePath: config.ClientApiUrl,
          fetchApi: (requestInfo: RequestInfo, requestInit?: RequestInit) => {
            deferred({requestInfo, requestInit});
            return fetch(requestInfo, requestInit);
          },
        }),
      );

      const invokePromise = invoke(
        client
          .withPreMiddleware(tokenPreMiddleware)
          .withPostMiddleware(errorPostMiddleWare)
          .withPostMiddleware(offlinePostMiddleware),
        method,
        ...params,
      ) as Promise<PromiseResolveType<MethodReturnTypeOf<T, K>>>;

      setLoading(true);
      const fetchParams = await deferRequest;
      const url = fetchParams.requestInfo as string;
      const preferOffline = endpointConfig(url, fetchParams.requestInit?.method)
        ?.preferOffline;
      const offline = endpointConfig(url, fetchParams.requestInit?.method)
        ?.offline;

      // if preferOffline get result from cache if any
      if (preferOffline) {
        const cache = await global.caches
          ?.open('api-offline-cache')
          .catch(failSilently);
        const response = await cache?.match(fetchParams.requestInfo);
        if (response) {
          const responseJson = await response.json();
          setData(responseJson);
          setLoading(false);
          invokePromise.then(result => setData(result));
          return responseJson;
        }
      }

      try {
        const result = await invokePromise;
        setData(result);
        setLoading(false);
        return result;
      } catch (e) {
        if (offline) {
          const cache = await global.caches
            ?.open('api-offline-cache')
            .catch(failSilently);
          const response = await cache?.match(fetchParams.requestInfo);
          if (response) {
            const responseJson = await response.json();
            setData(responseJson);
            setLoading(false);
            return responseJson;
          }
        }
        const alert = endpointConfig(url, fetchParams.requestInit?.method)
          ?.errorAlert;
        alert && notification({...alert});
        track(AnalyticType.ApiError, {
          error,
          url,
          headers: fetchParams.requestInit?.headers,
          method: fetchParams.requestInit?.method || 'GET',
          body: JSON.stringify(fetchParams.requestInit?.body),
        });
        throw e;
      }
    },
    [],
  );

  return {run, loading, data, error};
};

const endpointConfig = (url: string, method?: string) => {
  const methodMappings = endpointsConfig[method || 'GET'];
  const match = Object.keys(methodMappings).find(exp => url.match(exp));
  if (match) {
    return methodMappings[match];
  }
};

const failSilently = (err: Error) => {
  console.error(err);
  return null;
};

export default useOpenApi;
