import {createContext, useContext, useEffect, useState} from 'react';
import {AnalyticType, useAnalyticsProvider} from './AnalyticsProvider';

type Context = boolean;

export const NetworkAvailableContext = createContext<Context>(navigator.onLine);

export function NetworkAvailableProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const {track} = useAnalyticsProvider();
  const [timeSpentOffline, setTimeSpentOffline] = useState<number>(0);
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    function _setOnline() {
      setOnline(true);
      if (timeSpentOffline) {
        track(AnalyticType.TimeOffline, {time: +new Date() - timeSpentOffline});
        setTimeSpentOffline(0);
      }
    }
    function _setOffline() {
      setOnline(false);
      setTimeSpentOffline(+new Date());
    }

    window.addEventListener('online', _setOnline);
    window.addEventListener('offline', _setOffline);

    return () => {
      window.removeEventListener('online', _setOnline);
      window.removeEventListener('offline', _setOffline);
    };
  }, [timeSpentOffline]);

  return (
    <NetworkAvailableContext.Provider value={online}>
      {children}
    </NetworkAvailableContext.Provider>
  );
}

export function useNetworkAvailable(): Context {
  return useContext(NetworkAvailableContext);
}
