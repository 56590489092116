import {
  CayanClient,
  CayanConfig as Config,
  CayanProxyAuthFailure,
  CayanTransactionResponse,
  CayanVaultProxyResponse,
  CayanVoidProxyResponse,
  Device,
  DeviceBusy,
  DeviceNotReady,
  DeviceUnreachable,
  IdleScreen,
  Logger,
  ProxyTransactionType,
  SKUDisplay,
  TODO_CayanUnknownFailure,
  TransactionDetailsRequest,
  TransactionRequest,
  TransactionVaultRequest,
  transformCayanToTransactionResponse,
  transformCayanVaultToTransactionResponse,
  transformCayanToVoidResponse,
  UnableToMakeVaultPayment,
  UnableToCreateOrder,
  UnableToVoidOrder,
  UnableToCancelTransaction,
  VoidRequest,
} from './';
// TODO: make this configured by the build system
const ROOT_PROXY_URL = 'https://clientapi.dev.emporos.io';

interface Err {
  toString(): string;
}

interface CayanTransactionProxy {
  data: {
    transportKey: string;
    validationKey: string;
    messages: Array<string>;
  };
}

/**
 * Check the device status to ensure that the device is ready to begin a
 * transaction.
 */
async function isDeviceReady(client: CayanClient) {
  try {
    const status = await client.checkStatus();
    // this noise is  probably unnecessary, but doing it anyway to be safe
    /* istanbul ignore next */
    if (status instanceof Error) {
      throw status;
    }
    if (status.CurrentScreen === SKUDisplay) {
      throw new Error(DeviceBusy);
    } else if (status.CurrentScreen !== IdleScreen) {
      throw new Error(DeviceNotReady);
    } else {
      return status;
    }
  } catch (err) {
    // if the device is not connected, we expect the error message to look like:
    // `request to
    // http://${config.CEDHostname}:8080/v2/pos?Action=Status&Format=JSON failed, reason: connect EHOSTDOWN ${config.CEDHostname}:8080 Local (192.168.86.240:55099)`
    if ((err as Err).toString().indexOf('EHOSTDOWN') > -1) {
      throw new Error(DeviceUnreachable);
    } else {
      // TODO: As we experience more error messages, let’s unwrap them into
      // useful error messages for the application
      throw err;
    }
  }
}

// In the case a request experiences a timeout, we need to be able to request
// the details of a transaction via it’s orderId. transportKeys are a private
// detail of Cayan and not exposed outside of this module.
const keysByOrderId = new Map<string, string>();
export default function create(
  config: Config,
  logger: Logger,
): Promise<Device> {
  const client = CayanClient.create(config);
  let cancelSignal: null | symbol = null;
  return Promise.resolve({
    async cancelTransaction() {
      try {
        const result = await client.cancel();
        if (result.Status === 'Denied') {
          throw new Error(UnableToCancelTransaction);
        }
      } catch (err) {
        cancelSignal = Symbol.for('TransactionAbortSignal');
        throw Symbol.for('TransactionAbortSignal');
      }
      return 'Okay';
    },
    async voidTransaction(request: VoidRequest) {
      const voidRequest = {
        siteId: config.siteId,
        // 0-100 The token identifier returned from a previous transaction.
        // Note: Either Token or MerchantTransactionId is required.
        transactionId: request.orderId,

        // 0-100 The identifier for the register or point of sale device submitting the transaction.
        // RegisterNumber: request.clerkId,
        // 0-100 The merchant-defined identifier for the transaction. Note: Either Token or MerchantTransactionId is required.
        referenceNumber: request.transactionId,
        terminalId: '',
        userId: request.clerkId,
      };

      const stagedRaw = (await fetch(`${ROOT_PROXY_URL}/client/payment/void`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${config.accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(voidRequest),
      }).then(res => res.json())) as CayanVoidProxyResponse;

      if (stagedRaw.data.status === 'Unknown') {
        throw new Error(UnableToVoidOrder);
      }
      logger({
        authorizationCode: stagedRaw.data.authorizationCode,
        token: stagedRaw.data.referenceNumber,
        status: stagedRaw.data.status,
      });
      return transformCayanToVoidResponse(stagedRaw);
    },

    async requestTransaction(transaction: TransactionRequest) {
      // VERIFY: is it possible for a TransactionAbortSignal to exist here that
      // we need to clean up with something like `if(signal) resetSignal()`
      const orderId = transaction.orderId.slice(0, 8);
      // this will throw if the device is not ready.
      await isDeviceReady(client);
      //This call is breaking the e285
      // const order = await client.startOrder(orderId);
      // if (order instanceof Error || order.Status !== 'Success') {
      //   throw new Error(UnableToCreateOrder);
      // }
      const transactionInfo = {
        siteId: config.siteId,
        requestType: ProxyTransactionType.Sale,
        totalAmount: transaction.amount,
        qhpAmount: transaction.qhpSubtotal,
        transactionId: orderId,
        terminalId: config.processorLaneId,
        userId: transaction.clerkId,
        purchaseOrderNumber: orderId,
      };

      const stagedRaw = (await fetch(
        `${ROOT_PROXY_URL}/client/payment/charge`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${config.accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(transactionInfo),
        },
      ).then(res => {
        if (res.ok) {
          return res.json();
        } else {
          switch (res.status) {
            case 401:
              return new Error(CayanProxyAuthFailure);
            default:
              return new Error(UnableToCreateOrder);
          }
        }
      })) as CayanTransactionProxy;
      if (cancelSignal) {
        const tmp = cancelSignal;
        cancelSignal = null;
        throw tmp;
      }

      if (stagedRaw instanceof Error) {
        throw stagedRaw;
      }

      const staged = stagedRaw.data;
      if (
        staged.validationKey === '' ||
        staged.transportKey === '' ||
        staged.messages.length > 0
      ) {
        logger({
          transportKey: staged.transportKey,
          authorizationCode: '',
          token: '',
          status: staged.messages.join('\n'),
          message: staged.messages.join('\n'),
        });
        throw new Error(UnableToCreateOrder);
      }

      keysByOrderId.set(orderId, staged.transportKey);
      const initiated = await client.initiateTransaction(staged.transportKey);
      /* istanbul ignore next */
      if (initiated instanceof Error) {
        // TODO: introspect these error messages and provide human errors
        throw {...initiated, orderId};
      } else {
        logger({
          transportKey: staged.transportKey,
          authorizationCode: initiated.AuthorizationCode,
          token: initiated.Token,
          status: initiated.Status,
          message: initiated.ErrorMessage,
        });
        return transformCayanToTransactionResponse(
          // we have a more explicit interface that refines some strings to
          // unions
          initiated as CayanTransactionResponse,
          transaction,
        );
      }
    },

    async requestTransactionDetails(request: TransactionDetailsRequest) {
      if (!keysByOrderId.has(request.orderId)) {
        // in reality we get undefined back from cayan for these accounts in
        // error scenarios, but we are lying to the type system and are assuming
        // that consumers are switching on the status before looking at details
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const undefinedString = (undefined as any) as string;
        return Promise.resolve({
          accountNumber: undefinedString,
          amountApproved: 0,
          entryMode: 'Vault',
          paymentType: '',
          status: 'FAILED',
          transactionDate: new Date().toJSON(),
          transactionId: undefinedString,
          rawResponse: '{}',
        });
      }
      const transportKey = keysByOrderId.get(request.orderId);
      const siteId = config.siteId;
      const staged = (await fetch(`${ROOT_PROXY_URL}/client/payment/detail`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${config.accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({siteId, transportKey}),
      }).then(onApiResponse)) as CayanVaultProxyResponse;

      if (staged instanceof Error) {
        throw staged;
      }

      logger({
        transportKey,
        authorizationCode: staged.data.authorizationCode,
        token: '', // initiated.Token,
        status: staged.data.status,
        // TODO: is our proxy mapping error messages?
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message: staged.errorMessage,
      });
      return transformCayanVaultToTransactionResponse(staged.data);
    },

    async requestVaultTransaction(transaction: TransactionVaultRequest) {
      const orderId = transaction.orderId.slice(0, 8);
      const data = {
        siteId: config.siteId,
        credentialType: 'TRANSPORT',
        token: transaction.vaultToken,
        qhpAmount: transaction.qhpSubtotal,
        totalAmount: transaction.amount,
        transactionId: orderId,
        terminalId: '',
        userId: transaction.clerkId,
        purchaseOrderNumber: transaction.orderId,
      };
      const staged = (await fetch(
        `${ROOT_PROXY_URL}/client/payment/charge/tokenized`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${config.accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      ).then(onApiResponse)) as CayanVaultProxyResponse;

      if (staged instanceof Error) {
        throw staged;
      }

      logger({
        status: staged.data.status,
      });
      return transformCayanVaultToTransactionResponse(staged.data);
    },
  });
}

function onApiResponse(res: Response) {
  if (res.ok) {
    return res.json();
  } else {
    switch (res.status) {
      case 500:
        return new Error(UnableToMakeVaultPayment);
      case 401:
        return new Error(CayanProxyAuthFailure);
      default:
        return new Error(TODO_CayanUnknownFailure);
    }
  }
}
