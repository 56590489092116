import createCayanInstance from './Cayan';
import createVantivInstance from './Vantiv';
import {CayanConfig, VantivConfig, Device, Logger} from './Types';

export enum Processor {
  Cayan,
  Vantiv,
}

/* istanbul ignore next */
export function processorToString(
  p: Processor,
): 'Cayan' | 'Vantiv' | '<unknown>' {
  switch (p) {
    case Processor.Cayan:
      return 'Cayan';
    case Processor.Vantiv:
      return 'Vantiv';
    default:
      return '<unknown>';
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

export function create(
  processor: Processor,
  config: VantivConfig | CayanConfig,
  logger: Logger = noop,
): Promise<Device> {
  switch (processor) {
    case Processor.Cayan:
      return createCayanInstance(config as CayanConfig, logger);
    case Processor.Vantiv:
      return createVantivInstance(config as VantivConfig, logger);
  }
}

export async function fetchWithTimeout(
  resource: RequestInfo,
  options: RequestInit & {timeout: number},
): Promise<Response> {
  const {timeout} = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}
