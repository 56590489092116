/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Peripheral,
    PeripheralFromJSON,
    PeripheralFromJSONTyped,
    PeripheralToJSON,
} from './';

/**
 * 
 * @export
 * @interface SessionPeripheral
 */
export interface SessionPeripheral {
    /**
     * 
     * @type {string}
     * @memberof SessionPeripheral
     */
    sessionId: string;
    /**
     * 
     * @type {number}
     * @memberof SessionPeripheral
     */
    peripheralId: number;
    /**
     * 
     * @type {Peripheral}
     * @memberof SessionPeripheral
     */
    peripheral?: Peripheral;
    /**
     * 
     * @type {string}
     * @memberof SessionPeripheral
     */
    dataVersion?: string | null;
}

export function SessionPeripheralFromJSON(json: any): SessionPeripheral {
    return SessionPeripheralFromJSONTyped(json, false);
}

export function SessionPeripheralFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionPeripheral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'peripheralId': json['peripheralId'],
        'peripheral': !exists(json, 'peripheral') ? undefined : PeripheralFromJSON(json['peripheral']),
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
    };
}

export function SessionPeripheralToJSON(value?: SessionPeripheral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'peripheralId': value.peripheralId,
        'peripheral': PeripheralToJSON(value.peripheral),
        'dataVersion': value.dataVersion,
    };
}


