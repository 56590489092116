import {ScrollContainer, Stack} from '@emporos/components';
import BillBoard from './BillBoard';

interface Props {
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
}

export default function CashPayment({
  amount,
  setAmount,
  totalDue,
}: Props): JSX.Element {
  return (
    <>
      <ScrollContainer>
        <Stack style={{flex: 1}}>
          <BillBoard
            value={amount}
            onChange={setAmount}
            totalDue={totalDue}
            showNumberButtons
          />
        </Stack>
      </ScrollContainer>
    </>
  );
}
