import {Redirect, RouteComponentProps} from '@reach/router';
import {memo} from 'react';
import {useGlobalData} from '../../../contexts/GlobalDataProvider';
import {useTransactionsState} from '../../../contexts/TransactionsStateProvider';
import useInvoice from '../../../hooks/useInvoice';
import useTotals from '../../../hooks/useTotals';
import Completed from './CompletedTransaction';
import {withRootPage} from '../../../hocs/withChildPage';

function unimplemented() {
  throw new Error('unimplemented!');
}

function CompletedTransactionIntegration(_: RouteComponentProps): JSX.Element {
  const {currentInvoiceId} = useTransactionsState();
  const {paymentTendersResult} = useGlobalData();
  const {invoice, updateInvoice} = useInvoice();
  const {totals} = useTotals();

  if (!paymentTendersResult || !paymentTendersResult.data) {
    return <Redirect to="/sales" noThrow />;
  }

  return (
    <Completed
      currentInvoiceId={currentInvoiceId}
      invoice={invoice}
      totals={totals}
      paymentTenders={paymentTendersResult.data}
      onUpdateInvoice={updateInvoice}
      onEmailReceipt={unimplemented}
      onPrintReceipt={unimplemented}
    />
  );
}

export default memo(withRootPage(CompletedTransactionIntegration));
