import {RouteComponentProps} from '@reach/router';
import {withChildPage} from '../../../../../../hocs/withChildPage';
import {useGlobalData} from '../../../../../../contexts/GlobalDataProvider';
import assert from 'assert';
import mapAcknowledgement from '../utils';
import useInvoice from '../../../../../../hooks/useInvoice';
import {memo} from 'react';
import AcknowledgementsInfo from './AcknowledgementInfo';

const AcknowledgementsInfoIntegration = ({navigate}: RouteComponentProps) => {
  const {settingsResult, paymentTendersResult} = useGlobalData();
  const {invoice} = useInvoice();

  assert(settingsResult && settingsResult.data, 'Missing settingsResult');
  assert(
    paymentTendersResult && paymentTendersResult.data,
    'Missing paymentTendersResult',
  );
  assert(navigate, 'Missing navigate');

  const {
    rxMessage,
    arMessage,
    ccMessage,
    sudafedMessage,
    noSafetyCapMessage,
  } = mapAcknowledgement(
    invoice,
    settingsResult.data,
    paymentTendersResult.data,
  );

  return (
    <AcknowledgementsInfo
      rxMessage={rxMessage}
      arMessage={arMessage}
      ccMessage={ccMessage}
      sudafedMessage={sudafedMessage}
      noSafetyCapMessage={noSafetyCapMessage}
      navigate={navigate}
    />
  );
};

export default memo(withChildPage(AcknowledgementsInfoIntegration));
