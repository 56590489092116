import {createContext, useCallback, useContext, useState} from 'react';
import {Customer} from '@emporos/api-enterprise/src/gen-session';
import {mapInvoiceCustomer} from '../utils/mappers';
import useOpenApi from '../api/useOpenApi';
import {CustomersApi} from '@emporos/api-enterprise/src/gen';
import useInvoice from '../hooks/useInvoice';

type CustomerSearchContext = {
  customer: Customer | null;
  setCustomer: (customer: Customer | null) => void;
  refresh: () => Promise<void>;
};

export const CustomerSearchContext = createContext<CustomerSearchContext>({
  customer: null,
  setCustomer: () => null,
  refresh: async () => console.log(),
});

type CustomerSearchProviderProps = {children: React.ReactNode};

export function CustomerSearchProvider(
  props: CustomerSearchProviderProps,
): JSX.Element {
  const {invoice} = useInvoice();
  const [customer, setCustomer] = useState<Customer | null>(
    invoice.customer || null,
  );
  const {run: getCustomerById} = useOpenApi(
    CustomersApi,
    'clientCustomersCustomerIdGet',
  );

  const refresh = useCallback(async () => {
    if (!customer) {
      return;
    }
    return getCustomerById({customerId: customer.id}).then(
      ({data}) => data && setCustomer(mapInvoiceCustomer(data)),
    );
  }, [customer]);

  return (
    <CustomerSearchContext.Provider value={{customer, setCustomer, refresh}}>
      {props.children}
    </CustomerSearchContext.Provider>
  );
}

export const useCustomerSearch = (): CustomerSearchContext =>
  useContext(CustomerSearchContext);
