/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client API (Palpatine)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    PaymentType,
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentTypeListApiResponse
 */
export interface PaymentTypeListApiResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentTypeListApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof PaymentTypeListApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {Array<PaymentType>}
     * @memberof PaymentTypeListApiResponse
     */
    data?: Array<PaymentType> | null;
}

export function PaymentTypeListApiResponseFromJSON(json: any): PaymentTypeListApiResponse {
    return PaymentTypeListApiResponseFromJSONTyped(json, false);
}

export function PaymentTypeListApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTypeListApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(PaymentTypeFromJSON)),
    };
}

export function PaymentTypeListApiResponseToJSON(value?: PaymentTypeListApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(PaymentTypeToJSON)),
    };
}


