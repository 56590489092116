/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Client Session Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEV LTS 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Invoice,
    InvoiceFromJSON,
    InvoiceToJSON,
    InvoiceRequest,
    InvoiceRequestFromJSON,
    InvoiceRequestToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdInvoicesGetRequest {
    sessionId: string;
    includeChildren?: boolean;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePostRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdDeleteRequest {
    sessionId: string;
    invoiceId: string;
}

export interface ClientCacheSessionsSessionIdInvoicesInvoiceIdGetRequest {
    sessionId: string;
    invoiceId: string;
    includeChildren?: boolean;
}

export interface ClientCacheSessionsSessionIdInvoicesPutRequest {
    sessionId: string;
    invoiceRequest?: InvoiceRequest;
}

/**
 * 
 */
export class InvoicesApi extends runtime.BaseAPI {

    /**
     * Gets a list of invoices for a session
     */
    async clientCacheSessionsSessionIdInvoicesGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesGetRequest): Promise<runtime.ApiResponse<Array<Invoice>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceFromJSON));
    }

    /**
     * Gets a list of invoices for a session
     */
    async clientCacheSessionsSessionIdInvoicesGet(requestParameters: ClientCacheSessionsSessionIdInvoicesGetRequest): Promise<Array<Invoice>> {
        const response = await this.clientCacheSessionsSessionIdInvoicesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Indicates that an invoice is ready to be posted as a transaction
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePostRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePostRequest): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePost.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}/complete`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Indicates that an invoice is ready to be posted as a transaction
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePost(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdCompletePostRequest): Promise<Invoice> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdCompletePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdDelete.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an invoice
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdDelete(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdDeleteRequest): Promise<void> {
        await this.clientCacheSessionsSessionIdInvoicesInvoiceIdDeleteRaw(requestParameters);
    }

    /**
     * Gets an invoice by session id and invoice id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdGetRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdGetRequest): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdGet.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling clientCacheSessionsSessionIdInvoicesInvoiceIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChildren !== undefined) {
            queryParameters['includeChildren'] = requestParameters.includeChildren;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices/{invoiceId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Gets an invoice by session id and invoice id
     */
    async clientCacheSessionsSessionIdInvoicesInvoiceIdGet(requestParameters: ClientCacheSessionsSessionIdInvoicesInvoiceIdGetRequest): Promise<Invoice> {
        const response = await this.clientCacheSessionsSessionIdInvoicesInvoiceIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts or updates a invoice
     */
    async clientCacheSessionsSessionIdInvoicesPutRaw(requestParameters: ClientCacheSessionsSessionIdInvoicesPutRequest): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdInvoicesPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/invoices`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceRequestToJSON(requestParameters.invoiceRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a invoice
     */
    async clientCacheSessionsSessionIdInvoicesPut(requestParameters: ClientCacheSessionsSessionIdInvoicesPutRequest): Promise<Invoice> {
        const response = await this.clientCacheSessionsSessionIdInvoicesPutRaw(requestParameters);
        return await response.value();
    }

}
