import {
  BarcodeComponent,
  OtcItem,
  Prescription,
} from '@emporos/api-enterprise/src/gen';
import {Invoice} from '@emporos/api-enterprise/src/gen-session';
import {CompleteBarcodeComponent, decode, ScanResult} from '@emporos/barcodes';
import {
  Button,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  PendingChangesModal,
  Stack,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import {useCallback, useState} from 'react';
import {OfflineInvoice} from '../../../../api/common';
import {useAlertState} from '../../../../contexts/AlertStateProvider';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../../contexts/AnalyticsProvider';
import {
  AlertLogTypes,
  useLog,
  UserLogTypes,
} from '../../../../contexts/LoggingProvider';
import OtcSearch from './OtcSearch';
import {barcode} from '../utils';

export type AddItemsResult = {otc?: OtcItem[]; rx?: Prescription[]};

interface Props {
  onAdd: (result: AddItemsResult) => void;
  barcodeComponents: BarcodeComponent[];
  currentInvoiceId: Invoice['invoiceId'];
  invoice: OfflineInvoice;
  loading?: boolean;
  onCancel: () => void;
  online?: boolean;
  otcItems?: OtcItem[];
  pmsName: string;
  title: string;
  invoiceChanged: boolean;
}

export default function OtcItemsPanel({
  barcodeComponents,
  invoice,
  invoiceChanged,
  loading,
  // online,
  otcItems,
  pmsName,
  title,
  onAdd,
  onCancel,
}: Props): JSX.Element {
  const {notification, reset} = useAlertState();
  const {track} = useAnalyticsProvider();
  const {logAlert, logUserSelection} = useLog();
  const [selectedOtcItems, setSelectedOtcItems] = useState<Array<OtcItem>>([]);
  const [scrollIndex, setScrollIndex] = useState<number>();
  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);
  const customer = invoice?.customer;

  const addItems = useCallback(() => {
    onAdd({
      otc: selectedOtcItems,
    });
  }, [customer, selectedOtcItems]);

  const canSave = selectedOtcItems.length;
  const cancelWithModal = customer && invoiceChanged;

  const _onCancel = useCallback(() => {
    if (cancelWithModal) {
      logAlert(AlertLogTypes.PendingChanges, {
        location: 'OtcItemsPanel',
      });
      setShowPendingChangesModal(true);
    } else {
      onCancel();
    }
  }, [onCancel, customer]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setShowPendingChangesModal(false);
    addItems();
  }

  const handleOTCScan = useCallback(
    (scanResult: ScanResult) => {
      if (!otcItems) {
        return;
      }
      const _barcode = barcode(scanResult);

      const otcItem =
        otcItems && otcItems.find(otcItem => otcItem.itemNumber === _barcode);

      if (otcItem) {
        setScrollIndex(
          otcItems.findIndex(
            ({itemNumber}) => itemNumber === otcItem.itemNumber,
          ),
        );
        if (otcItem.itemType.id === 16 && invoice.pseCheckResult === 1) {
          notification({
            title: 'PSE Items Locked',
            description:
              'The user has already passed the NPLEx check and these items cannot be edited.',
            type: 'warning',
            icon: 'Warning',
          });
        } else {
          setSelectedOtcItems(selectedRx => [...selectedRx, otcItem]);
        }
        logUserSelection(UserLogTypes.ScannedOTC, {
          result: otcItem.description,
        });
      } else {
        notification({
          title: 'Item Not Found',
          description: 'Item couldn’t be found in the database.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.ScanningError, {
          message: 'Scanned OTC item couldn’t be found in the database.',
        });
      }
    },
    [otcItems],
  );

  const onScan = useCallback(
    (scanResult: ScanResult) => {
      const item = decode(
        barcodeComponents as CompleteBarcodeComponent[],
        scanResult.raw,
        pmsName,
      );
      reset();

      if (item.rx && item.partial && item.fill && item.site) {
        notification({
          title: 'Item Not Found',
          description: 'Item couldn’t be found in the database.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.ScanningError, {
          message: 'Scanned RX item in OTC search.',
        });
      } else {
        handleOTCScan(scanResult);
      }
    },
    [barcodeComponents, pmsName, handleOTCScan],
  );

  return (
    <>
      <Stack
        gutter={Gutter.XL}
        justify="stretch"
        style={{height: 'calc(var(--vh, 1vh) * 100 - 140px)'}}
        as="form"
        onSubmit={onSubmit}
      >
        <Header title={title} />

        <OtcSearch
          lockPseItems={invoice.pseCheckResult === 1}
          otcItems={otcItems}
          scrollToIndex={scrollIndex}
          selectedOTCItems={selectedOtcItems}
          setSelectedOTCItems={setSelectedOtcItems}
        />

        <FooterGroup>
          <Button
            type="button"
            onClick={_onCancel}
            variant={cancelWithModal ? BV.Danger : BV.Secondary}
            flex
          >
            Cancel
          </Button>
          <Button
            variant={BV.Primary}
            type="submit"
            flex
            loading={loading}
            disabled={!canSave}
          >
            Add to Sale
          </Button>
        </FooterGroup>
      </Stack>
      <PageBarcodeScanner onScan={onScan} />
      <PendingChangesModal
        visible={showPendingChangesModal}
        onCancel={() => setShowPendingChangesModal(false)}
        onContinue={onCancel}
      />
    </>
  );
}
