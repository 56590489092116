import IntermissionThemed from '../components/IntermissionThemed';
import config from '../config';

export const oidcCallbackComponents = {
  notAuthenticated: function NotAuthenticated(): JSX.Element {
    return <IntermissionThemed error="You are not logged in." />;
  },
  notAuthorized: function NotAuthorized(): JSX.Element {
    return (
      <IntermissionThemed error="You are not authorized to access this resource." />
    );
  },
  authenticating: function Authenticating(): JSX.Element {
    return <IntermissionThemed />;
  },
  callbackComponentOverride: function CallbackComponentOverride(): JSX.Element {
    return <IntermissionThemed />;
  },
  sessionLostComponent: function SessionLostComponent(): JSX.Element {
    return <IntermissionThemed error="Session lost, please log in again." />;
  },
};

export const oidcConfiguration = {
  monitorSession: false,
  client_id: config.OidcClient,
  redirect_uri: `${window.location.origin}/signin-oidc`,
  response_type: 'code',
  post_logout_redirect_uri: `${window.location.origin}/login`,
  scope: 'openid profile emp-v10 clientapi',
  authority: config.OidcAuthority,
  silent_redirect_uri: `${window.location.origin}/authentication/silent_callback`,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
};
