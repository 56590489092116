import {Customer} from '@emporos/api-enterprise/src/gen-session';
import {useCallback, useState} from 'react';
import {useAlertState} from '../contexts/AlertStateProvider';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../contexts/AnalyticsProvider';
import {Invoice} from '../contexts/TransactionsStateProvider';
import {activeInvoiceCustomersIds} from '../utils/customer';
import useInvoice from './useInvoice';
import useSession from './useSession';
import {OfflineInvoice, OfflineSynced} from '../api/common';

function invoiceHasOtherCustomer(customer: Customer, invoice: Invoice) {
  return invoice && invoice.customer && invoice.customer.id !== customer.id;
}

function customerIsActive(customer: Customer, activeCustomers: number[]) {
  return (
    Boolean(activeCustomers?.length) &&
    activeCustomers.some(customerId => customerId === customer.id)
  );
}

type UsePendingInvoiceHook = {
  invoice: Invoice;
  invoiceChanged: boolean;
  commit(changes?: Partial<OfflineInvoice>): void;
  tryAttachCustomer(customer: Customer | null): void;
};

export function usePendingInvoice(): UsePendingInvoiceHook {
  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();

  const {invoice, updateInvoice} = useInvoice();
  const {pendingInvoices} = useSession();
  const [pendingInvoice, setPendingInvoice] = useState({...invoice});
  const tryAttachCustomer = useCallback(
    (customer: Customer | null) => {
      if (!customer) {
        return;
      }

      if (customer && customer.id === invoice.customerId) {
        setPendingInvoice({...invoice, customer, customerId: customer.id});
        return;
      }

      if (invoiceHasOtherCustomer(customer, invoice)) {
        notification({
          title: "Customer Doesn't Match",
          description:
            'This prescription doesn’t match the customer attached to the sale.',
          type: 'error',
          icon: 'X',
        });
        track(AnalyticType.UserError, {
          message:
            'User scanned prescription that does not match the customer attached to the sale.',
        });
        return;
      }

      if (
        customerIsActive(customer, activeInvoiceCustomersIds(pendingInvoices))
      ) {
        notification({
          title: 'Customer Already Active',
          description: 'This customer is already attached to a transaction.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.UserError, {
          message: 'User attempted to add an active customer to another sale.',
        });
        return;
      }

      setPendingInvoice({...invoice, customer, customerId: customer.id});
    },
    [invoice],
  );

  const invoiceChanged = invoice.customer?.id !== pendingInvoice.customer?.id;

  const commit = useCallback(
    (changes?: Partial<Invoice>) => {
      const nextInvoice = {
        ...pendingInvoice,
        ...changes,
        dataVersion: invoice.dataVersion,
      };
      updateInvoice(prevInvoice => ({
        ...nextInvoice,
        isSynced: false,
        items: prevInvoice.items
          .filter(item => (item as OfflineSynced).isDeleted)
          .concat(nextInvoice.items),
      }));
      setPendingInvoice(nextInvoice);
    },
    [invoice, pendingInvoice, updateInvoice],
  );

  return {
    invoice: pendingInvoice,
    invoiceChanged,
    commit,
    tryAttachCustomer,
  };
}
