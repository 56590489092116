import {
  VantivConfig as Config,
  Device,
  Logger,
  VoidRequest,
  VoidResponse,
  TransactionRequest,
  TransactionResponse,
  TransactionDetailsRequest,
  TransactionVaultRequest,
  transformTransactionToVantiv,
  transformVoidToVantiv,
  transformVantivToTransactionResponse,
  transformVantivToVoidResponse,
} from './Types';
import {
  Link,
  VantivBaseResponse,
  VantivSalesResponse,
  VantivReversalResponse,
} from './VantivTypes';
import {v4 as uuid} from 'uuid';
import {
  InvalidConfiguration,
  VantivUnknownFailure as UnknownFailure,
} from './ErrorMessages';

const triposRoot = 'https://triposcert.worldpay.com/api/';
// TODO: enable opt-in to real money in configuration
// https://tripos.worldpay.com/api/

interface VantivConfig {
  acceptorId: string;
  accountId: string;
  accountToken: string;

  terminalId: string;
  processorLaneId: number;
  applicationId: string;
  applicationName: string;
  applicationVersion: string;
}

function mapCredentials(c: Config): VantivConfig {
  return {
    acceptorId: c.gatewayId,
    accountId: c.gatewayUsername,
    accountToken: c.gatewayPassword,
    // The ApplicationId is hardcoded per Paul Blick with WorldPay
    // TODO: get a new application ID for this application during certification
    terminalId: c.gatewayTerminal,
    applicationId: c.partnerId,
    processorLaneId: c.processorLaneId,

    applicationName: 'Emporos.POS',
    applicationVersion: '1.0.0',
  };
}

function baseHeaders(config: VantivConfig): Headers {
  const headers = new Headers();
  // from https://developer.vantiv.com/docs/DOC-2483#jive_content_id_Required_HTTP_Request_Headers
  headers.append('tp-authorization', 'Version=2.0');
  headers.append('tp-application-id', config.applicationId);
  headers.append('tp-application-name', config.applicationName);
  headers.append('tp-application-version', config.applicationVersion);
  headers.append('tp-request-id', uuid());
  headers.append('tp-express-acceptor-id', config.acceptorId);
  headers.append('tp-express-account-id', config.accountId);
  headers.append('tp-express-account-token', config.accountToken);
  headers.append(
    'tp-return-logs',
    (process.env.NODE_ENV !== 'production').toString(),
  );
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  return headers;
}

const SecretConstructorToken = Symbol('VantivHATEOS/secret');
class VantivHATEOS<R extends VantivBaseResponse> {
  response: R;
  headers: Headers;
  config: VantivConfig;

  constructor(
    secretToken?: typeof SecretConstructorToken,
    response?: R,
    headers?: Headers,
    config?: VantivConfig,
  ) {
    /* istanbul ignore next */
    if (
      secretToken !== SecretConstructorToken ||
      !response ||
      !headers ||
      !config
    ) {
      throw new Error('new VantivHATEOS() is unsupported');
    }
    this.headers = headers;
    this.response = response;
    this.config = config;
  }

  static async create<R extends VantivBaseResponse>(
    link: Link,
    config: VantivConfig,
    // eslint-disable-next-line @typescript-eslint/ban-types
    payload?: object,
  ): Promise<VantivHATEOS<R>> {
    const headers = baseHeaders(config);
    const result = await fetch(link.href, {
      method: link.method,
      headers,
      body: payload ? JSON.stringify(payload) : null,
    })
      .then(async res => {
        if (res.ok) {
          return res.json();
        } else {
          // console.error(res);
          // unwrap the error
          throw await res.json();
        }
      })
      .then(json => {
        // TODO: handle _errors, _hasErrors, _logs, and _warnings
        return json;
      })
      .catch(err => {
        /* istanbul ignore else */
        if (err.exceptionType === 'System.ArgumentException') {
          throw new TypeError(InvalidConfiguration);
        } else {
          // TODO: pass this to our analytics service
          // console.log(err);
          throw new Error(UnknownFailure);
        }
      });

    /*
    console.log(
      'create(%s: %s)\n  %o\n  %o',
      link.method || 'GET',
      link.href,
      payload,
      result,
    );
    */
    return new VantivHATEOS<R>(SecretConstructorToken, result, headers, config);
  }

  // this is really only for snapshot testing and debugging
  getLinks() {
    return this.response._links.reduce((acc, link) => {
      acc[link.rel] = [link.method, link.href];
      return acc;
    }, {} as {[key: string]: [string, string]});
  }

  async follow<R extends VantivBaseResponse>(
    name: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    payload?: object,
  ) {
    const link = this.response._links.find(res => res.rel === name);
    /* istanbul ignore else */
    if (link) {
      // console.log('follow(%s)', link.method + ': ' + link.href, payload);
      return await VantivHATEOS.create<R>(link, this.config, payload);
    } else {
      throw new Error(`Unable to find resource "${name}"`);
    }
  }
}
type Create = (config: Config, logger: Logger) => Promise<Device>;
function memoConfig(creater: Create) {
  let result: Promise<Device>;
  let lastConfig: Config;
  return function (curConfig: Config, curLogger: Logger): Promise<Device> {
    if (lastConfig != curConfig) {
      result = creater(curConfig, curLogger);
      lastConfig = curConfig;
    }
    return result;
  };
}

export default memoConfig(async function create(
  config: Config,
  logger: Logger,
): Promise<Device> {
  const requestConfig = mapCredentials(config);
  const api = await VantivHATEOS.create<VantivBaseResponse>(
    {rel: 'root', href: `${triposRoot}v1`, method: 'GET'},
    requestConfig,
  );
  /* istanbul ignore next */
  switch (api.constructor) {
    case Error:
    case TypeError:
      throw api;
      throw api;
    default:
    // hey everything looks good. Let’s accept some money!
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transactionHateosLinks: Record<string, VantivHATEOS<any>> = {};

  const client: Device = {
    async cancelTransaction() {
      return Promise.resolve('Okay');
    },

    async voidTransaction(request: VoidRequest): Promise<VoidResponse> {
      const transactionApi = transactionHateosLinks[request.transactionId];
      const {response, headers} = await transactionApi.follow<
        VantivReversalResponse
      >('reversal', transformVoidToVantiv(request, config));
      // For the Vantiv tests we have a convenient helper to refresh the
      // response data in the fixtures file. This is ignored from test
      // coverage and DCE’d during a production build.
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        if (process.env.FIXTURE_FILE) {
          const {writeFileSync} = await require('fs');
          writeFileSync(
            __dirname + '/__tests__/' + process.env.FIXTURE_FILE,
            JSON.stringify(response, null, 2),
            'utf8',
          );
        }
      }

      logger(headers.get('tp-request-id'), response);
      return transformVantivToVoidResponse(response);
    },

    async requestTransaction(
      transaction: TransactionRequest,
    ): Promise<TransactionResponse> {
      const client = await api.follow<VantivSalesResponse>(
        'sale',
        transformTransactionToVantiv(transaction, config),
      );
      // For the Vantiv tests we have a convenient helper to refresh the
      // response data in the fixtures file. This is ignored from test
      // coverage and DCE’d during a production build.
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        if (process.env.FIXTURE_FILE) {
          const {writeFileSync} = await require('fs');
          writeFileSync(
            __dirname + '/__tests__/' + process.env.FIXTURE_FILE,
            JSON.stringify(client.response, null, 2),
            'utf8',
          );
        }
      }
      const {response, headers} = client;
      transactionHateosLinks[response.transactionId] = client;

      logger(headers.get('tp-request-id'), response);
      return transformVantivToTransactionResponse(response);
    },

    async requestTransactionDetails(
      _transaction: TransactionDetailsRequest,
    ): Promise<TransactionResponse> {
      throw new Error('unimplemented!');
    },

    async requestVaultTransaction(
      _transaction: TransactionVaultRequest,
    ): Promise<TransactionResponse> {
      throw new Error('unimplemented!');
    },
  };
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production') {
    client.__TEST_UTILS__ = {
      getClient() {
        return api;
      },
    };
  }
  return client;
});
