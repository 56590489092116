import assert from 'assert';

const configDefaults = {
  EnableBetaFeatures: 'false',
  OidcClient: 'client-anakin',
};

const configShape = {
  // General
  NodeEnv: {type: String, key: 'NODE_ENV' as const},
  ClientApiUrl: {type: String, key: 'CLIENT_API_URL' as const},
  EnableBetaFeatures: {type: Boolean, key: 'ENABLE_BETA_FEATURES' as const},
  // OIDC
  OidcAuthority: {type: String, key: 'OIDC_AUTHORITY' as const},
  OidcClient: {type: String, key: 'OIDC_CLIENT' as const},
  // License
  ScanditLicenseKey: {type: String, key: 'SCANDIT_LICENSE_KEY' as const},
  DataDogClientToken: {type: String, key: 'DATADOG_CLIENT_TOKEN' as const},
  SegmentWriteKey: {type: String, key: 'SEGMENT_WRITE_KEY' as const},
};

export type ConfigShape = {
  [K in keyof typeof configShape]: ReturnType<typeof configShape[K]['type']>;
};

type Keys = {
  [K in keyof typeof configShape]: typeof configShape[K]['key'];
}[keyof typeof configShape];

type OptionalConfigKey = keyof typeof configDefaults;

const root = ((window as unknown) as {
  __EMPOROS_CONFIG__: {[K in Keys]?: string};
}).__EMPOROS_CONFIG__;

const names = Object.keys(configShape) as (keyof typeof configShape)[];

export default names.reduce((config, name) => {
  const {key, type} = configShape[name];
  const value = root[key] || configDefaults[name as OptionalConfigKey];
  assert(
    value,
    `Failed to configure app: missing required environment variable "${key}"`,
  );
  // eslint-disable-next-line
  // @ts-ignore
  config[name] = type(value);
  return config;
}, {} as ConfigShape);
