import {
  Button,
  Variant as BV,
  Gutter,
  Header,
  Row,
  Stack,
} from '@emporos/components';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import {NavigateFn} from '@reach/router';
import {memo} from 'react';

interface Props {
  rxMessage?: string;
  arMessage?: string;
  ccMessage?: string;
  sudafedMessage?: string;
  noSafetyCapMessage?: string;
  navigate: NavigateFn;
}

const AcknowledgementsInfo = ({
  rxMessage,
  arMessage,
  ccMessage,
  sudafedMessage,
  noSafetyCapMessage,
  navigate,
}: Props) => {
  return (
    <Stack data-testid="AcknowledgementsPanel">
      <Header title="Acknowledgements" />

      <Stack style={{flex: 1}} gutter={Gutter.XXL}>
        {rxMessage && <Text variant={TV.Main}>{rxMessage}</Text>}
        {arMessage && <Text variant={TV.Main}>{arMessage}</Text>}
        {ccMessage && <Text variant={TV.Main}>{ccMessage}</Text>}
        {sudafedMessage && <Text variant={TV.Main}>{sudafedMessage}</Text>}
        {noSafetyCapMessage && (
          <Text variant={TV.Main}>{noSafetyCapMessage}</Text>
        )}
      </Stack>

      <Row>
        <Button variant={BV.Secondary} onClick={() => navigate('../')} flex>
          Okay
        </Button>
      </Row>
    </Stack>
  );
};

export default memo(AcknowledgementsInfo);
