import {PaymentType} from '@emporos/api-enterprise/src/gen';
import {Invoice, InvoicePayment} from '@emporos/api-enterprise/src/gen-session';
import {Modal} from '@emporos/components';
import {NavigateFn} from '@reach/router';
import moment from 'moment';
import {
  Dispatch,
  forwardRef,
  memo,
  SetStateAction,
  useImperativeHandle,
  useState,
} from 'react';
import {UpdateInvoiceFn} from '../../../../../hooks/useInvoice';
import {mapInvoicePayment} from '../../../../../utils/mappers';
import {getPaymentTypeIdByPaymentType} from '../utils';
import {DeviceStatus} from './CreditCard';
import {HandleTransactionResponseRef, TransactionResponse} from './Types';

interface Props {
  paymentTenders: PaymentType[];
  status: DeviceStatus;
  setStatus: Dispatch<SetStateAction<DeviceStatus>>;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  invoice: Invoice;
  creditCardPendingPaymentExpirationMinutes: number;
  updateInvoice: UpdateInvoiceFn;
  onCancel: () => void;
  onContinue: () => void;
  navigate: NavigateFn;
}

const HandleTransactionResponse = forwardRef<
  HandleTransactionResponseRef,
  Props
>(function HandleTransactionResponseFn(
  {
    paymentTenders,
    status,
    creditCardPendingPaymentExpirationMinutes,
    setStatus,
    setSelectedPayment,
    invoice,
    updateInvoice,
    onCancel,
    onContinue,
    navigate,
  }: Props,
  ref,
): JSX.Element {
  const [partialAmount, setPartialAmount] = useState<number>();

  useImperativeHandle(ref, () => ({
    handleTransactionResponse,
  }));

  const handleTransactionResponse = (
    result: TransactionResponse,
    pendingPayment?: InvoicePayment,
  ) => {
    switch (result.status) {
      case 'APPROVED': {
        setStatus(DeviceStatus.PaymentSuccess);
        const updates = resultToPartialInvoicePayment(result, {
          recordStatus: 'Active',
        });
        if (pendingPayment) {
          updatePayment(pendingPayment.invoicePaymentId, updates);
        } else {
          addPayment(updates);
        }
        break;
      }
      case 'PARTIAL APPROVED': {
        setStatus(DeviceStatus.PartialPaymentSuccess);
        const updates = resultToPartialInvoicePayment(result, {
          recordStatus: 'Active',
        });
        if (pendingPayment) {
          updatePayment(pendingPayment.invoicePaymentId, updates);
        } else {
          addPayment(updates);
        }
        if (updates.amount) {
          setPartialAmount(updates.amount);
        }
        break;
      }
      case 'OVERCHARGED': {
        setStatus(DeviceStatus.PaymentWarning);
        const updates = resultToPartialInvoicePayment(result, {
          recordStatus: 'OverCharged',
        });
        if (pendingPayment) {
          updatePayment(pendingPayment.invoicePaymentId, updates);
          setSelectedPayment(pendingPayment.invoicePaymentId);
        } else {
          const newPayment = addPayment(updates);
          setSelectedPayment(newPayment.invoicePaymentId);
        }
        return navigate('payment-info');
      }
      case 'DECLINED': {
        setStatus(DeviceStatus.PaymentDeclined);
        if (pendingPayment) {
          deletePendingPayment(pendingPayment.invoicePaymentId);
        }
        break;
      }
      case 'CANCELED': {
        setStatus(DeviceStatus.UserCanceled);
        if (pendingPayment) {
          deletePendingPayment(pendingPayment.invoicePaymentId);
        }
        break;
      }
      case 'EXPIRED CARD': {
        setStatus(DeviceStatus.PaymentExpired);
        if (pendingPayment) {
          deletePendingPayment(pendingPayment.invoicePaymentId);
        }
        break;
      }
      case 'FAILED': {
        if (!pendingPayment) break;
        if (
          moment(pendingPayment.createdOn)
            .add(creditCardPendingPaymentExpirationMinutes, 'minutes')
            .isAfter(moment())
        ) {
          setStatus(DeviceStatus.PaymentProcessing);
        } else {
          setStatus(DeviceStatus.PaymentError);
          deletePendingPayment(pendingPayment.invoicePaymentId);
        }
        break;
      }
      case 'DUPLICATE':
      case 'OUT OF BALANCE':
      case 'HOSTERROR':
      case 'ERROR':
      case 'DECLINED - PICK UP CARD':
      case 'CALL ISSUER':
      case 'NOT DEFINED':
      case 'INVALID DATA':
      case 'INVALID ACCOUNT':
      case 'INVALID REQUEST':
      case 'AUTHENTICATION FAILED':
      case 'NOTACCESSIBLE':
      case 'NOT AUTHORIZED': {
        setStatus(DeviceStatus.PaymentError);
        if (pendingPayment) {
          deletePendingPayment(pendingPayment.invoicePaymentId);
        }
        break;
      }
      default: {
        throw result;
      }
    }
  };

  const resultToPartialInvoicePayment = (
    result: TransactionResponse,
    updates?: Partial<InvoicePayment>,
  ): Partial<InvoicePayment> => ({
    paymentNumber: result.accountNumber.substr(-4),
    amount: Number(result.amountApproved),
    epsReference: result.transactionId,
    paymentTypeID: getPaymentTypeIdByPaymentType(
      result.paymentType,
      paymentTenders,
    ),
    jsonPaymentProcessorResponse: result.rawResponse,
    ...updates,
  });

  const addPayment = (updates: Partial<InvoicePayment>) => {
    const newPayment = mapInvoicePayment(invoice.invoiceId, 0, 0, updates);
    updateInvoice(prevInvoice => ({
      payments: prevInvoice.payments.concat(newPayment),
    }));
    return newPayment;
  };

  const updatePayment = (
    invoicePaymentId: string,
    updates: Partial<InvoicePayment>,
  ) => {
    updateInvoice(prevInvoice => ({
      payments: prevInvoice.payments.map(p => ({
        ...p,
        ...(p.invoicePaymentId === invoicePaymentId && {
          ...updates,
          isSynced: false,
        }),
      })),
    }));
  };

  const deletePendingPayment = (invoicePaymentId: string) =>
    updateInvoice(prevInvoice => ({
      payments: prevInvoice.payments.map(p => ({
        ...p,
        ...(p.invoicePaymentId === invoicePaymentId && {
          isSynced: false,
          isDeleted: true,
        }),
      })),
    }));

  return (
    <>
      <Modal
        visible={status === DeviceStatus.Connecting}
        data-testid="Modal__Connecting"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Connecting to Payment Device"
        subtitle="Establishing connection with the device. Please hold tight."
        buttonText="Cancel"
        onContinue={onCancel}
      />
      <Modal
        visible={status === DeviceStatus.CheckStatus}
        data-testid="Modal__CheckStatus"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Checking Payment Status"
        subtitle="Establishing connection with the gateway to verify status of the payment."
        buttonText="Cancel"
        onContinue={onCancel}
      />
      <Modal
        visible={status === DeviceStatus.CancelTransactionFailed}
        data-testid="Modal__CancelTransactionFailed"
        icon="Spinner"
        color="warning"
        iconSpinning={true}
        title="Unable to cancel transaction at this stage."
        subtitle="Please finish payment on device."
      />
      <Modal
        data-testid="Modal__PaymentSuccess"
        visible={status === DeviceStatus.PaymentSuccess}
        icon="Checkmark"
        color="success"
        onContinue={onContinue}
        buttonText="Okay"
        title="Payment Successful"
        subtitle="The card payment has been processed. Please remove card and give back to customer."
      />
      <Modal
        data-testid="Modal__PartialPaymentSuccess"
        visible={status === DeviceStatus.PartialPaymentSuccess}
        icon="Checkmark"
        color="success"
        onContinue={onContinue}
        buttonText="Okay"
        title="Partial Payment Successful"
        subtitle={
          'A partial payment has been processed. $' +
          partialAmount +
          ' was successfully applied.'
        }
      />
      <Modal
        data-testid="Modal__PaymentOvercharged"
        visible={status === DeviceStatus.PaymentWarning}
        icon="Warning"
        color="warning"
        onContinue={onContinue}
        buttonText="Okay"
        title="Payment Overcharged"
        subtitle={
          'Payment was approved, but there was an overpayment. \r\nPlease void the payment and try again.'
        }
      />
      <Modal
        data-testid="Modal__PaymentDeclined"
        visible={status === DeviceStatus.PaymentDeclined}
        icon="Warning"
        color="warning"
        onContinue={onCancel}
        buttonText="Okay"
        title="Payment Declined"
        subtitle="Customer’s card has been declined. Please use another card or try again."
      />
      <Modal
        data-testid="Modal__PaymentExpired"
        visible={status === DeviceStatus.PaymentExpired}
        icon="Warning"
        color="warning"
        onContinue={onCancel}
        buttonText="Okay"
        title="Expired Card"
        subtitle="Customer’s card has expired. Please use another card or try again."
      />
      <Modal
        data-testid="Modal__PaymentError"
        visible={status === DeviceStatus.PaymentError}
        icon="Warning"
        color="warning"
        onContinue={onCancel}
        buttonText="Okay"
        title="Payment Error"
        subtitle="Transaction could not be processed. Please use another card or try again."
      />
      <Modal
        data-testid="Modal__PaymentProcessing"
        visible={status === DeviceStatus.PaymentProcessing}
        icon="Warning"
        color="warning"
        onContinue={() => setStatus(DeviceStatus.Unknown)}
        buttonText="Okay"
        title="Transaction is Processing"
        subtitle="Please wait for transaction to finalize and “Check Status” of the payment."
      />
      <Modal
        data-testid="Modal__AppCanceled"
        visible={status === DeviceStatus.AppCanceled}
        icon="Warning"
        color="warning"
        onContinue={onCancel}
        buttonText="Okay"
        title="Payment Was Canceled"
        subtitle="Payment request was canceled."
      />
      <Modal
        data-testid="Modal__UserCanceled"
        visible={status === DeviceStatus.UserCanceled}
        icon="Warning"
        color="warning"
        onContinue={onCancel}
        buttonText="Okay"
        title="Payment Was Canceled"
        subtitle="Transaction was canceled from payment device. Please try transaction again."
      />
    </>
  );
});

export default memo(HandleTransactionResponse);
