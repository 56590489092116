import {
  CustomerApiResponse,
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
  PaymentOption,
  PaymentType,
  Setting,
} from '@emporos/api-enterprise/src/gen';
import {
  Customer,
  CustomerAccount,
  InvoicePayment,
  SignatureType,
} from '@emporos/api-enterprise/src/gen-session';
import {
  Button,
  ButtonShape,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  IndicatorCircle,
  Modal,
  Row,
  ButtonSize as Size,
  SmallSidebarWrapper,
  Stack,
} from '@emporos/components';
import Text, {Variant as TV} from '@emporos/components/src/Text';
import {navigate} from '@reach/router';
import assert from 'assert';
import {
  createRef,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  AnalyticType,
  useAnalyticsProvider,
} from '../../../../../contexts/AnalyticsProvider';
import {useCardReaderCredentials} from '../../../../../contexts/CardReaderCredentialsProvider';
import {AlertLogTypes, useLog} from '../../../../../contexts/LoggingProvider';
import {
  Invoice,
  InvoiceUpdates,
} from '../../../../../contexts/TransactionsStateProvider';
import {PaymentProvider} from '../../../../../hooks/usePaymentProvider';
import {TotalsResult} from '../../../../../hooks/useTotals';
import {mapInvoicePayment} from '../../../../../utils/mappers';
import mapComplianceIndicators from '../compliance/ComplianceIndicators';
import mapIdCheckIndicators from '../id-check/IdCheckIndicators';
import {AcceptedPaymentTypes, getPaymentTypeId} from '../utils';
import ARPayment from './AR';
import CashPayment from './Cash';
import CheckPayment from './Check';
import CreditCard from './CreditCard';
import Paid from './Paid';
import PaymentOptions from './PaymentOptions';
import PDPayment from './PD';
import {PaymentInfo, PaymentRef} from './Types';
import UDPayment from './UDP';

const RightSidebar = styled(SmallSidebarWrapper)`
  padding: 0 20px 20px;
  @media (max-width: 1034px) {
    flex: 1;
    padding: 0 12px 20px 12px;
  }
`;

function formatMoney(money: number) {
  return money.toFixed(2);
}

function mapPaymentOptions(paymentOptions: PaymentOption[]): Set<string> {
  return new Set(paymentOptions.map(o => o.displayName));
}

export function mapUDP(
  paymentTenders: PaymentType[],
  paymentOptions: PaymentOption[],
): PaymentType[] {
  return paymentOptions
    .filter(o => o.displayName.startsWith('User'))
    .map(o => paymentTenders.find(t => t.paymentDescriptor === o.displayName))
    .filter(Boolean) as PaymentType[];
}

interface Props {
  customer: Customer | null;
  showCreditCardsOnFile: boolean;
  showVantiv?: boolean;
  showHostedPayment?: boolean;
  creditCardPendingPaymentExpirationMinutes: number;
  isOnline: boolean;
  navigate: typeof navigate;
  credentials: ReturnType<typeof useCardReaderCredentials>['credentials'];
  invoice: Invoice;
  processedPayments: InvoicePayment[];
  totals: TotalsResult;
  settings: Setting[];
  paymentTenders: PaymentType[];
  paymentOptions: PaymentOption[];
  updateInvoice: (updates: InvoiceUpdates) => void;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  onSearchEligibility: (
    query: string,
  ) => Promise<EligibilitySearchMatchListApiResponse>;
  onGetCustomer: () => void;
  onGetEmployee: (
    customer: EligibilitySearchMatch,
  ) => Promise<CustomerApiResponse>;
  onCreateAR: () => Promise<CustomerAccount | undefined>;
  signatureTypes: Array<SignatureType>;
  paymentProvider: PaymentProvider;
}

export default function CustomerPayment({
  customer,
  showCreditCardsOnFile,
  showVantiv,
  showHostedPayment,
  creditCardPendingPaymentExpirationMinutes,
  isOnline,
  navigate,
  credentials,
  invoice,
  processedPayments,
  totals,
  settings,
  paymentTenders,
  paymentOptions,
  updateInvoice,
  onSearchEligibility,
  onGetCustomer,
  onGetEmployee,
  onCreateAR,
  setSelectedPayment,
  signatureTypes,
  paymentProvider,
}: Props): JSX.Element {
  const {track} = useAnalyticsProvider();
  const {logAlert} = useLog();
  const [amount, setAmount] = useState(0);
  const [awaitingContinue, setAwaitingContinue] = useState(false);
  const [canPayWithPD, setCanPayWithPD] = useState(false);
  const [canPayWithAR, setCanPayWithAR] = useState(false);
  const [canPayWithUDP, setCanPayWithUDP] = useState(false);
  const [canPayWithCreditCard, setCanPayWithCreditCard] = useState(false);
  const [changeDueOpen, setChangeDueOpen] = useState(false);
  const [paymentNumber, setPaymentNumber] = useState<string | null>(null);
  const [paymentTypeId, setPaymentTypeId] = useState<number>(0); // TODO make this dynamic
  const [paymentType, setPaymentType] = useState<AcceptedPaymentTypes>(
    '<unknown>',
  );
  const paymentRef = createRef<PaymentRef>();
  const {totalDue, qhpAmount} = totals;
  const showingPaidView = totalDue === 0 && !awaitingContinue;

  const isConfirmPaymentDisabled =
    amount === 0 ||
    !paymentTypeId ||
    (paymentType !== 'Cash' && amount > totalDue) ||
    (paymentType === 'Check' && !paymentNumber) ||
    (paymentType === 'PD' && !canPayWithPD) ||
    (paymentType === 'UDP' && !canPayWithUDP) ||
    (paymentType === 'Credit Card' && !canPayWithCreditCard) ||
    (paymentType === 'AR' && !canPayWithAR);

  useEffect(() => {
    // whenever the payment type changes, we refresh this to exact...
    // this could be implemented by moving amount management to each payment
    // option
    setAmount(paymentType === 'Cash' ? 0 : totals.totalDue);
    if (paymentType !== 'UDP') {
      setPaymentNumber('');
    }

    if (paymentType !== 'Credit Card' && awaitingContinue) {
      setAwaitingContinue(false);
    }
  }, [paymentType, processedPayments.length]);

  const _setPaymentType = useCallback(
    (type: AcceptedPaymentTypes) => {
      setPaymentType(type);
      setPaymentTypeId(getPaymentTypeId(type, paymentTenders) || 0);
    },
    [paymentType, paymentTenders],
  );

  function addPaymentWithoutSideEffects(paymentInfo?: PaymentInfo) {
    const paymentAmount = paymentInfo?.amountApproved || amount;
    const newPayment = mapInvoicePayment(
      invoice.invoiceId,
      paymentInfo?.paymentTypeId || paymentTypeId,
      paymentAmount,
      {
        ...(amount > totalDue && {
          amountReturned: amount - totalDue,
        }),
        paymentNumber: paymentInfo?.paymentId || paymentNumber || '',
        epsReference: paymentInfo?.epsReference,
        recordStatus: paymentInfo?.recordStatus || 'Active',
        jsonPaymentProcessorResponse: paymentInfo?.jsonPaymentProcessorResponse,
      },
    );

    updateInvoice(prevInvoice => ({
      payments: [...prevInvoice.payments, newPayment],
    }));
  }

  function addPayment() {
    addPaymentWithoutSideEffects();
    setChangeDueOpen(false);
  }

  async function onConfirmPayment() {
    // Send info to segment about payment type and amount
    track(AnalyticType.AddPaymentType, {
      total: amount,
      revenueType: paymentType,
    });
    switch (paymentType) {
      case 'Credit Card': {
        // flipping this off is the responsibility of the <CreditCard />
        // experience which has confirmation modals for every action
        setAwaitingContinue(true);
        assert(
          paymentRef.current,
          'Attempting to make a card transaction without the Card UI open',
        );
        await paymentRef.current.onConfirmPayment();
        break;
      }
      case 'AR':
      case 'PD': {
        assert(
          paymentRef.current,
          'Attempting to make a AR transaction without the AR UI open',
        );
        const paymentInfo = await paymentRef.current.onConfirmPayment();
        if (paymentInfo) {
          addPaymentWithoutSideEffects(paymentInfo);
        }
        break;
      }
      // Things we are pretty sure are fine. JK, change can only be given for
      // Cash
      case 'Cash':
        if (amount > totalDue) {
          setChangeDueOpen(true);
          logAlert(AlertLogTypes.ChangeDue);
        } else {
          addPayment();
        }
        break;
      case 'Check':
      case 'UDP':
        addPayment();
        break;
      default:
        console.warn('Should we do special handling for ', paymentType);
    }
  }

  return (
    <>
      <Stack gutter={Gutter.L}>
        <Header title="Customer Payment">
          <ButtonShape
            size={Size.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={() => navigate('customer-info')}
          />
        </Header>

        <Row gutter={Gutter.XXL} style={{height: '100%', minHeight: 0}} noWrap>
          <Stack style={{flex: 2}}>
            {(showingPaidView && <Paid />) ||
              (paymentType === 'Cash' && (
                <CashPayment
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                />
              )) ||
              (paymentType === 'Check' && (
                <CheckPayment
                  amount={amount}
                  setAmount={setAmount}
                  paymentNumber={paymentNumber || ''}
                  setPaymentNumber={setPaymentNumber}
                  totalDue={totalDue}
                />
              )) ||
              (paymentType === 'Credit Card' && (
                <CreditCard
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithCreditCard(
                      paymentRef.current.isAcceptablePayment(),
                    );
                  }}
                  cardsOnFile={
                    (showCreditCardsOnFile && customer?.creditCards) || []
                  }
                  showVantiv={showVantiv}
                  showHostedPayment={showHostedPayment}
                  creditCardPendingPaymentExpirationMinutes={
                    creditCardPendingPaymentExpirationMinutes
                  }
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  qhpAmount={qhpAmount}
                  // TODO: verify what tax amount we should send through. Is
                  // this additive to the totalDue or subtractive or ignored?
                  deviceCredentials={credentials}
                  onContinue={() => setAwaitingContinue(false)}
                  navigate={navigate}
                  paymentTenders={paymentTenders}
                  ref={paymentRef}
                  paymentProvider={paymentProvider}
                  invoice={invoice}
                  updateInvoice={updateInvoice}
                  setSelectedPayment={setSelectedPayment}
                />
              )) ||
              (paymentType === 'AR' && customer && (
                <ARPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithAR(paymentRef.current.isAcceptablePayment());
                  }}
                  customer={customer}
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  onChangeCustomer={onGetCustomer}
                  onCreateAR={onCreateAR}
                  arCreditLimit={Number(
                    settings.find(({name}) => name === 'ARCreditLimit')
                      ?.value || 0,
                  )}
                  ref={paymentRef}
                />
              )) ||
              (paymentType === 'PD' && customer && (
                <PDPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithPD(paymentRef.current.isAcceptablePayment());
                  }}
                  customer={customer}
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  onGetEmployee={onGetEmployee}
                  onSearchEligibility={onSearchEligibility}
                  isOnline={isOnline}
                  paymentTenders={paymentTenders}
                  ref={paymentRef}
                />
              )) ||
              (paymentType === 'UDP' && (
                <UDPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithUDP(paymentRef.current.isAcceptablePayment());
                  }}
                  udps={mapUDP(paymentTenders, paymentOptions)}
                  amount={amount}
                  setAmount={setAmount}
                  setPaymentTypeId={setPaymentTypeId}
                  totalDue={totalDue}
                  ref={paymentRef}
                />
              )) || (
                <Stack justify="center" align="center" style={{flex: 1}}>
                  <IndicatorCircle
                    variant="success"
                    icon="CashRegister"
                    size="large"
                  />
                  <Text variant={TV.T2} align="center">
                    Please Select Payment Type
                  </Text>
                </Stack>
              )}

            {totalDue > 0 || awaitingContinue ? (
              <FooterGroup>
                <Button
                  type="button"
                  data-testid="back-button"
                  variant={BV.Secondary}
                  disabled={!!processedPayments.length}
                  flex
                  onClick={() => {
                    const {
                      showCounsel,
                      showHipaa,
                      showRelationship,
                    } = mapComplianceIndicators(invoice, settings);
                    if (showCounsel || showHipaa || showRelationship) {
                      return navigate(
                        '/sales/transactions/payments/compliance',
                      );
                    }

                    const {
                      isNplex,
                      isControlledSubstance,
                      isRequiredAge,
                    } = mapIdCheckIndicators(invoice);
                    if (
                      (isNplex && invoice.pseCheckResult !== 1) ||
                      isControlledSubstance ||
                      isRequiredAge
                    ) {
                      return navigate('/sales/transactions/payments/id-check');
                    }

                    // time for animation to complete, then navigate
                    setTimeout(() => navigate('/sales/transactions'), 400);
                  }}
                >
                  Back
                </Button>
                <Button
                  flex
                  disabled={isConfirmPaymentDisabled}
                  onClick={onConfirmPayment}
                >
                  Confirm Payment
                </Button>
              </FooterGroup>
            ) : (
              <FooterGroup>
                <Button
                  flex
                  onClick={() =>
                    navigate(
                      signatureTypes.length
                        ? '../acknowledgements'
                        : '../receipts',
                    )
                  }
                >
                  {signatureTypes.length
                    ? 'Customer Signature'
                    : 'Receipt Delivery'}
                </Button>
              </FooterGroup>
            )}
          </Stack>

          <RightSidebar data-testid="Sidebar">
            <PaymentOptions
              paymentOptions={mapPaymentOptions(paymentOptions)}
              customerPaymentsAvailable={!!customer}
              disabled={showingPaidView}
              activePaymentType={paymentType}
              setPaymentType={_setPaymentType}
            />
          </RightSidebar>
        </Row>
      </Stack>

      <Modal
        data-testid="Modal__ChangeDue"
        visible={changeDueOpen}
        icon="Cash"
        color="success"
        onContinue={addPayment}
        buttonText="Okay"
        title={`Change Due: $${formatMoney(amount - totalDue)}`}
        subtitle="Please provide change to the customer. Amount can be viewed again in the payment details."
      />
    </>
  );
}
