import React from 'react';
import styled, {css, keyframes} from 'styled-components';
import {LogosHilo} from './';
import Text, {Variant} from './Text';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(7, 35, 47, 0.15);
  }
  100% {
    box-shadow: 0 0 0 24px rgba(0, 0, 0, 0);
  }
`;

const animation = () =>
  css`
    ${pulse} 2s infinite
  `;

const IntermissionWrapper = styled.div`
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: ${props => props.theme.colors.gray_100};
  flex-direction: column;
`;

const IntermissionCircle = styled.div<{error: boolean}>`
  align-items: center;
  animation: ${({error}) => (error ? 'none' : animation)};
  background: ${({theme}) =>
    `linear-gradient(135deg, ${theme.colors.blue}, ${theme.colors.purple} 70%)`}; // more purple than theme.colors.celestial
  border: 1px solid ${props => props.theme.colors.gray_200};
  border-radius: 50%;
  display: flex;
  height: 200px;
  justify-content: center;
  max-width: 200px;
  width: 200px;
`;

const IntermissionError = styled.div`
  border-radius: 8px;
  background-color: ${props => props.theme.colors.error};
  margin-top: 20px;
  padding: 4px 8px;

  > .light {
    color: ${props => props.theme.colors.white};
  }
`;

export type IntermissionProps = {
  error?: string;
};

export function Intermission(props: IntermissionProps) {
  return (
    <IntermissionWrapper>
      <IntermissionCircle error={Boolean(props.error)}>
        <LogosHilo.LogoMarkWhite height="120" />
      </IntermissionCircle>
      {props.error && (
        <IntermissionError>
          <Text variant={Variant.T3} className="light">
            {props.error}
          </Text>
        </IntermissionError>
      )}
    </IntermissionWrapper>
  );
}
