import {
  BarcodeCollectionApiResponse,
  BarcodesApi,
  OtcItemListApiResponse,
  OtcItemsApi,
  PaymentOptionListApiResponse,
  PaymentOptionsApi,
  PaymentTypeListApiResponse,
  PaymentTypesApi,
  RelationshipListApiResponse,
  RelationshipsApi,
  SettingListApiResponse,
  SettingsApi,
  SignatureType,
  SignatureTypesApi,
  TaxGroupListApiResponse,
  TaxGroupsApi,
} from '@emporos/api-enterprise/src/gen';
import useOpenApi from '../api/useOpenApi';
import {navigate} from '@reach/router';
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useSession from '../hooks/useSession';
import {useAuthentication} from './AuthenticationProvider';
import {useTransactionsState} from './TransactionsStateProvider';

interface GlobalDataProviderProps {
  loading: boolean;
  isError: boolean;
  barcodesResult: BarcodeCollectionApiResponse | void;
  otcItemsResult: OtcItemListApiResponse | void;
  taxGroupsResult: TaxGroupListApiResponse | void;
  settingsResult: SettingListApiResponse | void;
  relationshipsResult: RelationshipListApiResponse | void;
  paymentTendersResult: PaymentTypeListApiResponse | void;
  paymentOptionsResult: PaymentOptionListApiResponse | void;
  signatureTypesResult: SignatureType[] | void;
  pmsName: string;
  barcodeName: string;
  thirdPartySiteNumber: string;
}

// TODO temporary hardcoded
const PMS_NAME = 'McKesson';
const BARCODE_NAME = 'Standard1DEmporos';
const THIRD_PARTY_SITE_NUMBER = '104';

const GlobalDataContext = createContext<GlobalDataProviderProps>({
  loading: false,
  isError: false,
  barcodesResult: undefined,
  otcItemsResult: undefined,
  taxGroupsResult: undefined,
  settingsResult: undefined,
  relationshipsResult: undefined,
  paymentTendersResult: undefined,
  paymentOptionsResult: undefined,
  signatureTypesResult: undefined,
  pmsName: PMS_NAME,
  barcodeName: BARCODE_NAME,
  thirdPartySiteNumber: THIRD_PARTY_SITE_NUMBER,
});

function GlobalDataProvider({children}: {children: ReactNode}): ReactElement {
  const {
    session,
    setCurrentInvoiceId,
    setSelectedPayment,
  } = useTransactionsState();
  const {user} = useAuthentication();
  const sessionRef = useRef<string | null>(null);
  const {pendingInvoices} = useSession();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const {siteId, stationId} = session;
  const {} = useMemo(() => ({}), []);
  const {
    loading: loadingBarcodes,
    error: errorBarcodes,
    data: barcodesResult,
  } = useOpenApi(BarcodesApi, 'clientBarcodesGet', []);
  const {
    loading: loadingOtcItems,
    error: errorOtcItems,
    data: otcItemsResult,
  } = useOpenApi(OtcItemsApi, 'clientOtcItemsSiteIdGet', [
    {
      siteId,
    },
  ]);
  const {
    loading: loadingTaxGroups,
    error: errorTaxGroups,
    data: taxGroupsResult,
  } = useOpenApi(TaxGroupsApi, 'clientTaxGroupsGet', [{}]);
  const {
    loading: loadingSettings,
    error: errorSettings,
    data: settingsResult,
  } = useOpenApi(SettingsApi, 'clientSettingsGet', [
    {
      siteId,
      stationId,
    },
  ]);
  const {
    loading: loadingRelationships,
    error: errorRelationships,
    data: relationshipsResult,
  } = useOpenApi(RelationshipsApi, 'clientRelationshipsGet', [{}]);
  const {
    loading: loadingPaymentTenders,
    error: errorPaymentTenders,
    data: paymentTendersResult,
  } = useOpenApi(PaymentTypesApi, 'clientPaymentTendersGet', [{}]);
  const {
    loading: loadingPaymentOptions,
    error: errorPaymentOptions,
    data: paymentOptionsResult,
  } = useOpenApi(PaymentOptionsApi, 'clientPaymentOptionsGet', [{}]);
  const {
    loading: loadingSignatureTypes,
    error: errorSignatureTypes,
    data: signatureTypesResult,
  } = useOpenApi(SignatureTypesApi, 'clientSignaturesTypesGet', []);

  useEffect(() => {
    setLoading(
      loadingBarcodes ||
        loadingOtcItems ||
        loadingTaxGroups ||
        loadingSettings ||
        loadingRelationships ||
        loadingPaymentTenders ||
        loadingSignatureTypes ||
        loadingPaymentOptions,
    );
  }, [
    loadingBarcodes,
    loadingOtcItems,
    loadingTaxGroups,
    loadingSettings,
    loadingRelationships,
    loadingPaymentTenders,
    loadingPaymentOptions,
    loadingSignatureTypes,
  ]);

  useEffect(() => {
    setIsError(
      !!errorBarcodes ||
        !!errorOtcItems ||
        !!errorTaxGroups ||
        !!errorSettings ||
        !!errorRelationships ||
        !!errorPaymentTenders ||
        !!errorPaymentOptions ||
        !!errorSignatureTypes,
    );
  }, [
    errorBarcodes,
    errorOtcItems,
    errorTaxGroups,
    errorSettings,
    errorRelationships,
    errorPaymentTenders,
    errorPaymentOptions,
    errorSignatureTypes,
  ]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (
      session &&
      sessionRef.current !== session.sessionId &&
      !loading &&
      !isError &&
      barcodesResult
    ) {
      sessionRef.current = session.sessionId;
      const invoiceWithPayment = pendingInvoices.find(
        ({payments}) => payments.length,
      );
      if (invoiceWithPayment) {
        setCurrentInvoiceId(invoiceWithPayment.invoiceId);
        const pendingPayment = invoiceWithPayment.payments.find(
          ({recordStatus}) => ['OverCharged', 'Pending'].includes(recordStatus),
        );
        if (pendingPayment) {
          setSelectedPayment(pendingPayment.invoicePaymentId);
          navigate(
            '/sales/transactions/payments/customer-payment/payment-info',
            {
              replace: true,
            },
          );
        } else {
          navigate('/sales/transactions/payments/customer-payment', {
            replace: true,
          });
        }
      }
    }
  }, [user, session, sessionRef, loading, isError]);

  return (
    <GlobalDataContext.Provider
      value={{
        loading,
        isError,
        barcodesResult,
        otcItemsResult,
        taxGroupsResult,
        settingsResult,
        relationshipsResult,
        paymentTendersResult,
        signatureTypesResult,
        paymentOptionsResult,
        pmsName: PMS_NAME,
        barcodeName: BARCODE_NAME,
        thirdPartySiteNumber: THIRD_PARTY_SITE_NUMBER,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
}
const useGlobalData = (): GlobalDataProviderProps =>
  useContext(GlobalDataContext);
export {GlobalDataProvider, useGlobalData};
