import React, {memo} from 'react';
import styled from 'styled-components';
import {ColorType, Gutter, Icons, IndicatorCircle, Stack} from './';
import Text, {Intent, Variant} from './Text';

interface Props {
  icon: keyof typeof Icons;
  color?: ColorType;
  title: string;
  subtitle?: string;
  dateTime?: string;
}

const Card = styled(Stack)`
  display: inline-flex;
  padding: 24px;
  height: 240px;
  min-width: 225px;
  background-color: ${({theme}) => theme.colors.gray_100};
  border-radius: 12px;
  border: 2px solid ${({theme}) => theme.colors.gray_200};
`;

const Subtitle = styled(Text)`
  color: ${({theme}) => theme.colors.steel};
`;

export const PaymentCard = memo(
  ({
    icon,
    color,
    title,
    subtitle,
    dateTime,
    ...props
  }: Props & React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    return (
      <Card gutter={Gutter.S} align="center" {...props}>
        <IndicatorCircle variant={color} icon={icon} size="large" />
        <Text
          variant={Variant.T3}
          intent={title.includes('Void') && Intent.Alert}
        >
          {title}
        </Text>
        {subtitle && <Subtitle variant={Variant.Main}>{subtitle}</Subtitle>}
        {dateTime && <Subtitle variant={Variant.Main}>{dateTime}</Subtitle>}
      </Card>
    );
  },
);
