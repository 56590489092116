import {
  CashDrawer,
  Peripheral,
  Site,
  Station,
} from '@emporos/api-enterprise/src/gen';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Row,
  ScrollContainer,
  Select,
  Stack,
  toCurrency,
  Variant,
} from '@emporos/components';
import TextInput, {Size} from '@emporos/components/src/TextInput';
import {memo, useState} from 'react';
import {GenericStruct} from './__tests__/testdata';

export const NONE_PERIPHERAL: Peripheral = {
  hostName: 'None',
  name: 'None',
  peripheralId: -1,
  peripheralTypeId: 0,
  siteId: 0,
  ipV4Address: 'None',
};

type FormState = {
  site: Site;
  station: Station;
  paymentDevice: Peripheral;
  till: CashDrawer;
  tillStartingAmount: number;
};

type FormStateWithSite = Pick<FormState, 'site'> &
  {[K in keyof Omit<FormState, 'site'>]: null};
type FormStateWithStation = Pick<FormState, 'site' | 'station'> &
  {
    [K in keyof Omit<FormState, 'site' | 'station'>]:
      | Omit<FormState, 'site' | 'station'>[K]
      | null;
  };
export type SessionConfig =
  | {[K in keyof FormState]: null}
  | FormStateWithSite
  | FormStateWithStation;

export function isSessionConfigCompleted(
  config: SessionConfig,
): config is FormState {
  return (
    config.site !== null &&
    config.station !== null &&
    config.till !== null &&
    config.paymentDevice !== null &&
    config.tillStartingAmount !== null
  );
}

interface Props {
  sites: Site[];
  stations: Station[];
  peripherals: Peripheral[];
  tills: CashDrawer[];
  config: SessionConfig;
  configSession?: boolean;
  loading: boolean;
  onChange: (config: SessionConfig) => void;
  onLogout?: () => void;
  onConfirm?: () => void;
}
function getTitle({name}: GenericStruct): string {
  return name;
}

function CreateSession({
  sites,
  stations,
  peripherals,
  tills,
  config,
  configSession = false,
  onChange,
  onLogout,
  onConfirm,
  loading,
}: Props): JSX.Element {
  const {site, station, paymentDevice, till} = config;
  const [tillStartingAmount, setTillStartingAmount] = useState('');
  const paymentDevices = [
    NONE_PERIPHERAL,
    ...peripherals.filter(
      ({peripheralType}) => peripheralType?.description === 'Payment Device',
    ),
  ];
  const confirmDisabled = !isSessionConfigCompleted(config);
  const disableField = configSession || !site;

  return (
    <Stack>
      {!configSession && <Header title="Create Session" />}
      <ScrollContainer>
        <Stack
          gutter={Gutter.L}
          style={{flex: 1, marginTop: 6, paddingTop: configSession ? 16 : 0}}
        >
          <Row>
            <Select
              disabled={configSession}
              name="site"
              label="Site*"
              options={sites.map(({id}) => id)}
              optionsText={sites.map(s => s.shortName)}
              value={site?.id || null}
              onChangeValue={value => {
                const site = sites.find(({id}) => id === value);
                if (site && !configSession) {
                  setTillStartingAmount('');
                  onChange({
                    site,
                    station: null,
                    paymentDevice: null,
                    till: null,
                    tillStartingAmount: null,
                  });
                }
              }}
            />
            <Select
              disabled={disableField}
              name="station"
              label="Station*"
              options={stations.map(({id}) => id)}
              optionsText={stations.map(getTitle)}
              value={station?.id || null}
              onChangeValue={value => {
                const station = stations.find(({id}) => id === value);
                if (station) {
                  onChange({
                    site: config.site as Site,
                    station,
                    paymentDevice: config.paymentDevice,
                    till: config.till,
                    tillStartingAmount: config.tillStartingAmount,
                  });
                }
              }}
            />
          </Row>
          <Row>
            <Select
              disabled={disableField}
              name="till"
              label="Till*"
              options={tills.map(({cashDrawerId}) => cashDrawerId)}
              optionsText={tills.map(
                ({cashDrawerName}) => cashDrawerName || '',
              )}
              value={till?.cashDrawerId || null}
              onChangeValue={value => {
                const till = tills.find(
                  ({cashDrawerId}) => cashDrawerId === value,
                );
                if (till) {
                  onChange({
                    site: config.site as Site,
                    station: config.station as Station,
                    paymentDevice: config.paymentDevice,
                    till,
                    tillStartingAmount: config.tillStartingAmount,
                  });
                }
              }}
            />
            <TextInput
              disabled={disableField}
              name="tillStartingAmount"
              label="Till Starting Amount*"
              value={
                configSession && config.tillStartingAmount
                  ? `$${toCurrency(config.tillStartingAmount, '.')}`
                  : tillStartingAmount
              }
              onFocus={() => {
                if (!tillStartingAmount) {
                  setTillStartingAmount('$0.00');
                  onChange({
                    site: config.site as Site,
                    station: config.station as Station,
                    paymentDevice: config.paymentDevice,
                    till: config.till,
                    tillStartingAmount: 0,
                  });
                }
              }}
              onChange={value => {
                const valueAsCurrency = toCurrency(value.target.value, '.');
                setTillStartingAmount(`$${valueAsCurrency}`);
                onChange({
                  site: config.site as Site,
                  station: config.station as Station,
                  paymentDevice: config.paymentDevice,
                  till: config.till,
                  tillStartingAmount: Number(valueAsCurrency),
                });
              }}
              onClear={() => setTillStartingAmount('')}
              style={{flex: '1'}}
              inputSize={Size.Large}
              inputMode="numeric"
            />
          </Row>
          <Row>
            <Select
              name="payment"
              label="Payment Device*"
              options={paymentDevices.map(({peripheralId}) => peripheralId)}
              optionsText={paymentDevices.map(({name}) => name || '')}
              value={paymentDevice?.peripheralId || null}
              disabled={!site}
              onChangeValue={value => {
                const peripheral = [NONE_PERIPHERAL, ...peripherals].find(
                  ({peripheralId}) => peripheralId === value,
                );
                if (peripheral) {
                  onChange({
                    site: config.site as Site,
                    station: config.station as Station,
                    paymentDevice: peripheral,
                    till: config.till,
                    tillStartingAmount: config.tillStartingAmount,
                  });
                }
              }}
            />
            <TextInput
              disabled={
                !site || paymentDevice === NONE_PERIPHERAL || !paymentDevice
              }
              name="ipAddress"
              label="IP Address*"
              value={config.paymentDevice?.ipV4Address || ''}
              onChange={value =>
                onChange({
                  site: config.site as Site,
                  station: config.station as Station,
                  paymentDevice: {
                    ...(config.paymentDevice as Peripheral),
                    ipV4Address: value.target.value,
                  },
                  till: config.till,
                  tillStartingAmount: config.tillStartingAmount,
                })
              }
              style={{flex: '1'}}
              inputSize={Size.Large}
            />
          </Row>
        </Stack>
      </ScrollContainer>
      {!configSession && onLogout && onConfirm && (
        <FooterGroup>
          <Button variant={Variant.Danger} flex onClick={onLogout}>
            Logout
          </Button>
          <Button
            flex
            disabled={confirmDisabled}
            onClick={confirmDisabled ? undefined : onConfirm}
            loading={loading}
          >
            Confirm
          </Button>
        </FooterGroup>
      )}
    </Stack>
  );
}

export default memo(CreateSession);
