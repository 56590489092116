import {PaymentsApi} from '@emporos/api-enterprise/src/gen';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Modal,
  Row,
  ScrollContainer,
  Stack,
  Variant,
} from '@emporos/components';
import TextInput from '@emporos/components/src/TextInput';
import {NavigateFn, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useCallback, useState} from 'react';
import useOpenApi from '../../../../../api/useOpenApi';
import {AuthClaim} from '../../../../../auth/const';
import {useAuthentication} from '../../../../../contexts/AuthenticationProvider';
import {useGlobalData} from '../../../../../contexts/GlobalDataProvider';
import {withChildPage} from '../../../../../hocs/withChildPage';
import useInvoice from '../../../../../hooks/useInvoice';
import useScript from '../../../../../hooks/useScript';
import useSession from '../../../../../hooks/useSession';
import {mapInvoicePayment} from '../../../../../utils/mappers';
import {getPaymentTypeIdByCardType} from '../utils';

export enum Status {
  Loading,
  Success,
  Partial,
  Error,
}

const HostedPaymentIntegration = withChildPage(
  ({
    navigate,
    location,
  }: RouteComponentProps<{
    location: {
      state: {
        amount: number;
      };
    };
  }>): JSX.Element => {
    const [validationErrors, setValidationErrors] = useState<
      Array<ValidationError>
    >([]);
    const [status, setStatus] = useState<Status | null>(null);
    const [amountApproved, setAmountApproved] = useState(0);
    const {settingsResult, paymentTendersResult} = useGlobalData();
    const {siteId} = useSession();
    const {
      invoice: {invoiceId, qhpAmount, serverTransactionID},
      updateInvoice,
    } = useInvoice();
    const {user} = useAuthentication();
    const userId = user?.profile[AuthClaim.UserId] || '';
    const {run: postPayment} = useOpenApi(
      PaymentsApi,
      'clientPaymentChargeTokenizedPost',
    );

    assert(navigate, 'Missing navigate');
    assert(location, 'Missing location');
    assert(settingsResult, 'Missing settingsResult');

    const webApiKey = settingsResult.data?.find(
      ({name}) => name === 'GPIGeniusCheckoutWebApiKey',
    )?.value;
    // inject external script in react
    useScript(
      'https://ecommerce.merchantware.net/v1/CayanCheckoutPlus.js',
      () => {
        if (webApiKey) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          CayanCheckoutPlus.setWebApiKey(webApiKey);
        }
      },
    );

    const successCallback = async (tokenResponse: {token: string}) => {
      assert(
        paymentTendersResult && paymentTendersResult.data,
        'Missing paymentTendersResult',
      );

      try {
        const result = await postPayment({
          paymentVaultRequest: {
            siteId,
            credentialType: 'ECOM',
            token: tokenResponse.token,
            qhpAmount:
              qhpAmount > location.state.amount
                ? location.state.amount
                : qhpAmount,
            totalAmount: location.state.amount,
            transactionId: String(serverTransactionID),
            terminalId: '',
            userId: userId,
            purchaseOrderNumber: invoiceId,
          },
        });
        assert(result.data, 'Missing result data');

        const {
          amountApproved,
          accountNumber,
          status,
          referenceNumber,
          rawResponse,
        } = result.data;
        setAmountApproved(amountApproved);
        const paymentTypeId = getPaymentTypeIdByCardType(
          JSON.parse(rawResponse).CardType,
          paymentTendersResult.data,
        );
        if (status === 'Approved') {
          if (amountApproved < location.state.amount) {
            setStatus(Status.Partial);
          } else {
            setStatus(Status.Success);
          }
          updateInvoice(prevInvoice => ({
            payments: prevInvoice.payments.concat(
              mapInvoicePayment(invoiceId, paymentTypeId || 0, amountApproved, {
                paymentNumber: accountNumber.substr(-4),
                epsReference: referenceNumber,
                jsonPaymentProcessorResponse: rawResponse,
              }),
            ),
          }));
        } else {
          setStatus(Status.Error);
        }
      } catch (e) {
        setStatus(Status.Error);
      }
    };

    const failureCallback = (errors: Array<ValidationError>) => {
      setStatus(null);
      setValidationErrors(errors);
    };

    const onSubmit = useCallback(() => {
      setStatus(Status.Loading);
      setValidationErrors([]);
      // validates the form
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      CayanCheckoutPlus.createPaymentToken({
        success: successCallback,
        error: failureCallback,
      });
    }, []);

    const resetStatus = useCallback(() => {
      setStatus(null);
    }, []);

    return (
      <HostedPayment
        amount={location.state.amount}
        amountApproved={amountApproved}
        resetStatus={resetStatus}
        errors={validationErrors}
        status={status}
        onSubmit={onSubmit}
        navigate={navigate}
      />
    );
  },
);

export interface ValidationError {
  reason: string;
}

interface Props {
  amount: number;
  amountApproved: number;
  errors: Array<ValidationError>;
  status: Status | null;
  onSubmit: () => void;
  resetStatus: () => void;
  navigate: NavigateFn;
}

export const HostedPayment = ({
  amount,
  amountApproved,
  errors,
  status,
  onSubmit,
  resetStatus,
  navigate,
}: Props): JSX.Element => {
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiration, setExpiration] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');

  const onContinue = () => navigate('../');

  const reset = () => {
    resetStatus();
    setCardNumber('');
    setCvv('');
    setExpiration('');
    setExpirationMonth('');
    setExpirationYear('');
  };

  return (
    <ScrollContainer>
      <Stack justify="space-between" style={{height: '100%'}}>
        <Stack gutter={Gutter.L}>
          <Header title={`Payment Portal: $${amount.toFixed(2)}`} />
          <Row style={{flex: 1, display: 'flex'}}>
            <TextInput
              label="Card number"
              value={cardNumber}
              onChange={e => setCardNumber(e.target.value)}
              data-cayan="cardnumber"
              style={{flex: 5}}
              isError={errors.some(({reason}) => reason === 'cardnumber')}
            />
            <TextInput
              label="CVV"
              value={cvv}
              onChange={e => setCvv(e.target.value)}
              data-cayan="cvv"
              style={{flex: 2}}
              isError={errors.some(({reason}) => reason === 'cvv')}
            />
            <TextInput
              label="Expiration"
              value={expiration}
              onChange={e => {
                setExpiration(e.target.value);
                const expirationDate = e.target.value.split('/');
                setExpirationMonth(expirationDate[0]);
                setExpirationYear(expirationDate[1]);
              }}
              mask="99/99"
              inputMode="numeric"
              style={{flex: 3}}
              isError={errors.some(({reason}) =>
                [
                  'expirationmonth',
                  'expirationyear',
                  'expirationdate',
                ].includes(reason),
              )}
            />
            <input
              type="hidden"
              value={expirationMonth}
              data-cayan="expirationmonth"
            />
            <input
              type="hidden"
              value={expirationYear}
              data-cayan="expirationyear"
            />
          </Row>
        </Stack>
        <FooterGroup>
          <Button
            style={{flex: 1}}
            onClick={() => navigate('../')}
            variant={Variant.Danger}
          >
            Cancel
          </Button>
          <Button
            style={{flex: 1}}
            onClick={onSubmit}
            loading={status === Status.Loading}
          >
            Submit
          </Button>
        </FooterGroup>
      </Stack>
      <Modal
        data-testid="Modal__CheckPayment"
        visible={status === Status.Loading}
        icon="CreditCard"
        color="primary"
        title="Checking Payment Status"
        subtitle="Establishing connection with the gateway to verify status of payment."
        iconSpinning={true}
      />
      <Modal
        data-testid="Modal__PaymentSuccess"
        visible={status === Status.Success}
        icon="Checkmark"
        color="success"
        onContinue={onContinue}
        buttonText="Close"
        title="Payment Successful"
        subtitle="The card payment has been processed. Please remove card and give back to customer."
      />
      <Modal
        data-testid="Modal__PartialPaymentSuccess"
        visible={status === Status.Partial}
        icon="Checkmark"
        color="success"
        onContinue={onContinue}
        buttonText="Close"
        title="Partial Payment Successful"
        subtitle={
          'A partial payment has been processed. $' +
          amountApproved +
          ' was successfully applied.'
        }
      />
      <Modal
        data-testid="Modal__PaymentError"
        visible={status === Status.Error}
        icon="Warning"
        color="warning"
        onCancel={reset}
        onContinue={reset}
        buttonText="Retry"
        title="Payment Error"
        subtitle="Transaction could not be processed. Please use another card or try again."
      />
    </ScrollContainer>
  );
};

export default memo(HostedPaymentIntegration);
