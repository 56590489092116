import {Invoice} from '@emporos/api-enterprise/src/gen-session';
import {
  SessionUpdates,
  useTransactionsState,
} from '../contexts/TransactionsStateProvider';
import {OfflineInvoice, OfflineSynced} from '../api/common';
import {orderBy} from 'lodash';
import {filterDeletedInvoiceChildren} from './utils';

const useSession = (): {
  siteId: number;
  pendingInvoices: Array<Invoice>;
  completedInvoices: Array<Invoice>;
  updateSession: (updates: SessionUpdates) => void;
  canClose: boolean;
  canCreateInvoice: boolean;
} => {
  const {session, setSession} = useTransactionsState();
  const invoices = session.invoices.filter(
    invoice => !(invoice as OfflineSynced).isDeleted,
  );

  const siteId = session.siteId;
  const pendingInvoices = orderBy(
    invoices
      .filter(
        invoice =>
          !(invoice as OfflineInvoice).isCompleted && invoice.status !== 2,
      )
      .map(filterDeletedInvoiceChildren),
    ['saleDate'],
    ['desc'],
  );
  const completedInvoices = orderBy(
    invoices
      .filter(
        invoice =>
          (invoice as OfflineInvoice).isCompleted || invoice.status === 2,
      )
      .map(filterDeletedInvoiceChildren),
    ['saleDate'],
    ['desc'],
  );
  const canClose = !invoices.some(invoice => invoice.status !== 2);
  const canCreateInvoice = !pendingInvoices.some(
    invoice => !invoice.customer && !invoice.items.length,
  );

  const updateSession = (updates: SessionUpdates) => {
    if (typeof updates === 'function') {
      setSession(prevSession => ({
        ...prevSession,
        triggerSync: true,
        ...updates(session),
      }));
    } else {
      setSession(prevSession => ({
        ...prevSession,
        triggerSync: true,
        ...updates,
      }));
    }
  };

  return {
    siteId,
    pendingInvoices,
    completedInvoices,
    updateSession,
    canClose,
    canCreateInvoice,
  };
};

export default useSession;
